import {Grid, Typography} from "@mui/material";
import {TextItem} from "../TextItem";
import Box from "@mui/material/Box";
import RenderUserRedirect from "../cellRender/RenderUserRedirect";
import {unixToString} from "../../services/helper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ConfirmIconButton from "../buttons/ConfirmIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import StartIconButton from "../buttons/StartIconButton";
import RedoIcon from "@mui/icons-material/Redo";
import BaseModal from "./BaseModal";
import React from "react";
import {useTranslation} from "react-i18next";
import StatusIndicator from "../Statusindicator";

export default function EditFountainModal({open, onClose, fountain, onDelete, onRestore}) {
    const {t} = useTranslation();
    return (<BaseModal open={open} onClose={onClose}>
        <Grid container direction={'column'} spacing={2} alignItems="center" sx={{mt:1, minHeight:'10em', minWidth:'10em'}} >
            {
                fountain.uid &&
                <TextItem
                    color={'primary'}
                    label={t('creator')}
                    value={
                        <Box sx={{
                            '& button': {color: 'secondary.light'},
                            '& button:hover': {color: 'secondary.main'}
                        }}>
                            <RenderUserRedirect value={fountain.uid} url={fountain.uid}
                                                showUsername/>
                        </Box>
                    }
                />
            }
            {
                fountain.createdAt &&
                <TextItem
                    color={'primary'}
                    label={t('creationDate')}
                    value={unixToString(fountain.createdAt)}
                />
            }
            <Grid>
                <StatusIndicator isActive={!fountain.deleted}/>
            </Grid>
            <Grid item>
                <ConfirmIconButton
                    onConfirm={() => {
                        onDelete(fountain);
                        onClose();
                    }}
                    title={t('confirmDeletion')}
                    text={t('requestDefinitiveEliminationFountain')}
                >
                    <DeleteIcon/>
                </ConfirmIconButton>
            </Grid>
            {
                fountain.deleted &&
                <>
                    <Grid item>
                        <StartIconButton
                            onClick={() => {
                                onRestore(fountain);
                                onClose();
                            }}
                            title={t('restore')}
                            startIcon={<RedoIcon/>} color={'secondary'} selected/>
                    </Grid>
                    <TextItem
                        color={'light'}
                        label={t('deletedBy')}
                        value={
                            <Box sx={{
                                '& button': {color: 'secondary.light'},
                                '& button:hover': {color: 'secondary.main'}
                            }}>
                                <RenderUserRedirect value={fountain.deletedBy} url={fountain.deletedBy}
                                                    showUsername/>
                            </Box>
                        }
                    />
                    <TextItem
                        color={'primary'}
                        label={t('deletedAt')}
                        value={unixToString(fountain.deletedAt)}
                    />
                </>
            }
        </Grid>
    </BaseModal>);
}