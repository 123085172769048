import {Delete, Email} from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Button, IconButton, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import {useSnackbar} from "notistack";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {getGenderCodeValue} from "../../constants/genderCode";
import {resources} from "../../services/ability";
import {AbilityContext} from "../../services/Can";
import {getErrorMessage, MESSAGES, post} from "../../services/Client";
import {formatAddress} from "../../services/helper";
import StartIconButton from "../buttons/StartIconButton";
import RenderBoolean from "../cellRender/RenderBoolean";
import RenderCell from "../cellRender/RenderCell";
import RenderEditableUsername from "../cellRender/RenderEditableUsername";
import RenderUserAddressHistory from "../cellRender/RenderUserAddressHistory";
import SelectUsersByEmailModal from "../modals/SelectUsersByEmailModal";
import SendMessageModal from "../modals/SendMessageModal";
import NTMXGrid, {timestampFormatter, timestampFormatterWithTimezone} from "../NTMXGrid";

export default function UserTable({
	                                  users = [],
	                                  status,
	                                  saveEdit,
	                                  showSerialNumber,
	                                  showIban,
	                                  customColumns,
	                                  showOrganizationName,
	                                  rightButton,
	                                  allowEditEmail,
	                                  sendEmailValidation,
	                                  deleteUser,
	                                  title,
	                                  showActivationDate,
	                                  selectUsersByEmailVisible = false
                                  }) {

	const {t} = useTranslation();
	const ability = useContext(AbilityContext);
	const canReadPrivateData = ability.can("read", resources.PRIVATEUSERDATA);
	const {enqueueSnackbar} = useSnackbar();
	let [selectedRows, setSelectedRows] = useState([]);
	let [isSendNotificationModalOpen, setIsSendNotificationModalOpen] = useState(false);
	let [targetsToSendMessage, setTargetsToSendMessage] = useState([]);
	let [isSendingMessageToSpecificUsers, setIsSendingMessageToSpecificUsers] = useState(false);

	const sendMessage = (values) => {
		enqueueSnackbar(t('sending...'), {variant: "info"});
		// console.log(selectedRows)
		const uids = getSendMessageModalTarget().map(u => u.uid);
		values.sendTo = uids;

		post(`${MESSAGES}/send`, {body: values})
			.then(() => {
				setTargetsToSendMessage([]);
				setIsSendNotificationModalOpen(false);
				enqueueSnackbar(t('sent'), {variant: "success"});
			})
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
	};

	const sendMessageToUsersList = (usersList) => {
		setTargetsToSendMessage(users.filter(u => usersList.includes(u.email) || usersList.includes(u.uid)));
		setIsSendNotificationModalOpen(true);
	};

	const columns = [
		{
			headerName: t('userID'),
			field: 'uid',
			width: 280,
		},
		showActivationDate && {
			headerName: t('activationDate'),
			field: 'activationDate',
			width: 280,
			...timestampFormatterWithTimezone
		},
		showActivationDate && {
			headerName: t('activeEnrollment'),
			field: 'endDate',
			width: 180,
			type: "boolean",
			valueGetter: ({value}) => dayjs(value).isAfter(dayjs(new Date())),
			renderCell: (params) => <RenderBoolean params={params}></RenderBoolean>
		},
		{
			headerName: t('email'),
			field: 'email',
			width: 220,
			renderCell: allowEditEmail ? (params) =>
					<RenderCell
						params={params}
						saveEdit={(id, field, value) => saveEdit(params.row.uid, field, value)}
					/>
				: undefined
		},
		{
			headerName: t('phoneNumber'),
			field: 'phoneNumber',
			width: 170,
			//hide: true,
			renderCell: (params) => <RenderCell params={params}
			                                    saveEdit={(id, field, value) => saveEdit(params.row.uid, field, value)}/>
		},
		{
			headerName: t('birthDate'),
			field: 'birthDate_date',
			width: 180,
			//hide: true,
			...timestampFormatter
		},
		{
			headerName: t('username'),
			field: 'username',
			width: 130,
			renderCell: (params) => <RenderEditableUsername params={params} saveEdit={saveEdit} key={params.row.uid}/>
			/*renderCell: (params) => <RenderUserUidRedirect username={params.value} uid={params.row.uid}/>*/
		},
		{
			headerName: t('firstName'),
			field: 'firstName',
			width: 130,
			renderCell: (params) => <RenderCell params={params}
			                                    saveEdit={(id, field, value) => saveEdit(params.row.uid, field, value)}/>
		},
		{
			headerName: t('lastName'),
			field: "lastName",
			width: 130,
			renderCell: (params) => <RenderCell params={params}
			                                    saveEdit={(id, field, value) => saveEdit(params.row.uid, field, value)}/>
		},
		{
			headerName: t('gender'),
			field: "genderCode",
			valueFormatter: (params) => t(getGenderCodeValue(params.value)),
			width: 130,
			//hide: true
		},
		{
			headerName: t('fiscalCode'),
			field: 'fiscalCode',
			width: 180,
			//hide: true,
			renderCell: (params) => <RenderCell params={params}
			                                    saveEdit={(id, field, value) => saveEdit(params.row.uid, field, value)}/>
		},
		showSerialNumber && {
			headerName: t('serialNumber'),
			field: 'serialNumber',
			width: 220,
			//hide: true,
			renderCell: (params) => <RenderCell params={params}
			                                    saveEdit={(id, field, value) => saveEdit(params.row.uid, field, value)}/>
		},
		showIban && {
			headerName: t('iban'),
			field: 'iban',
			width: 220,
			//hide: true,
			renderCell: (params) => <RenderCell params={params}
			                                    saveEdit={(id, field, value) => saveEdit(params.row.uid, field, value)}/>
		},
		{
			headerName: 'Cluster',
			field: 'customField',
			width: 180,
		},
		canReadPrivateData && {
			headerName: t('homeAddress'),
			field: 'homeAddresses',
			width: 250,
			valueFormatter: ({value}) => value ? value.map(a => `${formatAddress(a)} (${a.latitude}-${a.longitude})`).join('\n') : "",
			renderCell: ({value, colDef}) => value && <RenderUserAddressHistory addresses={value} width={colDef.width}/>
		},
		canReadPrivateData && {
			headerName: t('workAddresses'),
			field: 'workAddresses',
			width: 250,
			//hide: true,
			valueFormatter: ({value}) => value ? value.map(a => `${formatAddress(a)} (${a.latitude}-${a.longitude})`).join('\n') : "",
			renderCell: ({value, colDef}) => value && <RenderUserAddressHistory addresses={value} width={colDef.width}
			                                                                    showOrganizationName={showOrganizationName}/>
		},
		canReadPrivateData && {
			field: "lastLoggedIn",
			headerName: t('lastLoggedIn'),
			width: 280,
			...timestampFormatterWithTimezone,
			//hide: true
		},
		{
			headerName: t('details'),
			field: "details",
			width: 150,
			sortable: false,
			disableColumnMenu: true,
			disableExport: true,
			hideable: false,
			renderCell: (params) => <Grid container direction={"row"} justifyContent="center">
				<Grid item><Link to={"/profile/" + params.row.uid}
				                 target="_blank"><IconButton><VisibilityIcon/></IconButton></Link></Grid>
				{
					deleteUser &&
					<Grid item><IconButton onClick={() => deleteUser(params.row)}><Delete/></IconButton></Grid>
				}
				{
					sendEmailValidation &&
					<Grid item>
						<Tooltip title={t('sendEmailValidation')}>
							<IconButton
								onClick={() => sendEmailValidation(params.row.uid)}
							>
								<Email/>
							</IconButton>
						</Tooltip>
					</Grid>
				}
			</Grid>
		}
	].filter(Boolean);

	if(customColumns) columns.push(...customColumns);
	users = users.slice().sort((a, b) => (b.lastLoggedIn || 0) - (a.lastLoggedIn || 0));

	const getSendMessageModalTarget = () => {
		if(targetsToSendMessage.length !== 0) return targetsToSendMessage;
		if(selectedRows.length === 0) return users;

		let target = users.filter(u => selectedRows.includes(u.activationDate ? u.uid + u.activationDate : u.uid));
		return [...new Map(target.map(t => [t.uid, t])).values()];
	};

	return <>
		<NTMXGrid
			checkboxSelection
			columns={columns}
			rows={users || {}}
			title={title ?? t('users')}
			getRowId={(row) => users && (row.activationDate ? row.uid + row.activationDate : row.uid)}
			loading={status === "loading"}
			onSelectionModelChange={(newSelection) => {
				setSelectedRows(newSelection);
			}}
			selectionModel={selectedRows}
			rightButton={
				<Grid container justifyContent={"flex-end"}>
					{
						rightButton
					}
					<Button
						color="primary"
						startIcon={<SendIcon/>}
						disabled={selectedRows.length === 0}
						onClick={() => setIsSendNotificationModalOpen(true)}
					>
						{t('sendNotification')}
					</Button>
					{selectUsersByEmailVisible &&
						<StartIconButton onClick={() => setIsSendingMessageToSpecificUsers(true)}
						                 title={t('sendMessageToUsers')} startIcon={<SendIcon/>}/>}
				</Grid>
			}
			initialState={{pinnedColumns: {right: ['details']}}}
		/>

		{isSendNotificationModalOpen &&
			<SendMessageModal
				targets={getSendMessageModalTarget()}
				open={!!isSendNotificationModalOpen}
				onClose={() => {
					setIsSendNotificationModalOpen(false);
					setTargetsToSendMessage([]);
				}}
				onSave={sendMessage}/>
		}

		{selectUsersByEmailVisible &&
			<SelectUsersByEmailModal
				open={!!isSendingMessageToSpecificUsers}
				onClose={() => setIsSendingMessageToSpecificUsers(false)}
				onSave={sendMessageToUsersList}/>
		}

	</>;

}
