import {useParams} from "react-router-dom";
import {useGetOrganizationCustomField,useGetOrganizationRanks} from "../../services/ContentManager";
import RanksManager from "../RanksManager";


export default function OrganizationRanksManager(){

    let {id} = useParams();
    let {ranks = [], status} = useGetOrganizationRanks(id);
    let {customField = []} = useGetOrganizationCustomField(id);

    return <RanksManager ranks={ranks} loading={status === "loading"} organizationId={id} customField={customField}/>
}