import BaseModal from "./BaseModal";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import {CircularProgress,Divider,ListItem,ListItemAvatar,ListItemText} from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const useStyles = makeStyles((theme) => ({
    preview : {
        color : theme.palette.primary.light,
        "white-space" : "pre-line",
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(5),
        maxHeight: "34rem"
    },
    title: {
        paddingLeft: theme.spacing(1),
        fontWeight: "bold"
    },
    listItemText:{
        fontSize:'1.3rem',
    },
    circleProgress: {
        color : theme.palette.primary.light,
    }
}));

export default function ShowEditSessionsStatus({open, onClose, values}) {

    let classes = useStyles();

    return <BaseModal open={open} onClose={onClose}>
        <Grid container className={classes.preview} direction="column">
            <Grid item xs>
                <Typography gutterBottom variant="h4" className={classes.title}>
                    Sessione: stato modifica
                </Typography>
                <List>
                    {
                        Object.keys(values).map(k => {
                            return <>
                                <ListItem key={k}>
                                    <ListItemText
                                        primary={values[k] !== "loading" && values[k] !== "saved" ? k + ": " + values[k] : k}
                                        classes={{primary:classes.listItemText}}/>
                                    {
                                        values[k] === "loading" &&
                                        <ListItemAvatar>
                                            &nbsp; &nbsp;
                                            <CircularProgress color="primary" size="2rem" className={classes.circleProgress}/>
                                        </ListItemAvatar>
                                    }
                                    {
                                        values[k] === "saved" &&
                                        <ListItemAvatar>
                                            &nbsp; &nbsp;
                                            <CheckCircleIcon sx={{fontSize : "2rem"}}/>
                                        </ListItemAvatar>
                                    }
                                </ListItem>
                                <Divider variant="inset" component="li" sx={{margin: 0}}/>
                            </>
                        })
                    }
                </List>
            </Grid>
        </Grid>
    </BaseModal>
}