import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {get, getErrorMessage, post, USERS} from "../services/Client";
import Grid from "@mui/material/Grid";
import {CircularProgress, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import NTMXGrid from "../components/NTMXGrid";
import {useTranslation} from "react-i18next";

export function Revolut(){

	const {t} = useTranslation();
	let [counterparties, setCounterparties] = useState([]);
	let [loading, setLoading] = useState(false);
	const {enqueueSnackbar} = useSnackbar();

	useEffect(getCounterparties, [])

	function getCounterparties() {
		get("revolut/counterparties").then(data => setCounterparties(data.map(c => {
			return {
				...c.accounts[0],
				id:c.id
			}
		})))
	}

	function upload(file, type) {
		let formData = new FormData();

		formData.append("csvfile", file);
		formData.append("type", type)
		setLoading(true)

		post("revolut/massive", {body:formData, header:{"Content-Type": "multipart/form-data"}}).then(response => {
			enqueueSnackbar(t('finished'), {variant: "success"})
			const blob = new Blob([response.data], {type: response.data.type});
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			let fileName = t('outcome') + '.csv';
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
			window.URL.revokeObjectURL(url);
		})
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
			.finally(() => setLoading(false));

	}

	return <Paper>
		<Grid container>
			<Grid item xs={12}>
				<NTMXGrid
					key={"revolut-table"}
					columns={[
						{
							title: t('id'),
							field: "id"
						},
						{
							title: t('name'),
							field: "name"
						},
						{
							title: t('iban'),
							field: "iban"
						},
						{
							title: t('bic'),
							field: "bic"
						},
						{
							title: t('email'),
							field: "email"
						},
					]}
					rows={counterparties}
				/>
			</Grid>
			<Grid item container
			      direction="row"
			      justifyContent="space-evenly"
			      alignItems="center"
				style={{margin:"1rem"}}
			>
				{
					loading ?
						<CircularProgress/> :
						<>
							<div>
								<input
									accept="text/csv"
									style={{display:"none"}}
									id="counterparties-file"
									type="file"
									onChange={event => upload(event.target.files[0], "c")}
								/>
								<label htmlFor="counterparties-file">
									<Button variant="contained" color="primary" component="span">
										{t('uploadCounterparts')}
									</Button>
								</label>
							</div>
							<div>
								<input
									accept="text/csv"
									style={{display:"none"}}
									id="payments-file"
									type="file"
									onChange={event => upload(event.target.files[0], "p")}
								/>
								<label htmlFor="payments-file">
									<Button variant="contained" color="primary" component="span">
										{t('uploadPayments')}
									</Button>
								</label>
							</div>
						</>
				}

			</Grid>
		</Grid>
	</Paper>
}
