import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {Redirect, useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {useGetOfficeArticles, useGetOrganizationOffices} from "../../services/ContentManager";
import useGlobalStyles from "../../services/useGlobalStyles";
import React, {useEffect, useState} from "react";
import CircularLoading from "../../components/CircularLoading";
import {removeImagesFirebase} from "../../services/FirebaseManager";
import FormBuilder from "../../components/FormBuilder";
import SelectArticleModal from "../../components/modals/SelectArticleModal";
import {FORM_TYPE} from "../../constants/FormBuilder";
import {ARTICLES, post, put, get} from "../../services/Client";
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {Album, ArrowBack} from "@mui/icons-material";
import {MULT_VALUE_TO_POINT} from "../../constants/articles";
import {isEmpty, unixToString} from "../../services/helper";
import {useGetErrorMessages} from "../../constants/errorMessages";

export default function OfficeArticle({oid, id, goBack}) {
    const globalClasses = useGlobalStyles();
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();
    let history = useHistory();
    const {t} = useTranslation();
    const errorMessage = useGetErrorMessages();

    let {articles, status} = useGetOfficeArticles(oid);
    let {offices, status: statusOffices} = useGetOrganizationOffices(oid);
    const [openCopyModal, setOpenCopyModal] = useState(false);
    const [images, setImages] = useState({
        imagesToDelete: [],
        addedImages: []
    });

    const [article, setArticle] = useState({});
    useEffect(() => {
        let newArticle = {//valori di default
            isExpire: false,
            isNumbered: false,
            visible: true,
            expirationDate: new dayjs().valueOf()
        };
        if (id && status === 'success') {
            newArticle = articles.find(a => a.id === id);
            newArticle.isExpire = !!newArticle.expirationDate;//indica se l'articolo ha una data di scadenza, non viene salvato il booleano su db
            newArticle.isNumbered = !!newArticle.numberArticles; //indica se l'articolo ha un numero massimo di elementi
            if (!newArticle.expirationDate) newArticle.expirationDate = new dayjs().valueOf();
        }
        setArticle(newArticle);
    }, [status]);

    //attendi che siano caricati
    if (status === 'loading' || statusOffices === 'loading')
        return <CircularLoading/>;

    function save(values, stopLoading) {
        if (!values.isExpire) {
            values.expirationDate = null;
        } else {
            values.expirationDate = dayjs(values.expirationDate).add(dayjs.duration({'days': 1})).valueOf()//todo:nel salvataggio le date si modificano
        }
        if (!values.isNumbered) {
            values.numberArticles = null;
        }
        //lo shop associato è quello primario
        let primaryOffice = offices.find(o => o.isPrimary);
        values.shopId = primaryOffice.id;
        values.shops = [primaryOffice.id];

        //se e' nuovo allora non avra' un id quindi sara' una post
        (values.id ? put : post)(ARTICLES, {
            body: values,
            elem: values.id || ''
        })
            .then((saved) => {
                //rimuovi le immagini definitivamente da firebase
                removeImagesFirebase(images.imagesToDelete);
                queryClient.invalidateQueries([ARTICLES, {oid: oid}]).then(() => handleGoBack());
                stopLoading();
                enqueueSnackbar(t('prizeSaved'), {variant: 'success'});
            })
            .catch(() => {
                stopLoading();
                enqueueSnackbar(t('errorSavingData'), {variant: "error"})
            });
    }

    function onError() {
        enqueueSnackbar(t('errorSavingData'), {variant: "error"})
    }

    function handleCopyFromArticle() {
        setOpenCopyModal(true);
    }

    function copyArticle(article) {
        let newArticle = {...article}
        //cancella dati che generano conflitti
        delete newArticle.id;

        setArticle((article) => ({...article, ...newArticle}));
    }

    async function handleDeleteImage(image, index) {
        let isDeletable = false
        try {
            isDeletable = await get(`${ARTICLES}/verifyImageBeforeDelete`, {
                params: {
                    updatingArticleId: article.id,
                    image: image
                }
            })
            if (isDeletable) {//se l'immagine che sto eliminando è uguale a quella del def Shop vuol dire che ho inserito i dati di default e quindi non devo eliminare la foto da firebase
                let newImages = {...images};
                newImages.imagesToDelete.push(image);//eliminare alla fine solo quando salva
            }
            enqueueSnackbar(t('imageDeleted'), {variant: 'success'});
        } catch (e) {
            enqueueSnackbar(t('errorOccurred'), {variant: "error"});
        }
        return isDeletable;
    }

    const handleAddImage = (image) => {
        let newImages = {...images};
        newImages.addedImages.push(image);
        setImages(newImages);
        return true;
    }

    const handleGoBack = async () => {
        await queryClient.invalidateQueries([ARTICLES, {oid: oid}]);
        goBack();
    }

    const fields = [
        {
            key: 'goBack',
            type: FORM_TYPE.customElement,
            customElement: <>
                <Grid container justifyContent="space-between" className={globalClasses.marginBottom}>
                    <Button
                        onClick={(e) => {
                            removeImagesFirebase(images.addedImages);//rimuovi le immagini quando si va indietro
                            handleGoBack(e);
                        }}
                        className={globalClasses.backButton}
                    >
                        <ArrowBack className={globalClasses.backButtonIcon}/>
                        {t('goBack')}
                    </Button>
                </Grid>
            </>
        },
        {
            key: 'copyFromArticle',
            type: FORM_TYPE.customElement,
            customElement: <>
                {
                    (articles && articles.length > 0) &&
                    <Grid container justifyContent="space-between" className={globalClasses.marginBottom}>
                        <Grid item>
                            <Button
                                onClick={handleCopyFromArticle}
                                className={globalClasses.backButton}
                            >
                                <Album className={globalClasses.backButtonIcon}/>
                                {t('copyDataFromOtherArticle')}
                            </Button>
                        </Grid>
                    </Grid>
                }
            </>
        }, {
            key: 'title',
            label: t('title'),
            type: FORM_TYPE.textInput,
        },
        {
            key: 'description',
            label: t('description'),
            type: FORM_TYPE.textInput,
        },
        {
            key: 'points',
            label: t('pointsValue'),
            type: FORM_TYPE.textInput,
            params: {
                type: "number"
            },
        },
        {
            key: 'spacer',
            type: FORM_TYPE.customElement,
            customElement: <Grid md={6} xs={0}/>,
        },
        {
            key: 'isExpire',
            type: FORM_TYPE.switchContainer,
            label: t('canExpire'),
            params: {
                isInnerElementVisible: (isExpire) => isExpire
            },
            customFields: [
                {
                    key: 'expirationDate',
                    label: t('expireDate'),
                    type: FORM_TYPE.dateInput,
                    params: {
                        min: unixToString(new Date()),
                    },
                    xs: 12,
                    md: 12,
                    validation: data => !(data.isExpire && isEmpty(data.expirationDate)),
                },
            ]
        },
        {
            key: 'isNumbered',
            type: FORM_TYPE.switchContainer,
            label: t('hasALimit'),
            params: {
                isInnerElementVisible: (isNumbered) => isNumbered
            },
            customFields: [
                {
                    key: 'numberArticles',
                    label: t('numberOfElements'),
                    type: FORM_TYPE.textInput,
                    params: {
                        type: "number",
                        min: article.redeemNumber
                    },
                    xs: 12,
                    md: 12,
                    validation: data => {
                        if (data.isNumbered) {
                            if (isEmpty(data.numberArticles) || data.numberArticles < 0)
                                return errorMessage.MANDATORY;
                            if ((data.numberArticles || 0) - data.redeemNumber < 0)
                                return `${t('minimumNumberItemError')} ${data.redeemNumber}`;
                        }
                    },
                },
                {
                    key: 'rest',
                    label: t('rest'),
                    type: FORM_TYPE.textItem,
                    customValue: (data) => (data.numberArticles || 0) - (data.redeemNumber || 0),
                    required: false,
                },
            ]
        },
        {
            key: 'image',
            label: t('articleImage'),
            type: FORM_TYPE.singleImage,
            params: {
                folder: 'articles/images',
                onDeleteImage: handleDeleteImage,
                onAddImage: handleAddImage
            },
        },
        {
            key: 'visible',
            label: (data) => data.visible ? t('awardIsVisible') : t('awardIsNotVisible'),
            type: FORM_TYPE.button,
            params: (data, setData) => ({
                onClick: () => setData({...data, visible: !data.visible}),
                className: data.visible ? globalClasses.colorGreen : globalClasses.colorRed
            }),
            customElement: (data) => data.visible ? t("activeAward") : t("notActiveAward")

        }
    ];

    return (
        <>
            <FormBuilder fields={fields} onSubmit={save} onError={onError} formData={article} setFormData={setArticle}/>
            <SelectArticleModal onSubmit={copyArticle} onClose={() => setOpenCopyModal(false)} open={openCopyModal}
                                articles={articles} currentArticleId={article.id}/>
        </>
    );
}