import {useGetShopByPublicLink, useGetShops, useGetShopVerified} from "../../../services/ContentManager";
import CouponRedeemSection from "../../../components/VendorComponents/CouponRedeemSection";
import React, {useState} from "react";
import {Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import { Redirect } from "react-router-dom";
import {useParams} from "react-router-dom";
import CircularLoading from "../../../components/CircularLoading";

/**
 * Mostra la schermata di riscatto del codice
 * @param uid se settato mostra la lista di possibili shop che possono fare la redeem
 * @param auto prende gli id dall' url ignorando uid
 * @returns {JSX.Element}
 * @constructor
 */
export default function CouponRedeem({uid, auto = false}) {
    let params = useParams();//prendo i dati dall'url
    let publicLink

    if (auto) {
        publicLink = params.publicLink;
    }

    let {shops, status: statusShops} = useGetShops(uid, !auto);// carica gli shop dall' uid se non è auto
    let {shop, status: statusShop} = useGetShopByPublicLink(publicLink, auto);//se in auto carica il singolo shop dato nell' url verificando che appartenga al vendor
    const [isCouponOpen, setIsCouponOpen] = useState(false);//nascondere tutto se si apre la schermata dei coupon
    const {t} = useTranslation();

    if (statusShops === 'loading' || statusShop === 'loading') return <CircularLoading/>;

    if (auto && !shop.id) {//se lo shop non è stato trovato allora è un url errato
        return <Redirect exact to="/unutorized"/>;
    }

    return (
        <Grid container direction={'column'}>
            <Grid item>
                <CouponRedeemSection
                    shop={auto && shop}
                    shops={shops}
                    onCouponDetailsOpen={setIsCouponOpen}
                />
            </Grid>
            {
                !isCouponOpen &&
                <Grid item>
                    <Grid container direction="row">
                        <Grid item xs={12}>
                            <h2 style={{marginBottom: '1rem', textAlign: 'center'}}>
                                <b>{t('whatAwardsAndVoucherAre')}</b></h2>
                        </Grid>
                        <Grid item xs={12}>
                            <p style={{marginBottom: '1rem'}}>
                                <b>{t('awardsInCatalog')}:</b> {t('awardsInCatalogDescription')}
                            </p>
                        </Grid>
                        <Grid item xs={12}>
                            <p>
                                <b>{t('voucher')}:</b> {t('voucherDescription')}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}
