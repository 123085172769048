import React, {useState} from "react";
import { FormControl, Grid, Typography, Box, CssBaseline, Paper } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FormNewVendor from "./FormNewVendor";
import LanguageSelect from "../../../components/LanguageSelect";
import { Link } from "../../../../node_modules/react-router-dom/index";
import AlghoBot from "components/VendorComponents/AlghoBot";

import AutocompleteAddress from "components/AutocompleteAddress";
import { isEmpty } from "services/helper";
import { useGetErrorMessages } from "constants/errorMessages";
import { Avatar } from "../../../../node_modules/@mui/material/index";

import logo from "../../../images/pinbike-logo-animato.gif";

export default function NewVendor() {
    const theme = useTheme();
    let { t, i18n } = useTranslation();
    let [showForm, setShowForm] = useState(false);

    const errorMessages = useGetErrorMessages();
    const [data, setData] = useState({ firstShop: {} });
    const [errors, setErrors] = useState({});
    const [placesService] = useState(new window.google.maps.places.PlacesService(document.createElement('div')));

    const whiteLinkStyle = {
        color: 'white'
    };
    const blackLinkStyle = {
        color: '#290e08'
    };

    function placeToDto(place) {
        const dto = place.address_components.reduce((acc, component) => {
            if (component.types.includes('route')) {
                acc.address = component.long_name;
            } else if (component.types.includes('street_number')) {
                acc.number = component.long_name;
            } else if (component.types.includes('locality')) {
                acc.city = component.long_name;
            } else if (component.types.includes('postal_code')) {
                acc.postalCode = component.long_name.replace(/\s/g, '');
            } else if (component.types.includes('administrative_area_level_2')) {
                acc.province = component.short_name;
            } else if (component.types.includes('country')) {
                acc.country = component.long_name;
            }
            return acc;
        }, {});

        dto.name = place.name;

        dto.latitude = place.geometry.location.lat();
        dto.longitude = place.geometry.location.lng();

        if (place.international_phone_number) {
            const phoneNumberParts = place.international_phone_number.split(' ');
            dto.phonePrefix = phoneNumberParts[0];
            dto.phone = phoneNumberParts.slice(1).join(' ').replace(/\s/g, '');
        }
        return dto;
    }

    function onAddressChange(address, placeId, key) {
        setErrors((error) => ({ ...error, address: null }));
        if (isEmpty(address)) {
            setErrors((error) => ({ ...error, key: errorMessages.MANDATORY }));
            return;
        }
        searchCoordinates(placeId, key);
    }

    function searchCoordinates(placeId, key) {
        let sessionToken = new window.google.maps.places.AutocompleteSessionToken();

        placesService.getDetails({ placeId, sessionToken }, (place) => {
            if (!place) {
                setErrors((error) => ({ ...error, [key]: t('internalServerError') }));
                return;
            }
            if (place) {
                console.log(place);
                const dto = placeToDto(place);
                setData(data => ({
                    ...data,
                    businessName: dto.name,
                    phonePrefix: dto.phonePrefix,
                    phone: dto.phone,
                    address: dto.address,
                    number: dto.number,
                    city: dto.city,
                    postalCode: dto.postalCode,
                    province: dto.province,
                    country: dto.country,
                    firstShop: {
                        ...data.firstShop,
                        name: dto.name,
                        latitude: dto.latitude,
                        longitude: dto.longitude,
                        addressInput: dto.address,
                        address: dto.address,
                        number: dto.postalCode,
                        city: dto.city,
                    }
                }));
                console.log(data);
                goToNewVendorForm();
            }
        });
    }

    function SearchPlaceComponent(props) {
        return (
            <Box {...props}>
                <AutocompleteAddress
                    label={t('newVendorInput')}
                    value={data.addressInput || null}
                    error={errors && errors.addressInput}
                    onAddressInputChange={(address) => { }}
                    onAddressSelect={(address, placeId) => onAddressChange(address, placeId, 'addressInput')}
                />
            </Box>
        );
    }

    function goToNewVendorForm() {
        setShowForm(true);
    }

    function GoToLogin(props) {
        return (
            <>
                <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                    {t('alreadyPinbiker')}{' '}
                </Typography>
                <Typography variant="body1" align="center">
                    <Link to="login" style={blackLinkStyle}>
                        {t('loginToRegister')}
                    </Link>
                </Typography>
            </>
        );
    }

    function Copyright(props) {
        return (
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                <a href="https://pin.bike/" target="_blank" rel="noopener noreferrer">
                    FB Innovation
                </a>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    }

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid item xs={12} sm={12} md={4} lg={5} xl={6}
                sx={{
                    backgroundImage: 'url(/new-vendor.jpeg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) => `rgba(255, 255, 255, 0.7)`, // Imposta la trasparenza qui
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '192px',
                }}
            />
            <Grid item xs={12} sm={12} md={8} lg={7} xl={6} component={Paper} elevation={6} square sx={{
                [theme.breakpoints.up('md')]: {
                    maxHeight: '100vh',
                    overflowY: 'auto',
                },
            }}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        py: 4,
                        px: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                        height: 'calc(100% - 128px)',
                    }}
                >
                    <a href="https://pin.bike/" target="_blank" rel="noopener noreferrer">
                        <img height={80} alt={"home"} src={logo} />
                    </a>
                    {!showForm ? <>

                        <Box component="form" noValidate sx={{ position: 'relative', maxWidth: '600px' }}>
                            <Typography component="h1" variant="h5" sx={{ mt: 4, fontWeight: 'bold', textAlign: 'center' }}>
                                {t('newVendorTitle1')}
                            </Typography>
                            <Typography component="h1" variant="h5" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>
                                {t('newVendorTitle2')}
                            </Typography>

                            <Box key='bp-1' sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                                <Avatar sx={{ marginRight: '8px', height: '24px', width: '24px', bgcolor: '#e14d43' }}>1</Avatar>
                                <Typography variant="body2">
                                    {t('newVendorPoint1')}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    borderRadius: '16px',
                                    padding: '16px',
                                    marginTop: '16px',
                                    marginBottom: 4,
                                    backgroundColor: '#e14d43',
                                    color: 'white',
                                }}
                            >
                                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                                    {t('newVendorFindYourShop')}
                                </Typography>
                                <SearchPlaceComponent sx={{ mt: 1 }} />
                                <Typography variant="h6" sx={{ mt: 1, textAlign: 'center' }}>
                                    <Link to="#" style={whiteLinkStyle} onClick={() => { goToNewVendorForm() }}>
                                        {t('newVendorShopNotFound')}
                                    </Link>
                                </Typography>
                                <GoToLogin />
                            </Box>

                            <Box key='bp-2' sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                                <Avatar sx={{ marginRight: '8px', height: '24px', width: '24px', bgcolor: '#e14d43' }}>2</Avatar>
                                <Typography variant="body2">
                                    {t('newVendorPoint2')}
                                </Typography>
                            </Box>
                            <Box key='bp-3' sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                                <Avatar sx={{ marginRight: '8px', height: '24px', width: '24px', bgcolor: '#e14d43' }}>3</Avatar>
                                <Typography variant="body2">
                                    {t('newVendorPoint3')}
                                </Typography>
                            </Box>
                            <Box key='bp-4' sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                                <Avatar sx={{ marginRight: '8px', height: '24px', width: '24px', bgcolor: '#e14d43' }}>4</Avatar>
                                <Typography variant="body2">
                                    {t('newVendorPoint4')}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Copyright sx={{ mt: 6, mb: 4 }} />
                        </Box>
                    </>
                        :
                        <FormNewVendor errorMessages={errorMessages} data={data} setData={setData}
                            errors={errors} setErrors={setErrors} placesService={placesService}
                            goBack={() => setShowForm(false)} />}
                    <Box sx={
                        {
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}>
                        <FormControl sx={
                            {
                                minWidth: 120,
                                backgroundColor: '#fff',
                            }
                        } size="small">
                            <LanguageSelect value={i18n.language} onChange={({ target }) => i18n.changeLanguage(target.value)} color='black' />
                        </FormControl>
                    </Box>
                </Box>
            </Grid>
            <AlghoBot />
        </Grid>
    );
}
