import { useTranslation } from "react-i18next";
import { LEG_TYPE, legTypesEndings, useGetLegTypeTranslation } from "../../../constants/legType";


export function useUrbanPointsSettingsFields(values, setValues, error, setError, onError) {
    const { t } = useTranslation();

    return [
        {
            type: "switch",
            name: "isActiveUrbanPoints",
            label: t('activeUrbanPoints'),
            control: String(values.isActiveUrbanPoints) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                delete newValues.startDateUrbanPoints
                delete newValues.endDateUrbanPoints
                delete newValues.multiplier
                delete newValues.homeWorkPointsTolerance
                delete newValues.isActiveDateTimeSlotMultiplier
                delete newValues.endDateBonus
                delete newValues.startDateBonus
                delete newValues.endTimeBonus
                delete newValues.startTimeBonus
                setValues(newValues);
                onError(false);
                setError({});
            },
            xs: 12
        },
        {
            type: "date",
            name: "startDateUrbanPoints",
            label: t('startDateUrbanPoints'),
            condition1: values.isActiveUrbanPoints + "" === "true",
            xs: 6,
        },
        {
            type: "date",
            name: "endDateUrbanPoints",
            label: t('endDateUrbanPoints'),
            condition1: values.isActiveUrbanPoints + "" === "true",
            xs: 6,
        },
        {
            type: "number",
            name: "multiplier",
            label: t('multiplier'),
            condition1: values.isActiveUrbanPoints + "" === "true",
            startAdornment: "x",
            min: 0,
            required: true,
            step: 0.01,
            xs: 6,
        },
        {
            type: "number",
            name: "homeWorkPointsTolerance",
            label: t('homeWorkPointsTolerance'),
            condition1: values.isActiveUrbanPoints + "" === "true",
            startAdornment: t('meters'),
            min: 0,
            required: true,
            tooltip: t('homeWorkPointsToleranceExplain'),
            xs: 6,
        },
        {
            type: "switch",
            name: "isActiveDateTimeSlotMultiplier",
            label: t('multiplierValid'),
            control: String(values.isActiveDateTimeSlotMultiplier) === "true",
            condition1: values.isActiveUrbanPoints + "" === "true",
            onChange: (event) => {
                setValues({ ...values, [event.target.name]: event.target.checked })
                delete values.startDateBonus
                delete values.endDateBonus
                delete values.startTimeBonus
                delete values.endTimeBonus
                delete error["startDateBonus"]
                delete error["endDateBonus"]
                delete error["startTimeBonus"]
                delete error["endTimeBonus"]
                if (Object.keys(error).length === 0) onError(false)
            },
            xs: 12
        },
        {
            type: "date",
            name: "startDateBonus",
            label: t('startDateBonus'),
            condition1: values.isActiveUrbanPoints + "" === "true",
            condition2: values.isActiveDateTimeSlotMultiplier + "" === "true",
            required: true,
            xs: 6,
        },
        {
            type: "date",
            name: "endDateBonus",
            label: t('endDateBonus'),
            condition1: values.isActiveUrbanPoints + "" === "true",
            condition2: values.isActiveDateTimeSlotMultiplier + "" === "true",
            required: true,
            xs: 6,
        }
    ]
}

export function useCouponsExpirationDateSettingsFields(values, setValues, error, setError, onError) {
    const { t } = useTranslation();

    return [
        {
            type: "date",
            name: "couponExpireDate",
            label: t('couponsExpirationDate'),
            xs: 6,
        }
    ]
}

export function useRefundsFields(values, setValues) {
    const { t } = useTranslation();

    return [
        {
            type: "radio",
            label: t('typeThresholdForRefund'),
            name: "typeThresholdForRefund",
            options: [
                {
                    value: "km",
                    label: t('km')
                },
                {
                    value: "euro",
                    label: t('euro')
                }
            ],
            xs: 6,
        },
        {
            type: "number",
            name: "minThresholdForRefund",
            label: t('minThresholdForRefund'),
            startAdornment: values.typeThresholdForRefund === "euro" ? t('currency') : t('km'),
            min: 0,
            step: values.typeThresholdForRefund === "euro" ? 0.01 : 1,
            required: true,
            tooltip: values.typeThresholdForRefund === "euro" ? t('minThresholdForRefundExplainEuro') : t('minThresholdForRefundExplainKm'),
            xs: 6,
        },
        {
            type: "radio",
            label: t('couponRefundType'),
            name: "couponRefundType",
            options: [
                {
                    value: "esercenti",
                    label: t('vendors')
                },
                {
                    value: "pa",
                    label: t('publicAdministration')
                }
            ],
            xs: 12,
        },
        {
            type: "switch",
            name: "ibanRequirement",
            label: t('ibanRequired'),
            control: String(values.ibanRequirement) === "true",
            onChange: (event) => {
                setValues({ ...values, [event.target.name]: event.target.checked })
            },
            xs: 12
        },
        {
            type: "switch",
            name: "serialNumberRequirement",
            label: "Richiedi Matricola Pinbiker",
            control: String(values.serialNumberRequirement) === "true",
            onChange: (event) => {
                setValues({ ...values, [event.target.name]: event.target.checked })
            },
            xs: 12
        },
        {
            type: "switch",
            name: "fiscalCodeRequirement",
            label: "Richiedi Codice Fiscale",
            control: String(values.fiscalCodeRequirement) === "true",
            onChange: (event) => {
                setValues({ ...values, [event.target.name]: event.target.checked })
            },
            xs: 12
        },
        {
            type: "number",
            name: "minUrbanPointsForRefund",
            label: t('minUrbanPointsForRefund'),
            startAdornment: t('points'),
            min: 0,
            tooltip: t('minUrbanPointsForRefundExplain'),
            xs: 6,
        },
        {
            type: "number",
            name: "euroMaxRefundInADay",
            label: t('euroMaxRefundInADay'),
            startAdornment: t('currency'),
            min: 0,
            step: 0.01,
            tooltip: t('valueNullMeaning'),
            xs: 6,
        },
        {
            type: "number",
            name: "euroMaxRefundInAMonth",
            label: t('euroMaxRefundInAMonth'),
            startAdornment: t('currency'),
            min: 0,
            step: 0.01,
            tooltip: t('valueNullMeaning'),
            xs: 6,
        },
        {
            type: "number",
            name: "euroMaxRefundInATime",
            label: t('euroMaxRefundInATime'),
            startAdornment: t('currency'),
            min: 0,
            step: 0.01,
            tooltip: t('valueNullMeaning'),
            xs: 6,
        },
    ]
}

export function useHomeWorkRefundsFields(values, setValues, setError, onError) {
    const { t } = useTranslation();

    return [
        {
            type: "switch",
            name: "isActiveHomeWorkRefunds",
            label: t('isActiveHomeWorkRefunds'),
            control: String(values.isActiveHomeWorkRefunds) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                delete newValues.isHomeAddressInUrbanAreaRequired
                delete newValues.isWorkAddressInUrbanAreaRequired
                delete newValues.isHomeOrWorkAddressInUrbanAreaRequired
                delete newValues.dailyLimitHomeWorkSessions
                delete newValues.emailAddressApprovalManager
                event.target.checked ? newValues.homeWorkRefundType = "urbanPoints" : delete newValues.homeWorkRefundType
                delete newValues.euroMaxRefundInADay
                delete newValues.euroMaxRefundInAMonth
                delete newValues.euroMaxRefundInATime
                delete newValues.valueKmHomeWorkBike
                delete newValues.valueKmHomeWorkElectricBike
                delete newValues.homeWorkPathTolerancePerc
                setValues(newValues);
                onError(false);
                setError({});
            }
        },
        {
            type: "switch",
            name: "isHomeAddressInUrbanAreaRequired",
            label: t('isHomeAddressInUrbanAreaRequired'),
            control: String(values.isHomeAddressInUrbanAreaRequired) === "true",
            condition1: values.isActiveHomeWorkRefunds + "" === "true",
            onChange: (event) => {
                setValues({ ...values, [event.target.name]: event.target.checked })
            },
            xs: 12
        },
        {
            type: "switch",
            name: "isWorkAddressInUrbanAreaRequired",
            label: t('isWorkAddressInUrbanAreaRequired'),
            control: String(values.isWorkAddressInUrbanAreaRequired) === "true",
            condition1: values.isActiveHomeWorkRefunds + "" === "true",
            onChange: (event) => {
                setValues({ ...values, [event.target.name]: event.target.checked })
            },
            xs: 12
        },
        {
            type: "switch",
            name: "isHomeOrWorkAddressInUrbanAreaRequired",
            label: t('isHomeOrWorkAddressInUrbanAreaRequired'),
            control: String(values.isHomeOrWorkAddressInUrbanAreaRequired) === "true",
            condition1: values.isActiveHomeWorkRefunds + "" === "true",
            onChange: (event) => {
                setValues({ ...values, [event.target.name]: event.target.checked })
            },
            xs: 12
        },
        {
            type: "switch",
            name: "addressValidation",
            label: t('addressValidationResponsible'),
            control: String(values.addressValidation) === "true",
            condition1: values.isActiveHomeWorkRefunds + "" === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                delete newValues.emailAddressApprovalManager
                setValues(newValues);
            }
        },
        {
            type: "text",
            name: "emailAddressApprovalManager",
            label: t('emailAddressApprovalManager'),
            condition1: values.isActiveHomeWorkRefunds + "" === "true",
            condition2: values.addressValidation + "" === "true",
            required: true,
            tooltip: t('emailAddressApprovalManagerExplain'),
            xs: 12,
        },
        {
            type: "radio",
            label: t('homeWorkRefundType'),
            name: "homeWorkRefundType",
            onChange: (newValue) => {
                let newValues = { ...values };
                newValues["homeWorkRefundType"] = newValue;
                setValues(newValues);
            },
            options: [{ value: "urbanPoints", label: t('initiativePoints') }, { value: "euro", label: t('euro') }],
            condition1: values.isActiveHomeWorkRefunds + "" === "true",
            xs: 12,
        },
        {
            type: "number",
            name: "dailyLimitHomeWorkSessions",
            label: t('dailyLimitHomeWorkSessions'),
            min: 0,
            step: 1,
            tooltip: t('dailyLimitHomeWorkSessions'),
            condition1: values.isActiveHomeWorkRefunds + "" === "true",
            xs: 12,
        },

    ]
}

export function useHomeWorkRefundsLegsFields(values, setValues, setError, onError) {
    const { t } = useTranslation();

    const legTranslation = useGetLegTypeTranslation();

    //ultilizzo il vettore di terminazioni per creare un vettore di impostazioni del tipo euroValueKmUrbanPathBike, ...
    let legsNamesFields = legTypesEndings.map(l => {
        l.namePerKm = 'valueKmHomeWork' + l.ending;
        l.namePerMinute = 'valueMinuteHomeWork' + l.ending;
        return l;
    });

    let fields = [];
    legsNamesFields.forEach(leg => {
        //se è public transport si può inserire il coefficiente per minuto o per km. Inserisco uno spazio per separare dagli altri elementi
        if (leg.id === LEG_TYPE.PUBLIC_TRANSPORT) {
            fields.push({
                type: "space",
                xs: 12,
                isSlim: true
            })
        }

        let unitTranslation = values["refundCoefficientTypeHomeWork" + leg.ending] === 'Minute' ? t("minute") : "Km";

        fields.push({
            type: "number",
            name: values["refundCoefficientTypeHomeWork" + leg.ending] === 'Minute' ?
                leg.namePerMinute
                :
                leg.namePerKm,
            label: legTranslation[leg.id],
            condition1: values.isActiveHomeWorkRefunds + "" === "true",
            startAdornment: values.homeWorkRefundType === "euro" ? t('currency') : t('points'),
            min: 0,
            required: true,
            step: values.homeWorkRefundType === "euro" ? 0.01 : 1,
            tooltip: values.homeWorkRefundType === "euro" ?
                t('valueKmHomeWorkBikeExplainEuro', { unit: unitTranslation })
                :
                t('valueKmHomeWorkBikeExplainPoints', { unit: unitTranslation }),
            xs: 6,
        });

        if (leg.id === LEG_TYPE.PUBLIC_TRANSPORT) {
            fields.push({
                type: "radio",
                label: `${t('coefficientType')} ${legTranslation[leg.id]}`,
                name: "refundCoefficientTypeHomeWorkPublicTransport",
                options: [
                    {
                        value: "Minute",
                        label: t('perTime')
                    },
                    {
                        value: "Km",
                        label: t('perKm')
                    }
                ],
                xs: 6,
            },);

            fields.push({
                type: "space",
                xs: 12,
            })
        }
    })

    fields.push(
        {
            type: "number",
            name: "homeWorkPathTolerancePerc",
            label: t('homeWorkPathTolerancePerc'),
            condition1: values.isActiveHomeWorkRefunds + "" === "true",
            startAdornment: "%",
            min: 0,
            required: true,
            tooltip: t('homeWorkPathTolerancePercExplain'),
            xs: 12,
            onChange: (value) => {
                let newValues = { ...values };
                if (value < 0) newValues["homeWorkPathTolerancePerc"] = 0;
                else newValues["homeWorkPathTolerancePerc"] = value;
                setValues(newValues);
            }
        });

    fields.push({
        type: "number",
        label: t('userWorkAddressesLimit') + ", " + t('userWorkAddressesLimitExplain'),
        name: "userWorkAddressesLimit",
        min: 1,
        required: true,
        tooltip: t('userWorkAddressesLimitExplain'),
        xs: 12,
    },);

    return fields;
}

export function useUrbanPathRefundsFields(values, setValues, setError, onError) {
    const { t } = useTranslation();

    return [
        {
            type: "switch",
            name: "isActiveUrbanPathRefunds",
            label: t('isActiveUrbanPathRefunds'),
            control: String(values.isActiveUrbanPathRefunds) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                delete newValues.euroValueKmUrbanPathBike
                delete newValues.euroValueKmUrbanPathElectricBike
                setValues(newValues);
                onError(false);
                setError({});
            }
        },
    ]
}

export function useUrbanPathRefundsLegsFields(values, setValues, setError, onError) {
    const { t } = useTranslation();

    const legTranslation = useGetLegTypeTranslation();

    //ultilizzo il vettore di terminazioni per creare un vettore di impostazioni del tipo euroValueKmUrbanPathBike, ...
    let legsNamesFields = legTypesEndings.map(l => {
        l.namePerKm = 'euroValueKmUrbanPath' + l.ending;
        l.namePerMinute = 'euroValueMinuteUrbanPath' + l.ending;
        return l;
    });

    let fields = [];
    legsNamesFields.forEach(leg => {

        //se è public transport si può inserire il coefficiente per minuto o per km. Inserisco uno spazio per separare dagli altri elementi
        if (leg.id === LEG_TYPE.PUBLIC_TRANSPORT) {
            fields.push({
                type: "space",
                xs: 12,
                isSlim: true
            })
        }

        let unitTranslation = values["refundCoefficientTypeUrbanPath" + leg.ending] === 'Minute' ? t("minute") : "Km";

        fields.push({
            type: "number",
            name: values["refundCoefficientTypeUrbanPath" + leg.ending] === 'Minute' ?
                leg.namePerMinute
                :
                leg.namePerKm,
            label: legTranslation[leg.id],
            condition1: values.isActiveUrbanPathRefunds + "" === "true",
            startAdornment: t('currency'),
            min: 0,
            step: 0.01,
            required: true,
            tooltip: t('euroValueKmUrbanPathBike', {unit:unitTranslation}),
            xs: 6,
        },)

        if (leg.id === LEG_TYPE.PUBLIC_TRANSPORT) {
            fields.push({
                type: "radio",
                label: `${t('coefficientType')} ${legTranslation[leg.id]}`,
                name: "refundCoefficientTypeUrbanPathPublicTransport",
                options: [
                    {
                        value: "Minute",
                        label: t('perTime')
                    },
                    {
                        value: "Km",
                        label: t('perKm')
                    }
                ],
                xs: 6,
            },);

            fields.push({
                type: "space",
                xs: 12,
            })
        }
    })

    return fields;
}

export function useCustomFieldManagerFields(values, setValues) {
    const { t } = useTranslation();

    return [
        {
            type: "switch",
            name: "exclusiveCustomField",
            label: t('exclusiveCustomField'),
            control: String(values.exclusiveCustomField) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                setValues(newValues);
            },
            xs: 12
        }
    ]
}

export function useGlobalRanksAchievementsManagerFields(values, setValues) {
    const { t } = useTranslation();

    return [
        {
            type: "switch",
            name: "canViewGlobalRanksAchievements",
            label: t('canViewGlobalRanksAchievements'),
            control: String(values.canViewGlobalRanksAchievements) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                setValues(newValues);
            },
            xs: 12
        }
    ]
}

export function useGeneralSettingsManagerFields(values, setValues) {
    const { t } = useTranslation();

    return [
        {
            type: "switch",
            name: "refundNotCertificatedSessions",
            label: t('refundNotCertificatedSessions'),
            control: String(values.refundNotCertificatedSessions) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                setValues(newValues);
            },
            xs: 12
        },
        {
            type: "switch",
            name: "isActiveMergingLegs",
            label: t('isActiveMergingLegs'),
            control: String(values.isActiveMergingLegs) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                setValues(newValues);
            },
            xs: 12
        },
        {
            type: "text",
            name: "uniqueInitiativeCode",
            label: t('uniqueInitiativeCode'),
            onChange: (target) => {
                let newValues = { ...values };
                newValues.uniqueInitiativeCode = target;
                setValues(newValues);
            },
            xs: 6,
        },
    ]
}

export function useVendorsManagerFields(values, setValues) {
    const { t } = useTranslation();

    return [
        {
            type: "switch",
            name: "hasVendorFunctionality",
            label: t('hasVendorFunctionality'),
            control: String(values.hasVendorFunctionality) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                setValues(newValues);
            },
            xs: 12
        },
        {
            type: "radio",
            name: "shopAssociationType",
            label: t('shopAssociation'),
            options: [
                {
                    label: t('manual'), value: "0",
                },
                {
                    label: t('automaticUrbanArea'), value: "1",
                },
                {
                    label: t('automaticIstatCode'), value: "2",
                },
            ],
            onChange: (event) => {
                let newValues = { ...values };
                newValues.shopAssociationType = event;
                setValues(newValues);
            },
            xs: 12
        }
    ]
}

export function useAwardLimitFields(values, setValues) {
    const { t } = useTranslation();

    return [
        {
            type: "switch",
            name: "hasAwardRankingLimit",
            label: t('rankingAwardLimit'),
            control: String(values.hasAwardRankingLimit) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                if (String(event.target.checked) !== "true") {
                    newValues.awardRankingLimit = null;
                } else {
                    newValues.awardRankingLimit = 0;
                }
                setValues(newValues);
            },
            xs: 12
        },
        {
            type: "number",
            name: "awardRankingLimit",
            label: t('rankingAwardLimit'),
            startAdornment: t('currency'),
            min: 0,
            required: true,
            xs: 12,
            condition1: values.hasAwardRankingLimit + "" === "true",
        },
        {
            type: "switch",
            name: "hasAwardAchievementLimit",
            label: t('awardAchievementLimit'),
            control: String(values.hasAwardAchievementLimit) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                if (String(event.target.checked) !== "true") {
                    newValues.awardAchievementLimit = null;
                } else {
                    newValues.awardAchievementLimit = 0;
                }
                setValues(newValues);
            },
            xs: 12
        },
        {
            type: "number",
            name: "awardAchievementLimit",
            label: t('awardAchievementLimit'),
            startAdornment: t('currency'),
            min: 0,
            required: true,
            xs: 12,
            condition1: values.hasAwardAchievementLimit + "" === "true",
        },
        {
            type: "switch",
            name: "hasAwardPositionLimit",
            label: t('awardPositionLimit'),
            control: String(values.hasAwardPositionLimit) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                if (String(event.target.checked) !== "true") {
                    newValues.awardPositionLimit = null;
                } else {
                    newValues.awardPositionLimit = 0;
                }
                setValues(newValues);
            },
            xs: 12
        },
        {
            type: "number",
            name: "awardPositionLimit",
            label: t('awardPositionLimit'),
            startAdornment: t('currency'),
            min: 0,
            required: true,
            xs: 12,
            condition1: values.hasAwardPositionLimit + "" === "true",
        },
        {
            type: "switch",
            name: "hasAwardOrganizationLimit",
            label: t('awardOrganizationLimit'),
            control: String(values.hasAwardOrganizationLimit) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                if (String(event.target.checked) !== "true") {
                    newValues.awardOrganizationLimit = null;
                } else {
                    newValues.awardOrganizationLimit = 0;
                }
                setValues(newValues);
            },
            xs: 12,
        },
        {
            type: "number",
            name: "awardOrganizationLimit",
            label: t('awardOrganizationLimit'),
            startAdornment: t('currency'),
            min: 0,
            required: true,
            xs: 12,
            condition1: values.hasAwardOrganizationLimit + "" === "true",
        },
    ]
}

export function useOpenDataFields(values, setValues) {
    const { t } = useTranslation();

    return [
        {
            type: "switch",
            name: "isBicification",
            label: t('showBannerEIT'),
            control: String(values.isBicification) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                setValues(newValues);
            },
            xs: 12
        },
    ]
}

export function usePlafondFields(values, setValues) {
    const { t } = useTranslation();

    return [
        {
            type: "switch",
            name: "isPlafond",
            label: t('activatePlafond'),
            control: String(values.isPlafond) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                newValues.plafond = null;
                setValues(newValues);
            },
            xs: 12
        },
        {
            type: "number",
            name: "plafond",
            label: t('plafond'),
            condition1: values.isPlafond + "" === "true",
            startAdornment: t('currency'),
            min: 0,
            required: true,
            xs: 12,

        },
        {
            type: "switch",
            name: "assignMonthlyPlafond",
            label: t('assignMonthlyPlafond'),
            control: String(values.assignMonthlyPlafond) === "true",
            condition1: values.isPlafond + "" === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                newValues.monthlyPlafond = null;
                setValues(newValues);
            },
            xs: 12
        },
        {
            type: "number",
            name: "monthlyPlafond",
            label: t('monthlyPlafond'),
            condition1: values.isPlafond + "" === "true" && values.assignMonthlyPlafond + "" === "true",
            startAdornment: t('currency'),
            min: 0,
            required: true,
            xs: 12,
        },
    ]
}

export function useKmToPointCoefficientFields(values, setValues) {
    const { t } = useTranslation();
    const legTranslation = useGetLegTypeTranslation();

    let fields = [
        {
            type: "number",
            name: "pointsPerKmInitiativeBike",
            label: legTranslation[LEG_TYPE.BIKE],
            tooltip: t('pointsPerKmExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        },
        {
            type: "number",
            name: "pointsPerKmInitiativeElectricBike",
            label: legTranslation[LEG_TYPE.ELECTRIC_BIKE],
            tooltip: t('pointsPerKmExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        },
        {
            type: "number",
            name: "pointsPerKmInitiativeFoot",
            label: legTranslation[LEG_TYPE.FOOT],
            tooltip: t('pointsPerKmExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        },
        {
            type: "number",
            name: "pointsPerPassengerInitiativeCarpooling",
            label: legTranslation[LEG_TYPE.CARPOOLING],
            tooltip: t('pointsPerPassengerExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        },
        {
            type: "number",
            name: "pointsPerKmInitiativeMicromobility",
            label: legTranslation[LEG_TYPE.MICROMOBILITY],
            tooltip: t('pointsPerKmExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        },
        {
            type: "number",
            name: "pointsPerKmInitiativeSharingServices",
            label: legTranslation[LEG_TYPE.SHARING_SERVICES],
            tooltip: t('pointsPerKmExplain'),
            startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6
        },
        {
            type: "space",
            xs: 12,
        }, {
            type: "number",
            name: values.pointsCoefficientTypePublicTransport === 'Minute' ?
                "pointsPerMinuteInitiativePublicTransport"
                :
                "pointsPerKmInitiativePublicTransport",
            label: legTranslation[LEG_TYPE.PUBLIC_TRANSPORT],
            startAdornment: t('points'),
            tooltip: values.pointsCoefficientTypePublicTransport === 'Minute' ? t('pointsPerMinuteExplain') : t('pointsPerKmExplain'),
            min: 0,
            required: true,
            xs: 6
        },
        {
            type: "radio",
            label: t('coefficientType'),
            name: "pointsCoefficientTypePublicTransport",
            options: [
                {
                    value: "Minute",
                    label: t('perTime')
                },
                {
                    value: "Km",
                    label: t('perKm')
                }
            ],
            xs: 6,
        }
    ];

    return fields;
}

export function useCo2CoefficientFields(values, setValues) {
    const { t } = useTranslation();
    const legTranslation = useGetLegTypeTranslation();

    let legsNamesFields = legTypesEndings.map(l => {
        l.name = 'co2' + l.ending;
        return l;
    });

    let fields = [];
    legsNamesFields.forEach(leg => {
        fields.push({
            type: "number",
            name: leg.name,
            label: legTranslation[leg.id],
            //startAdornment: t('points'),
            min: 0,
            required: true,
            xs: 6,
        })
    })

    return fields;
}

export function useCarpoolingFields(values, setValues) {
    const { t } = useTranslation();

    return [
        {
            type: "switch",
            name: "isActiveCarpooling",
            label: t('activeCarpooling'),
            control: String(values.isActiveCarpooling) === "true",
            onChange: (event) => {
                let newValues = { ...values };
                newValues[event.target.name] = event.target.checked;
                newValues.pointsPerRide = null;
                newValues.sessionCarpoolingArrivalDeadline = null;
                setValues(newValues);
            },
            xs: 12
        },
        // {
        //     type: "number",
        //     name: "pointsPerRide",
        //     label: t('pointsPerRide'),
        //     condition1: values.isCarpooling + "" === "true",
        //     startAdornment: t('points'),
        //     min: 0,
        //     xs: 12,

        // },
        // {
        //     type: "number",
        //     name: "sessionCarpoolingArrivalDeadline",
        //     label: t('sessionCarpoolingArrivalDeadline'),
        //     condition1: values.isCarpooling + "" === "true",
        //     startAdornment: t('hours'),
        //     min: 0,
        //     xs: 12,
        //     tooltip: t('sessionCarpoolingArrivalDeadlineDescription'),
        // },
    ]
}
