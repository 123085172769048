import {Grid, MenuItem, Typography} from "@mui/material";
import {NTMAutocomplete} from "./NTMAutocomplete";
import React, {useEffect, useState} from "react";
import useAddressPredictions from "../services/useAddressPredictions";
import {useTranslation} from "react-i18next";
import {value} from "lodash/seq";

export default function AutocompleteAddress({
                                                onAddressSelect,
                                                onAddressInputChange,
                                                disabled,
                                                error,
                                                defaultValue,
                                                label,
                                                value,
                                                types
                                            }) {
    const [addressInput, setAddressInput] = useState(defaultValue);
    const [autocompleteValue, setAutocompleteValue] = useState(value ? {
        mainText: value,
        secondaryText: ''
    } : null);
    const {t} = useTranslation();
    useEffect(() => {
        setAddressInput(value);
        setAutocompleteValue(value ? {
            mainText: value,
            secondaryText: ''
        } : null);
    }, [value]);

    function handleAddressInputChange(input) {
        setAddressInput(input);
        onAddressInputChange(input);
    }

    function getLabel(option) {
        return (option.mainText || '') + (option.secondaryText ? ' ' + option.secondaryText : '')
    }

    const {addressPredictions, isLoading} = useAddressPredictions(addressInput, types);
    return (
        <NTMAutocomplete
            color={"primary"}
            disablePortal
            defaultValue={defaultValue ? {mainText: defaultValue, secondaryText: ''} : null}
            value={value === undefined ? undefined : autocompleteValue}
            isOptionEqualToValue={(option, value) => getLabel(option).toLowerCase().trim() === getLabel(value).toLowerCase().trim()}
            options={isLoading ? [] : addressPredictions}
            getOptionLabel={(option) => option ? (getLabel(option)) : ""}
            renderOption={(props, option) => (
                <MenuItem {...props}>
                    <Grid container direction={'column'}>
                        <Typography variant={'body1'}>{option.mainText}</Typography>
                        <Typography variant={'caption'}>{option.secondaryText}</Typography>
                    </Grid>
                </MenuItem>
            )}
            onChange={(event, option) => {
                onAddressSelect(option ? getLabel(option) : '', (option || {}).placeId);
            }}
            onInputChange={(event, newInputValue) => {
                handleAddressInputChange(newInputValue);
            }}
            filterOptions={(x) => x}
            autoComplete
            includeInputInList
            textFieldParams={{
                label: label,
                type: "text",
                disabled: disabled
            }}
            error={error}
            loading={isLoading}
            disabled={disabled}
        />
    )
}