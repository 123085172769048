import {useTranslation} from "react-i18next";

export default function useGetSessionType() {

    const {t} = useTranslation();

    return [
        {
            id: 0,
            name: t('bike'),
            value: 'BIKE',
            key:'bike'
        },
        {
            id: 1,
            name: t('eBike'),
            value: 'ELECTRIC_BIKE',
            key:'eBike'
        },
        {
            id: 2,
            name: t('micromobility'),
            value: 'MICROMOBILITY',
            key:'micromobility'
        },
        {
            id: 3,
            name: t('byFeet'),
            value: 'FOOT',
            key:'byFeet'
        },
        {
            id: 4,
            name: t('Carpooling'),
            value: 'CARPOOLING',
            key:'Carpooling'
        },
        {
            id: 5,
            name: t('publicTransport'),
            value: 'PUBLIC TRANSPORT',
            key:'publictransport'
        }
    ]
}
