import makeStyles from "@mui/styles/makeStyles";
import React,{useState} from "react";
import HoverableButton from "../layout/HoverableButton";
import EditIcon from "@mui/icons-material/Edit";
import EditAgeIntervalModal from "../modals/EditAgeIntervalModal";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    }
}));

export default function RenderCellAgeInterval ({params, saveEdit, infoMessage, warningMessage}) {

    let classes = useStyles();
    let [modal, setModal] = useState(false);

    if(!saveEdit) return params.value

    return <div>
            <HoverableButton
                item={{
                    icon: <EditIcon/>,
                    name: params.value || ""
                }}
                onClick={() => setModal(true)}
                classes={{root: classes.root}}
            />
            <EditAgeIntervalModal open={modal} onClose={() => setModal(false)} onSubmit={newValue => saveEdit(params.row.id,params.field,newValue)}
                                  infoMessage={infoMessage} warningMessage={warningMessage}/>
    </div>
}