import {useParams} from "react-router-dom";
import {useGetOrganizationManagers} from "../../services/ContentManager";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import Grid from "@mui/material/Grid";
import StartIconButton from "../../components/buttons/StartIconButton";
import AddIcon from "@mui/icons-material/Add";
import NTMXGrid, {timestampFormatter} from "../../components/NTMXGrid";
import React, {useState} from "react";
import AddUserModal from "../../components/modals/AddUserModal";
import {getErrorMessage, ORGANIZATIONS, post, USERS} from "../../services/Client";
import {useTranslation} from "react-i18next";
import {getGenderCodeValue} from "../../constants/genderCode";

export default function UserManager() {

    const {t} = useTranslation();
    let {id} = useParams();
    let {managers, status} = useGetOrganizationManagers(id);
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();
    let [isAddingManager, setIsAddingManager] = useState(false);

    const defaultColumns = [
        {
            headerName: t('userID'),
            field: 'uid',
            width: 280,
            //hide: true
        },
        {
            headerName: t('username'),
            field: 'username',
            width: 220
        },
        {
            headerName: t('firstName'),
            field: 'firstName',
            width: 220
        },
        {
            headerName: t('lastName'),
            field: "lastName",
            width: 220
        },
        {
            headerName: t('gender'),
            field: "genderCode",
            valueFormatter: (params) => t(getGenderCodeValue(params.value)),
            width: 130,
            //hide: true
        },
        {
            headerName: t('email'),
            field: 'email',
            width: 220
        },
        {
            headerName: t('phoneNumber'),
            field: 'phoneNumber',
            width: 170,
            //hide: true,
        },
        {
            headerName: t('birthDate'),
            field: 'birthDate_date',
            width: 180,
            //hide: true,
            ...timestampFormatter
        }
    ];

    const createManager = (values) => {
        let newManager = {...values}
        if (newManager.birthDate) newManager.birthDate = new Date(newManager.birthDate).getTime();
        enqueueSnackbar(t('saving...'), {variant: "info"});
        post(ORGANIZATIONS + "/" + id + "/" + USERS, {body: newManager})
            .then(() => enqueueSnackbar(t('saved'), {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, {id: id}, USERS],));
    }

    return <div>
        <NTMXGrid
            key={"manager-users-table"}
            columns={defaultColumns}
            rows={managers || []}
            title={t('managers')}
            getRowId={(row) => managers && row.uid}
            loading={status === "loading"}
            rightButton={<Grid container justifyContent={"flex-end"}>
                <StartIconButton onClick={() => setIsAddingManager(true)} title={t('addManager')}
                                 startIcon={<AddIcon/>}/>
            </Grid>}
        />
        <AddUserModal open={!!isAddingManager} onClose={() => setIsAddingManager(false)} onSave={createManager}/>
    </div>
}
