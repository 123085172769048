import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useKmToPointCoefficientFields, useUrbanPointsSettingsFields } from "./settingsFields";
import RenderFields from "./RenderFields";
import { useTranslation } from "react-i18next";
import { TextInputContainer } from "../../../components/forms/TextInput";
import { IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import StartIconButton from "../../../components/buttons/StartIconButton";
import AddIcon from "@mui/icons-material/Add";

export default function UrbanPointsSettings({ values, setValues, onError }) {

    const { t } = useTranslation();
    let [error, setError] = useState({});
    let [timeBonusN, setTimeBonusN] = useState(1);
    let fields = useUrbanPointsSettingsFields(values, setValues, error, setError, onError)
    let fieldsLegs = useKmToPointCoefficientFields(values, setValues);

    useEffect(() => {
        if (timeBonusN === 1) {
            if (values.startTimeBonus_2) setTimeBonusN(2);
            if (values.startTimeBonus_3) setTimeBonusN(3);
            if (values.startTimeBonus_4) setTimeBonusN(4);
            if (values.startTimeBonus_5) setTimeBonusN(5);
        }

        if (values.isActiveDateTimeSlotMultiplier + "" === "true") {
            checkError("startDateBonus", values.startDateBonus);
            checkError("endDateBonus", values.endDateBonus);
            checkError("startTimeBonus_1", values.startTimeBonus_1);
            checkError("endTimeBonus_1", values.endTimeBonus_1);
        }
        checkError("homeWorkPointsTolerance", values.homeWorkPointsTolerance);
        checkError("multiplier", values.multiplier);
        checkError("startDateUrbanPoints", values.startDateUrbanPoints);
        checkError("endDateUrbanPoints", values.endDateUrbanPoints);
    }, [values])

    const checkError = (prop, value) => {
        if (error && error[prop]) {
            delete error[prop]
            onError(false)
        }
        if (prop === "startDateUrbanPoints" && values.endDateUrbanPoints && value > values.endDateUrbanPoints) {
            setError({ ...error, startDateUrbanPoints: t('invalidValue') })
            onError(true)
        }
        if (prop === "endDateUrbanPoints" && values.startDateUrbanPoints && value < values.startDateUrbanPoints) {
            setError({ ...error, endDateUrbanPoints: t('invalidValue') })
            onError(true)
        }
        if (prop === "homeWorkPointsTolerance" && value < 1) {
            onError(true);
            setError({ ...error, homeWorkPointsTolerance: t('invalidValue') })
        }
        if (prop === "multiplier" && value < 1) {
            setError({ ...error, multiplier: t('invalidValue') })
            onError(true)
        }
        if (prop === "startDateBonus" && values.endDateBonus && value > values.endDateBonus) {
            setError({ ...error, startDateBonus: t('invalidValue') })
            onError(true)
        }
        if (prop === "endDateBonus" && values.startDateBonus && value < values.startDateBonus) {
            setError({ ...error, endDateBonus: t('invalidValue') })
            onError(true)
        }
        if (prop !== "startDateUrbanPoints" && prop !== "endDateUrbanPoints" && !value) {
            setError({ ...error, [prop]: t('mandatoryField') })
            onError(true)
        }
    }

    const onTextChange = (prop) => (value) => {
        let newValues = { ...values };
        newValues[prop] = value;
        setValues(newValues);
    }

    const timeBonusFields = [
        {
            type: "time",
            label: t('startTimeBonus'),
            name: "startTimeBonus_1",
            required: true,
            xs: 5,
        },
        {
            type: "time",
            label: t('endTimeBonus'),
            name: "endTimeBonus_1",
            required: true,
            xs: 5,
        }
    ]

    const deleteTimeBonus = (n) => {
        let newValues = { ...values };
        for (let m = n + 1; m <= timeBonusN; m++) {
            let tempStartTime = newValues["startTimeBonus_" + (m - 1)];
            let tempEndTime = newValues["endTimeBonus_" + (m - 1)];
            newValues["startTimeBonus_" + (m - 1)] = newValues["startTimeBonus_" + m];
            newValues["endTimeBonus_" + (m - 1)] = newValues["endTimeBonus_" + m];
            newValues["startTimeBonus_" + m] = tempStartTime;
            newValues["endTimeBonus_" + m] = tempEndTime;
        }
        newValues["startTimeBonus_" + timeBonusN] = null;
        newValues["endTimeBonus_" + timeBonusN] = null;
        setTimeBonusN(pS => pS - 1)
        setValues(newValues)
    }

    const getTimeBonusAfter1 = () => {
        let rows = [];
        for (let n = 2; n <= timeBonusN; n++) {
            let clonedTimeBonusFields = JSON.parse(JSON.stringify(timeBonusFields));
            clonedTimeBonusFields.map((t, i) => (i === 0 ? t.name = "startTimeBonus_" + n : t.name = "endTimeBonus_" + n))
            clonedTimeBonusFields.map(t => t.required = false)

            rows.push(<Grid container key={values["startTimeBonus_" + n] + "" + n}>
                <RenderFields fields={clonedTimeBonusFields} values={values} error={error} onTextChange={onTextChange} />

                <Grid item xs={1} style={{ padding: "16px" }}>
                    <IconButton color={"primary"} size="large" onClick={() => deleteTimeBonus(n)}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>);
        }
        return <>{rows}</>;
    }

    return <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "0.5rem" }}>

        <RenderFields fields={fields} values={values} error={error} onTextChange={onTextChange} />

        {values.isActiveUrbanPoints + "" === "true" && values.isActiveDateTimeSlotMultiplier + "" === "true" &&

            <>
                <Grid container key={values.endTimeBonus_1 + "1"}>
                    <RenderFields fields={timeBonusFields} values={values} error={error} onTextChange={onTextChange} />
                </Grid>
                {getTimeBonusAfter1()}

                {timeBonusN < 5 &&
                    <Grid container justifyContent={"center"}>
                        <StartIconButton color={"primary"} startIcon={<AddIcon />}
                            onClick={() => setTimeBonusN(pS => pS + 1)}
                            title={t('addNewTimeBonus')} />
                    </Grid>
                }

                <Grid xs="12" sx={{ my: 1 }}>
                    <Typography textAlign={'center'} sx={{ color: 'gray' }} >{t('pointcoefficient')}</Typography>
                </Grid>

                <RenderFields fields={fieldsLegs} values={values} error={error} onTextChange={onTextChange} />
            </>
        }

    </Grid>
}