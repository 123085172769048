import { useState } from "react";
import SearchIcons from "../SearchIcon";
import BaseModal from "./BaseModal";

export default function EditIconModal({open, onClose, onSubmit, icon, color}){

    const [values, setValues] = useState({icon, color});


    const save = () => {
        onSubmit(values.icon, values.color)
        onClose()
    }

    return <BaseModal open={open} onClose={onClose} onSave={save}>
        <SearchIcons selectIcon={(icon, color) => setValues({icon:icon.importName, color})}></SearchIcons>
    </BaseModal>
}