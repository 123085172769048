import { useParams } from "react-router-dom";
import { useGetOrganizationMessages } from "../../../services/ContentManager";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import Grid from "@mui/material/Grid";
import StartIconButton from "../../../components/buttons/StartIconButton";
import SendIcon from "@mui/icons-material/Send";
import NTMXGrid, { timestampFormatter, timestampFormatterWithTimezone } from "../../../components/NTMXGrid";
import React, { useState } from "react";
import { getErrorMessage, MESSAGES, ORGANIZATIONS, post } from "../../../services/Client";
import SendMessageModal from "../../../components/modals/SendMessageModal";
import { IconButton } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import MessageInfo from "./MessageInfo";
import { useTranslation } from "react-i18next";
import { useGetOrganizationUsers } from "../../../services/UserDataManager";
import SelectUsersByEmailModal from "../../../components/modals/SelectUsersByEmailModal";


export default function Messages() {

    const { t } = useTranslation();
    let { id } = useParams();
    let { messages, status } = useGetOrganizationMessages(id);
    const { enqueueSnackbar } = useSnackbar();
    let queryClient = useQueryClient();
    let [isSendingMessage, setIsSendingMessage] = useState(false);
    let [isSendingMessageToSpecificUsers, setIsSendingMessageToSpecificUsers] = useState(false);
    let [showInfo, setShowInfo] = useState(false);
    let [targetsToSendMessage, setTargetsToSendMessage] = useState([]);
    let { status: statusUser, users } = useGetOrganizationUsers(id);

    const defaultColumns = [
        {
            headerName: t('sentDate'),
            field: 'createdDate',
            width: 280,
            ...timestampFormatterWithTimezone
        },
        {
            headerName: t('title'),
            field: 'title',
            width: 220
        },
        {
            headerName: t('body'),
            field: "body",
            width: 220
        },
        {
            headerName: t('recipients'),
            field: "",
            width: 130,
            renderCell: (params) => <IconButton onClick={() => setShowInfo(params.row)} size="large"><GroupIcon /></IconButton>
        }
    ];

    const sendMessageToUsersList = (usersList) => {
        setTargetsToSendMessage(users.filter(u => usersList.includes(u.email) || usersList.includes(u.uid)));
        setIsSendingMessage(true);
    }

    const sendMessage = (values) => {
        enqueueSnackbar(t('sending...'), { variant: "info" });
        values.organization = id
        values.sendTo = []
        targetsToSendMessage.map(t => values.sendTo = [...values.sendTo, t.uid])
        post(`${MESSAGES}/send`, { body: values })
            .then(() => {
                enqueueSnackbar(t('sent'), { variant: "success" })
                closeSendMessageModal()
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries([ORGANIZATIONS, { id: id }, MESSAGES],));
    }

    const closeSendMessageModal = () => {
        setIsSendingMessage(false)
        setTargetsToSendMessage([]);
    }

    if (showInfo) return <MessageInfo goBack={() => setShowInfo(null)} messageId={showInfo.id} />

    return <div>
        <NTMXGrid
            key={"manager-messages-table"}
            columns={defaultColumns}
            rows={messages || []}
            title={t('messages')}
            getRowId={(row) => messages && row.id}
            loading={status === "loading"}
            rightButton={<Grid container justifyContent={"flex-end"}>
                <StartIconButton onClick={() => setIsSendingMessage(true)} title={t('sendMessageToAll')}
                    startIcon={<SendIcon />} />
                <StartIconButton onClick={() => setIsSendingMessageToSpecificUsers(true)} title={t('sendMessageToUsers')}
                    startIcon={<SendIcon />} disabled={statusUser === "loading"} />
            </Grid>}
        />
        <SendMessageModal open={!!isSendingMessage} onClose={closeSendMessageModal} onSave={sendMessage}
            isInputUsersEmailEnabled={true} targets={targetsToSendMessage.length === 0 ? users : targetsToSendMessage} />
        <SelectUsersByEmailModal open={!!isSendingMessageToSpecificUsers} onClose={() => setIsSendingMessageToSpecificUsers(false)}
            onSave={sendMessageToUsersList} />
    </div>
}
