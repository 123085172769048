import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {Typography, Grid} from "@mui/material";
import NTMSelect from "../NTMSelect";
import useGlobalStyles from "../../services/useGlobalStyles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            '& fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
                borderWidth: "3px"
            },
        },
    },
    input: {
        minWidth: "11rem",
        height: "3vh",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.dark,
        '&:focused': {
            borderColor: theme.palette.secondary.main,
        },
        '&:hover': {
            color: theme.palette.secondary.main,
        },
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: theme.palette.secondary.main,
            opacity: 1 /* Firefox */
        },
        '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: theme.palette.secondary.main,
        },
        '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: theme.palette.secondary.main,
        }
    },
    grid: {
        padding: theme.spacing(2)
    }
}));

/**
 * mpostra gli Shop
 * @param value
 * @param onChange
 * @param shops
 * @param coupon
 * @param error
 * @returns {JSX.Element}
 * @constructor
 */
export default function ShopSelect({shopId, onChange, shops, error}) {
    let globalClasses = useGlobalStyles();
    let classes = useStyles();
    const {t} = useTranslation();

    return <Grid container justifyContent={"center"} className={classes.grid} direction={"column"}>
        <Grid item xs={12} className={globalClasses.marginBottom}>
            <Typography variant={"h6"}
                        align={'center'}><b>{shops.length > 1 ? t('selectShop') : t('shop')}</b></Typography>
        </Grid>
        <Grid item xs={12}>
            <NTMSelect
                color={'light'}
                label={t('shop')}
                error={error}
                items={
                    shops.map(o => ({value: o.id, text: o.name}))
                }
                onChange={onChange}
                value={shopId}
                onDelete={() => onChange(null)}
            />
        </Grid>
    </Grid>
}