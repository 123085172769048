import styled from "@emotion/styled";
import { ArrowBack } from "@mui/icons-material";
import { Button, Card, CardContent, CardMedia, Grid, ImageList, ImageListItem, Pagination, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { MenuButton } from "../../components/buttons/MenuButton";
import InfoGrid from "../../components/InfoGrid";
import NTMImageInput from "../../components/NTMImageInput";
import NTMSelect from "../../components/NTMSelect";
import ListPagination from "../../components/Pagination";
import ResponsiveCircularLoading from "../../components/ResponsiveCircularLoading";
import ClickableItem from "../../components/VendorComponents/ClickableItem";
import { useGetCategories, useGetParticipantShopsByOrganization, useGetShopsByOrganization } from "../../services/ContentManager";
import { formatAddress } from "../../services/helper";
import useGlobalStyles from "../../services/useGlobalStyles";
import Shop from "../Vendor/Shop/Shop";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} placement='top' classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(255, 255, 255, 0.93)',
        color: 'black',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));


const ShopData = ({ shop, goBack }) => {
    const { t } = useTranslation();
    const { categories, status: categoriesStatus } = useGetCategories();
    const globalClasses = useGlobalStyles();
    return (
        <>
            <Grid container justifyContent="space-between" className={globalClasses.marginBottom}>
                <Button
                    onClick={goBack}
                    className={globalClasses.backButton}
                >
                    <ArrowBack className={globalClasses.backButtonIcon} />
                    {t('goBack')}
                </Button>
            </Grid>

            <Grid container direction={'column'} sx={{ px: 3, maxWidth: (theme) => theme.breakpoints.values.lg, margin: 'auto', gap: 2 }}>
                <Grid item >
                    <Typography variant="h4" align='center' color="primary" fontWeight={'bold'}>
                        {shop.name}
                    </Typography>
                </Grid>
                <Grid item >
                    <Box sx={{ backgroundImage: `url(${shop.logo})`, backgroundSize: 'cover', height: '15em', width: '15em', margin: 'auto' }}></Box>
                </Grid>
            </Grid>

            <InfoGrid
                sx={{ py: 3, maxWidth: (theme) => theme.breakpoints.values.lg, margin: 'auto' }}
                infos={[
                    {
                        field: 'claim',
                        label: t('claim'),
                        md: 6,
                        xs: 12
                    },
                    {
                        field: 'description',
                        label: t('description'),
                        md: 6,
                        xs: 12
                    },
                    {
                        field: 'website',
                        label: t('webAddress'),
                        md: 6,
                        xs: 12
                    },
                    {
                        field: 'phone',
                        label: t('phoneNumber'),
                        formatValue: (value, data) => shop.phonePrefix + ' ' + shop.phone,
                        md: 6,
                        xs: 12
                    },
                    {
                        field: 'categories',
                        label: t('shopTag'),
                        formatValue: (value, data) => (shop.categories || []).reduce((prev, current) => (prev ? prev + ', ' : '') + t((categories.find(c => c.id === current) || {}).translationKey ?? '-'), null),
                        md: 6,
                        xs: 12
                    },
                    {
                        field: 'address',
                        label: t('address'),
                        formatValue: (value, data) => shop.isEcommerce ? t('ecommerce') : formatAddress(shop),
                        md: 6,
                        xs: 12
                    }
                ]} data={shop}
            />

            <Box sx={{ maxWidth: 800, py: 3, margin: 'auto' }}>
                <ImageList cols={shop.images.length > 3 ? 3 : shop.images.length} gap={8}>
                    {shop.images.map((item) => (
                        <ImageListItem key={item}>
                            <img
                                style={{ maxWidth: '20em', margin: 'auto' }}
                                src={item}
                                alt={'shop images'}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>


        </>
    )
}

export default function OpenDataShops() {
    let { id: oid } = useParams();
    let { shops, status } = useGetParticipantShopsByOrganization(oid);
    const [selectedShop, setSelectedShop] = useState(null);
    const { t } = useTranslation();
    const { categories, status: categoriesStatus } = useGetCategories();
    const [filters, setFilters] = useState([]);

    function categoryName(id) {
        return t((categories.find(c => c.id === id) || {}).translationKey)
    }

    if (selectedShop)
        return <ShopData
            shop={{ ...selectedShop, addressInput: formatAddress(selectedShop) }}
            goBack={() => setSelectedShop(null)}
        />

    return (
        <>
            <Box sx={{ maxWidth: '40em', margin: 'auto' }}>
                <NTMSelect
                    label={t('filterCategories')}
                    items={categories.map(c => ({ value: c.id, text: t(c.translationKey) }))}
                    value={filters}
                    onChange={(value) => setFilters([...filters, value])}
                    onDelete={(value) => setFilters(filters.filter(f => f !== value))}
                    maxItemSize={categories.length}
                />
            </Box>

            <Grid container
                direction="row"
                justifyContent='flex-start'
                alignItems='stretch'
                sx={{ py: 3, maxWidth: (theme) => theme.breakpoints.values.lg, margin: 'auto' }}
            >
                {
                    status !== 'success' && <ResponsiveCircularLoading></ResponsiveCircularLoading>
                }
                <ListPagination paginationSize={4} elements={shops} renderElement={
                    (s) => (filters.length === 0 || filters.some(f => (s.categories || []).includes(f))) &&
                        <Grid item lg={3} md={4} xs={12} sx={{ p: 2, maxWidth: 'calc(100vw - 96px)' }}>
                            <Card sx={{ '&:hover': { transform: 'scale(1.02)' }, transition: 'all 0.1s', cursor: 'pointer', height: '100%' }}
                                onClick={() => setSelectedShop(s)}
                            >
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={s.logo}
                                    alt="shop image"
                                />
                                <CardContent>
                                    <LightTooltip
                                        title={
                                            s.categories && s.categories.reduce((prev, current) =>
                                                (prev ? prev + ', ' : '') + categoryName(current), null)
                                        }
                                    >
                                        <Typography gutterBottom variant="caption" component="p" color="primary.main" sx={{ whiteSpace: 'noWrap', overflow: 'hidden', textOverflow: 'ellipsis', height: '1.7em' }}>
                                            {s.categories && s.categories.reduce((prev, current) =>
                                                (prev ? prev + ', ' : '') + categoryName(current), null)}
                                        </Typography>
                                    </LightTooltip>

                                    <Typography variant="h5" align="center" fontWeight={'bold'} sx={{
                                        mt: 1.5,
                                        mb: 1,
                                        height: '2.7em',
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        '-webkit-box-orient': 'vertical',
                                        '-webkit-line-clamp': '2',
                                        textOverflow: 'ellipsis',
                                    }}>
                                        {s.name}
                                    </Typography>

                                    <Typography variant="body1" align="center" color="primary.main">
                                        {t('address')}
                                    </Typography>
                                    <Typography variant="body2" align="center">
                                        {!s.isEcommerce ? formatAddress(s) : '-'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                } ></ListPagination>
            </Grid>

        </>
    )
}