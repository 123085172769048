import BaseModal from "./BaseModal";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";
import {TextInputContainer} from "../forms/TextInput";
import {languages} from "../../constants/lenguages";
import {messagesFirebaseRef} from "../../constants/messages";

export default function WelcomeMessageModal({message, onEdit, onClose, firebaseRef}) {
    const [values, setValues] = useState(message || {});
    const [ref, setRef] = useState(firebaseRef);
    const {t} = useTranslation();

    useEffect(() => {
        setValues(message || {});
    }, [message]);

    useEffect(() => {
        setRef(firebaseRef);
    }, [firebaseRef])


    const edit = () => {
        let newMessage = {...values};
        let key = newMessage.key;
        delete newMessage.key;//evita di salvare la chiave sull'oggetto firebase
        onEdit(key, newMessage, ref);
        close();
    }

    const close = () => {
        setValues({});
        onClose();
    }

    return <BaseModal open={!!message} onClose={close} onSave={edit}>
        <Grid container>
            {
                !values.key && //solo ai nuovi elementi posso settare il tipo
                <TextInputContainer xs={12} select value={ref} label={t('type')}
                                    onTextChange={(val) => setRef(val)}
                >
                    <MenuItem value={messagesFirebaseRef.welcomeMessages}>{t('welcomeMessages')}</MenuItem>
                    <MenuItem value={messagesFirebaseRef.returnNotifications}>{t('returnNotifications')}</MenuItem>
                </TextInputContainer>
            }
            {
                languages.map(l =>
                    <TextInputContainer
                        xs={60}
                        value={values && values[l.code]}
                        label={l.label}
                        onTextChange={(text) => values && setValues({
                            ...values,
                            [l.code]: text
                        })}
                        multiline
                    />)
            }
        </Grid>
    </BaseModal>
}