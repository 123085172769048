import {Block} from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import SavingsIcon from '@mui/icons-material/Savings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import {useSnackbar} from "notistack";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "react-query";
import {useParams} from "react-router-dom";
import ConfirmButton from "../components/buttons/ConfirmButton";
import ConfirmIconButton from "../components/buttons/ConfirmIconButton";
import StartIconButton from "../components/buttons/StartIconButton";
import EditCouponDateModal from "../components/modals/EditCouponDateModal";
import NTMXGrid, {timestampFormatter, timestampFormatterWithTimezone} from "../components/NTMXGrid";
import {AWARD_TYPE, REFUNDABLE_AWARD_TYPE} from "../constants/awardType";
import {roles} from "../services/ability";
import {COUPONS, getErrorMessage, ORGANIZATIONS, post, put} from "../services/Client";
import {useGetCoupons, useGetShopsByOrganization} from "../services/ContentManager";
import {UserContext} from "./App";


export default function CouponManagement({nationals = false}) {
	const {t} = useTranslation();
	let {id} = useParams();//id dell' organizzazione presa da url
	if(nationals) {
		id = null;
	}
	const isAdmin = useContext(UserContext).userType === roles.ADMIN;

	let queryClient = useQueryClient();
	const {enqueueSnackbar} = useSnackbar();
	let {coupons, status} = useGetCoupons(id);
	let [isChangingDate, setIsChangingDate] = useState(false);
	let [selectedRows, setSelectedRows] = useState([]);
	let [toShow, setToShow] = useState(0);
	const {shops} = useGetShopsByOrganization(id);

	const showValues = [t('all'), t('toRefund'), t('allRefunded'), t('plafond')];

	const filterCoupons = toShow => {
		let rows;
		let toFilter = removeRevoked();
		switch(toShow) {
			case 1:
				rows = toFilter.filter((c) => c.redemptionDate && !c.revokedDate && !c.refundDate && (REFUNDABLE_AWARD_TYPE.includes(c.awardType)));
				break;
			case 2:
				rows = toFilter.filter(c => !!c.refundDate && !c.revokedDate && (REFUNDABLE_AWARD_TYPE.includes(c.awardType)));
				break;
			case 3:
				rows = toFilter.filter(c => !(!c.status && c.revokedDate) &&
					(c.awardType == AWARD_TYPE.MONEY || c.awardType == AWARD_TYPE.SHOP));
				break;
			default:
				rows = toFilter;
		}
		return rows;
	};

	const removeRevoked = () => {
		if(isAdmin)//solo gli admin possono vedere i coupon revocati
		{
			return (coupons || []);
		}
		return (coupons || []).filter(c => !c.revokedDate);
	};

	/**
	 * @param refund se è true fa il rimborso, se è false annulla il rimborso
	 */
	const handleRefund = (refund = true) => {
		enqueueSnackbar(t('saving...'), {variant: "info"});
		put(ORGANIZATIONS + '/' + (refund ? '' : 'reverse-') + 'refund-coupons', {
			body: selectedRows,
			params: {oid: id}
		})
			.then((response) => {
				let errorLog = [];
				Object.entries(response.data).forEach(([key, value]) => {
					if(value !== 'refund') {
						//c'è stato un errore
						errorLog.push(<>
							<Typography
								style={{marginBottom: '5px'}}>{t('errorRefundingCoupon') + ' "' + key + '": ' + value}</Typography>
						</>);
					}
				});
				if(errorLog.length > 0) {
					enqueueSnackbar(<Grid direction={'column'}>{errorLog}</Grid>, {variant: "error"});
				} else {
					enqueueSnackbar(t('saved'), {variant: "success"});
				}
			})
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
			.finally(() => queryClient.invalidateQueries([COUPONS, {organization: id}]));
	};

	const changeRefundDate = newDate => {
		let body = {
			codes: selectedRows,
			date: newDate
		};
		enqueueSnackbar(t('saving...'), {variant: "info"});
		put(`${ORGANIZATIONS}/change-coupon-refund`, {body})
			.then((response) => {
				let errorLog = [];
				Object.entries(response.data).forEach(([key, value]) => {
					if(value !== 'OK') {
						//c'è stato un errore
						errorLog.push(<>
							<Typography
								style={{marginBottom: '5px'}}>{t('errorRefundingCoupon') + ' "' + key + '": ' + value}</Typography>
						</>);
					}
				});
				if(errorLog.length > 0) {
					enqueueSnackbar(<Grid direction={'column'}>{errorLog}</Grid>, {variant: "error"});
				} else {
					enqueueSnackbar(t('saved'), {variant: "success"});
				}
			})
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
			.finally(() => queryClient.invalidateQueries([COUPONS, {organization: id}]));
	};

	const assignResidueCoupons = () => {
		enqueueSnackbar(t('saving...'), {variant: "info"});
		post(`${ORGANIZATIONS}/${id}/assign-residue-coupons`)
			.then(() => enqueueSnackbar(t('saved'), {variant: "success"}))
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
			.finally(() => queryClient.invalidateQueries([COUPONS, {organization: id}]));
	};

	function findShop(sid) {
		return shops.find(s => s.id === sid) || {};
	}

	function findPrimaryShop(sid) {
		return shops.find(s => (s.vendorUid === findShop(sid).vendorUid) && s.primary) || {};
	}

	const revoke = (coupon) => {
		enqueueSnackbar(t('saving...'), {variant: "info"});
		put(`${COUPONS}/${coupon.code}/revoke`)
			.then(() => enqueueSnackbar(t('saved'), {variant: "success"}))
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
			.finally(() => queryClient.invalidateQueries([COUPONS, {organization: id}]));
	};

	const columns = [
		{
			headerName: t('status'),
			field: 'status',
			width: 150,
			valueGetter: params => {
				if(!params.row.redemptionDate && !params.row.refundDate && !params.row.revokedDate) {
					return t('emitted');
				}

				if(params.row.redemptionDate && !params.row.refundDate && !params.row.revokedDate) {
					return t('redeemed');
				}

				if(params.row.refundDate && !params.row.revokedDate) {
					return t('refunded');
				}

				if(params.row.refundDate && !params.row.revokedDate) {
					return t('refunded');
				}

				if(!params.row.status && !params.row.revokedDate) {
					return t('coupon_active');
				}

				if(!params.row.status && params.row.revokedDate) {
					return t('coupon_manually_revoked');
				}

				if(params.row.status === 1) {
					return t('coupon_automatically_revoked');
				}
			}
		},
		{
			headerName: t('code'),
			field: 'code',
			width: 150,
		}, {
			headerName: t('value'),
			field: 'value',
			width: 150,
		},
		{
			headerName: t('type'),
			field: 'awardType',
			width: 150,
			valueGetter: ({value}) => {
				switch(value) {
					case AWARD_TYPE.MONEY:
						return t('moneyTransferred');
					case AWARD_TYPE.POINTS:
						return t('points');
					case AWARD_TYPE.CITYHALL:
						return t('collectedInMunicipality');
					case AWARD_TYPE.SHOP:
						return t('collectedInShop');
					case AWARD_TYPE.COMMUNITY:
						return t('awardCollectedInShop');
					default:
						return '';
				}
			},
			//hide: true,
		}, {
			headerName: t('emissionDate'),
			field: 'emissionDate_date',
			width: 200,
			...timestampFormatter
		}, {
			headerName: t('redemptionDate'),
			field: 'redemptionDate',
			width: 280,
			...timestampFormatterWithTimezone,
			//hide: true
		}, {
			headerName: t('refundDate'),
			field: 'refundDate_date',
			width: 180,
			...timestampFormatter
		}, {
			headerName: t('expireDate'),
			field: 'expireDate_date',
			width: 180,
			...timestampFormatter
		}, {
			headerName: t('revokeDate'),
			field: 'revokedDate_date',
			width: 180,
			...timestampFormatter
		}, {
			headerName: t('title'),
			field: 'name',
			width: 180,
		}, {
			headerName: t('userID'),
			field: 'uid',
			width: 280,
			//hide: true
		},
		{
			headerName: t('username'),
			field: 'username',
			width: 220,
			//hide: true
		},
		{
			headerName: t('firstName'),
			field: 'firstName',
			width: 220,
		},
		{
			headerName: t('lastName'),
			field: 'lastName',
			width: 220,
		},
		{
			headerName: t('fiscalCode'),
			field: 'fiscalCode',
			width: 220,
		},
		{
			headerName: t('iban'),
			field: 'iban',
			width: 220,
		},
		{
			headerName: t('bic'),
			field: 'bic',
			width: 220,
		},
		{
			headerName: t('email'),
			field: 'email',
			width: 220,
		},
		{
			headerName: t('address'),
			field: 'fullAddress',
			width: 220,
		},
		{
			headerName: t('city'),
			field: 'city',
			width: 220,
		},
		{
			headerName: t('serialNumber'),
			field: 'serialNumber',
			width: 220,
			//hide: true
		},
		{
			headerName: t('shopId'),
			//hide: true,
			field: 'shopId',
			width: 220
		}, {
			headerName: t('shop'),
			field: 'shopName',
			//hide: true,
			width: 220
		}, {
			headerName: t('primaryShop'),
			field: 'primaryShopName',
			width: 220,
			valueGetter: ({row}) => findPrimaryShop(row.shopId).name
		}, {
			headerName: t('businessNameVendor'),
			field: 'shopBusinessName',
			width: 220,
		},
		{
			headerName: t('vendorIban'),
			field: 'shopIban',
			width: 220,
			//hide: true
		},
		{
			headerName: t('vendorBic'),
			field: 'shopBic',
			width: 220,
		},
		{
			headerName: t('vendorEmail'),
			field: 'shopEmail',
			width: 220,
		},
		{
			headerName: t('revoke'),
			field: 'revoke',
			width: 100,
			sortable: false,
			disableColumnMenu: false,
			renderCell: ({row}) => (
				((!row.expireDate || dayjs(row.expireDate).isAfter(dayjs(new Date()))) && (row.awardType === AWARD_TYPE.MONEY || !row.redemptionDate) && !row.refoundDate && !row.revokedDate) &&
				<ConfirmIconButton title={t('revokeConfirm')} onConfirm={() => revoke(row)}><Block></Block>
				</ConfirmIconButton>)
		},
	];

	return <Paper style={{padding: "2rem"}}>
		{isAdmin && (
            <ConfirmButton
                onConfirm={assignResidueCoupons}
                title={t('confirmCloseProject')}
                text={t('requestCreationAllVoucher')}
                buttonProps={{title: t('closeProject'), startIcon: <SavingsIcon/>}}
            />
        )}
		<NTMXGrid
			key={"manager-coupon-table"}
			checkboxSelection={toShow !== 0}
			columns={columns}
			rows={filterCoupons(toShow)}
			title={t('voucher')}
			getRowId={(row) => coupons && row.code}
			loading={status === "loading"}
			rightButton={
				<Grid container direction={'columns'}>
					<Grid container justifyContent={"flex-end"}>
						{
							showValues.map((v, i) => <StartIconButton onClick={() => setToShow(i)}
								title={v} selected={toShow === i}
								key={i}
								startIcon={toShow === i && <VisibilityIcon/>}/>)
						}
					</Grid>
					{
						toShow === 1 &&
						<Grid container justifyContent={"flex-end"}>
							<StartIconButton onClick={() => handleRefund(true)} title={t('makeRefund')}
								startIcon={<CheckIcon/>}
								disabled={selectedRows.length === 0}/>
						</Grid>
					}
					{
						toShow === 2 &&
						<Grid container justifyContent={"flex-end"}>
							<StartIconButton onClick={() => setIsChangingDate(true)} title={t('editData')}
								startIcon={<CheckIcon/>}
								disabled={selectedRows.length === 0}/>
						</Grid>
					}
					{/*{*/}
					{/*    toShow === 0 &&*/}
					{/*    <Box sx={{height: '4.3em', width: '1px'}}/>*/}
					{/*}*/}
				</Grid>

			}
			onSelectionModelChange={(newSelection) => {
				setSelectedRows(newSelection);
			}}
			selectionModel={selectedRows}
			initialState={{pinnedColumns: {right: ['revoke']}}}
		/>

		<EditCouponDateModal open={isChangingDate} onClose={() => setIsChangingDate(false)}
			onSubmit={changeRefundDate}/>
	</Paper>;

}
