import {useTranslation} from "react-i18next";

export const ACHIEVEMENT_TYPE = {
    TROPHY: 0, CHALLENGE: 1
}

export function useAchievementTypeNames() {
    const {t} = useTranslation();

    return [
        t('thropy'),
        t('challenge')
    ]
}

export function useAchievementType() {
    const {t} = useTranslation();

    return [
        {
            id: 0,
            name: t('thropy')
        },
        {
            id: 1,
            name: t('challenge'),
        },
    ]
}
