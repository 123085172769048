import {MenuButton} from "./buttons/MenuButton";
import React from "react";
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function StatusIndicator({isActive, color = 'light'}) {
    const {t} = useTranslation();
    return <Grid container spacing={0.3} alignItems={'center'}>
        <Grid item>
            <FiberManualRecordIcon sx={{color: isActive ? "green.main" : "primary.main"}}/>
        </Grid>
        <Grid item>
            <Typography variant={'body2'} sx={{color: color === 'light' ? 'secondary.light' : 'black' }} textAlign={'center'}>
                {isActive ? t('active') : t('notActive')}
            </Typography>
        </Grid>
    </Grid>
}