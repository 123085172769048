import {useTranslation} from "react-i18next";
import React,{useState} from "react";
import BaseModal from "./BaseModal";
import TextInput from "../forms/TextInput";
import Grid from "@mui/material/Grid";
import {Alert} from "@mui/material";
import {SettingsTooltip} from "../forms/CustomTooltip";


export default function SelectUsersByEmailModal({open, onClose, onSave}) {

    const {t} = useTranslation();
    let [users, setUsers] = useState({});
    let [error, setError] = useState(false);

    const close = () => {
        onClose();
    }

    const save = () => {
        if(users === "" || users === undefined) setError(t('mandatoryField'))
        let usersEmailList = users.trim().split(/\s+/);
        onSave(usersEmailList);
        close();
    }

    const textChange = (value) => {
        setUsers(value);
        setError(false);
    }

    return <BaseModal open={open} onClose={close} onSave={save}>
        <Grid item xs={12} style={{marginBottom : "0.5rem", padding: "16px"}}>
            <Alert variant="outlined" severity="warning">{t('warningUsersListInput')}</Alert>
        </Grid>
        <Grid container spacing={4} style={{margin: 0, width: "30rem", marginBottom: "1rem"}}>
            <Grid item xs={12} style={{padding: "16px"}}>
                <SettingsTooltip title={t('usersListExplain')}>
                    <div>
                        <TextInput label={t('email')} type="string" multiline={true} error={error} onTextChange={textChange}/>
                    </div>
                </SettingsTooltip>
            </Grid>
        </Grid>
    </BaseModal>
}