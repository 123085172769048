import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { useTranslation } from "react-i18next";

import logo from "../../images/pinbike-logo-animato.gif";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://pin.bike/">
				FB Innovation
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	error: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: theme.palette.secondary.main
	},
	forgot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: theme.palette.primary.main,
		textDecoration: "underline",
		'&:hover': {
			cursor: 'pointer'
		}
	},
	input: {
		'& :-webkit-autofill': {
			'transition-delay': '9999s'
		},
	},
}));

export function LoginPage({ onSubmit, onResetPassword, error }) {
	let history = useHistory();
	const classes = useStyles();
	const { t } = useTranslation();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [forgotPassword, setForgotPassword] = useState(false)
	let [showPassword, setShowPassword] = useState(false);

	const preventLoseFocus = (event) => {
		event.preventDefault()
	}

	const onKeyPress = (ev) => {
		if (ev.key === 'Enter') {
			onSubmit(username, password);
			ev.preventDefault();
		}
	}

	const resetPassword = () => {
		setForgotPassword(false)
		onResetPassword(username)
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<a href="https://pin.bike/" target="_blank" rel="noopener noreferrer">
					<img height={80} alt={"home"} src={logo} />
				</a>
				<Typography component="h1" variant="h5">
					{t('doLogIn')}
				</Typography>
				<TextField
					variant="outlined"
					className={classes.input}
					margin="normal"
					required
					fullWidth
					id="email"
					label={t('emailAddress')}
					name="email"
					autoComplete="email"
					autoFocus
					onChange={event => {
						setUsername(event.target.value);
					}}
					value={username}
					onKeyPress={onKeyPress}
				/>
				<TextField
					variant="outlined"
					className={classes.input}
					margin="normal"
					required
					fullWidth
					name="password"
					label={t('password')}
					type={showPassword ? "text" : "password"}
					id="password"
					autoComplete="current-password"
					onChange={event => {
						setPassword(event.target.value);
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setShowPassword(!showPassword)}
									onMouseDown={preventLoseFocus}
									onMouseUp={preventLoseFocus}
									edge="end"
									size="large">
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>)
					}}
					value={password}
					onKeyPress={onKeyPress}
				/>
				<Box mt={4}>
					<div className={classes.error}>{error}</div>
				</Box>
				<Button
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
					onClick={() => {
						onSubmit(username, password);
					}}
				>
					{t('join')}
				</Button>
			</div>
			<Box mt={4}>
				<div className={classes.forgot} onClick={() => setForgotPassword(true)}>
					{t('forgotPassword')}
				</div>
			</Box>
			<Box mt={8}>
				<Copyright />
			</Box>
			<Dialog
				open={forgotPassword}
				onClose={() => setForgotPassword(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{t('passwordRecovery')}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{
							username ?
								`${t('forgotPasswordConfirm').replace('${username}', username)}`:
								t('forgotPasswordError')
						}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setForgotPassword(false)} color="secondary">
						{t('abort')}
					</Button>
					{username && <Button onClick={resetPassword} color="primary" autoFocus>
						{t('send')}
					</Button>}
				</DialogActions>
			</Dialog>
		</Container>
	);
}

export function LogoutView({ onClick }) {
	return (
		<div>
			<span>You are logged in</span>
			<button onClick={onClick}>Logout</button>
		</div>
	);
}
