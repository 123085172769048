import {useQueryClient} from "react-query";
import {useSnackbar} from "notistack";
import {Redirect, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {isAdmin as getIsAdmin} from "../../services/PermissionManager";
import React, {useContext, useState} from "react";
import {UserContext} from "../App";
import {
    useGetArticles,
    useGetOfficeArticles,
    useGetOrganizationOffices,
    useGetOrganizations
} from "../../services/ContentManager";
import CircularLoading from "../../components/CircularLoading";
import {ARTICLE_ROUTE} from "../../constants/vendors";
import {ARTICLES, getErrorMessage, put} from "../../services/Client";
import ArticleList from "../../components/VendorComponents/ArticleList";
import Article from "../Vendor/Article/Article";
import ArticleHistory from "../Vendor/Article/ArticleHistory";
import OfficeArticle from "./OfficeArticle";
import Grid from "@mui/material/Grid";
import {Link, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

/**
 * Mostra la lista di articoli creati dagli uffici di un organizzazione
 * @param oid
 * @returns {JSX.Element}
 * @constructor
 */
export default function OfficeArticles({oid}) {
    let queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();
    let history = useHistory();
    const {t} = useTranslation();
    const [articleId, setArticleId] = useState(null);
    const [showHistory, setShowHistory] = useState(null);

    const {status: organizationStatus, organizations, error} = useGetOrganizations(!oid);
    let organizationId = oid ?? (organizations[0] || []).id;
    let {articles, status} = useGetOfficeArticles(organizationId);
    let {offices, status: statusOffices} = useGetOrganizationOffices(oid);

    if (status === 'loading' || organizationStatus === 'loading' || statusOffices === 'loading')
        return <CircularLoading/>
    if (!offices || offices.length === 0) {
        return <Container maxWidth={"md"} sx={{p: 1}}>
            <Paper sx={{p: 3, textAlign: 'center'}}>
                <Typography variant={'h5'}>{t('thereAreNoOffices')}</Typography>
                <Typography>{t('thereAreNoOfficesDescription')}</Typography>
                <Button onClick={() => history.push(`/dashboard/${oid}/offices`)}>{t('createOffice')}</Button>
            </Paper>
        </Container>
    }


    function editArticle(id) {
        setArticleId({id: id});
    }

    function articleHistory(id) {
        setShowHistory(id);
    }

    const editVisible = (id, field, value, row) => {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        put(`${ARTICLES}/${id}/updateVisible`, {body: {[field]: value, shopId: row.shopId}})
            .then(() => {
                enqueueSnackbar(t('saved'), {variant: "success"});
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([ARTICLES, {oid: organizationId}]));
    };

    function goBack() {
        setArticleId(null);
        setShowHistory(null);
    }

    if (showHistory)
        return <ArticleHistory oid={organizationId} aid={showHistory} goBack={goBack}/>

    return (
        articleId ?
            <OfficeArticle oid={organizationId} id={articleId.id} goBack={goBack}/>
            :
            <ArticleList articles={articles} articleHistory={articleHistory} editArticle={editArticle}
                         editVisible={editVisible}/>
    )

}
