import {Alert, AlertTitle, Grid, Paper, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import React from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import logo from "../images/splash.png";

export function EmailConfirmedPage() {
    const params = new URLSearchParams(window.location.search);
    let isVendor = params.get("isVendor");
    let {t} = useTranslation();
    let history = useHistory();

    return <Grid container justify="center" alignItems="center"
                 sx={{
                     mt: 8,
                     minHeight: 'calc(100% - 64px)',
                     minWidth: '100%'
                 }}
                 direction={'column'}>
        <Grid item xs/>
        <Paper sx={{width: '80vw', minHeight: '60vh'}} elevation={5}>
            <Grid container sx={{width: '100%', height: '100%'}} alignItems={'center'} justify={'center'}>
                <Grid container justify="center" alignItems="center" sx={{
                    p: 3,
                    minHeight: '80%',
                    minWidth: '100%'
                }} direction="column">

                    <img style={{
                        height: '7rem',
                    }} alt={"home"} src={logo}/>

                    <Grid item sx={{
                        p: 3,
                        fontWeight: 600
                    }}>
                        <Typography variant={'h4'}>
                            {t('emailValidated')}
                        </Typography>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant={'h6'} align='center'>
                                {
                                    isVendor !== 'true' ?
                                        t('returnToApp')
                                        :
                                        <>{t('emailValidatedDescription')} <Link
                                            onClick={() => history.push('/login')}>{t('clickHere')}</Link> {t('toAccess')}</>
                                }

                            </Typography>
                            {
                                isVendor === 'true' &&
                                <Typography variant={'subtitle1'}
                                            align='center'>{t('emailValidatedAddShops')}</Typography>
                            }

                        </Grid>

                        {
                            isVendor === 'true' &&
                            <Grid item xs={12} sx={{marginBottom: "0.5rem", p: 3}}>
                                <Alert severity="warning">
                                    <AlertTitle sx={{
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase'
                                    }}>{t('warning')}</AlertTitle>
                                    {t('emailConfirmedNewVendorAlert')}
                                </Alert>
                            </Grid>
                        }
                    </Grid>

                </Grid>
            </Grid>
        </Paper>
        <Grid item xs/>
    </Grid>
}