import { useTranslation } from "react-i18next";

export const SESSION_STATUS = {
    VALID: 0,
    ERROR: 1,
    DISTANCE_ERROR: 2,
    SPEED_ERROR: 3,
    VALID_OFFLINE: 4,
    NOT_CERTIFICATED: 5,
    DEBUG: 6,
    ACCELERATION_PEAK: 7,
    CERTIFICATED: 8
}

export const useGetSessionStatusTranslation = () => {
    const {t} = useTranslation();
    return {
        [SESSION_STATUS.VALID]: t('valid'),
        [SESSION_STATUS.ERROR]: t('error'),
        [SESSION_STATUS.DISTANCE_ERROR]: t('distanceError'),
        [SESSION_STATUS.SPEED_ERROR]: t('speedError'),
        [SESSION_STATUS.VALID_OFFLINE]: t('validOffline'),
        [SESSION_STATUS.NOT_CERTIFICATED]: t('notCertificated'),
        [SESSION_STATUS.DEBUG]: t('debug'),
        [SESSION_STATUS.ACCELERATION_PEAK]: t('accelerationPeak'),
        [SESSION_STATUS.CERTIFICATED]: t('certificated')
    }
}