export const AGE_RANGES = {
    age_range_0: '&le; 17',   // ≤ 17
    age_range_1: '18 - 29',
    age_range_2: '30 - 39',
    age_range_3: '40 - 49',
    age_range_4: '50 - 59',
    age_range_5: '&ge; 60'    // ≥ 60
};

export const AGE_ORDER = ["age_range_0", "age_range_1", "age_range_2", "age_range_3", "age_range_4", "age_range_5"];
