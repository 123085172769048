import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {Alert, IconButton} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";

export default function ConfirmIconButton({
                                              title,
                                              text,
                                              warningMessage,
                                              onConfirm,
                                              cancelText = "Cancella",
                                              confirmText = "Conferma",
                                              children,
                                              sx
                                          }) {

	let [open, setOpen] = useState(false);
	const {t} = useTranslation();
	let handleClose = () => setOpen(false);
	let handleConfirm = () => {
		setOpen(false)
		onConfirm()
	}

    return <>
        <IconButton onClick={() => setOpen(true)} size="large" sx={sx}>
            {children}
        </IconButton>

		<Dialog
			open={open}
			onClose={handleClose}
		>
			<DialogTitle>{title}</DialogTitle>
			{text &&
			<DialogContent>
				{warningMessage &&
					<Grid item xs={12} sx={{marginBottom : "0.6rem"}}>
						<Alert variant="outlined" severity="warning">{warningMessage}</Alert>
					</Grid>
				}
				<DialogContentText>
					{text}
				</DialogContentText>
			</DialogContent>}
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					{cancelText || t('cancel')}
				</Button>
				<Button onClick={handleConfirm} color="primary" autoFocus>
					{confirmText || t('confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	</>;
}
