import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {Close} from "@mui/icons-material";

import {adaptV4Theme} from "@mui/material";
import Button from "@mui/material/Button";
import {itIT} from "@mui/material/locale";
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {LicenseInfo} from "@mui/x-data-grid-pro";

import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {LoadScript} from "@react-google-maps/api";
import it from "date-fns/locale/it";
import dayjs from "dayjs";
import localeData from 'dayjs/plugin/localeData';
import i18n from "i18next";
import {SnackbarProvider} from "notistack";
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

import {QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter as Router} from "react-router-dom";
import {libraries} from "./components/MapContainer";
import App from "./containers/App";
import './i18n';
import reportWebVitals from './reportWebVitals';

const theme = createTheme(adaptV4Theme({
	palette: {
		primary: {
			main: "#dd3333",
			light: "#fcd4d4",
			dark: "#e14d43", //"#32323a",
			contrastText: '#fff',
		},
		secondary: {
			main: "#32323a", //"#e14d43",
			light: "#e7e7ef",
		},
		text: {
			primary: "#290e08"
		},
		error: {
			main: "#ec1807",
			light: "#ffc900"
		},
		green: {
			main: "#8BC34A",
			light: "#99c26a"
		},
		chartColors: ["#dd3333", "#ffc900", "#32323a", "#8BC34A", "#fcd4d4"]
	},
	typography: {
		body1: {
			fontSize: "0.875rem",
			lineHeight: 1.43,
			letterSpacing: "0.01071em",
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					whiteSpace: "nowrap",
					verticalAlign: "middle",
					userSelect: "none",
					border: "1px solid transparent",
					padding: "0.75rem 1rem",
					fontWeight: "bold",
					textAlign: "center",
					borderRadius: 0,
					minWidth: "auto",
					fontSize: "0.875rem",
					lineHeight: "1.25",
				}
			}
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					"&:hover": {backgroundColor: '#D4EDFC'}
				}
			}
		},
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					fontSize: '0.875rem',
					lineHeight: 1.43,
					letterSpacing: '0.01071em',
				},
			},
		}
	}
}, itIT));

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
	notistackRef.current.closeSnackbar(key);
};

LicenseInfo.setLicenseKey(
	'96982ca227141c27dcacdc03f96229ecT1JERVI6NDA4ODQsRVhQSVJZPTE2ODAzNjY1NjMwMDAsS0VZVkVSU0lPTj0x',
);

Bugsnag.start({
    apiKey: '9d7f75104e4b65ffb74ecf903146381e',
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: [ 'production' ]
})
const ErrorBoundary = Bugsnag.getPlugin('react')
	.createErrorBoundary(React);


const queryClient = new QueryClient();

const duration = require('dayjs/plugin/duration');
const customParseFormat = require('dayjs/plugin/customParseFormat');
let utc = require('dayjs/plugin/utc');
let timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(localeData);


ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
						<SnackbarProvider
							ref={notistackRef}
							action={(key) => (
								<Button onClick={onClickDismiss(key)}>
									<Close color="secondary"/>
								</Button>
							)}
							autoHideDuration={3000}>
							<Suspense fallback="...is loading">
								<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
								            libraries={libraries} language={i18n.language}>
									<QueryClientProvider client={queryClient}>
										<Router>
											<App/>
										</Router>
									</QueryClientProvider>
								</LoadScript>
							</Suspense>
						</SnackbarProvider>
					</LocalizationProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
