import EditIcon from '@mui/icons-material/Edit';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";

import useGetRefundStatus from "../../constants/refundStatus";
import useGetSessionType from "../../constants/sessionType";
import { resources } from "../../services/ability";
import { AbilityContext } from "../../services/Can";
import { getErrorMessage, post, put, SESSIONS, USERS } from "../../services/Client";
import RenderBoolean from "../cellRender/RenderBoolean";
import RenderCell from "../cellRender/RenderCell";
import renderCellExpand from "../cellRender/renderCellExpand";
import { RenderUserUidRedirect } from "../cellRender/RenderUserRedirect";
import RenderVerificationRequiredCell from "../cellRender/RenderVerificationRequiredCell";
import { SettingsTooltip } from "../forms/CustomTooltip";
import CreateSessionModal from "../modals/CreateSessionModal";
import NTMXGrid, {
    numberFormatter,
    secondsToDuration,
    timestampFormatter
} from "../NTMXGrid";
import useGetWeatherType from 'constants/weatherType';
import {isSuperUser as getIsSuperUser} from "../../services/PermissionManager";

const storageKey = "session-api-table-columns";

export function useGetVerificationRequiredStrings() {
    const { t } = useTranslation();

    return [
        t('toBeRevised'),
        t('revised')
    ]
}

function getHomeCoordinates(params) {
    if (!getValue(params, 'homeAddressLng') && !getValue(params, 'homeAddressLat')) return ""
    return `${getValue(params, 'homeAddressLat') || ''} - ${getValue(params, 'homeAddressLng') || ''
        }`;
}

function getWorkCoordinates(params) {
    if (!getValue(params, 'workAddressLng') && !getValue(params, 'workAddressLat')) return ""
    return `${getValue(params, 'workAddressLat') || ''} - ${getValue(params, 'workAddressLng') || ''
        }`;
}

function getValue(params, field) {
    if (!params.row[field]) return ""
    return params.row[field]
}

function getSessionPoints(id, api) {
    let row = id && api && api.getRow(id)
    return row && row.sessionPoints ? row.sessionPoints : []
}

export default function SessionApiTable({
    sessions = [],
    loading,
    queryKey,
    rightButton,
    onFilterModelChange,
    filterMode,
    onSortModelChange,
    sortingMode,
    onPageChange,
    onPageSizeChange,
    paginationMode,
    rowCount
}) {

    const { t } = useTranslation();
    let [isCloningSession, setIsCloningSession] = useState(false)
    let [isEditingSession, setIsEditingSession] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    let queryClient = useQueryClient()
    const sessionType = useGetSessionType()
    const verificationRequiredStrings = useGetVerificationRequiredStrings()
    const refundStatus = useGetRefundStatus()
    const weatherType = useGetWeatherType()
    const ability = useContext(AbilityContext)
    const canReadPrivateData = ability.can("read", resources.PRIVATEUSERDATA)
    let [selectedRows, setSelectedRows] = useState([]);

    const format = (row, field, timezone) => {
        if (!row) return;
        let value = row[field], template = "DD/MM/YYYY HH:mm:ss";
        if (!value) return "";
        else if (!timezone) return dayjs(new Date(value)).format(template);
        else return dayjs.tz(new Date(value), timezone).format(template) + " (" + timezone + ")";
    }

    const columns = [
         (canReadPrivateData || getIsSuperUser) && {
            headerName: t('sessionID'),
            field: 'id',
            width: 310,
            //hide:true
        },
        {
            headerName: t('userID'),
            field: 'uid',
            width: 280,
            renderCell: (params) => <RenderUserUidRedirect uid={params.row.uid} />
        },
        {
            headerName: t('sessionType'),
            field: 'type',
            type: 'singleSelect',
            valueOptions: [
                { value: '0', label: t('bike') },
                { value: '1', label: t('eBike') },
                { value: '2', label: t('micromobility') },
                { value: '3', label: t('byFeet') },
                { value: '4', label: t('Carpooling') },
                { value: '5', label: t('publicTransport') }
            ],
            width: 280,
            valueGetter: ({ value, row }) => (
                row.version === 2 ?
                    row.legTypeList !== undefined ? row.legTypeList.split('|').map(id => sessionType.find(s => s.id + '' === id)?.name).join(', ') : "-"
                    :
                    value !== undefined ? sessionType.find(s => s.id === value).name : "-"
            )
            //renderCell: (params) => <RenderCell params = {params} saveEdit = {saveEditSession} type={"select"} options={sessionType}/>
        },
        {
            headerName: t('email'),
            field: 'email',
            width: 220,
        },
        {
            headerName: t('phoneNumber'),
            field: 'phoneNumber',
            width: 170,
            renderCell: (params) => <RenderCell params={params}
                saveEdit={(id, field, newValue) => saveEditUser(params.row.uid, field, newValue)} />
        },
        {
            headerName: t('username'),
            field: 'username',
            width: 170,
            renderCell: (params) => <RenderUserUidRedirect username={params.value} uid={params.row.uid} />
        },
        {
            headerName: t('firstName'),
            field: 'firstName',
            width: 130,
        },
        {
            headerName: t('lastName'),
            field: "lastName",
            width: 130,
        },
        {
            headerName: t('bikeType'),
            field: 'bikeType',
            filterable: false,
            sortable: false,
            width: 280,
            renderCell: (params) => <RenderCell params={params} saveEdit={saveEditSession} />,
        },
        canReadPrivateData && {
            headerName: t('wheelDiameter'),
            field: 'wheelDiameter',
            filterable: false,
            width: 280,
            ...numberFormatter
            //renderCell: (params) => <RenderCell params = {params} saveEdit = {saveEditSession} type="number" decimals={2}/>
        },
        canReadPrivateData && {
            headerName: t('description'),
            field: 'description',
            filterable: false,
            width: 280,
            renderCell: (params) => <RenderCell params={params} saveEdit={saveEditSession} />
        },
        canReadPrivateData && {
            headerName: t('valid'),
            field: 'valid',
            type: "boolean",
            width: 120,
            renderCell: (params) => <RenderBoolean params={params} /*saveEdit = {saveEditSession}*/ />
        },
        canReadPrivateData && {
            headerName: t('certificated'),
            field: 'certificated',
            type: "boolean",
            width: 120,
            renderCell: (params) => <RenderBoolean params={params} /*saveEdit = {saveEditSession}*/ />
        },
        canReadPrivateData && {
            headerName: t('manuallyCertificated'),
            field: 'lastValidatedAt',
            type: 'date',
            width: 260,
            valueGetter: ({ row, field }) => {
                if (row.timezone) return format(row, field, row.timezone)
                else return format(row, field)
            },
        },
        {
            headerName: t('startDate'),
            field: 'startTime',
            type: 'dateTime',
            width: 260,
            valueGetter: ({ row, field }) => {
                if (row.timezone) return format(row, field, row.timezone)
                else return format(row, field)
            },
        },
        {
            headerName: t('endDate'),
            field: 'endTime',
            type: 'dateTime',
            width: 260,
            valueGetter: ({ row, field }) => {
                if (row.timezone) return format(row, field, row.timezone)
                else return format(row, field)
            },
        },
        {
            headerName: t('duration'),
            field: 'duration',
            type: 'number',
            width: 180,
            ...secondsToDuration
        },
        canReadPrivateData && {
            headerName: t('gyroDistance'),
            field: 'gyroDistance',
            type: 'number',
            width: 200,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number" />,
            ...numberFormatter
        },
        canReadPrivateData && {
            headerName: t('gpsDistance'),
            field: 'gpsDistance',
            type: 'number',
            width: 200,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number" />,
            ...numberFormatter
        },
        canReadPrivateData && {
            headerName: t('googleMapsDistance'),
            field: 'gmapsDistance',
            filterable: false,
            sortable: false,
            width: 200,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number" />,
            ...numberFormatter
        },
        {
            headerName: t('nationalPoints'),
            field: 'nationalPoints',
            type: 'number',
            width: 200,
            ...numberFormatter
            //renderCell: (params) => <RenderCell params = {params} saveEdit = {saveEditSession} type="number" decimals={0}/>
        },
        {
            headerName: t('initiativePoints'),
            field: 'sessionPoints',
            type: 'number',
            filterable: false,
            sortable: false,
            width: 280,
            valueGetter: ({ id, api }) => {
                let value = ""
                getSessionPoints(id, api).map(sp => value += `${sp.organizationTitle}: ${sp.points}`)
                return value
            },
            renderCell: ({ row, colDef }) => {
                if (!row) return;
                if (row.sessionPoints) {
                    let values = []
                    row.sessionPoints.forEach((sp) => {
                        values.push({ showValue: sp.organizationTitle + ": " + sp.points })
                    })
                    return renderCellExpand(values, colDef)
                } else return renderCellExpand("", colDef)
            },
        },
        {
            headerName: t('euro'),
            field: 'euro',
            filterable: false,
            sortable: false,
            width: 280,
            valueGetter: ({ id, api }) => {
                let value = ""
                getSessionPoints(id, api).map(sp => value += `${sp.organizationTitle}: ${sp.euro ? parseFloat(sp.euro).toFixed(2) : " - "}`)
                return value
            },
            renderCell: ({ row, colDef }) => {
                if (!row) return;
                if (row.sessionPoints) {
                    let values = []
                    row.sessionPoints.forEach((sp) => {
                        let value = sp.euro ? parseFloat(sp.euro).toFixed(2) : " - "
                        values.push({ showValue: sp.organizationTitle + ": " + value + t('currency') })
                    })
                    return renderCellExpand(values, colDef)
                } else return renderCellExpand("", colDef)
            }
        },
        {
            headerName: t('refundStatus'),
            field: 'sessionPointsReimb',
            filterable: false,
            sortable: false,
            width: 280,
            valueGetter: ({ id, api }) => {
                let value = ""
                getSessionPoints(id, api).map(sp => value += `${sp.organizationTitle}: ${refundStatus.find(s => s.id === sp.refundStatus) ? refundStatus.find(s => s.id === sp.refundStatus).name : ""}`)
                return value
            },
            renderCell: ({ row, colDef }) => {
                if (!row) return;
                if (row.sessionPoints) {
                    let values = []
                    row.sessionPoints.forEach((sp) => {
                        values.push({ showValue: sp.organizationTitle + ": " + (refundStatus.find(s => s.id === sp.refundStatus) ? refundStatus.find(s => s.id === sp.refundStatus).name : "") })
                    })
                    return renderCellExpand(values, colDef)
                } else return renderCellExpand("", colDef)
            }
        },
        {
            headerName: t('co2'),
            field: 'co2',
            filterable: false,
            width: 120,
            renderCell: (params) => <RenderCell params={params} saveEdit={saveEditSession} measureUnit="g" type="number" />,
            ...numberFormatter
        },
        {
            headerName: t('initiativeKm'),
            field: 'initiativeKm',
            type: 'number',
            filterable: false,
            sortable: false,
            width: 280,
            valueGetter: ({ id, api }) => {
                let value = ""
                getSessionPoints(id, api).map(sp => value += `${sp.organizationTitle}: ${parseFloat(sp.distance).toFixed(2)}`)
                return value
            },
            renderCell: ({ row, colDef }) => {
                if (!row) return;
                if (row.sessionPoints) {
                    let values = []
                    row.sessionPoints.forEach((sp) => {
                        let value = parseFloat(sp.distance).toFixed(2)
                        values.push({ showValue: sp.organizationTitle + ": " + value + "km" })
                    })
                    return renderCellExpand(values, colDef);
                } else return renderCellExpand("", colDef);
            }
        },
        {
            headerName: t('nationalKm'),
            field: 'nationalKm',
            type: 'number',
            width: 200,
            renderCell: (params) => <RenderCell params={params} /*saveEdit = {saveEditSession}*/ measureUnit="km" type="number" />,
            ...numberFormatter
        },
        {
            headerName: t('homeWorkPath'),
            field: 'isHomeWorkPath',
            type: "boolean",
            width: 280,
            valueFormatter: ({
                id,
                api
            }) => getSessionPoints(id, api).map(sp => sp.homeWorkDistance > 0).reduce((a, b) => a || b, false),
            valueGetter: ({
                id,
                api
            }) => getSessionPoints(id, api).map(sp => sp.homeWorkDistance > 0).reduce((a, b) => a || b, false),
            renderCell: ({ id, api, colDef }) => {
                let row = api.getRow(id)
                if (row.sessionPoints) {
                    let values = []
                    row.sessionPoints.forEach((sp) => {
                        values.push({
                            key: sp.organizationTitle, value: !!sp.homeWorkDistance, id: sp.organizationId,
                            type: "boolean", field: "isHomeWorkPath",
                            showValue: sp.organizationTitle + ": ", booleanValue: !!sp.homeWorkDistance
                        })
                    })
                    return renderCellExpand(values, colDef)
                } else return renderCellExpand("", colDef)

            }
        },
        canReadPrivateData && {
            headerName: t('homeAddress'),
            field: 'homeAddress',
            filterable: false,
            sortable: false,
            width: 250,
            //renderCell: (params) => <RenderCell params = {params} saveEdit = {saveEdit}/>
        },
        canReadPrivateData && {
            headerName: t('homeCoordinates'),
            field: 'homeAddressCoordinates',
            filterable: false,
            sortable: false,
            width: 250,
            valueGetter: getHomeCoordinates,
        },
        canReadPrivateData && {
            headerName: t('workAddress'),
            field: 'workAddress',
            filterable: false,
            sortable: false,
            width: 250,
            //renderCell: (params) => <RenderCell params = {params} saveEdit = {saveEdit}/>
        },
        canReadPrivateData && {
            headerName: t('workCoordinates'),
            field: 'workAddressCoordinates',
            filterable: false,
            sortable: false,
            width: 250,
            valueGetter: getWorkCoordinates,
        },
        {
            headerName: t('multiplier'),
            field: 'initiativeMultiplier',
            type: 'number',
            filterable: false,
            sortable: false,
            width: 280,
            valueGetter: ({ id, api }) => {
                let value = ""
                getSessionPoints(id, api).map(sp => value += `${sp.organizationTitle}: x${sp.multiplier || 1}`)
                return value
            },
            renderCell: params => {
                if (params.row.sessionPoints) {
                    let values = []
                    params.row.sessionPoints.map((sp) => {
                        values.push({/*key: sp.organizationTitle, value: parseFloat(sp.multiplier),
                            startAdornment: "x", type: "number", id: sp.id, field: "multiplier",*/
                            showValue: sp.organizationTitle + ": x" + (sp.multiplier || "-")
                        })
                    })
                    return renderCellExpand(values, params.colDef)
                } else return renderCellExpand("", params.colDef)
            }
        },
        canReadPrivateData && {
            headerName:  t('revisedBy'),
            field: "lastValidatedBy",
            filterable: true,
            sortable: true,
            width: 250,
        },
        canReadPrivateData && {
            headerName: t('toBeRevised'),  //null: non da revisionare, true: da revisionare, false: già revisionata
            field: "verificationRequired",
            type: "boolean",
            width: 250,
            //hide: true,
            valueGetter: ({ value }) => value === true ? verificationRequiredStrings[0] : value === false ? verificationRequiredStrings[1] : "",
            renderCell: params => params.value &&
                <RenderVerificationRequiredCell params={params} saveEdit={saveEditSession} />
        },
        canReadPrivateData && {
            headerName: t('revisedType'),
            field: "verificationRequiredType",
            filterable: false,
            sortable: false,
            width: 250,
            valueGetter: ({ id, api }) => {
                let value = ""
                if (api.getRow(id).verificationRequiredType)
                    api.getRow(id).verificationRequiredType.map(vt => value += `${vt.value}`)
                return value
            },
            renderCell: ({ row, colDef }) => {
                if (!row) return;
                if (row.verificationRequiredType) {
                    let values = []
                    row.verificationRequiredType.forEach(e => values.push({ showValue: e.value }))
                    return renderCellExpand(values, colDef)
                } else return ""
            }
        },
        canReadPrivateData && {
            headerName: t('revisedNote'),
            field: "verificationRequiredNote",
            filterable: false,
            sortable: false,
            width: 250,
            //hide: true,
            renderCell: ({ value, colDef }) => {
                if (value) {
                    return renderCellExpand([{ showValue: value }], colDef)
                } else return ""
            }
        },
        canReadPrivateData && {
            headerName: t('sensorStartBattery'),
            field: 'startBattery',
            type: 'number',
            width: 280,
            renderCell: (params) => params.value === 0 || params.value ? params.value + "%" : "",
            ...numberFormatter
        },
        canReadPrivateData && {
            headerName: t('sensorEndBattery'),
            field: 'endBattery',
            type: 'number',
            width: 280,
            renderCell: (params) => params.value === 0 || params.value ? params.value + "%" : "",
            ...numberFormatter
        },
        canReadPrivateData && {
            headerName: t('phoneStartBattery'),
            field: 'phoneStartBattery',
            type: 'number',
            width: 280,
            renderCell: (params) => params.value === 0 || params.value ? params.value + "%" : "",
            ...numberFormatter
        },
        canReadPrivateData && {
            headerName: t('phoneEndBattery'),
            field: 'phoneEndBattery',
            width: 280,
            renderCell: (params) => params.value === 0 || params.value ? params.value + "%" : "",
            ...numberFormatter
        },
        canReadPrivateData && {
            headerName: t('sensor'),
            field: "sensor",
            width: 250,
        },
        canReadPrivateData && {
            headerName: t('sensorName'),
            field: "sensorName",
            width: 250,
        },
        canReadPrivateData && {
            headerName: t('firmware'),
            field: "firmware",
            width: 250,
        },
        canReadPrivateData && {
            headerName: t('appVersion'),
            field: "appVersion",
            width: 250,
        },
        canReadPrivateData && {
            headerName: t('platform'),
            field: "platform",
            type: 'singleSelect',
            valueOptions: ['android', 'iOS'],
            width: 250,
        },
        canReadPrivateData && {
            headerName: t('deviceModel'),
            field: "phoneModel",
            width: 250,
        },
        canReadPrivateData && {
            headerName: t('sentGrifo'),
            field: "forwardedAt",
            width: 250,
            ...timestampFormatter
        },
        {
            headerName: t('weather'),
            field: "weatherMain",
            width: 200,
            valueGetter: ({ value, row }) => value !== undefined ? weatherType.find(w => w.id === value).name : "-"
        },
        canReadPrivateData && {
            headerName: t('details'),
            field: "polyline",
            filterable: false,
            sortable: false,
            width: 140,
            disableColumnMenu: true,
            resizable: false,
            disableExport: true,
            hideable: false,
            renderCell: (params) => <Grid container direction={"row"}>
                <SettingsTooltip title={t('information')}><Link to={"/sessions/" + params.row.id}
                    target="_blank"><IconButton><VisibilityIcon /></IconButton></Link></SettingsTooltip>
                <SettingsTooltip title={t('clone')}><IconButton onClick={() => setIsCloningSession(params.row)}
                    aria-label="delete"><FilterNoneIcon /></IconButton></SettingsTooltip>
                <SettingsTooltip title={t('edit')}><IconButton onClick={() => setIsEditingSession(params.row)}
                    aria-label="edit"><EditIcon /></IconButton></SettingsTooltip>
            </Grid>
        }
    ].filter(Boolean);

    const saveEditSession = (id, field, value) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        put(SESSIONS, { body: { [field]: value }, elem: id })
            .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(queryKey));
    }

    const saveEditUser = (uid, field, value) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        put(USERS, { body: { [field]: value }, elem: uid })
            .then(() => {
                enqueueSnackbar(t('saved'), { variant: "success" });
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(queryKey));
    }

    const saveSession = async (values) => {
        enqueueSnackbar("saving...", { variant: "info" });

        if (values.id) await put(`${SESSIONS}/${values.id}/revalidate`, { body: values })
            .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(queryKey));
        else
            await post(`${SESSIONS}/generate`, { body: values })
                .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
                .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
                .finally(() => queryClient.invalidateQueries(queryKey));
    }

    return <>
        <NTMXGrid
            key={storageKey}
            columns={columns}
            rows={sessions || undefined}
            // rows={canReadPrivateData ? (localSessions || undefined) : localSessions && localSessions.filter(s => s.certificated)}
            title={t('sessions')}
            getRowId={(row) => sessions && row.id}
            rightButton={<Grid container justifyContent={"flex-end"}>{rightButton}</Grid>}
            initialState={{ pinnedColumns: { right: ['polyline'] } }}
            loading={loading}
            onFilterModelChange={onFilterModelChange}
            filterMode={filterMode}
            onSortModelChange={onSortModelChange}
            sortingMode={sortingMode}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            paginationMode={paginationMode}
            rowCount={rowCount}
        />

        {
            (!!isCloningSession || !!isEditingSession) && <CreateSessionModal
                open={!!isCloningSession || !!isEditingSession}
                onClose={() => {
                    setIsCloningSession(false);
                    setIsEditingSession(false);
                }}
                onSubmit={saveSession}
                session={isCloningSession || isEditingSession}
                type={isEditingSession ? "edit" : isCloningSession ? "clone" : "create"}
            />
        }
    </>
}
