import { useTranslation } from "react-i18next";

export const LEG_TYPE = {
    BIKE: 0,
    ELECTRIC_BIKE: 1,
    MICROMOBILITY: 2,
    FOOT: 3,
    CARPOOLING: 4,
    PUBLIC_TRANSPORT: 5,
    SHARING_SERVICES: 6,
    NONE: -1
}

export const LEG_COLOR = {
    [LEG_TYPE.BIKE]: '#C9105F',
    [LEG_TYPE.ELECTRIC_BIKE]: '#dd1268',
    //[LEG_TYPE.SCOOTER]: '#ff8bb0',
    [LEG_TYPE.FOOT]: '#6bc1ff',
    [LEG_TYPE.CARPOOLING]: '#57c900',
    [LEG_TYPE.MICROMOBILITY]: '#a709fb',
    [LEG_TYPE.PUBLIC_TRANSPORT]: '#0081de',
    [LEG_TYPE.SHARING_SERVICES]: '#F07937',
    [LEG_TYPE.NONE]: '#000000',
}

export const useGetLegTypeTranslation = () => {
    const { t } = useTranslation();
    return {
        [LEG_TYPE.BIKE]: t('bike'),
        [LEG_TYPE.ELECTRIC_BIKE]: t('eBike'),
        //[LEG_TYPE.SCOOTER]: t('electricScooter'),
        [LEG_TYPE.FOOT]: t('byFeet'),
        [LEG_TYPE.CARPOOLING]: t('Carpooling'),
        [LEG_TYPE.MICROMOBILITY]: t('micromobility'),
        [LEG_TYPE.PUBLIC_TRANSPORT]: t('publicTransport'),
        [LEG_TYPE.SHARING_SERVICES]: t('sharingServices'),
        [LEG_TYPE.NONE]: t('all'),
    }
}

export const legTypesEndings = [
    { ending: 'Bike', id: LEG_TYPE.BIKE },
    { ending: 'ElectricBike', id: LEG_TYPE.ELECTRIC_BIKE },
    //{ ending: 'Scooter', id: LEG_TYPE.SCOOTER },
    { ending: 'Foot', id: LEG_TYPE.FOOT },
    { ending: 'Carpooling', id: LEG_TYPE.CARPOOLING },
    { ending: 'Micromobility', id: LEG_TYPE.MICROMOBILITY },
    { ending: 'SharingServices', id: LEG_TYPE.SHARING_SERVICES },
    { ending: 'PublicTransport', id: LEG_TYPE.PUBLIC_TRANSPORT }
];

