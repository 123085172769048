import React, { useEffect } from "react";

export default function AlghoBot() {
    useEffect(() => {
        const tag = document.createElement("algho-viewer");
        tag.setAttribute("bot-id", "d34aa04acc3d86c3f9e73c1328ff0437");
        tag.setAttribute("group-id", "54");
        tag.setAttribute("widget", "true");
        tag.setAttribute("audio", "false");
        tag.setAttribute("voice", "false");
        tag.setAttribute("open", "false");
        tag.setAttribute("tooltip", "Ciao, se hai bisogno di aiuto io sono qui!");
        tag.setAttribute("z-index", "9999");
        document.body.appendChild(tag);

        const script = document.createElement("script");
        script.setAttribute("id", "algho-viewer-module");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("defer", "defer");
        script.setAttribute("charset", "UTF-8");
        script.setAttribute("src", "https://virtualassistant.alghoncloud.com/algho-viewer.min.js");
        document.body.appendChild(script);

        // Cleanup dello script e dell'elemento algho-viewer quando il componente viene smontato
        return () => {
            document.body.removeChild(tag);
            document.body.removeChild(script);
        };
    }, []);

    return null;  // Non renderizza nulla visivamente, si occupa solo dello script.
}