import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RenderFields from "./RenderFields";
import { useHomeWorkRefundsFields, useHomeWorkRefundsLegsFields } from "./settingsFields";

export default function HomeWorkRefunds({ values, setValues, onError }) {

    const { t } = useTranslation();
    let [error, setError] = useState({});
    let fields = useHomeWorkRefundsFields(values, setValues, setError, onError)
    let fieldsLeg = useHomeWorkRefundsLegsFields(values, setValues, setError, onError)

    useEffect(() => {
        if (values.isActiveHomeWorkRefunds + "" === "true") {
            checkError("homeWorkPathTolerancePerc", values.homeWorkPathTolerancePerc);
            checkError("valueKmHomeWorkBike", values.valueKmHomeWorkBike);
            if (values.addressValidation + "" === "true") checkError("emailAddressApprovalManager", values.emailAddressApprovalManager);
        }
    }, [values])

    const checkError = (prop, value) => {
        if (error && error[prop]) {
            delete error[prop]
            if (Object.keys(error).length === 0) onError(false)
        }
        if (prop === "emailAddressApprovalManager" && !value) {
            onError(true);
            setError({ ...error, emailAddressApprovalManager: t('mandatoryField') })
        }
        if (prop === "valueKmHomeWorkBike" && !value) {
            onError(true);
            setError({ ...error, valueKmHomeWorkBike: t('mandatoryField') })
        }
        if (prop === "homeWorkPathTolerancePerc" && !value) {
            onError(true);
            setError({ ...error, homeWorkPathTolerancePerc: t('mandatoryField') })
        }
    }

    const onTextChange = (prop) => (value) => {
        let newValues = { ...values };
        newValues[prop] = value;
        setValues(newValues);
        checkError(prop, value)
    }

    return <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "0.5rem" }}>

        <RenderFields fields={fields} values={values} error={error} onTextChange={onTextChange} />

        {/* <Grid xs="12" sx={{ my: 1 }}>
            <Typography textAlign={'center'} sx={{ color: 'gray' }} >{values.homeWorkRefundType === "euro" ? t('valueKmHomeWorkBikeExplainEuro') : t('valueKmHomeWorkBikeExplainPoints')}</Typography>
        </Grid> */}

        <RenderFields fields={fieldsLeg} values={values} error={error} onTextChange={onTextChange} />
    </Grid>

}