const synonyms = {
    Abc: 'alphabet character font letter symbol text type',
    AccessAlarm: 'clock time',
    AccessAlarms: 'clock time',
    Accessibility: 'accessible body handicap help human people person user',
    AccessibilityNew: 'accessible arms body handicap help human people person user',
    Accessible: 'accessibility body handicap help human people person user wheelchair',
    AccessibleForward:
      'accessibility body handicap help human people person wheelchair',
    AccessTime: 'clock time',
    AccountBalance:
      'bank bill building card cash coin commerce court credit currency dollars finance money online payment structure temple transaction',
    AccountBalanceWallet:
      'bank bill card cash coin commerce credit currency dollars finance money online payment transaction',
    AccountBox: 'avatar face human people person profile square thumbnail user',
    AccountCircle: 'avatar face human people person profile thumbnail user',
    AccountTree:
      'analytics chart connect data diagram flow infographic measure metrics process project sitemap square statistics structure tracking',
    AcUnit: 'air cold conditioner freeze snowflake temperature weather winter',
    Adb: 'android bridge debug',
    Add: '+ create item new plus symbol',
    AddAlarm: 'clock plus time',
    AddAlert:
      '+ active alarm announcement bell callout chime information new notifications notify plus reminder ring sound symbol',
    AddAPhoto: '+ camera lens new photography picture plus symbol',
    AddBox: 'create new plus square symbol',
    AddBusiness:
      '+ bill building card cash coin commerce company credit currency dollars market money new online payment plus retail shopping storefront symbol',
    AddCard:
      '+ bill cash coin commerce cost credit currency dollars finance money new online payment plus price shopping symbol',
    Addchart:
      '+ analytics bars data diagram infographic measure metrics new plus statistics symbol tracking',
    AddCircle: '+ create new plus',
    AddCircleOutline: '+ create new plus',
    AddComment: '+ bubble chat communicate feedback message new plus speech symbol',
    AddIcCall: '+ cell contact device hardware mobile new plus symbol telephone',
    AddLink: 'attach clip new plus symbol',
    AddLocation: '+ destination direction gps maps new pin place plus stop symbol',
    AddLocationAlt: '+ destination direction maps new pin place plus stop symbol',
    AddModerator:
      '+ certified new plus privacy private protection security shield symbol verified',
    AddPhotoAlternate:
      '+ image landscape mountains new photography picture plus symbol',
    AddReaction:
      '+ emoji emotions expressions face feelings glad happiness happy insert like mood new person pleased plus smile smiling social survey symbol',
    AddRoad:
      '+ destination direction highway maps new plus stop street symbol traffic',
    AddShoppingCart:
      'card cash checkout coin commerce credit currency dollars money online payment plus',
    AddTask: '+ approve check circle completed increase mark ok plus select tick yes',
    AddToDrive:
      '+ application backup cloud data files folders gdrive google plus recovery shortcut storage',
    AddToHomeScreen:
      'Android add arrow cell device hardware iOS mobile phone tablet to up',
    AddToPhotos: 'collection image landscape mountains photography picture plus',
    AddToQueue:
      '+ Android backlog chrome desktop device display hardware iOS lineup mac monitor new plus screen symbol television watch web window',
    AdfScanner: 'document feeder machine office',
    Adjust:
      'alter center circles control dot edit filter fix image mix move setting slider sort switch target tune',
    AdminPanelSettings:
      'account avatar certified face human people person privacy private profile protection security shield user verified',
    AdUnits:
      'Android banner cell device hardware iOS mobile notifications phone tablet top',
    Agriculture:
      'automobile cars cultivation farm harvest maps tractor transport travel truck vehicle',
    Air: 'blowing breeze flow wave weather wind',
    Airlines: 'airplane airport flight transportation travel trip',
    AirlineSeatFlat:
      'bed body business class first human people person rest sleep travel',
    AirlineSeatFlatAngled:
      'bed body business class first human people person rest sleep travel',
    AirlineSeatIndividualSuite:
      'bed body business class first human people person rest sleep travel',
    AirlineSeatLegroomExtra: 'body feet human people person sitting space travel',
    AirlineSeatLegroomNormal: 'body feet human people person sitting space travel',
    AirlineSeatLegroomReduced: 'body feet human people person sitting space travel',
    AirlineSeatReclineExtra:
      'body feet human legroom people person sitting space travel',
    AirlineSeatReclineNormal:
      'body extra feet human legroom people person sitting space travel',
    AirlineStops:
      'arrow destination direction layover location maps place transportation travel trip',
    AirplanemodeActive: 'flight flying on signal',
    AirplanemodeInactive:
      'airport disabled enabled flight flying maps offline slash transportation travel',
    AirplaneTicket: 'airport boarding flight fly maps pass transportation travel',
    Airplay:
      'apple arrow cast connect control desktop device display monitor screen signal television tv',
    AirportShuttle:
      'automobile bus cars commercial delivery direction maps mini public transportation travel truck van vehicle',
    Alarm: 'alart bell clock countdown date notification schedule time',
    AlarmAdd:
      '+ alart bell clock countdown date new notification plus schedule symbol time',
    AlarmOff:
      'alart bell clock disabled duration enabled notification slash stop timer watch',
    AlarmOn:
      'alart bell checkmark clock disabled duration enabled notification off ready slash start timer watch',
    Album:
      'artist audio bvb cd computer data disk file music play record sound storage track vinyl',
    AlignHorizontalCenter: 'alignment format layout lines paragraph rules style text',
    AlignHorizontalLeft: 'alignment format layout lines paragraph rules style text',
    AlignHorizontalRight: 'alignment format layout lines paragraph rules style text',
    AlignVerticalBottom: 'alignment format layout lines paragraph rules style text',
    AlignVerticalCenter: 'alignment format layout lines paragraph rules style text',
    AlignVerticalTop: 'alignment format layout lines paragraph rules style text',
    AllInbox: 'Inbox delivered delivery email letter message post send',
    AllInclusive:
      'endless forever infinite infinity loop mobius neverending strip sustainability sustainable',
    AllOut: 'arrows circle directional expand shape',
    AlternateEmail: '@ address contact tag',
    AltRoute:
      'alternate alternative arrows direction maps navigation options other routes split symbol',
    Analytics:
      'assessment bar chart data diagram infographic measure metrics statistics tracking',
    Anchor: 'google logo',
    Android: 'brand character logo mascot operating system toy',
    Animation: 'circles film motion movement movie moving sequence video',
    Announcement:
      '! alert attention balloon bubble caution chat comment communicate danger error exclamation feedback important mark message news notification speech symbol warning',
    Aod: 'Android always device display hardware homescreen iOS mobile phone tablet',
    Apartment:
      'accommodation architecture building city company estate flat home house office places real residence residential shelter units workplace',
    Api: 'developer development enterprise software',
    AppBlocking:
      'Android applications cancel cell device hardware iOS mobile phone stopped tablet',
    Apple: 'brand logo',
    AppRegistration: 'apps edit pencil register',
    Approval:
      'apply approvals approve certificate certification disapproval drive file impression ink mark postage stamp',
    Apps: 'all applications circles collection components dots grid homescreen icons interface squares ui ux',
    AppSettingsAlt:
      'Android applications cell device gear hardware iOS mobile phone tablet',
    AppShortcut:
      'bookmarked favorite highlight important mobile saved software special star',
    AppsOutage:
      'all applications circles collection components dots grid interface squares ui ux',
    Architecture: 'art compass design drawing engineering geometric tool',
    Archive: 'inbox mail store',
    ArrowBack:
      'application components direction disable_ios interface left navigation previous screen ui ux website',
    ArrowBackIos:
      'application chevron components direction disable_ios interface left navigation previous screen ui ux website',
    ArrowBackIosNew:
      'application chevron components direction disable_ios interface left navigation previous screen ui ux website',
    ArrowCircleDown: 'direction navigation',
    ArrowCircleLeft: 'direction navigation',
    ArrowCircleRight: 'direction navigation',
    ArrowCircleUp: 'direction navigation',
    ArrowDownward:
      'application components direction interface navigation screen ui ux website',
    ArrowDropDown:
      'application components direction interface navigation screen ui ux website',
    ArrowDropDownCircle:
      'application components direction interface navigation screen ui ux website',
    ArrowDropUp:
      'application components direction interface navigation screen ui ux website',
    ArrowForward:
      'application arrows components direction interface navigation right screen ui ux website',
    ArrowForwardIos:
      'application chevron components direction interface navigation next right screen ui ux website',
    ArrowLeft:
      'application backstack backward components direction interface navigation previous screen ui ux website',
    ArrowOutward:
      'application arrows components direction forward interface navigation right screen ui ux website',
    ArrowRight:
      'application components continue direction forward interface navigation screen ui ux website',
    ArrowRightAlt: 'arrows direction east navigation pointing shape',
    ArrowUpward:
      'application components direction interface navigation screen submit ui ux website',
    Article: 'clarify document file news page paper text writing',
    ArtTrack:
      'album artist audio display format image insert music photography picture sound tracks',
    AspectRatio: 'expand image monitor resize resolution scale screen square',
    Assessment:
      'analytics bars chart data diagram infographic measure metrics report statistics tracking',
    Assignment: 'article clipboard document task text writing',
    AssignmentInd: 'account clipboard document face people person profile task user',
    AssignmentLate:
      '! alert announcement attention caution clipboard danger document error exclamation important mark notification symbol task warning',
    AssignmentReturn: 'arrow back clipboard document left point retun task',
    AssignmentReturned: 'arrow clipboard document down point task',
    AssignmentTurnedIn:
      'approve checkmark clipboard complete document done finished ok select task tick validate verified yes',
    Assistant:
      'bubble chat comment communicate feedback message recommendation speech star suggestion twinkle',
    AssistantDirection:
      'destination location maps navigate navigation pin place right stop',
    AssistantPhoto: 'flag recommendation smart star suggestion',
    AssistWalker:
      'accessibility accessible body disability handicap help human injured injury mobility person',
    AssuredWorkload:
      'compliance confidential federal government regulatory secure sensitive',
    Atm: 'alphabet automated bill card cart cash character coin commerce credit currency dollars font letter machine money online payment shopping symbol teller text type',
    AttachEmail: 'attachment clip compose envelop letter link message send',
    AttachFile: 'add item link mail media paperclip',
    Attachment: 'compose file image item link paperclip',
    AttachMoney:
      'bill card cash coin commerce cost credit currency dollars finance online payment price profit sale symbol',
    Attractions: 'amusement entertainment ferris fun maps park places wheel',
    Attribution: 'attribute body copyright copywriter human people person',
    AudioFile: 'document key music note sound track',
    Audiotrack: 'key music note sound',
    AutoAwesome:
      'adjust editing enhance filter image photography photos setting stars',
    AutoAwesomeMosaic:
      'adjust collage editing enhance filter grid image layout photographs photography photos pictures setting',
    AutoAwesomeMotion:
      'adjust animation collage editing enhance filter image live photographs photography photos pictures setting video',
    AutoDelete: 'bin can clock date garbage remove schedule time trash',
    AutoFixHigh: 'adjust editing enhance erase magic modify pen stars tool wand',
    AutoFixNormal: 'edit erase magic modify stars wand',
    AutoFixOff: 'disabled edit enabled erase magic modify on slash stars wand',
    AutofpsSelect:
      'A alphabet character font frame frequency letter per rate seconds symbol text type',
    AutoGraph:
      'analytics chart data diagram infographic line measure metrics stars statistics tracking',
    AutoMode:
      'around arrows direction inprogress loading navigation nest refresh renew rotate turn',
    Autorenew:
      'around arrows cached direction inprogress loader loading navigation pending refresh rotate status turn',
    AutoStories: 'audiobook flipping pages reading story',
    AvTimer: 'clock countdown duration minutes seconds stopwatch',
    BabyChangingStation:
      'babies bathroom body children father human infant kids mother newborn people person toddler wc young',
    Backpack: 'bookbag knapsack storage travel',
    Backspace: 'arrow cancel clear correct delete erase remove',
    Backup: 'arrow cloud data drive files folders point storage submit upload',
    BackupTable: 'drive files folders format layout stack storage',
    Badge:
      'account avatar card certified employee face human identification name people person profile security user work',
    BakeryDining: 'bread breakfast brunch croissant food',
    Balance:
      'equal equilibrium equity impartiality justice parity stability. steadiness symmetry',
    Balcony:
      'architecture doors estate home house maps outside place real residence residential stay terrace window',
    Ballot: 'bullet bulllet election list point poll vote',
    BarChart:
      'analytics anlytics data diagram infographic measure metrics statistics tracking',
    BatchPrediction: 'bulb idea light',
    Bathroom: 'closet home house place plumbing shower sprinkler wash water wc',
    Bathtub: 'bathing bathroom clean home hotel human person shower travel',
    Battery0Bar: 'cell charge mobile power',
    Battery1Bar: 'cell charge mobile power',
    Battery2Bar: 'cell charge mobile power',
    Battery3Bar: 'cell charge mobile power',
    Battery4Bar: 'cell charge mobile power',
    Battery5Bar: 'cell charge mobile power',
    Battery6Bar: 'cell charge mobile power',
    BatteryAlert:
      '! attention caution cell charge danger error exclamation important mark mobile notification power symbol warning',
    BatteryChargingFull: 'cell charge lightening lightning mobile power thunderbolt',
    BatteryFull: 'cell charge mobile power',
    BatterySaver: '+ add charge charging new plus power symbol',
    BatteryStd: 'cell charge mobile plus power standard',
    BatteryUnknown:
      '? assistance cell charge help information mark mobile power punctuation question support symbol',
    BeachAccess: 'parasol places summer sunny umbrella',
    Bed: 'bedroom double full furniture home hotel house king night pillows queen rest size sleep',
    BedroomBaby:
      'babies children home horse house infant kid newborn rocking toddler young',
    BedroomChild:
      'children furniture home hotel house kid night pillows rest size sleep twin young',
    BedroomParent:
      'double full furniture home hotel house king master night pillows queen rest sizem sleep',
    Bedtime: 'nightime sleep',
    BedtimeOff: 'nightime sleep',
    Beenhere:
      'approve archive bookmark checkmark complete done favorite label library reading remember ribbon save select tag tick validate verified yes',
    Bento: 'box dinner food lunch meal restaurant takeout',
    BikeScooter: 'automobile cars maps transportation vehicle vespa',
    Biotech: 'chemistry laboratory microscope research science technology test',
    Blender: 'appliance cooking electric juicer kitchen machine vitamix',
    Blind:
      'accessibility accessible assist body cane disability handicap help human mobility person walker',
    Blinds: 'cover curtains nest open shutter sunshade',
    BlindsClosed: 'cover curtains nest shutter sunshade',
    Block:
      'allowed avoid banned cancel close disable entry exit not prohibited quit remove stop',
    Bloodtype: 'donate droplet emergency hospital medicine negative positive water',
    Bluetooth: 'cast connection device network paring streaming symbol wireless',
    BluetoothAudio: 'connection device music signal sound symbol',
    BluetoothConnected:
      'cast connection device network paring streaming symbol wireless',
    BluetoothDisabled:
      'cast connection device enabled network offline paring slash streaming symbol wireless',
    BluetoothDrive:
      'automobile cars cast connection device maps paring streaming symbol transportation travel vehicle wireless',
    BluetoothSearching: 'connection device network paring symbol wireless',
    BlurCircular: 'circle dots editing effect enhance filter',
    BlurLinear: 'dots editing effect enhance filter',
    BlurOff: 'disabled dots editing effect enabled enhance on slash',
    BlurOn: 'disabled dots editing effect enabled enhance filter off slash',
    Bolt: 'electric energy fast flash lightning power thunderbolt',
    Book: 'blog bookmark favorite label library reading remember ribbon save tag',
    Bookmark: 'archive favorite follow label library reading remember ribbon save tag',
    BookmarkAdd: '+ favorite plus remember ribbon save symbol',
    BookmarkAdded:
      'approve check complete done favorite remember save select tick validate verified yes',
    BookmarkBorder:
      'archive favorite label library outline reading remember ribbon save tag',
    BookmarkRemove: 'delete favorite minus remember ribbon save subtract',
    Bookmarks:
      'favorite label layers library multiple reading remember ribbon save stack tag',
    BookOnline:
      'Android admission appointment cell device event hardware iOS mobile pass phone reservation tablet ticket',
    BorderAll: 'doc editing editor spreadsheet stroke text type writing',
    BorderBottom: 'doc editing editor spreadsheet stroke text type writing',
    BorderClear: 'doc editing editor spreadsheet stroke text type writing',
    BorderColor:
      'all create doc editing editor marker pencil spreadsheet stroke text type writing',
    BorderHorizontal: 'doc editing editor spreadsheet stroke text type writing',
    BorderInner: 'doc editing editor spreadsheet stroke text type writing',
    BorderLeft: 'doc editing editor spreadsheet stroke text type writing',
    BorderOuter: 'doc editing editor spreadsheet stroke text type writing',
    BorderRight: 'doc editing editor spreadsheet stroke text type writing',
    BorderStyle: 'color doc editing editor spreadsheet stroke text type writing',
    BorderTop: 'doc editing editor spreadsheet stroke text type writing',
    BorderVertical: 'doc editing editor spreadsheet stroke text type writing',
    Boy: 'body gender human male people person social symbol',
    BrandingWatermark:
      'components copyright design emblem format identity interface layout logo screen stamp ui ux website window',
    BreakfastDining: 'bakery bread butter food toast',
    Brightness1: 'circle control crescent cresent level moon screen',
    Brightness2: 'circle control crescent cresent level moon night screen',
    Brightness3: 'circle control crescent cresent level moon night screen',
    Brightness4: 'circle control crescent cresent dark level moon night screen sun',
    Brightness5: 'circle control crescent cresent level moon screen sun',
    Brightness6: 'circle control crescent cresent level moon screen sun',
    Brightness7: 'circle control crescent cresent level light moon screen sun',
    BrightnessAuto: 'A control display level mobile monitor phone screen',
    BrightnessHigh: 'auto control mobile monitor phone',
    BrightnessLow: 'auto control mobile monitor phone',
    BrightnessMedium: 'auto control mobile monitor phone',
    BrokenImage: 'corrupt error landscape mountains photography picture torn',
    BrowseGallery: 'clock collection library stack watch',
    BrowserNotSupported:
      'disabled enabled internet off on page screen slash website www',
    BrowserUpdated:
      'Android arrow chrome desktop device display download hardware iOS mac monitor screen web window',
    BrunchDining: 'breakfast champagne champaign drink food lunch meal',
    Brush: 'art design draw editing painting tool',
    BubbleChart:
      'analytics bars data diagram infographic measure metrics statistics tracking',
    BugReport: 'animal file fix insect issue problem testing ticket virus warning',
    Build: 'adjust fix home nest repair spanner tools wrench',
    BuildCircle: 'adjust fix repair tool wrench',
    Bungalow:
      'architecture cottage estate home house maps place real residence residential stay traveling',
    BurstMode: 'image landscape mountains multiple photography picture',
    BusAlert:
      '! attention automobile cars caution danger error exclamation important maps mark notification symbol transportation vehicle warning',
    Business:
      'address apartment architecture building company estate flat home office place real residence residential shelter structure',
    BusinessCenter: 'baggage briefcase places purse suitcase work',
    Cabin:
      'architecture camping cottage estate home house log maps place real residence residential stay traveling wood',
    Cable: 'connection device electronics usb wire',
    Cached: 'around arrows inprogress loader loading refresh reload renew rotate',
    Cake: 'add baked birthday candles celebration dessert food frosting new party pastries pastry pie plus social sweet symbol',
    Calculate: '+ - = calculator count finance math',
    CalendarMonth: 'date event schedule today',
    CalendarToday: 'date event month remember reminder schedule week',
    CalendarViewDay:
      'date event format grid layout month remember reminder schedule today week',
    CalendarViewMonth: 'date event format grid layout schedule today',
    CalendarViewWeek: 'date event format grid layout month schedule today',
    Call: 'cell contact device hardware mobile talk telephone',
    CallEnd: 'cell contact device hardware mobile talk telephone',
    CallMade: 'arrow device mobile',
    CallMerge: 'arrow device mobile',
    CallMissed: 'arrow device mobile',
    CallMissedOutgoing: 'arrow device mobile',
    CallReceived: 'arrow device mobile',
    CallSplit: 'arrow device mobile',
    CallToAction:
      'alert bar components cta design information interface layout message notification screen ui ux website window',
    Camera: 'album aperture lens photography picture record screenshot shutter',
    CameraAlt: 'image photography picture',
    CameraEnhance: 'important lens photography picture quality special star',
    CameraFront: 'body human lens mobile person phone photography portrait selfie',
    CameraIndoor:
      'architecture building estate filming home house image inside motion nest picture place real residence residential shelter videography',
    CameraOutdoor:
      'architecture building estate filming home house image motion nest outside picture place real residence residential shelter videography',
    CameraRear: 'front lens mobile phone photography picture portrait selfie',
    CameraRoll: 'film image library photography',
    Cameraswitch: 'arrows flip rotate swap view',
    Campaign: 'alert announcement loud megaphone microphone notification speaker',
    Cancel: 'circle close cross disable exit status stop',
    CancelPresentation:
      'close device exit no quit remove screen share slide stop website window',
    CancelScheduleSend: 'email no quit remove share stop x',
    CandlestickChart:
      'analytics data diagram finance infographic measure metrics statistics tracking',
    CarCrash:
      'accident automobile cars collision direction maps public transportation vehicle',
    CardGiftcard:
      'account balance bill cart cash certificate coin commerce creditcard currency dollars money online payment present shopping',
    CardMembership:
      'bill bookmark cash certificate coin commerce cost creditcard currency dollars finance loyalty money online payment shopping subscription',
    CardTravel:
      'bill cash coin commerce cost creditcard currency dollars finance membership miles money online payment trip',
    Carpenter: 'building construction cutting handyman repair saw tool',
    CarRental: 'automobile cars key maps transportation vehicle',
    CarRepair: 'automobile cars maps transportation vehicle',
    Cases: 'baggage briefcase business purse suitcase',
    Casino: 'dice dots entertainment gamble gambling games luck places',
    Cast: 'Android airplay chromecast connect desktop device display hardware iOS mac monitor screencast streaming television tv web window wireless',
    CastConnected:
      'Android airplay chromecast desktop device display hardware iOS mac monitor screencast streaming television tv web window wireless',
    CastForEducation:
      'Android airplay chrome connect desktop device display hardware iOS learning lessons mac monitor screencast streaming teaching television tv web window wireless',
    Castle: 'fortress mansion palace',
    CatchingPokemon: 'go pokestop travel',
    Category: 'categories circle collection items product sort square triangle',
    Celebration: 'activity birthday event fun party',
    CellTower: 'broadcast casting network signal transmitting wireless',
    CellWifi: 'connection data internet mobile network phone service signal wireless',
    CenterFocusStrong: 'camera image lens photography zoom',
    CenterFocusWeak: 'camera image lens photography zoom',
    Chair:
      'comfort couch decoration furniture home house living lounging loveseat room seating sofa',
    ChairAlt: 'cahir furniture home house kitchen lounging seating table',
    Chalet:
      'architecture cottage estate home house maps place real residence residential stay traveling',
    ChangeCircle: 'around arrows direction navigation rotate',
    ChangeHistory: 'shape triangle',
    ChargingStation:
      'Android battery cell device electric hardware iOS lightning mobile phone tablet thunderbolt',
    Chat: 'bubble comment communicate feedback message speech talk text',
    ChatBubble: 'comment communicate feedback message speech talk text',
    ChatBubbleOutline: 'comment communicate feedback message speech talk text',
    Check:
      'checkmark complete confirm correct disable_ios done enter okay purchased select success tick yes',
    CheckBox:
      'approved button checkmark component control form ok selected selection square success tick toggle ui yes',
    CheckBoxOutlineBlank:
      'button checkmark component control deselected empty form selection square tick toggle ui',
    CheckCircle:
      'approve checkmark complete done download finished ok select success tick upload validate verified yes',
    CheckCircleOutline:
      'approve checkmark complete done finished ok select success tick validate verified yes',
    Checkroom: 'check closet clothes coat hanger',
    ChevronLeft: 'arrows back direction disable_ios triangle',
    ChevronRight: 'arrows direction forward triangle',
    ChildCare: 'babies baby children face infant kids newborn toddler young',
    ChildFriendly:
      'baby care carriage children infant kid newborn stroller toddler young',
    ChromeReaderMode: 'text',
    Church: 'christianity religion spiritual worship',
    Circle: 'bullet button dot full geometry moon period radio',
    CircleNotifications: 'active alarm alert bell chime notify reminder ring sound',
    Class:
      'archive bookmark category favorite item label library reading remember ribbon save tag',
    CleanHands: 'bacteria disinfect germs gesture sanitizer',
    CleaningServices: 'dust sweep',
    Clear: 'allowed back cancel correct cross delete disable erase exit not times',
    ClearAll: 'delete document erase format lines list notifications wipe',
    Close: 'allowed cancel cross disable exit not status stop times',
    ClosedCaption:
      'accessible alphabet character decoder font language letter media movies subtitles symbol text tv type',
    ClosedCaptionDisabled:
      'accessible alphabet character decoder enabled font language letter media movies off slash subtitles symbol text tv type',
    ClosedCaptionOff:
      'accessible alphabet character decoder font language letter media movies outline subtitles symbol text tv type',
    CloseFullscreen: 'action arrows collapse direction minimize',
    Cloud: 'connection internet network sky upload weather',
    CloudCircle:
      'application backup connection drive files folders internet network sky storage upload',
    CloudDone:
      'application approve backup checkmark complete connection drive files folders internet network ok select sky storage tick upload validate verified yes',
    CloudDownload:
      'application arrow backup connection drive files folders internet network sky storage upload',
    CloudOff:
      'application backup connection disabled drive enabled files folders internet network offline sky slash storage upload',
    CloudQueue: 'connection internet network sky upload',
    CloudSync:
      'application around backup connection drive files folders inprogress internet loading network refresh renew rotate sky storage turn upload',
    CloudUpload:
      'application arrow backup connection download drive files folders internet network sky storage',
    Co2: 'carbon chemical dioxide gas',
    Code: 'brackets css developer engineering html parenthesis platform',
    CodeOff:
      'brackets css developer disabled enabled engineering html on platform slash',
    Coffee: 'beverage cup drink mug plate set tea',
    CoffeeMaker: 'appliances beverage cup drink machine mug',
    Collections:
      'album gallery image landscape library mountains photography picture stack',
    CollectionsBookmark:
      'album archive favorite gallery label library reading remember ribbon save stack tag',
    Colorize: 'color dropper extract eye picker pipette tool',
    ColorLens: 'art paint pallet',
    Comment: 'bubble chat communicate document feedback message note outline speech',
    CommentBank:
      'archive bookmark bubble cchat communicate favorite label library message remember ribbon save speech tag',
    CommentsDisabled:
      'bubble chat communicate enabled feedback message offline on slash speech',
    Commit: 'accomplish bind circle dedicate execute line perform pledge',
    Commute: 'automobile car direction maps public train transportation trip vehicle',
    Compare:
      'adjustment editing edits enhance fix images photography photos scan settings',
    CompareArrows:
      'collide directional facing left pointing pressure push right together',
    CompassCalibration:
      'connection internet location maps network refresh service signal wifi wireless',
    Compress: 'arrows collide pressure push together',
    Computer:
      'Android chrome desktop device hardware iOS laptop mac monitor pc web window',
    ConfirmationNumber: 'admission entertainment event ticket',
    ConnectedTv:
      'Android airplay chrome desktop device display hardware iOS mac monitor screencast streaming television web window wireless',
    ConnectingAirports: 'airplanes flight transportation travel trip',
    ConnectWithoutContact: 'communicating distance people signal socialize',
    Construction:
      'build carpenter equipment fix hammer improvement industrial industry repair tools wrench',
    ContactEmergency:
      'account avatar call cell contacts face human information mobile people person phone profile user',
    Contactless:
      'applepay bluetooth cash connection connectivity credit device finance payment signal tap transaction wifi wireless',
    ContactMail:
      'account address avatar communicate email face human information message people person profile user',
    ContactPage:
      'account avatar data document drive face folders human people person profile sheet slide storage user writing',
    ContactPhone:
      'account avatar call communicate face human information message mobile number people person profile user',
    Contacts:
      'account address avatar call cell face human information mobile number people person phone profile user',
    ContactSupport:
      '? alert announcement bubble chat comment communicate help information mark message punctuation speech symbol vquestion',
    ContentCopy: 'cut document duplicate file multiple past',
    ContentCut: 'copy document file past scissors trim',
    ContentPaste: 'clipboard copy cut document file multiple',
    ContentPasteGo: 'clipboard disabled document enabled file slash',
    ContentPasteOff: 'clipboard disabled document enabled file slash',
    ContentPasteSearch: 'clipboard document file find trace track',
    Contrast:
      'black editing effect filter grayscale images photography pictures settings white',
    ControlCamera: 'adjust arrows center direction left move right',
    ControlPoint: '+ add circle plus',
    ControlPointDuplicate: '+ add circle multiple new plus symbol',
    Cookie: 'biscuit cookies dessert wafer',
    CoPresent: 'arrow co-present presentation screen share slides togather website',
    CopyAll: 'content cut document file multiple page paper past',
    Copyright: 'alphabet character circle emblem font legal letter owner symbol text',
    Coronavirus: '19 bacteria covid disease germs illness sick social',
    CorporateFare:
      'architecture building business estate organization place real residence residential shelter',
    Cottage:
      'architecture beach estate home house lake lodge maps place real residence residential stay traveling',
    Countertops: 'home house kitchen sink table',
    Create: 'compose editing input item new pencil write writing',
    CreateNewFolder:
      '+ add data directory document drive file plus sheet slide storage symbol',
    CreditCard:
      'bill cash charge coin commerce cost creditcard currency dollars finance information money online payment price shopping symbol',
    CreditCardOff:
      'charge commerce cost disabled enabled finance money online payment slash',
    CreditScore:
      'approve bill card cash check coin commerce complete cost currency dollars done finance loan mark money ok online payment select symbol tick validate verified yes',
    Crib: 'babies baby bassinet bed children cradle infant kid newborn sleeping toddler',
    CrisisAlert:
      '! attention bullseye caution danger error exclamation important mark notification symbol target warning',
    Crop: 'adjustments area editing frame images photos rectangle settings size square',
    Crop169:
      'adjustments area by editing frame images photos picture rectangle settings size square',
    Crop32:
      'adjustments area by editing frame images photos picture rectangle settings size square',
    Crop54:
      'adjustments area by editing frame images photos picture rectangle settings size square',
    Crop75:
      'adjustments area by editing frame images photos picture rectangle settings size square',
    CropDin:
      'adjustments area editing frame images photos picture rectangle settings size square',
    CropFree:
      'adjustments barcode editing focus frame image photos qrcode settings size square zoom',
    CropLandscape:
      'adjustments area editing frame images photos picture settings size square',
    CropOriginal:
      'adjustments area editing frame images photos picture settings size square',
    CropPortrait:
      'adjustments area editing frame images photos picture rectangle settings size square',
    CropRotate:
      'adjustments area arrows editing frame images photos settings size turn',
    CropSquare: 'adjustments area editing frame images photos rectangle settings size',
    Css: 'alphabet brackets character code developer engineering font html letter platform symbol text type',
    CurrencyBitcoin:
      'bill blockchain card cash commerce cost credit digital dollars finance franc money online payment price shopping symbol',
    CurrencyExchange:
      '360 around arrows cash coin commerce direction dollars inprogress money pay renew rotate sync turn universal',
    CurrencyFranc:
      'bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    CurrencyLira:
      'bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    CurrencyPound:
      'bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    CurrencyRuble:
      'bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    CurrencyRupee:
      'bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    CurrencyYen:
      'bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    CurrencyYuan:
      'bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    Curtains: 'blinds cover nest open shutter sunshade',
    CurtainsClosed: 'blinds cover nest shutter sunshade',
    Cyclone: 'crisis disaster natural rain storm weather winds',
    Dangerous: 'broken fix no sign stop update warning wrong',
    DarkMode: 'application device interface moon night silent theme ui ux website',
    Dashboard: 'cards format layout rectangle shapes square website',
    DashboardCustomize: 'cards format layout rectangle shapes square website',
    DataArray: 'brackets coder parentheses',
    DataObject: 'brackets coder parentheses',
    DataSaverOff:
      'analytics bars chart diagram donut infographic measure metrics ring statistics tracking',
    DataSaverOn:
      '+ add analytics chart diagram infographic measure metrics new plus ring statistics symbol tracking',
    DataThresholding: 'hidden privacy thresold',
    DataUsage:
      'analytics chart circle diagram infographic measure metrics statistics tracking',
    DateRange:
      'agenda calendar event month remember reminder schedule time today week',
    Deblur: 'adjust editing enhance face image lines photography sharpen',
    Deck: 'chairs furniture garden home house outdoors outside patio social terrace umbrella yard',
    Dehaze: 'adjust editing enhance image lines photography remove',
    Delete: 'bin garbage junk recycle remove trashcan',
    DeleteForever: 'bin cancel exit garbage junk recycle remove trashcan',
    DeleteOutline: 'bin can garbage remove trash',
    DeleteSweep: 'bin garbage junk recycle remove trashcan',
    DeliveryDining:
      'food meal restaurant scooter takeout transportation vehicle vespa',
    DensityLarge: 'horizontal lines rules',
    DensityMedium: 'horizontal lines rules',
    DensitySmall: 'horizontal lines rules',
    DepartureBoard:
      'automobile bus cars clock maps public schedule time transportation travel vehicle',
    Description:
      'article bill data document drive file folders invoice item notes page paper sheet slide text writing',
    Deselect: 'all disabled enabled off selection slash square tool',
    DesignServices: 'compose create draft editing input pencil ruler write writing',
    DesktopAccessDisabled:
      'Android apple chrome device display enabled hardware iOS mac monitor offline pc screen slash web window',
    DesktopMac:
      'Android apple chrome device display hardware iOS monitor pc screen web window',
    DesktopWindows:
      'Android chrome device display hardware iOS mac monitor pc screen television tv web',
    Details: 'editing enhance image photography sharpen triangle',
    DeveloperBoard: 'computer development devkit hardware microchip processor',
    DeveloperBoardOff:
      'computer development disabled enabled hardware microchip on processor slash',
    DeveloperMode:
      'Android bracket cell code development device engineer hardware iOS mobile phone tablet',
    DeviceHub:
      'Android circle computer desktop hardware iOS laptop mobile monitor phone square tablet triangle watch wearable web',
    Devices:
      'Android computer desktop hardware iOS laptop mobile monitor phone tablet watch wearable web',
    DevicesFold: 'Android cell foldable hardware iOS mobile phone tablet',
    DevicesOther:
      'Android cell chrome desktop gadget hardware iOS ipad mac mobile monitor phone smartwatch tablet vr wearables window',
    DeviceThermostat: 'celsius fahrenheit temperature thermometer',
    DeviceUnknown:
      '? Android assistance cell hardware help iOS information mark mobile phone punctuation question support symbol tablet',
    DialerSip:
      'alphabet call cell character contact device font hardware initiation internet letter mobile over protocol routing session symbol telephone text type voice',
    Dialpad: 'buttons call contact device dots mobile numbers phone',
    Diamond: 'fashion gems jewelry logo retail valuables',
    Difference: 'compare content copy cut document duplicate file multiple past',
    Dining: 'cafeteria cutlery diner eating fork room spoon',
    DinnerDining: 'breakfast food fork lunch meal restaurant spaghetti utensils',
    Directions: 'arrow maps naviate right route sign traffic',
    DirectionsBike: 'bicycle human maps person public route transportation',
    DirectionsBoat: 'automobile cars ferry maps public transportation vehicle',
    DirectionsBoatFilled: 'automobile cars ferry maps public transportation vehicle',
    DirectionsBus: 'automobile cars maps public transportation vehicle',
    DirectionsBusFilled: 'automobile cars maps public transportation vehicle',
    DirectionsCar: 'automobile cars maps public transportation vehicle',
    DirectionsCarFilled: 'automobile cars maps public transportation vehicle',
    DirectionsOff: 'arrow disabled enabled maps right route sign slash traffic',
    DirectionsRailway: 'automobile cars maps public train transportation vehicle',
    DirectionsRailwayFilled:
      'automobile cars maps public train transportation vehicle',
    DirectionsRun: 'body health human jogging maps people person route running walk',
    DirectionsSubway: 'automobile cars maps public rail train transportation vehicle',
    DirectionsSubwayFilled:
      'automobile cars maps public rail train transportation vehicle',
    DirectionsTransit:
      'automobile cars maps metro public rail subway train transportation vehicle',
    DirectionsTransitFilled:
      'automobile cars maps public rail subway train transportation vehicle',
    DirectionsWalk: 'body human jogging maps people person route run',
    DirtyLens: 'camera photography picture splat',
    DisabledByDefault: 'box cancel close exit no quit remove square stop',
    DiscFull:
      '! alert attention caution cd danger error exclamation important mark music notification storage symbol vinyl warning',
    DisplaySettings:
      'Android application change chrome desktop details device gear hardware iOS information mac monitor options personal screen service web window',
    Diversity1:
      'committee diverse family friends groups heart humans network people persons social team',
    Diversity2:
      'committee diverse family friends groups heart humans network people persons social team',
    Diversity3:
      'committee diverse family friends groups humans network people persons social team',
    Dns: 'address bars domain information ip list lookup name network server system',
    Dock: 'Android cell charger charging connector device hardware iOS mobile phone power station tablet',
    DocumentScanner:
      'article data drive file folders notes page paper sheet slide text writing',
    DoDisturb: 'cancel close denied deny remove silence stop',
    DoDisturbAlt: 'cancel close denied deny remove silence stop',
    DoDisturbOff:
      'cancel close denied deny disabled enabled on remove silence slash stop',
    DoDisturbOn:
      'cancel close denied deny disabled enabled off remove silence slash stop',
    Domain:
      'apartment architecture building business estate home place real residence residential shelter web www',
    DomainAdd:
      '+ apartment architecture building business estate home new place plus real residence residential shelter symbol web www',
    DomainDisabled:
      'apartment architecture building business company enabled estate home internet maps office offline on place real residence residential slash website',
    DomainVerification:
      'application approve check complete design desktop done interface internet layout mark ok screen select tick ui ux validate verified website window www yes',
    Done: 'approve checkmark complete disable_ios finished ok select success tick validate verified yes',
    DoneAll:
      'approve checkmark complete finished layers multiple ok select stack success tick validate verified yes',
    DoneOutline:
      'all approve checkmark complete finished ok select success tick validate verified yes',
    DoNotDisturb: 'cancel close denied deny remove silence stop',
    DoNotDisturbAlt: 'cancel close denied deny remove silence stop',
    DoNotDisturbOff:
      'cancel close denied deny disabled enabled on remove silence slash stop',
    DoNotDisturbOn:
      'cancel close denied deny disabled enabled off remove silence slash stop',
    DoNotDisturbOnTotalSilence: 'busy mute on quiet total',
    DoNotStep: 'boot disabled enabled feet foot off on shoe slash sneaker',
    DoNotTouch: 'disabled enabled fingers gesture hand off on slash',
    DonutLarge:
      'analytics chart circle complete data diagram infographic inprogress, measure metrics pie statistics tracking',
    DonutSmall:
      'analytics chart circle data diagram infographic inprogress measure metrics pie statistics tracking',
    DoorBack: 'closed doorway entrance exit home house',
    Doorbell: 'alarm home house ringing',
    DoorFront: 'closed doorway entrance exit home house',
    DoorSliding: 'automatic doorway double entrance exit glass home house two',
    DoubleArrow: 'arrows chevron direction multiple navigation right',
    DownhillSkiing:
      'athlete athletic body entertainment exercise hobby human people person ski snow social sports travel winter',
    Download: 'arrow downloads drive install upload',
    DownloadDone: 'arrows check downloads drive installed ok tick upload',
    DownloadForOffline: 'arrow circle for install offline upload',
    Downloading: 'arrow circle downloads install pending progress upload',
    Drafts: 'document email envelope file letter message read',
    DragHandle:
      'application components design interface layout lines menu move screen ui ux website window',
    DragIndicator:
      'application circles components design dots drop interface layout mobile monitor move phone screen shape shift tablet ui ux website window',
    DriveEta:
      'automobile cars destination direction estimate maps public transportation travel trip vehicle',
    DriveFileMove:
      'arrows data direction document folders right sheet side slide storage',
    DriveFileRenameOutline: 'compose create draft editing input pencil write writing',
    DriveFolderUpload: 'arrow data document file sheet slide storage',
    Dry: 'air bathroom dryer fingers gesture hand wc',
    DryCleaning: 'hanger hotel laundry places service towel',
    Duo: 'call chat conference device video',
    Dvr: 'Android audio chrome computer desktop device display electronic hardware iOS laptop list mac monitor recorder screen tv video web window',
    DynamicFeed: 'layer live mail_outline multiple post refresh update',
    DynamicForm: 'code electric fast lightning lists questionnaire thunderbolt',
    Earbuds: 'accessory audio earphone headphone listen music sound',
    EarbudsBattery: 'accessory audio charging earphone headphone listen music sound',
    East: 'arrow directional maps navigation right',
    EdgesensorHigh:
      'Android cell device hardware iOS mobile move phone sensitivity tablet vibrate',
    EdgesensorLow:
      'Android cell device hardware iOS mobile move phone sensitivity tablet vibrate',
    Edit: 'compose create editing input new pencil write writing',
    EditAttributes:
      'approve attribution check complete done mark ok select tick validate verified yes',
    EditLocation: 'destination direction gps maps pencil pin place stop write',
    EditLocationAlt: 'pencil pin',
    EditNotifications:
      'active alarm alert bell chime compose create draft editing input new notify pencil reminder ring sound write writing',
    EditOff:
      'compose create disabled draft editing enabled input new offline on pencil slash write writing',
    EditRoad: 'destination direction highway maps pencil street traffic',
    Egg: 'breakfast brunch food',
    EggAlt: 'breakfast brunch food',
    EighteenMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    EightK:
      '8000 8K alphabet character digit display font letter number pixels resolution symbol text type video',
    EightKPlus:
      '+ 7000 8K alphabet character digit display font letter number pixels resolution symbol text type video',
    EightMp:
      'camera digit font image letters megapixels number quality resolution symbol text type',
    EightteenMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    Eject: 'arrow disc drive dvd player remove triangle up usb',
    Elderly: 'body cane human old people person senior',
    ElderlyWoman:
      'body cane female gender girl human lady old people person senior social symbol women',
    ElectricalServices: 'charge cord plug power wire',
    ElectricBike:
      'automobile cars electricity maps scooter transportation travel vehicle vespa',
    ElectricBolt: 'energy fast lightning nest thunderbolt',
    ElectricCar: 'automobile cars electricity maps transportation travel vehicle',
    ElectricMeter:
      'energy fast lightning measure nest thunderbolt usage voltage volts',
    ElectricMoped:
      'automobile bike cars maps scooter transportation travel vehicle vespa',
    ElectricRickshaw: 'automobile cars india maps transportation truck vehicle',
    ElectricScooter: 'automobile bike cars maps transportation vehicle vespa',
    Elevator: 'body down human people person up',
    ElevenMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    Email: 'envelope letter message note post receive send write',
    EmergencyRecording:
      'alert attention camera caution danger filming hardware image important motion notification picture videography warning',
    EmergencyShare: 'alert attention caution danger important notification warning',
    EMobiledata: 'alphabet font letter text type',
    EmojiEmotions:
      '+ add emoticon expressions face feelings glad happiness happy insert like mood new person pleased plus smiley smiling social survey symbol',
    EmojiEvents:
      'achievement award chalice champion cup first prize reward sport trophy winner',
    EmojiFoodBeverage: 'coffee cup dring drink mug plate set tea',
    EmojiNature: 'animal bee daisy flower honey insect ladybug petals spring summer',
    EmojiObjects: 'creative idea lamp lightbulb solution thinking',
    EmojiPeople: 'arm body greeting human person social wave waving',
    EmojiSymbols: 'ampersand character hieroglyph music note percent sign',
    EmojiTransportation:
      'architecture automobile building cars commute company direction estate maps office place public real residence residential shelter travel vehicle',
    EnergySavingsLeaf: 'eco leaves nest usage',
    Engineering:
      'body cogs cogwheel construction fixing gears hat helmet human maintenance people person setting worker',
    EnhancedEncryption:
      '+ add locked new password plus privacy private protection safety secure security symbol',
    Equalizer:
      'adjustment analytics chart data graph measure metrics music noise sound static statistics tracking volume',
    Error:
      '! alert announcement attention caution circle danger exclamation feedback important mark notification problem symbol warning',
    ErrorOutline:
      '! alert announcement attention caution circle danger exclamation feedback important mark notification problem symbol warning',
    Escalator: 'down staircase up',
    EscalatorWarning: 'body child human kid parent people person',
    Euro: 'bill card cash coin commerce cost credit currency dollars euros finance money online payment price profit shopping symbol',
    EuroSymbol:
      'bill card cash coin commerce cost credit currency dollars finance money online payment price profit',
    Event: 'agenda calendar date item mark month range remember reminder today week',
    EventAvailable:
      'agenda approve calendar check complete done item mark ok schedule select tick time validate verified yes',
    EventBusy:
      'agenda calendar cancel close date exit item no remove schedule stop time unavailable',
    EventNote: 'agenda calendar date item schedule text time writing',
    EventRepeat:
      'around calendar date day inprogress loading month refresh renew rotate schedule turn',
    EventSeat: 'assigned bench chair furniture reservation row section sit',
    EvStation:
      'automobile cars charge charging electricity filling fuel gasoline maps places power station transportation vehicle',
    ExitToApp:
      'application arrow back components design export interface layout leave login logout mobile monitor move output phone pointing quit register right screen signin signout signup tablet ux website window',
    Expand: 'arrows compress enlarge grow move push together',
    ExpandCircleDown: 'arrows chevron collapse direction expandable list more',
    ExpandLess: 'arrows chevron collapse direction expandable list up',
    ExpandMore: 'arrows chevron collapse direction down expandable list',
    Explicit:
      'adult alphabet character content font language letter media movies music parent rating supervision symbol text type',
    Explore:
      'compass destination direction east location maps needle north south travel west',
    ExploreOff:
      'compass destination direction disabled east enabled location maps needle north slash south travel west',
    Exposure:
      'add brightness contrast editing effect image minus photography picture plus settings subtract',
    Extension: 'add-ons app extended game item jigsaw piece plugin puzzle shape',
    ExtensionOff: 'disabled enabled extended jigsaw piece puzzle shape slash',
    Face: 'account avatar emoji eyes human login logout people person profile recognition security social thumbnail unlock user',
    Face2:
      'account emoji eyes human login logout people person profile recognition security social thumbnail unlock user',
    Face3:
      'account emoji eyes human login logout people person profile recognition security social thumbnail unlock user',
    Face4:
      'account emoji eyes human login logout people person profile recognition security social thumbnail unlock user',
    Face5:
      'account emoji eyes human login logout people person profile recognition security social thumbnail unlock user',
    Face6:
      'account emoji eyes human login logout people person profile recognition security social thumbnail unlock user',
    Facebook: 'brand logo social',
    FaceRetouchingNatural:
      'editing effect emoji emotion faces image photography settings star tag',
    FaceRetouchingOff:
      'disabled editing effect emoji emotion enabled faces image natural photography settings slash tag',
    FactCheck: 'approve complete done list mark ok select tick validate verified yes',
    Factory: 'industry manufacturing warehouse',
    FamilyRestroom: 'bathroom children father kids mother parents wc',
    Fastfood: 'drink hamburger maps meal places',
    FastForward: 'control ff media music play speed time tv video',
    FastRewind: 'back control media music play speed time tv video',
    Favorite: 'appreciate health heart like love remember save shape success',
    FavoriteBorder: 'health heart like love outline remember save shape success',
    Fax: 'machine office phone send',
    FeaturedPlayList: 'audio collection highlighted item music playlist recommended',
    FeaturedVideo:
      'advertisement advertisment highlighted item play recommended watch,advertised',
    Feed: 'article headline information newspaper public social timeline',
    Feedback:
      '! alert announcement attention bubble caution chat comment communicate danger error exclamation important mark message notification speech symbol warning',
    Female: 'gender girl lady social symbol woman women',
    Fence: 'backyard barrier boundaries boundary home house protection',
    Festival: 'circus event local maps places tent tour travel',
    FiberDvr:
      'alphabet character digital electronics font letter network recorder symbol text tv type video',
    FiberManualRecord: 'circle dot play watch',
    FiberNew: 'alphabet character font letter network symbol text type',
    FiberPin: 'alphabet character font letter network symbol text type',
    FiberSmartRecord: 'circle dot play watch',
    FifteenMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    FileCopy:
      'bill clone content cut document duplicate invoice item multiple page past',
    FileDownload: 'arrows downloads drive export install upload',
    FileDownloadDone: 'arrows check downloads drive installed tick upload',
    FileDownloadOff:
      'arrow disabled drive enabled export install on save slash upload',
    FileOpen: 'arrow document drive left page paper',
    FilePresent:
      'clip data document drive folders note paper reminder sheet slide storage writing',
    FileUpload: 'arrows download drive export',
    Filter: 'editing effect image landscape mountains photography picture settings',
    Filter1:
      'digit editing effect images multiple number photography pictures settings stack symbol',
    Filter2:
      'digit editing effect images multiple number photography pictures settings stack symbol',
    Filter3:
      'digit editing effect images multiple number photography pictures settings stack symbol',
    Filter4:
      'digit editing effect images multiple number photography pictures settings stack symbol',
    Filter5:
      'digit editing effect images multiple number photography pictures settings stack symbol',
    Filter6:
      'digit editing effect images multiple number photography pictures settings stack symbol',
    Filter7:
      'digit editing effect images multiple number photography pictures settings stack symbol',
    Filter8:
      'digit editing effect images multiple number photography pictures settings stack symbol',
    Filter9:
      'digit editing effect images multiple number photography pictures settings stack symbol',
    Filter9Plus:
      '+ digit editing effect images multiple number photography pictures settings stack symbol',
    FilterAlt: 'edit funnel options refine sift',
    FilterAltOff: '[offline] disabled edit funnel options refine sift slash',
    FilterBAndW:
      'black contrast editing effect grayscale images photography pictures settings white',
    FilterCenterFocus: 'camera dot edit image photography picture',
    FilterDrama: 'camera cloud editing effect image photography picture sky',
    FilterFrames:
      'boarders border camera center editing effect filters focus image options photography picture',
    FilterHdr: 'camera editing effect image mountains photography picture',
    FilterList: 'lines organize sort',
    FilterListOff: '[offline] alt disabled edit options refine sift slash',
    FilterNone: 'multiple stack',
    FilterTiltShift: 'blur center editing effect focus images photography pictures',
    FilterVintage: 'editing effect flower images photography pictures',
    FindInPage:
      'data document drive file folders glass look magnifying paper search see sheet slide writing',
    FindReplace:
      'around arrows glass inprogress loading look magnifying refresh renew rotate search see',
    Fingerprint:
      'biometrics identification identity reader thumbprint touchid verification',
    FireExtinguisher: 'emergency water',
    Fireplace: 'chimney flame home house living pit room warm winter',
    FirstPage: 'arrow back chevron left rewind',
    Fitbit: 'athlete athletic exercise fitness hobby',
    FitnessCenter:
      'athlete dumbbell exercise gym health hobby places sport weights workout',
    FitScreen: 'enlarge format layout reduce scale size',
    FiveG:
      '5g alphabet cellular character data digit font letter mobile network number phone signal speed symbol text type wifi',
    FiveK:
      '5000 5K alphabet character digit display font letter number pixels resolution symbol text type video',
    FiveKPlus:
      '+ 5000 5K alphabet character digit display font letter number pixels resolution symbol text type video',
    FiveMp:
      'camera digit font image letters megapixels number quality resolution symbol text type',
    FivteenMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    Flag: 'country goal mark nation report start',
    FlagCircle: 'country goal mark nation report round start',
    Flaky:
      'approve check close complete contrast done exit mark no ok options select stop tick verified yes',
    Flare:
      'bright editing effect images lensflare light photography pictures shine sparkle star sun',
    FlashAuto: 'camera electric fast lightning thunderbolt',
    FlashlightOff: 'disabled enabled on slash',
    FlashlightOn: 'disabled enabled off slash',
    FlashOff: 'camera disabled electric enabled fast lightning on slash thunderbolt',
    FlashOn: 'camera disabled electric enabled fast lightning off slash thunderbolt',
    Flatware: 'cafeteria cutlery diner dining eating fork room spoon',
    Flight: 'airplane airport flying transportation travel trip',
    FlightClass: 'airplane business first seat transportation travel trip window',
    FlightLand:
      'airplane airport arrival arriving flying landing transportation travel',
    FlightTakeoff:
      'airplane airport departed departing flying landing transportation travel',
    Flip: 'editing image orientation scanning',
    FlipCameraAndroid:
      'center editing front image mobile orientation rear reverse rotate turn',
    FlipCameraIos:
      'android disable_ios editing front image mobile orientation rear reverse rotate turn',
    FlipToBack: 'arrangement format front layout move order sort',
    FlipToFront: 'arrangement back format layout move order sort',
    Flood: 'crisis disaster natural rain storm weather',
    Fluorescent: 'bright lamp lightbulb',
    FlutterDash: 'bird mascot',
    FmdBad:
      '! alert attention caution danger destination direction error exclamation important location maps mark notification pin place symbol warning',
    FmdGood: 'destination direction location maps pin place stop',
    Folder: 'data directory document drive file folders sheet slide storage',
    FolderCopy:
      'content cut data document drive duplicate file folders multiple paste sheet slide storage',
    FolderDelete:
      'bin can data document drive file folders garbage remove sheet slide storage trash',
    FolderOff:
      '[online] data disabled document drive enabled file folders sheet slash slide storage',
    FolderOpen: 'data directory document drive file folders sheet slide storage',
    FolderShared:
      'account collaboration data directory document drive face human people person profile sheet slide storage team user',
    FolderSpecial:
      'bookmark data directory document drive favorite file highlight important marked saved shape sheet slide star storage',
    FolderZip: 'compress data document drive file folders open sheet slide storage',
    FollowTheSigns: 'arrow body directional human people person right social',
    FontDownload: 'A alphabet character letter square symbol text type',
    FontDownloadOff:
      'alphabet character disabled enabled letter slash square symbol text type',
    FoodBank:
      'architecture building charity eat estate fork house knife meal place real residence residential shelter utensils',
    Forest: 'jungle nature plantation plants trees woodland',
    ForkLeft: 'arrows directions maps navigation path route sign traffic',
    ForkRight: 'arrows directions maps navigation path route sign traffic',
    FormatAlignCenter:
      'alignment doc editing editor lines spreadsheet text type writing',
    FormatAlignJustify:
      'alignment density doc editing editor extra lines small spreadsheet text type writing',
    FormatAlignLeft:
      'alignment doc editing editor lines spreadsheet text type writing',
    FormatAlignRight:
      'alignment doc editing editor lines spreadsheet text type writing',
    FormatBold:
      'B alphabet character doc editing editor font letter spreadsheet styles symbol text type writing',
    FormatClear:
      'T alphabet character disabled doc editing editor enabled font letter off slash spreadsheet style symbol text type writing',
    FormatColorFill:
      'bucket doc editing editor paint spreadsheet style text type writing',
    FormatColorReset:
      'clear disabled doc droplet editing editor enabled fill liquid off on paint slash spreadsheet style text type water writing',
    FormatColorText: 'doc editing editor fill paint spreadsheet style type writing',
    FormatIndentDecrease:
      'alignment doc editing editor indentation paragraph spreadsheet text type writing',
    FormatIndentIncrease:
      'alignment doc editing editor indentation paragraph spreadsheet text type writing',
    FormatItalic:
      'alphabet character doc editing editor font letter spreadsheet style symbol text type writing',
    FormatLineSpacing: 'alignment doc editing editor spreadsheet text type writing',
    FormatListBulleted:
      'alignment doc editing editor notes spreadsheet task text todo type writing',
    FormatListNumbered:
      'alignment digit doc editing editor notes spreadsheet symbol task text todo type writing',
    FormatListNumberedRtl:
      'alignment digit doc editing editor notes spreadsheet symbol task text todo type writing',
    FormatOverline:
      'alphabet character doc editing editor font letter spreadsheet style symbol text type under writing',
    FormatPaint:
      'brush color doc editing editor fill paintroller spreadsheet style text type writing',
    FormatQuote: 'doc editing editor quotation spreadsheet text type writing',
    FormatShapes:
      'alphabet character color doc editing editor fill font letter paint spreadsheet style symbol text type writing',
    FormatSize:
      'alphabet character color doc editing editor fill font letter paint spreadsheet style symbol text type writing',
    FormatStrikethrough:
      'alphabet character doc editing editor font letter spreadsheet style symbol text type writing',
    FormatTextdirectionLToR:
      'alignment doc editing editor ltr paragraph spreadsheet type writing',
    FormatTextdirectionRToL:
      'alignment doc editing editor paragraph rtl spreadsheet type writing',
    FormatUnderlined:
      'alphabet character doc editing editor font letter spreadsheet style symbol text type writing',
    Fort: 'castle fortress mansion palace',
    Forum:
      'bubble chat comment communicate community conversation feedback hub messages speech talk',
    Forward: 'arrow mail message playback right sent',
    Forward10:
      'arrow circle controls digit fast music number play rotate seconds speed symbol time video',
    Forward30:
      'arrow circle controls digit fast music number rotate seconds speed symbol time video',
    Forward5:
      '10 arrow circle controls digit fast music number rotate seconds speed symbol time video',
    ForwardToInbox: 'arrow email envelop letter message send',
    Foundation:
      'architecture base basis building construction estate home house real residential',
    FourGMobiledata:
      'alphabet cellular character digit font letter network number phone signal speed symbol text type wifi',
    FourGPlusMobiledata:
      'alphabet cellular character digit font letter network number phone signal speed symbol text type wifi',
    FourK:
      '4000 4K alphabet character digit display font letter number pixels resolution symbol text type video',
    FourKPlus:
      '+ 4000 4K alphabet character digit display font letter number pixels resolution symbol text type video',
    FourMp:
      'camera digit font image letters megapixels number quality resolution symbol text type',
    FourteenMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    FreeBreakfast: 'beverage cafe coffee cup drink mug tea',
    Fullscreen: 'adjust application components interface size ui ux view website',
    FullscreenExit: 'adjust application components interface size ui ux view website',
    Functions:
      'average calculate count custom doc editing editor math sigma spreadsheet style sum text type writing',
    Gamepad: 'buttons console controller device gaming playstation video',
    Games:
      'adjust arrows controller direction dpad gaming left move nintendo playstation right xbox',
    Garage: 'automobile automotive cars direction maps transportation travel vehicle',
    GasMeter: 'droplet energy measure nest usage water',
    Gavel:
      'agreement contract court document government hammer judge law mallet official police rules terms',
    Gesture: 'drawing finger gestures hand line motion',
    GetApp: 'arrows downloads export install play pointing retrieve upload',
    Gif: 'alphabet animated animation bitmap character font format graphics interchange letter symbol text type',
    GifBox:
      'alphabet animated animation bitmap character font format graphics interchange letter symbol text type',
    Girl: 'body female gender human lady people person social symbol woman women',
    Gite: 'architecture estate home hostel house maps place real residence residential stay traveling',
    GitHub: 'brand code',
    GMobiledata: 'alphabet character font letter network service symbol text type',
    GolfCourse:
      'athlete athletic ball club entertainment flag golfer golfing hobby hole places putt sports',
    Google: 'brand logo',
    GppBad:
      'cancel certified close error exit no privacy private protection remove security shield sim stop verified',
    GppGood: 'certified check ok pass security shield sim tick',
    GppMaybe:
      '! alert attention caution certified danger error exclamation important mark notification privacy private protection security shield sim symbol verified warning',
    GpsFixed: 'destination direction location maps pin place pointer stop tracking',
    GpsNotFixed:
      'destination direction disabled enabled fixed location maps not off online place pointer slash tracking',
    GpsOff:
      'destination direction disabled enabled fixed location maps not offline place pointer slash tracking',
    Grade:
      '. achievement favorite_news important likes marked rated rating reward saved shape special star_border_purple500 star_outline',
    Gradient: 'color editing effect filter images photography pictures',
    Grading:
      'approve check complete document done feedback grade mark ok reviewed select star_boarder star_border_purple500 star_outline star_purple500 star_rate tick validate verified writing yes',
    Grain: 'dots editing effect filter images photography pictures',
    GraphicEq: 'audio equalizer music recording sound voice',
    Grass: 'backyard fodder ground home lawn plant turf',
    Grid3x3: 'layout line space',
    Grid4x4: 'by layout lines space',
    GridGoldenratio: 'layout lines space',
    GridOff: 'collage disabled enabled image layout on slash view',
    GridOn: 'collage disabled enabled image layout off sheet slash view',
    GridView:
      'application blocks components dashboard design interface layout screen square tiles ui ux website window',
    Group:
      'accounts committee face family friends humans network people persons profiles social team users',
    GroupAdd:
      'accounts committee face family friends humans increase more network people persons plus profiles social team users',
    GroupRemove:
      'accounts committee face family friends humans network people persons profiles social team users',
    Groups:
      'body club collaboration crowd gathering human meeting people person social teams',
    Groups2:
      'body club collaboration crowd gathering hair human meeting people person social teams',
    Groups3:
      'abstract body club collaboration crowd gathering human meeting people person social teams',
    GroupWork: 'alliance circle collaboration film partnership reel teamwork together',
    GTranslate: 'emblem google language logo mark speaking speech translator words',
    Hail: 'body human people person pick public stop taxi transportation',
    Handshake: 'agreement partnership',
    Handyman: 'build construction fix hammer repair screwdriver tools',
    Hardware: 'break construction hammer nail repair tool',
    Hd: 'alphabet character definition display font high letter movies quality resolution screen symbol text tv type video',
    HdrAuto:
      'A alphabet camera character circle dynamic font high letter photo range symbol text type',
    HdrAutoSelect:
      '+ A alphabet camera character circle dynamic font high letter photo range symbol text type',
    HdrEnhancedSelect:
      'add alphabet character dynamic font high letter plus range symbol text type',
    HdrOff:
      'alphabet character disabled dynamic enabled enhance font high letter range select slash symbol text type',
    HdrOffSelect:
      'alphabet camera character circle disabled dynamic enabled font high letter photo range slash symbol text type',
    HdrOn:
      'add alphabet character dynamic enhance font high letter plus range select symbol text type',
    HdrOnSelect:
      '+ alphabet camera character circle dynamic font high letter photo range symbol text type',
    HdrPlus:
      '+ add alphabet character circle dynamic enhance font high letter range select symbol text type',
    HdrStrong: 'circles dots dynamic enhance high range',
    HdrWeak: 'circles dots dynamic enhance high range',
    Headphones: 'accessory audio device earphone headset listen music sound',
    HeadphonesBattery:
      'accessory audio charging device earphone headset listen music sound',
    Headset:
      'accessory audio device earbuds earmuffs earphone headphones listen music sound',
    HeadsetMic:
      'accessory audio chat device earphone headphones listen music sound talk',
    HeadsetOff:
      'accessory audio chat device disabled earphone enabled headphones listen mic music slash sound talk',
    Healing: 'bandage bandaid editing emergency fix health hospital image medicine',
    HealthAndSafety:
      '+ add certified cross home nest plus privacy private protection security shield symbol verified',
    Hearing: 'accessibility accessible aid handicap help impaired listen sound volume',
    HearingDisabled:
      'accessibility accessible aid enabled handicap help impaired listen off on slash sound volume',
    HeartBroken: 'break core crush health nucleus split',
    HeatPump: 'air conditioner cool energy furnance nest usage',
    Height:
      'arrows color doc down editing editor fill format paint resize spreadsheet stretch style text type up writing',
    Help: '? alert announcement assistance circle information mark punctuation question shape support symbol',
    HelpCenter: '? assistance information mark punctuation question support symbol',
    HelpOutline:
      '? alert announcement assistance circle information mark punctuation question shape support symbol',
    Hevc: 'alphabet character coding efficiency font high letter symbol text type video',
    Hexagon: 'shape sides six',
    HideImage: 'disabled enabled landscape mountains off on photography picture slash',
    HideSource: 'circle disabled enabled offline on shape slash',
    Highlight:
      'color doc editing editor emphasize fill flashlight format marker paint spreadsheet style text type writing',
    HighlightAlt: 'arrow box click cursor draw focus pointer selection target',
    HighlightOff:
      'cancel circle clear click close delete disable exit focus no quit remove stop target times',
    HighQuality:
      'alphabet character definition display font hq letter movies resolution screen symbol text tv type',
    Hiking:
      'backpacking bag climbing duffle mountain social sports stick trail travel walking',
    History:
      'arrow backwards clock date refresh renew reverse revert rotate schedule time turn undo',
    HistoryEdu:
      'document education feather letter paper pen quill school tools write writing',
    HistoryToggleOff: 'clock date schedule time',
    Hive: 'bee honeycomb',
    Hls: 'alphabet character developer engineering font letter platform symbol text type',
    HlsOff:
      '[offline] alphabet character developer disabled enabled engineering font letter platform slash symbol text type',
    HMobiledata: 'alphabet character font letter network service symbol text type',
    HolidayVillage:
      'architecture beach camping cottage estate home house lake lodge maps place real residence residential stay traveling vacation',
    Home: 'address application--house architecture building components design estate homepage interface layout place real residence residential screen shelter structure unit ux website window',
    HomeMax: 'device gadget hardware internet iot nest smart things',
    HomeMini: 'Internet device gadget hardware iot nest smart things',
    HomeRepairService: 'equipment fix kit mechanic repairing toolbox tools workshop',
    HomeWork:
      'architecture building estate house office place real residence residential shelter',
    HorizontalRule: 'gmail line novitas',
    HorizontalSplit: 'bars format layout lines stacked',
    Hotel: 'bed body human people person sleep stay travel trip',
    HotTub:
      'bathing bathroom bathtub hotel human jacuzzi person shower spa steam travel water',
    HourglassBottom: 'countdown half loading minutes time waiting',
    HourglassDisabled:
      'clock countdown empty enabled loading minutes off on slash time waiting',
    HourglassEmpty: 'countdown loading minutes start time waiting',
    HourglassFull: 'countdown loading minutes time waiting',
    HourglassTop: 'countdown half loading minutes time waiting',
    House:
      'architecture building estate family homepage places real residence residential shelter',
    Houseboat:
      'architecture beach estate floating home maps place real residence residential sea stay traveling vacation',
    HouseSiding:
      'architecture building construction estate exterior facade home real residential',
    HowToReg:
      'approve ballot check complete done election mark ok poll register registration select tick to validate verified vote yes',
    HowToVote: 'ballot election poll',
    HPlusMobiledata:
      '+ alphabet character font letter network service symbol text type',
    Html: 'alphabet brackets character code css developer engineering font letter platform symbol text type',
    Http: 'alphabet character font internet letter network symbol text transfer type url website',
    Https:
      'connection encrypt internet key locked network password privacy private protection safety secure security ssl web',
    Hub: 'center connection core focal network nucleus point topology',
    Hvac: 'air conditioning heating ventilation',
    Icecream: 'dessert food snack',
    IceSkating:
      'athlete athletic entertainment exercise hobby shoe skates social sports travel',
    Image:
      'disabled enabled frame hide landscape mountains off on photography picture slash',
    ImageAspectRatio: 'photography picture rectangle square',
    ImageNotSupported:
      'disabled enabled landscape mountains off on photography picture slash',
    ImageSearch:
      'find glass landscape look magnifying mountains photography picture see',
    ImagesearchRoller: 'art paint',
    ImportantDevices:
      'Android cell computer desktop hardware iOS mobile monitor phone star tablet web',
    ImportContacts: 'address book friends information magazine open',
    ImportExport: 'arrows direction down explort up',
    Inbox: 'archive email incoming message',
    IndeterminateCheckBox:
      'application button components control design form interface minus screen selected selection square toggle ui undetermined ux website',
    Info: 'about alert announcement announcment assistance bubble circle details help information service support',
    Input: 'arrow box download login move right',
    InsertChart:
      'analytics barchart bars data diagram infographic measure metrics statistics tracking',
    InsertChartOutlined:
      'analytics bars data diagram infographic measure metrics statistics tracking',
    InsertComment: 'add bubble chat feedback message',
    InsertDriveFile: 'bill document format invoice item sheet slide',
    InsertEmoticon:
      'account emoji face happy human like people person profile sentiment smiley user',
    InsertInvitation:
      'agenda calendar date event mark month range remember reminder today week',
    InsertLink: 'add anchor attach clip file mail media',
    InsertPageBreak: 'document file paper',
    InsertPhoto: 'image landscape mountains photography picture wallpaper',
    Insights:
      'analytics bars chart data diagram infographic measure metrics stars statistics tracking',
    Instagram: 'brand logo social',
    InstallDesktop:
      'Android chrome device display fix hardware iOS mac monitor place pwa screen web window',
    InstallMobile: 'Android cell device hardware iOS phone pwa tablet',
    IntegrationInstructions:
      'brackets clipboard code css developer document engineering html platform',
    Interests: 'circle heart shapes social square triangle',
    InterpreterMode: 'language microphone person speaking symbol',
    Inventory:
      'archive box buy check clipboard document e-commerce file list organize packages product purchase shop stock store supply',
    Inventory2: 'archive box file organize packages product stock storage supply',
    InvertColors: 'droplet editing hue inverted liquid palette tone water',
    InvertColorsOff:
      'disabled droplet enabled hue inverted liquid offline opacity palette slash tone water',
    IosShare:
      'arrows button direction export internet link send sharing social up website',
    Iron: 'appliance clothes electric ironing machine object',
    Iso: 'add editing effect image minus photography picture plus sensor shutter speed subtract',
    Javascript:
      'alphabet brackets character code css developer engineering font html letter platform symbol text type',
    JoinFull: 'circle combine command left outer outter overlap right sql',
    JoinInner: 'circle command matching overlap sql values',
    JoinLeft: 'circle command matching overlap sql values',
    JoinRight: 'circle command matching overlap sql values',
    Kayaking:
      'athlete athletic body canoe entertainment exercise hobby human lake paddle paddling people person rafting river row social sports summer travel water',
    KebabDining: 'dinner food meal meat skewer',
    Key: 'blackout password restricted secret unlock',
    Keyboard: 'computer device hardware input keypad letter office text type',
    KeyboardAlt: 'computer device hardware input keypad letter office text type',
    KeyboardArrowDown: 'arrows chevron open',
    KeyboardArrowLeft: 'arrows chevron',
    KeyboardArrowRight: 'arrows chevron open start',
    KeyboardArrowUp: 'arrows chevron submit',
    KeyboardBackspace: 'arrow left',
    KeyboardCapslock: 'arrow up',
    KeyboardCommandKey: 'button command control key',
    KeyboardControlKey: 'control key',
    KeyboardDoubleArrowDown: 'arrows direction multiple navigation',
    KeyboardDoubleArrowLeft: 'arrows direction multiple navigation',
    KeyboardDoubleArrowRight: 'arrows direction multiple navigation',
    KeyboardDoubleArrowUp: 'arrows direction multiple navigation',
    KeyboardHide: 'arrow computer device down hardware input keypad text',
    KeyboardOptionKey: 'alt key modifier',
    KeyboardReturn: 'arrow back left',
    KeyboardTab: 'arrow next right',
    KeyboardVoice: 'microphone noise recorder speaker',
    KeyOff: '[offline] disabled enabled on password slash unlock',
    KingBed:
      'bedroom double furniture home hotel house night pillows queen rest sleep',
    Kitchen:
      'appliance cabinet cold food freezer fridge home house ice places refrigerator storage',
    Kitesurfing:
      'athlete athletic beach body entertainment exercise hobby human people person social sports travel water',
    Label: 'badge favorite indent item library mail remember save stamp sticker tag',
    LabelImportant:
      'badge favorite important. indent item library mail remember save stamp sticker tag wing',
    LabelOff:
      'disabled enabled favorite indent library mail on remember save slash stamp sticker tag wing',
    Lan: 'computer connection data internet network service',
    Landscape: 'image mountains nature photography picture',
    Landslide: 'crisis disaster natural rain storm weather',
    Language: 'country earth globe i18n internet l10n planet website world www',
    Laptop:
      'Android chrome computer connect desktop device display hardware iOS link mac monitor smart tv web windows',
    LaptopChromebook:
      'Android chromebook device display hardware iOS mac monitor screen web window',
    LaptopMac:
      'Android apple chrome device display hardware iOS monitor screen web window',
    LaptopWindows: 'Android chrome device display hardware iOS mac monitor screen web',
    LastPage:
      'application arrow chevron components end forward interface right screen ui ux website',
    Launch:
      'application arrow box components core interface internal link new open screen ui ux website window',
    Layers: 'arrange disabled enabled interaction maps off overlay pages slash stack',
    LayersClear:
      'arrange delete disabled enabled interaction maps off overlay pages slash',
    Leaderboard:
      'analytics bars chart data diagram infographic measure metrics statistics tracking',
    LeakAdd: 'connection data link network service signals synce wireless',
    LeakRemove:
      'connection data disabled enabled link network offline service signals slash synce wireless',
    LegendToggle:
      'analytics chart data diagram infographic measure metrics monitoring stackdriver statistics tracking',
    Lens: 'circle full geometry moon',
    LensBlur: 'camera dim dot effect foggy fuzzy image photo soften',
    LibraryAdd:
      '+ collection layers multiple music new plus save stacked symbol video',
    LibraryAddCheck:
      'approve collection complete done layers mark multiple music ok select stacked tick validate verified video yes',
    LibraryBooks: 'add album audio collection reading',
    LibraryMusic: 'add album audio collection song sounds',
    Light: 'bulb ceiling hanging inside interior lamp lighting pendent room',
    Lightbulb: 'alert announcement idea information learning mode',
    LightbulbCircle: 'alert announcement idea information',
    LightMode: 'brightness day device lighting morning mornng sky sunny',
    LinearScale:
      'application components design interface layout measure menu screen slider ui ux website window',
    LineAxis: 'dash horizontal stroke vertical',
    LineStyle: 'dash dotted editor rule spacing',
    LineWeight: 'editor height size spacing style thickness',
    Link: 'anchor chain clip connection external hyperlink linked links multimedia unlisted url',
    LinkedCamera: 'connection lens network photography picture signals sync wireless',
    LinkedIn: 'brand logo social',
    LinkOff:
      'anchor attached chain clip connection disabled enabled linked links multimedia slash unlink url',
    Liquor: 'alcohol bar bottle club cocktail drink food party store wine',
    List: 'editor file format index menu options playlist task todo',
    ListAlt: 'box contained editor format lines reorder sheet stacked task title todo',
    LiveHelp:
      '? alert announcement assistance bubble chat comment communicate faq information mark message punctuation question speech support symbol',
    LiveTv:
      'Android antennas chrome desktop device hardware iOS mac monitor movie play stream television web window',
    Living:
      'chair comfort couch decoration furniture home house lounging loveseat room seating sofa',
    LocalActivity: 'event star things ticket',
    LocalAirport: 'airplane flight flying transportation travel trip',
    LocalAtm:
      'bill card cart cash coin commerce credit currency dollars financial money online payment price profit shopping symbol',
    LocalBar: 'alcohol bottle club cocktail drink food liquor martini wine',
    LocalCafe: 'bottle coffee cup drink food mug restaurant tea',
    LocalCarWash: 'automobile cars maps transportation travel vehicle',
    LocalConvenienceStore:
      '-- 24 bill building business card cash coin commerce company credit currency dollars maps market money new online payment plus shopping storefront symbol',
    LocalDining: 'cutlery eat food fork knife meal restaurant spoon',
    LocalDrink: 'cup droplet glass liquid park water',
    LocalFireDepartment: '911 climate firefighter flame heat home hot nest thermostat',
    LocalFlorist: 'flower shop',
    LocalGasStation: 'auto car filling fuel gasoline oil station vehicle',
    LocalGroceryStore: 'market shop',
    LocalHospital: '911 aid cross doctor emergency first health medical medicine plus',
    LocalHotel: 'bed body human people person sleep stay travel trip',
    LocalLaundryService: 'cleaning clothing dryer hotel washer',
    LocalLibrary: 'book community learning person read',
    LocalMall:
      'bill building business buy card cart cash coin commerce credit currency dollars handbag money online payment shopping storefront',
    LocalOffer: 'deal discount price shopping store tag',
    LocalParking:
      'alphabet auto car character font garage letter symbol text type vehicle',
    LocalPharmacy: '911 aid cross emergency first food hospital medicine places',
    LocalPhone: 'booth call telecommunication',
    LocalPizza: 'drink fastfood meal',
    LocalPolice: '911 badge law officer protection security shield',
    LocalPostOffice:
      'delivery email envelop letter message package parcel postal send stamp',
    LocalPrintshop: 'draft fax ink machine office paper printer send',
    LocalSee: 'camera lens photography picture',
    LocalShipping:
      'automobile cars delivery letter mail maps office package parcel postal semi send shopping stamp transportation truck vehicle',
    LocalTaxi:
      'automobile cab call cars direction lyft maps public transportation uber vehicle yellow',
    LocationCity:
      'apartments architecture buildings business company estate home landscape place real residence residential shelter town urban',
    LocationDisabled:
      'destination direction enabled maps off pin place pointer slash stop tracking',
    LocationOff:
      'destination direction disabled enabled gps maps pin place room slash stop',
    LocationOn:
      'destination direction disabled enabled gps maps off pin place room slash stop',
    LocationSearching: 'destination direction maps pin place pointer stop tracking',
    Lock: 'connection key locked logout padlock password privacy private protection safety secure security signout',
    LockClock:
      'date locked password privacy private protection safety schedule secure security time',
    LockOpen:
      'connection key login padlock password privacy private protection register safety secure security signin signup unlocked',
    LockReset:
      'around inprogress loading locked password privacy private protection refresh renew rotate safety secure security turn',
    Login:
      'access application arrow components design enter interface left screen ui ux website',
    LogoDev: 'dev.to',
    Logout:
      'application arrow components design exit interface leave login right screen ui ux website',
    Looks: 'circle half rainbow',
    Looks3: 'digit numbers square symbol',
    Looks4: 'digit numbers square symbol',
    Looks5: 'digit numbers square symbol',
    Looks6: 'digit numbers square symbol',
    LooksOne: '1 digit numbers square symbol',
    LooksTwo: '2 digit numbers square symbol',
    Loop: 'around arrows direction inprogress loader loading music navigation refresh renew repeat rotate turn',
    Loupe: '+ add details focus glass magnifying new plus symbol',
    LowPriority: 'arrange arrow backward bottom list move order task todo',
    Loyalty:
      'badge card credit heart love membership miles points program sale subscription tag travel trip',
    LteMobiledata:
      'alphabet character font internet letter network speed symbol text type wifi wireless',
    LtePlusMobiledata:
      '+ alphabet character font internet letter network speed symbol text type wifi wireless',
    Luggage: 'airport baggage carry flight hotel on suitcase travel trip',
    LunchDining: 'breakfast dinner drink fastfood hamburger meal',
    Lyrics:
      'audio bubble chat comment communicate feedback key message music note song sound speech track',
    MacroOff: '[offline] camera disabled enabled flower garden image on slash',
    Mail: 'email envelope inbox letter message send',
    MailLock:
      'email envelop letter locked message password privacy private protection safety secure security send',
    MailOutline: 'email envelope letter message note post receive send write',
    Male: 'boy gender man social symbol',
    Man: 'boy gender male social symbol',
    Man2: 'boy gender male social symbol',
    Man3: 'abstract boy gender male social symbol',
    Man4: 'abstract boy gender male social symbol',
    ManageAccounts:
      'change details face gear options people person profile service-human settings user',
    ManageHistory:
      'application arrow backwards change clock date details gear options refresh renew reverse rotate schedule settings time turn',
    ManageSearch: 'glass history magnifying text',
    Map: 'destination direction location maps pin place route stop travel',
    MapsHomeWork: 'building house office',
    MapsUgc:
      '+ add bubble comment communicate feedback message new plus speech symbol',
    Margin: 'design layout padding size square',
    MarkAsUnread: 'envelop letter mail postal receive send',
    MarkChatRead:
      'approve bubble check comment communicate complete done message ok select sent speech tick verified yes',
    MarkChatUnread: 'bubble circle comment communicate message notification speech',
    MarkEmailRead:
      'approve check complete done envelop letter message note ok select send sent tick yes',
    MarkEmailUnread: 'check circle envelop letter message note notification send',
    Markunread: 'email envelope letter message send',
    MarkUnreadChatAlt: 'bubble circle comment communicate message notification speech',
    MarkunreadMailbox: 'deliver envelop letter postal postbox receive send',
    Masks:
      'air cover covid face hospital medical pollution protection respirator sick social',
    Maximize:
      'application components design interface line screen shape ui ux website',
    MediaBluetoothOff:
      'connection connectivity device disabled enabled music note offline paring signal slash symbol wireless',
    MediaBluetoothOn:
      'connection connectivity device disabled enabled music note off online paring signal slash symbol wireless',
    Mediation:
      'alternative arrows compromise direction dots negotiation party right structure',
    MedicalInformation: 'badge card health id services',
    MedicalServices: 'aid bag briefcase emergency first kit medicine',
    Medication: 'doctor drug emergency hospital medicine pharmacy pills prescription',
    MedicationLiquid:
      '+ bottle doctor drug health hospital medications medicine pharmacy spoon vessel',
    MeetingRoom:
      'building doorway entrance home house interior logout office open places signout',
    Memory: 'card chip digital micro processor sd storage',
    Menu: 'application components hamburger interface lines playlist screen ui ux website',
    MenuBook: 'dining food meal page restaurant',
    MenuOpen:
      'application arrow chevron components hamburger interface left lines screen ui ux website',
    Merge: 'arrows directions maps navigation path route sign traffic',
    MergeType: 'arrow combine direction format text',
    Message: 'bubble chat comment communicate feedback speech talk text',
    Mic: 'hearing microphone noise record search sound speech voice',
    MicExternalOff: 'audio disabled enabled microphone slash sound voice',
    MicExternalOn: 'audio disabled enabled microphone off slash sound voice',
    MicNone: 'hearing microphone noise record sound voice',
    MicOff:
      'audio disabled enabled hearing microphone noise recording slash sound voice',
    Microwave: 'appliance cooking electric heat home house kitchen machine',
    MilitaryTech:
      'army award badge honor medal merit order privilege prize rank reward ribbon soldier star status trophy winner',
    Minimize:
      'application components design interface line screen shape ui ux website',
    MinorCrash:
      'accident automobile cars collision directions maps public transportation vehicle',
    MissedVideoCall:
      'arrow camera filming hardware image motion picture record videography',
    Mms: 'bubble chat comment communicate feedback image landscape message mountains multimedia photography picture speech',
    MobiledataOff:
      'arrow disabled down enabled internet network on slash speed up wifi wireless',
    MobileFriendly:
      'Android approve cell check complete device done hardware iOS mark ok phone select tablet tick validate verified yes',
    MobileOff:
      'Android cell device disabled enabled hardware iOS phone silence slash tablet',
    MobileScreenShare:
      'Android arrow cell device hardware iOS mirror monitor phone screencast streaming tablet tv wireless',
    Mode: 'compose create draft draw edit pencil write',
    ModeComment: 'bubble chat comment communicate feedback message mode speech',
    ModeEdit: 'compose create draft draw pencil write',
    ModeEditOutline: 'compose create draft draw pencil write',
    ModeFanOff: 'air conditioner cool disabled enabled nest slash',
    ModelTraining:
      'arrow bulb idea inprogress light loading refresh renew restore reverse rotate',
    ModeNight: 'dark disturb moon sleep weather',
    ModeOfTravel:
      'arrow destination direction location maps pin place stop transportation trip',
    ModeStandby: 'disturb power sleep target',
    MonetizationOn:
      'bill card cash circle coin commerce cost credit currency dollars finance money online payment price profit sale shopping symbol',
    Money:
      '100 bill card cash coin commerce cost credit currency digit dollars finance number online payment price profit shopping symbol',
    MoneyOff:
      'bill card cart cash coin commerce credit currency disabled dollars enabled finance money online payment price profit shopping slash symbol',
    MoneyOffCsred:
      'bill card cart cash coin commerce credit currency disabled dollars enabled online payment shopping slash symbol',
    Monitor: 'Android chrome device display hardware iOS mac screen web window',
    MonitorHeart: 'baseline device ecc fitness health medical track',
    MonitorWeight: 'body device diet health scale smart',
    MonochromePhotos: 'black camera image photography picture white',
    Mood: 'emoji emoticon emotions expressions face feelings glad happiness happy like person pleased smiley smiling social survey',
    MoodBad:
      'disappointment dislike emoji emoticon emotions expressions face feelings person rating smiley social survey unhappiness unhappy unpleased unsmile unsmiling',
    Moped:
      'automobile bike cars direction maps motorized public scooter transportation vehicle vespa',
    More: '3 archive badge bookmark dots etc favorite indent label remember save stamp sticker tab tag three',
    MoreHoriz:
      '3 application components disable_ios dots etc horizontal interface pending screen status three ui ux website',
    MoreTime: '+ add clock date new plus schedule symbol',
    MoreVert:
      '3 android application components disable_ios dots etc interface screen three ui ux vertical website',
    Mosque: 'islamic masjid muslim religion spiritual worship',
    MotionPhotosAuto:
      'A alphabet animation automatic character circle font gif letter live symbol text type video',
    MotionPhotosOff: 'animation circle disabled enabled slash video',
    Mouse: 'click computer cursor device hardware wireless',
    MoveDown: 'arrow direction jump navigation transfer',
    MoveToInbox:
      'archive arrow down email envelop incoming letter message move send to',
    MoveUp: 'arrow direction jump navigation transfer',
    Movie: 'cinema film media screen show slate tv video watch',
    MovieCreation: 'clapperboard film movies slate video',
    MovieFilter: 'clapperboard creation film movies slate stars video',
    Moving: 'arrow direction navigation travel up',
    Mp: 'alphabet character font image letter megapixel photography pixels quality resolution symbol text type',
    MultilineChart:
      'analytics bars data diagram infographic line measure metrics multiple statistics tracking',
    MultipleStop: 'arrows directions dots left maps navigation right',
    Museum:
      'architecture attraction building estate event exhibition explore local palces places real see shop store tour',
    MusicNote: 'audiotrack key sound',
    MusicOff: 'audiotrack disabled enabled key note on slash sound',
    MusicVideo: 'band mv recording screen tv watch',
    MyLocation: 'destination direction maps navigation pin place point stop',
    Nat: 'communication',
    Nature: 'forest outdoor outside park tree wilderness',
    NaturePeople:
      'activity body forest human outdoor outside park person tree wilderness',
    NavigateBefore: 'arrows direction left',
    NavigateNext: 'arrows direction right',
    Navigation: 'arrow destination direction location maps pin place point stop',
    NearbyError:
      '! alert attention caution danger exclamation important mark notification symbol warning',
    NearbyOff: 'disabled enabled on slash',
    NearMe:
      'arrow destination direction location maps navigation pin place point stop',
    NearMeDisabled:
      'destination direction enabled location maps navigation off pin place point slash',
    NestCamWiredStand: 'camera filming hardware image motion picture videography',
    NetworkCell: 'cellular data internet mobile phone speed wifi wireless',
    NetworkCheck: 'connection internet meter signal speed tick wifi wireless',
    NetworkLocked:
      'alert available cellular connection data error internet mobile not privacy private protection restricted safety secure security service signal warning wifi wireless',
    NetworkPing:
      'alert available cellular connection data internet ip mobile service signal wifi wireless',
    NetworkWifi: 'cellular data internet mobile phone speed wireless',
    NewReleases:
      '! alert announcement attention burst caution danger error exclamation important mark notification star symbol warning',
    Newspaper:
      'article data document drive file folders magazine media notes page sheet slide text writing',
    NextPlan: 'arrow circle right',
    NextWeek: 'arrow baggage briefcase business suitcase',
    Nfc: 'communication data field mobile near wireless',
    Nightlife:
      'alcohol bar bottle club cocktail dance drink food glass liquor music note wine',
    Nightlight: 'dark disturb mode moon sleep weather',
    NightlightRound: 'dark half mode moon',
    NightShelter: 'architecture bed building estate homeless house place real sleep',
    NightsStay: 'cloud crescent dark mode moon phases silence silent sky time weather',
    NineK:
      '9000 9K alphabet character digit display font letter number pixels resolution symbol text type video',
    NineKPlus:
      '+ 9000 9K alphabet character digit display font letter number pixels resolution symbol text type video',
    NineMp:
      'camera digit font image letters megapixels number quality resolution symbol text type',
    NineteenMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    NoAccounts:
      'avatar disabled enabled face human offline people person profile slash thumbnail unavailable unidentifiable unknown user',
    NoBackpack: 'accessory bookbag knapsack travel',
    NoCell:
      'Android device disabled enabled hardware iOS mobile off phone slash tablet',
    NoCrash:
      'accident automobile cars check collision confirm correct direction done enter maps mark okay select tick transportation vehicle yes',
    NoDrinks: 'alcohol beverage bottle cocktail food liquor wine',
    NoEncryption: 'disabled enabled lock off password safety security slash',
    NoEncryptionGmailerrorred: 'disabled enabled locked off slash',
    NoFlash:
      'camera disabled enabled image lightning off on photography picture slash thunderbolt',
    NoFood: 'disabled drink enabled fastfood hamburger meal off on slash',
    NoiseAware: 'audio cancellation music note sound',
    NoiseControlOff:
      '[offline] audio aware cancellation disabled enabled music note slash sound',
    NoLuggage: 'baggage carry disabled enabled off on slash suitcase travel',
    NoMeals:
      'dining disabled eat enabled food fork knife off restaurant slash spoon utensils',
    NoMeetingRoom:
      'building disabled doorway enabled entrance home house interior office on open places slash',
    NoPhotography: 'camera disabled enabled image off on picture slash',
    NordicWalking:
      'athlete athletic body entertainment exercise hiking hobby human people person social sports travel walker',
    North: 'arrow directional maps navigation up',
    NorthEast: 'arrow maps navigation noth right up',
    NorthWest: 'arrow directional left maps navigation up',
    NoSim: 'camera card device eject insert memory phone storage',
    NoStroller:
      'baby care carriage children disabled enabled infant kid newborn off on parents slash toddler young',
    NotAccessible: 'accessibility body handicap help human person wheelchair',
    Note: 'bookmark message paper',
    NoteAdd:
      '+ -doc create data document drive file folders new page paper plus sheet slide symbol writing',
    NoteAlt: 'clipboard document file memo page paper writing',
    Notes: 'comment document text write writing',
    NotificationAdd:
      '+ active alarm alert bell chime notifications notify plus reminder ring sound symbol',
    NotificationImportant:
      '! active alarm alert announcement attention bell caution chime danger error exclamation feedback mark notifications notify problem reminder ring sound symbol warning',
    Notifications: 'active alarm alert bell chime notify reminder ring sound',
    NotificationsActive: 'alarm alert bell chime notify reminder ringing sound',
    NotificationsNone: 'alarm alert bell notify reminder ring sound',
    NotificationsOff:
      'active alarm alert bell chime disabled enabled notify offline reminder ring slash sound',
    NotificationsPaused:
      '--- active alarm aleet alert bell chime ignore notify pause quiet reminder ring sleep snooze sound zzz',
    NotInterested:
      'allowed banned cancel circle close disabled dislike exit interested not off prohibited quit remove stop',
    NotListedLocation:
      '? assistance destination direction help information maps pin place punctuation questionmark stop support symbol',
    NoTransfer:
      'automobile bus cars direction disabled enabled maps off public slash transportation vehicle',
    NotStarted: 'circle media pause play video',
    Numbers: 'digit symbol',
    OfflineBolt: 'circle electric fast flash lightning spark thunderbolt',
    OfflinePin:
      'approve checkmark circle complete done ok select tick validate verified yes',
    OfflineShare:
      'Android arrow cell connect device direction hardware iOS link mobile multiple phone right tablet',
    OilBarrel: 'droplet gasoline nest water',
    OndemandVideo:
      'Android chrome desktop device hardware iOS mac monitor play television tv web window',
    OnDeviceTraining:
      'arrow bulb call cell contact hardware idea inprogress light loading mobile model refresh renew restore reverse rotate telephone',
    OneK: '1000 1K alphabet character digit display font letter number pixels resolution symbol text type video',
    OneKk:
      '10000 10K alphabet character digit display font letter number pixels resolution symbol text type video',
    OneKPlus:
      '+ 1000 1K alphabet character digit display font letter number pixels resolution symbol text type video',
    OnlinePrediction: 'bulb connection idea light network signal wireless',
    Opacity: 'color droplet hue inverted liquid palette tone water',
    OpenInBrowser: 'arrow box new up website window',
    OpenInFull: 'action arrows expand grow move',
    OpenInNew:
      'application arrow box components interface link screen ui ux website window',
    OpenInNewOff: 'arrow box disabled enabled export on slash window',
    OpenWith: 'arrows directional expand move',
    OtherHouses:
      'architecture cottage estate home maps place real residence residential stay traveling',
    Outbound: 'arrow circle directional right up',
    Outbox: 'mail send sent',
    OutdoorGrill: 'barbecue barbeque bbq charcoal cooking home house outside',
    Outlet: 'connecter electricity plug power',
    OutlinedFlag: 'country goal mark nation report start',
    Padding: 'design layout margin size square',
    Pages: 'article gplus paper post star',
    Pageview: 'document find glass magnifying paper search',
    Paid: 'circle currency money payment transaction',
    Palette: 'art colors filters paint',
    Panorama: 'angle image mountains photography picture view wide',
    PanoramaFishEye: 'angle circle image photography picture wide',
    PanoramaHorizontal: 'angle image photography picture wide',
    PanoramaHorizontalSelect: 'angle image photography picture wide',
    PanoramaPhotosphere: 'angle horizontal image photography picture wide',
    PanoramaPhotosphereSelect: 'angle horizontal image photography picture wide',
    PanoramaVertical: 'angle image photography picture wide',
    PanoramaVerticalSelect: 'angle image photography picture wide',
    PanoramaWideAngle: 'image photography picture',
    PanoramaWideAngleSelect: 'image photography picture',
    PanTool: 'drag fingers gesture hands human move scan stop touch wait',
    PanToolAlt: 'fingers gesture hands human move scan stop',
    Paragliding:
      'athlete athletic body entertainment exercise fly hobby human parachute people person skydiving social sports travel',
    Park: 'attraction fresh local nature outside plant tree',
    PartyMode: 'camera lens photography picture',
    Password: 'key login pin security star unlock',
    Pattern: 'key login password pin security star unlock',
    Pause: 'controls media music pending player status video wait',
    PauseCircle: 'controls media music video',
    PauseCircleFilled: 'controls media music pending status video wait',
    PauseCircleOutline: 'controls media music pending status video wait',
    PausePresentation:
      'application desktop device pending screen share slides status wait website window www',
    Payment:
      'bill cash charge coin commerce cost creditcard currency dollars finance financial information money online price shopping symbol',
    Payments:
      'bill card cash coin commerce cost credit currency dollars finance layer money multiple online price shopping symbol',
    PedalBike:
      'automobile bicycle cars direction human maps public route scooter transportation vehicle vespa',
    Pending: 'circle dots loading progress waiting',
    PendingActions: 'clipboard clock date document remember schedule time',
    Pentagon: 'five shape sides',
    People:
      'accounts committee community face family friends group humans network persons profiles social team users',
    PeopleAlt:
      'accounts committee face family friends group humans network persons profiles social team users',
    PeopleOutline:
      'accounts committee face family friends group humans network persons profiles social team users',
    Percent: 'math number symbol',
    PermCameraMic: 'image microphone min photography picture speaker',
    PermContactCalendar:
      'account agenda date face human information people person profile schedule time user',
    PermDataSetting:
      'cellular configure gear information network settings wifi wireless',
    PermDeviceInformation:
      'Android alert announcement cell hardware iOS important mobile phone tablet',
    PermIdentity:
      'account avatar face human information people person profile save, thumbnail user',
    PermMedia:
      'collection data directories document file folders images landscape mountains photography picture save storage',
    PermPhoneMsg:
      'bubble call cell chat comment communicate contact device message mobile recording save speech telephone voice',
    PermScanWifi:
      'alert announcement connection information internet network service signal wireless',
    Person: 'account avatar face human people profile user',
    Person2: 'account face human people profile user',
    Person3: 'account face human people profile user',
    Person4: 'account face human people profile user',
    PersonAdd:
      '+ account avatar face friend human new people plus profile symbol user',
    PersonAddAlt: '+ account face human people plus profile user',
    PersonAddDisabled:
      '+ account enabled face human new offline people plus profile slash symbol user',
    PersonalVideo:
      'Android cam chrome desktop device hardware iOS mac monitor television tv web window',
    PersonOff: 'account avatar disabled enabled face human people profile slash user',
    PersonOutline: 'account avatar face human people profile user',
    PersonPin:
      'account avatar destination direction face gps human location maps people place profile stop user',
    PersonPinCircle:
      'account destination direction face gps human location maps people place profile stop user',
    PersonRemove:
      'account avatar delete face human minus people profile unfriend user',
    PersonSearch:
      'account avatar face find glass human look magnifying people profile user',
    PestControl: 'bug exterminator insects',
    PestControlRodent: 'exterminator mice',
    Pets: 'animal cat claw dog hand paw',
    Phishing: 'fishing fraud hook scam',
    Phone: 'call cell chat contact device hardware mobile telephone text',
    PhoneAndroid: 'cell device hardware iOS mobile tablet',
    PhoneBluetoothSpeaker:
      'call cell connection connectivity contact device hardware mobile signal symbol telephone wireless',
    PhoneCallback: 'arrow cell contact device down hardware mobile telephone',
    PhoneDisabled:
      'call cell contact device enabled hardware mobile offline slash telephone',
    PhoneEnabled: 'call cell contact device hardware mobile telephone',
    PhoneForwarded:
      'arrow call cell contact device direction hardware mobile right telephone',
    PhoneInTalk:
      'call cell connection contact data device hardware mobile network scan service signal sound speaker telephone waves wireless',
    PhoneIphone: 'Android apple cell device hardware iOS mobile tablet',
    Phonelink:
      'Android chrome computer connect desktop device hardware iOS mac mobile sync tablet web windows',
    PhonelinkErase:
      'Android cancel cell close connection device exit hardware iOS mobile no remove stop tablet',
    PhonelinkLock:
      'Android cell connection device erase hardware iOS locked mobile password privacy private protection safety secure security tablet',
    PhonelinkOff:
      'Android chrome computer connect desktop device disabled enabled hardware iOS mac mobile slash sync tablet web windows',
    PhonelinkRing:
      'Android cell connection data device hardware iOS mobile network service signal tablet wireless',
    PhonelinkSetup:
      'Android call chat device hardware iOS information mobile settings tablet text',
    PhoneLocked:
      'call cell contact device hardware mobile password privacy private protection safety secure security telephone',
    PhoneMissed: 'arrow call cell contact device hardware mobile telephone',
    PhonePaused: 'call cell contact device hardware mobile telephone wait',
    Photo: 'image mountains photography picture',
    PhotoAlbum:
      'archive bookmark image label library mountains photography picture ribbon save tag',
    PhotoCamera: 'image photography picture',
    PhotoCameraBack: 'image landscape mountains photography picture rear',
    PhotoCameraFront:
      'account face human image people person photography picture portrait profile user',
    PhotoFilter: 'filters image photography picture stars',
    PhotoLibrary: 'album image mountains photography picture',
    PhotoSizeSelectActual: 'image mountains photography picture',
    PhotoSizeSelectLarge:
      'adjust album editing image library mountains photography picture',
    PhotoSizeSelectSmall:
      'adjust album editing image large library mountains photography picture',
    Php: 'alphabet brackets character code css developer engineering font html letter platform symbol text type',
    Piano: 'instrument keyboard keys musical social',
    PianoOff: 'disabled enabled instrument keyboard keys musical on slash social',
    PictureAsPdf:
      'alphabet character document file font image letter multiple photography symbol text type',
    PictureInPicture: 'cropped overlap photo position shape',
    PictureInPictureAlt: 'cropped overlap photo position shape',
    PieChart:
      'analytics bars data diagram infographic measure metrics statistics tracking',
    PieChartOutline:
      'analytics bars data diagram infographic measure metrics statistics tracking',
    Pin: '1 2 3 digit key login logout number password pattern security star symbol unlock',
    Pinch: 'arrows compress direction finger grasp hand navigation nip squeeze tweak',
    PinDrop: 'destination direction gps location maps navigation place stop',
    Pinterest: 'brand logo social',
    PivotTableChart:
      'analytics arrows bars data diagram direction drive editing grid infographic measure metrics rotate sheet statistics tracking',
    Pix: 'bill brazil card cash commerce credit currency finance money payment',
    Place: 'destination direction location maps navigation pin point stop',
    Plagiarism: 'document find glass look magnifying page paper search see',
    PlayArrow: 'controls media music player start video',
    PlayCircle: 'arrow controls media music video',
    PlayCircleFilled: 'arrow controls media music start video',
    PlayCircleFilledWhite: 'start',
    PlayCircleOutline: 'arrow controls media music start video',
    PlayDisabled: 'controls enabled media music off slash video',
    PlayForWork: 'arrow circle down google half',
    PlayLesson: 'audio bookmark digital ebook lesson multimedia play reading ribbon',
    PlaylistAdd: '+ collection music new plus symbol task todo',
    PlaylistAddCheck:
      'approve checkmark collection complete done music ok select task tick todo validate verified yes',
    PlaylistAddCheckCircle:
      'album artist audio cd collection mark music record sound track',
    PlaylistAddCircle:
      'album artist audio cd check collection mark music record sound track',
    PlaylistPlay: 'arow arrow collection music',
    PlaylistRemove: '- collection minus music',
    Plumbing: 'build construction fix handyman repair tools wrench',
    PlusOne: '1 add digit increase number symbol',
    Podcasts: 'broadcast casting network signal transmitting wireless',
    PointOfSale:
      'checkout cost machine merchant money payment pos retail system transaction',
    Policy:
      'certified find glass legal look magnifying privacy private protection search security see shield verified',
    Poll: 'analytics barchart bars data diagram infographic measure metrics statistics survey tracking vote',
    Polyline: 'compose create design draw vector',
    Pool: 'athlete athletic beach body entertainment exercise hobby human ocean people person places sea sports swimming water',
    PortableWifiOff:
      'connected connection data device disabled enabled internet network offline service signal slash usage wireless',
    Portrait: 'account face human people person photo picture profile user',
    PostAdd:
      '+ data document drive file folders item page paper plus sheet slide text writing',
    Power: 'charge cord electrical online outlet plug socket',
    PowerInput: 'dc lines supply',
    PowerOff: 'charge cord disabled electrical enabled on outlet plug slash',
    PowerSettingsNew: 'information off save shutdown',
    PrecisionManufacturing:
      'arm automatic chain conveyor crane factory industry machinery mechanical production repairing robot supply warehouse',
    PregnantWoman:
      'baby birth body female human lady maternity mom mother people person user women',
    PresentToAll: 'arrow presentation screen share slides website',
    Preview: 'design eye layout reveal screen see show website window www',
    PriceChange:
      'arrows bill card cash coin commerce cost credit currency dollars down finance money online payment shopping symbol up',
    PriceCheck:
      'approve bill card cash coin commerce complete cost credit currency dollars done finance mark money ok online payment select shopping symbol tick validate verified yes',
    Print: 'draft fax ink machine office paper printer send',
    PrintDisabled: 'enabled off on paper printer slash',
    PriorityHigh:
      '! alert attention caution danger error exclamation important mark notification symbol warning',
    PrivacyTip:
      'alert announcement announcment assistance certified details help information private protection security service shield support verified',
    ProductionQuantityLimits:
      '! alert attention bill card cart cash caution coin commerce credit currency danger dollars error exclamation important mark money notification online payment shopping symbol warning',
    Propane: 'gas nest',
    PropaneTank: 'bbq gas grill nest',
    Psychology:
      'behavior body brain cognitive function gear head human intellectual mental mind people person preferences psychiatric science settings social therapy thinking thoughts',
    PsychologyAlt:
      '? assistance behavior body brain cognitive function gear head help human information intellectual mark mental mind people person preferences psychiatric punctuation question science settings social support symbol therapy thinking thoughts',
    Public:
      'country earth global globe language map network planet social space web world',
    PublicOff:
      'disabled earth enabled global globe map network on planet slash social space web world',
    Publish: 'arrow cloud file import submit upload',
    PublishedWithChanges:
      'approve arrows check complete done inprogress loading mark ok refresh renew replace rotate select tick validate verified yes',
    PunchClock: 'date schedule timer timesheet',
    PushPin: 'location marker place remember save',
    QrCode: 'barcode camera media product quick response smartphone urls',
    QrCode2: 'barcode camera media product quick response smartphone urls',
    QrCodeScanner: 'barcode camera media product quick response smartphone urls',
    QueryBuilder: 'clock date hour minute save schedule time',
    QueryStats:
      'analytics chart data diagram find glass infographic line look magnifying measure metrics search see statistics tracking',
    QuestionAnswer:
      'bubble chat comment communicate conversation converse feedback message speech talk',
    QuestionMark:
      '? assistance help information mark punctuation question support symbol',
    Queue: 'add collection layers multiple music playlist stack stream video',
    QueueMusic: 'add collection playlist stream',
    QueuePlayNext:
      '+ add arrow collection desktop device display hardware monitor music new playlist plus screen steam symbol tv video',
    Quickreply:
      'bubble chat comment communicate fast lightning message speech thunderbolt',
    Quiz: '? assistance faq help information mark punctuation question support symbol test',
    Radar: 'detect military near network position scan',
    Radio:
      'antenna audio device frequency hardware listen media music player signal tune',
    RadioButtonChecked:
      'application bullet circle components design form interface off point record screen selected toggle ui ux website',
    RadioButtonUnchecked: 'bullet circle deselected form off point record toggle',
    RailwayAlert:
      '! attention automobile bike cars caution danger direction error exclamation important maps mark notification public scooter subway symbol train transportation vehicle vespa warning',
    RamenDining: 'breakfast dinner drink fastfood lunch meal noodles restaurant',
    RampLeft: 'arrows directions maps navigation path route sign traffic',
    RampRight: 'arrows directions maps navigation path route sign traffic',
    RateReview: 'chat comment feedback message pencil stars write',
    RawOff:
      'alphabet character disabled enabled font image letter original photography slash symbol text type',
    RawOn:
      'alphabet character disabled enabled font image letter off original photography slash symbol text type',
    ReadMore: 'arrow text',
    Receipt: 'bill credit invoice paper payment sale transaction',
    ReceiptLong: 'bill check document list paperwork record store transaction',
    RecentActors:
      'account avatar cards carousel contacts face human layers list people person profile thumbnail user',
    Recommend:
      'approved circle confirm favorite gesture hand like reaction social support thumbs well',
    RecordVoiceOver:
      'account face human people person profile recording sound speaking speech transcript user',
    Rectangle: 'four parallelograms polygons quadrilaterals recangle shape sides',
    Reddit: 'brand logo social',
    Redeem:
      'bill cart cash certificate coin commerce credit currency dollars giftcard money online payment present shopping',
    Redo: 'arrow backward forward next repeat rotate undo',
    ReduceCapacity: 'arrow body covid decrease down human people person social',
    Refresh:
      'around arrows direction inprogress loading navigation refresh renew right rotate turn',
    RememberMe:
      'Android avatar device hardware human iOS identity mobile people person phone profile tablet user',
    Remove: 'can delete line minus negative substract subtract trash',
    RemoveCircle:
      'allowed banned block can delete disable minus negative not substract trash',
    RemoveCircleOutline:
      'allowed banned block can delete disable minus negative not substract trash',
    RemoveDone:
      'approve check complete disabled enabled finished mark multiple off ok select slash tick yes',
    RemoveFromQueue:
      'collection desktop device display hardware list monitor screen steam television',
    RemoveModerator:
      'certified disabled enabled off privacy private protection security shield slash verified',
    RemoveRedEye: 'iris looking preview see sight vision',
    RemoveRoad:
      '- cancel close destination direction exit highway maps minus new no stop street symbol traffic',
    RemoveShoppingCart:
      'card cash checkout coin commerce credit currency disabled dollars enabled off online payment slash tick',
    Reorder: 'format lines list stacked',
    Repartition: 'arrows data refresh renew restore table',
    Repeat: 'arrows controls media music video',
    RepeatOn: 'arrows controls media music video',
    RepeatOne: '1 arrows controls digit media music number symbol video',
    RepeatOneOn: 'arrows controls digit media music number symbol video',
    Replay:
      'arrows controls music refresh reload renew repeat retry rewind undo video',
    Replay10:
      'arrows controls digit music number refresh renew repeat rewind symbol ten video',
    Replay30:
      'arrows controls digit music number refresh renew repeat rewind symbol thirty video',
    Replay5:
      'arrows controls digit five music number refresh renew repeat rewind symbol video',
    ReplayCircleFilled: 'arrows controls music refresh renew repeat video',
    Reply: 'arrow backward left mail message send share',
    ReplyAll: 'arrows backward group left mail message multiple send share',
    Report:
      '! alert attention caution danger error exclamation important mark notification octagon symbol warning',
    ReportGmailerrorred:
      '! alert attention caution danger exclamation important mark notification octagon symbol warning',
    ReportOff:
      '! alert attention caution danger disabled enabled error exclamation important mark notification octagon offline slash symbol warning',
    ReportProblem:
      '! alert announcement attention caution danger error exclamation feedback important mark notification symbol triangle warning',
    RequestPage: 'data document drive file folders paper sheet slide writing',
    RequestQuote:
      'bill card cash coin commerce cost credit currency dollars finance money online payment price shopping symbol',
    ResetTv: 'arrow device hardware monitor television',
    RestartAlt: 'around arrow inprogress loading reboot refresh renew repeat reset',
    Restaurant:
      'breakfast cutlery dining dinner eat food fork knife local lunch meal places spoon utensils',
    RestaurantMenu: 'book dining eat food fork knife local meal spoon',
    Restore:
      'arrow backwards clock date history refresh renew reverse rotate schedule time turn undo',
    RestoreFromTrash:
      'arrow backwards can clock date delete garbage history refresh remove renew reverse rotate schedule time turn up',
    RestorePage:
      'arrow data doc file history paper refresh rotate sheet storage undo web',
    Reviews:
      'bubble chat comment communicate feedback message rate rating recommendation speech',
    RiceBowl: 'dinner food lunch meal restaurant',
    RingVolume:
      'calling cell contact device hardware incoming mobile ringer sound telephone',
    RMobiledata: 'alphabet character font letter symbol text type',
    Rocket: 'spaceship',
    RocketLaunch: 'spaceship takeoff',
    RollerShades: 'blinds cover curtains nest open shutter sunshade',
    RollerShadesClosed: 'blinds cover curtains nest shutter sunshade',
    RollerSkating:
      'athlete athletic entertainment exercise hobby shoe skates social sports travel',
    Roofing:
      'architecture building chimney construction estate home house real residence residential service shelter',
    Room: 'destination direction gps location maps marker pin place spot stop',
    RoomPreferences:
      'building doorway entrance gear home house interior office open settings',
    RoomService: 'alert bell concierge delivery hotel notify',
    Rotate90DegreesCcw: 'arrows direction editing image photo turn',
    Rotate90DegreesCw: 'arrows ccw direction editing image photo turn',
    RotateLeft:
      'around arrow circle direction inprogress loading refresh reload renew reset turn',
    RotateRight: 'around arrow circle direction inprogress loading refresh renew turn',
    RoundaboutLeft: 'arrows directions maps navigation path route sign traffic',
    RoundaboutRight: 'arrows directions maps navigation path route sign traffic',
    RoundedCorner: 'adjust edit shape square transform',
    Route: 'directions maps path sign traffic',
    Router: 'box cable connection device hardware internet network signal wifi',
    Rowing: 'activity boat body canoe human people person sports water',
    RssFeed:
      'application blog connection data internet network service signal website wifi wireless',
    Rsvp: 'alphabet character font invitation invite letter plaît respond répondez sil symbol text type vous',
    Rtt: 'call real rrt text time',
    Rule: 'approve check done incomplete line mark missing no ok select tick validate verified wrong x yes',
    RuleFolder:
      'approve cancel check close complete data document done drive exit file mark no ok remove select sheet slide storage tick validate verified yes',
    RunCircle: 'body exercise human people person running',
    RunningWithErrors:
      '! alert attention caution danger duration exclamation important mark notification processing symbol time warning',
    RvHookup:
      'arrow attach automobile automotive back cars connect direction left maps public right trailer transportation travel truck van vehicle',
    SafetyCheck:
      'certified clock privacy private protection schedule security shield time verified',
    SafetyDivider: 'apart distance separate social space',
    Sailing:
      'entertainment fishing hobby ocean sailboat sea social sports travel water',
    Sanitizer: 'bacteria bottle clean covid disinfect germs pump',
    Satellite:
      'bluetooth connection connectivity data device image internet landscape location maps mountains network photography picture scan service signal symbol wifi wireless--',
    SatelliteAlt: 'alternative artificial communication space station television',
    Save: 'data diskette document drive file floppy multimedia storage write',
    SaveAlt: 'arrow diskette document down file floppy multimedia write',
    SaveAs:
      'compose create data disk document draft drive editing file floppy input multimedia pencil storage write writing',
    SavedSearch: 'find glass important look magnifying marked see star',
    Savings:
      'bank bill card cash coin commerce cost credit currency dollars finance money online payment piggy symbol',
    Scale: 'measure monitor weight',
    Scanner: 'copy device hardware machine',
    ScatterPlot:
      'analytics bars chart circles data diagram dot infographic measure metrics statistics tracking',
    Schedule: 'calendar clock date mark save time',
    ScheduleSend: 'calendar clock date email letter remember share time',
    Schema:
      'analytics chart data diagram flow infographic measure metrics statistics tracking',
    School:
      'academy achievement cap class college education graduation hat knowledge learning university',
    Science:
      'beaker chemical chemistry experiment flask glass laboratory research tube',
    Score:
      '2k alphabet analytics bars character chart data diagram digit font infographic letter measure metrics number statistics symbol text tracking type',
    Scoreboard: 'points sports',
    ScreenLockLandscape:
      'Android device hardware iOS mobile phone rotate security tablet',
    ScreenLockPortrait:
      'Android device hardware iOS mobile phone rotate security tablet',
    ScreenLockRotation:
      'Android arrow device hardware iOS mobile phone rotate tablet turn',
    ScreenRotation:
      'Android arrow device hardware iOS mobile phone rotate tablet turn',
    ScreenRotationAlt:
      'Android arrow device hardware iOS mobile phone rotate tablet turn',
    ScreenSearchDesktop: 'Android arrow device hardware iOS lock monitor rotate web',
    ScreenShare:
      'Android arrow cast chrome device display hardware iOS laptop mac mirror monitor steam streaming web window',
    Screenshot: 'Android cell crop device hardware iOS mobile phone tablet',
    ScreenshotMonitor:
      'Android chrome desktop device display hardware iOS mac screengrab web window',
    ScubaDiving: 'entertainment exercise hobby social swimming',
    Sd: 'alphabet camera card character data device digital drive flash font image letter memory photo secure symbol text type',
    SdCard: 'camera digital memory photos secure storage',
    SdCardAlert:
      '! attention camera caution danger digital error exclamation important mark memory notification photos secure storage symbol warning',
    SdStorage: 'camera card data digital memory microsd secure',
    Search: 'filter find glass look magnifying see up',
    SearchOff:
      'cancel close disabled enabled find glass look magnifying on see slash stop x',
    Security: 'certified privacy private protection shield verified',
    SecurityUpdate: 'Android arrow device download hardware iOS mobile phone tablet',
    SecurityUpdateGood:
      'Android checkmark device hardware iOS mobile ok phone tablet tick',
    SecurityUpdateWarning:
      '! Android alert attention caution danger device download error exclamation hardware iOS important mark mobile notification phone symbol tablet',
    Segment: 'alignment fonts format lines list paragraph part piece rules style text',
    SelectAll: 'selection square tool',
    SelfImprovement:
      'body calm care chi human meditate meditation people person relax sitting wellbeing yoga zen',
    Sell: 'bill card cart cash coin commerce credit currency dollars money online payment price shopping tag',
    Send: 'chat email message paper plane reply right share telegram',
    SendAndArchive: 'arrow download email letter save share',
    SendTimeExtension: 'deliver dispatch envelop mail message schedule',
    SendToMobile:
      'Android arrow device export forward hardware iOS phone right share tablet',
    SensorOccupied:
      'body connection human network people person scan sensors signal wireless',
    Sensors: 'connection network scan signal wireless',
    SensorsOff: 'connection disabled enabled network scan signal slash wireless',
    SentimentDissatisfied:
      'angry disappointed dislike emoji emoticon emotions expressions face feelings frown mood person sad smiley survey unhappy unsatisfied upset',
    SentimentNeutral:
      'emotionless emotions expressions face feelings indifference mood okay person survey',
    SentimentSatisfied:
      'emoji emoticon emotions expressions face feelings glad happiness happy like mood person pleased smiley smiling survey',
    SentimentSatisfiedAlt:
      'account emoji face happy human people person profile smile user',
    SentimentVeryDissatisfied:
      'angry disappointed dislike emoji emoticon emotions expressions face feelings mood person sad smiley sorrow survey unhappy unsatisfied upset',
    SentimentVerySatisfied:
      'emoji emoticon emotions expressions face feelings glad happiness happy like mood person pleased smiley smiling survey',
    SetMeal: 'chopsticks dinner fish food lunch restaurant teishoku',
    Settings: 'application change details gear information options personal service',
    SettingsAccessibility:
      'body details human information people personal preferences profile user',
    SettingsApplications:
      'change details gear information options personal save service',
    SettingsBackupRestore: 'arrow backwards history refresh reverse rotate time undo',
    SettingsBluetooth: 'connection connectivity device network signal symbol wifi',
    SettingsBrightness: 'dark filter light mode sun',
    SettingsCell: 'Android cellphone device hardware iOS mobile tablet',
    SettingsEthernet:
      'arrows brackets computer connection connectivity dots internet network parenthesis wifi',
    SettingsInputAntenna:
      'airplay arrows computer connection connectivity dots internet network screencast stream wifi wireless',
    SettingsInputComponent:
      'audio av cables connection connectivity internet plugs points video wifi',
    SettingsInputComposite: 'cable component connection connectivity plugs points',
    SettingsInputHdmi:
      'cable connection connectivity definition high plugin points video wire',
    SettingsInputSvideo:
      'cable connection connectivity definition plugin plugs points standard svideo,',
    SettingsOverscan: 'arrows expand image photo picture',
    SettingsPhone: 'call cell contact device hardware mobile telephone',
    SettingsPower: 'information off save shutdown',
    SettingsRemote:
      'bluetooth connection connectivity control device signal wifi wireless',
    SettingsSuggest:
      'change details gear options recommendation service suggestion system',
    SettingsSystemDaydream: 'backup cloud drive storage',
    SettingsVoice: 'microphone recorder speaker',
    SevenK:
      '7000 7K alphabet character digit display font letter number pixels resolution symbol text type video',
    SevenKPlus:
      '+ 7000 7K alphabet character digit display font letter number pixels resolution symbol text type video',
    SevenMp:
      'camera digit font image letters megapixels number quality resolution symbol text type',
    SeventeenMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    SevereCold: 'crisis diaster snowflake weather',
    ShapeLine: 'circle draw editing square',
    Share:
      'android connect contect disable_ios link multimedia multiple network options send shared sharing social',
    ShareLocation: 'destination direction gps maps pin place stop tracking',
    Shield: 'certified privacy private protection secure security verified',
    ShieldMoon:
      'certified disturb do night not privacy private protection security verified',
    Shop: 'arrow bag bill briefcase buy card cart cash coin commerce credit currency dollars google money online payment play purchase shopping store',
    Shop2: 'add arrow buy cart google play purchase shopping',
    ShoppingBag:
      'bill business buy card cart cash coin commerce credit currency dollars money online payment storefront',
    ShoppingBasket:
      'add bill buy card cart cash checkout coin commerce credit currency dollars money online payment purchase',
    ShoppingCart:
      'add bill buy card cash checkout coin commerce credit currency dollars money online payment purchase',
    ShoppingCartCheckout:
      'arrow cash coin commerce currency dollars money online payment right',
    ShopTwo: 'add arrow briefcase buy cart google play purchase shopping',
    Shortcut: 'arrow direction forward right',
    ShortText: 'brief comment document lines note write writing',
    ShowChart:
      'analytics bars chart data diagram infographic line measure metrics presentation show statistics stock tracking',
    Shower: 'bathroom closet home house place plumbing sprinkler wash water wc',
    Shuffle: 'arrows controls music random video',
    ShuffleOn: 'arrows controls music random video',
    ShutterSpeed:
      'aperture camera duration image lens photography photos picture setting stop timer watch',
    Sick: 'covid discomfort emotions expressions face feelings fever flu ill mood pain person survey upset',
    SignalCellular0Bar: 'data internet mobile network phone speed wifi wireless',
    SignalCellular4Bar: 'data internet mobile network phone speed wifi wireless',
    SignalCellularAlt:
      'analytics bar chart data diagram infographic internet measure metrics mobile network phone statistics tracking wifi wireless',
    SignalCellularAlt1Bar: 'data internet mobile network phone speed wifi wireless',
    SignalCellularAlt2Bar: 'data internet mobile network phone speed wifi wireless',
    SignalCellularConnectedNoInternet0Bar:
      '! alert attention caution danger data error exclamation important mark mobile network notification phone symbol warning wifi wireless',
    SignalCellularConnectedNoInternet1Bar: 'network',
    SignalCellularConnectedNoInternet2Bar: 'network',
    SignalCellularConnectedNoInternet3Bar: 'network',
    SignalCellularConnectedNoInternet4Bar:
      '! alert attention caution danger data error exclamation important mark mobile network notification phone symbol warning wifi wireless',
    SignalCellularNodata: 'internet mobile network offline phone quit wifi wireless x',
    SignalCellularNoSim:
      'camera card chip device disabled enabled memory network offline phone slash storage',
    SignalCellularNull: 'data internet mobile network phone wifi wireless',
    SignalCellularOff:
      'data disabled enabled internet mobile network offline phone slash wifi wireless',
    SignalWifi0Bar: 'cellular data internet mobile network phone wireless',
    SignalWifi1Bar: 'network',
    SignalWifi1BarLock: 'network',
    SignalWifi2Bar: 'network',
    SignalWifi2BarLock: 'network',
    SignalWifi3Bar: 'network',
    SignalWifi3BarLock: 'network',
    SignalWifi4Bar: 'cellular data internet mobile network phone wireless',
    SignalWifi4BarLock:
      'cellular data internet locked mobile network password phone privacy private protection safety secure security wireless',
    SignalWifiBad:
      'bar cancel cellular close data exit internet mobile network no phone quit remove stop wireless',
    SignalWifiConnectedNoInternet4:
      'cellular data mobile network offline phone wireless x',
    SignalWifiOff:
      'cellular data disabled enabled internet mobile network phone slash speed wireless',
    SignalWifiStatusbar4Bar:
      'cellular data internet mobile network phone speed wireless',
    SignalWifiStatusbarConnectedNoInternet4:
      '! alert attention caution cellular danger data error exclamation important mark mobile network notification phone speed symbol warning wireless',
    SignalWifiStatusbarNull:
      'cellular data internet mobile network phone speed wireless',
    SignLanguage: 'communication deaf fingers gesture hand',
    Signpost: 'arrow direction left maps right signal signs street traffic',
    SimCard: 'camera chip device memory network phone storage',
    SimCardAlert:
      '! attention camera caution danger digital error exclamation important mark memory notification photos sd secure storage symbol warning',
    SimCardDownload: 'arrow camera chip device memory phone storage',
    SingleBed:
      'bedroom double furniture home hotel house king night pillows queen rest sleep twin',
    Sip: 'alphabet call character dialer font initiation internet letter over phone protocol routing session symbol text type voice',
    SixK: '6000 6K alphabet character digit display font letter number pixels resolution symbol text type video',
    SixKPlus:
      '+ 6000 6K alphabet character digit display font letter number pixels resolution symbol text type video',
    SixMp:
      'camera digit font image letters megapixels number quality resolution symbol text type',
    SixteenMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    SixtyFps: 'camera digit frames number symbol video',
    SixtyFpsSelect: 'camera digits frame frequency numbers per rate seconds video',
    Skateboarding:
      'athlete athletic body entertainment exercise hobby human people person skateboarder social sports',
    SkipNext: 'arrow back controls forward music play previous transport video',
    SkipPrevious: 'arrow backward controls forward music next play transport video',
    Sledding:
      'athlete athletic body entertainment exercise hobby human people person sledge snow social sports travel winter',
    Slideshow: 'movie photos play presentation square video view',
    SlowMotionVideo: 'arrow circle controls music play speed time',
    SmartButton:
      'action auto components composer function interface special stars ui ux website',
    SmartDisplay:
      'airplay chrome connect device screencast stream television tv video wireless',
    Smartphone: 'Android call cell chat device hardware iOS mobile tablet text',
    SmartScreen:
      'Android airplay cell connect device hardware iOS mobile phone screencast stream tablet video',
    SmartToy: 'games robot',
    SmokeFree:
      'cigarette disabled enabled never no off places prohibited slash smoking tobacco warning zone',
    SmokingRooms: 'allowed cigarette places smoke tobacco zone',
    Sms: '3 bubble chat comment communication conversation dots message more service speech three',
    SmsFailed:
      '! alert attention bubbles caution chat comment communication conversation danger error exclamation important mark message notification service speech symbol warning',
    SnippetFolder: 'data document drive file sheet slide storage',
    Snooze: 'alarm bell clock duration notification set timer watch',
    Snowboarding:
      'athlete athletic body entertainment exercise hobby human people person social sports travel winter',
    Snowmobile:
      'automobile car direction skimobile social sports transportation travel vehicle winter',
    Snowshoeing: 'body human people person sports travel walking winter',
    Soap: 'bathroom clean fingers gesture hand wash wc',
    SocialDistance: '6 apart body ft human people person space',
    SolarPower: 'eco energy heat nest sunny',
    Sort: 'filter find lines list organize',
    SortByAlpha:
      'alphabetize az by character font letters list order organize symbol text type',
    Sos: 'font help letters save text type',
    SoupKitchen: 'breakfast brunch dining food lunch meal',
    Source:
      'code composer content creation data document file folder mode storage view',
    South: 'arrow directional down maps navigation',
    SouthAmerica: 'america continent landscape place region south',
    SouthEast: 'arrow directional down maps navigation right',
    SouthWest: 'arrow directional down left maps navigation',
    Spa: 'aromatherapy flower healthcare leaf massage meditation nature petals places relax wellbeing wellness',
    SpaceBar: 'keyboard line',
    SpatialAudio: 'music note sound',
    SpatialAudioOff: '[offline] disabled enabled music note on slash sound',
    SpatialTracking: '[offline] audio disabled enabled music note on slash sound',
    Speaker: 'audio box electronic loud music sound stereo system video',
    SpeakerGroup: 'audio box electronic loud multiple music sound stereo system video',
    SpeakerNotes:
      'bubble cards chat comment communicate format list message speech text',
    SpeakerNotesOff:
      'bubble cards chat comment communicate disabled enabled format list message on slash speech text',
    SpeakerPhone: 'Android cell device hardware iOS mobile sound tablet volume',
    Speed:
      'arrow clock controls dial fast gauge measure motion music slow speedometer test velocity video',
    Spellcheck:
      'alphabet approve character checkmark edit font letter ok processor select symbol text tick type word write yes',
    Splitscreen: 'grid layout multitasking two',
    Spoke: 'connection network radius',
    Sports:
      'athlete athletic basketball blowing coach entertainment exercise game hobby instrument live referee soccer social sound trophy warning whistle',
    SportsBar: 'alcohol beer drink liquor pint places pub',
    SportsBaseball: 'athlete athletic entertainment exercise game hobby social',
    SportsBasketball: 'athlete athletic entertainment exercise game hobby social',
    SportsCricket:
      'athlete athletic ball bat entertainment exercise game hobby social',
    SportsEsports:
      'controller entertainment gamepad gaming hobby online playstation social video xbox',
    SportsFootball:
      'american athlete athletic entertainment exercise game hobby social',
    SportsGolf:
      'athlete athletic ball club entertainment exercise game golfer golfing hobby social',
    SportsGymnastics: 'athlete athletic entertainment exercise hobby social',
    SportsHandball:
      'athlete athletic body entertainment exercise game hobby human people person social',
    SportsHockey:
      'athlete athletic entertainment exercise game hobby ice social sticks',
    SportsKabaddi:
      'athlete athletic body combat entertainment exercise fighting game hobby human judo martial people person social wrestle wrestling',
    SportsMartialArts:
      'athlete athletic entertainment exercise hobby human karate people person social',
    SportsMma:
      'arts athlete athletic boxing combat entertainment exercise fighting game glove hobby martial mixed social',
    SportsMotorsports:
      'athlete athletic automobile bike drive driving entertainment helmet hobby motorcycle protect social vehicle',
    SportsRugby: 'athlete athletic ball entertainment exercise game hobby social',
    SportsScore: 'destination flag goal',
    SportsSoccer: 'athlete athletic entertainment exercise football game hobby social',
    SportsTennis:
      'athlete athletic ball bat entertainment exercise game hobby racket social',
    SportsVolleyball: 'athlete athletic entertainment exercise game hobby social',
    Square: 'draw four quadrangle shape sides',
    SquareFoot: 'construction feet inches length measurement ruler school set tools',
    SsidChart: 'graph lines network wifi',
    StackedBarChart:
      'analytics chart-chart data diagram infographic measure metrics statistics tracking',
    StackedLineChart:
      'analytics data diagram infographic measure metrics statistics tracking',
    Stadium: 'activity amphitheater arena coliseum event local star things ticket',
    Stairs: 'down staircase up',
    Star: 'best bookmark favorite highlight ranking rate rating save toggle',
    StarBorder:
      'best bookmark favorite highlight outline ranking rate rating save toggle',
    StarBorderPurple500:
      'best bookmark favorite highlight outline ranking rate rating save toggle',
    StarHalf:
      '0.5 1/2 achievement bookmark favorite highlight important marked ranking rate rating reward saved shape special toggle',
    StarOutline: 'bookmark favorite half highlight ranking rate rating save toggle',
    StarPurple500: 'best bookmark favorite highlight ranking rate rating save toggle',
    StarRate:
      'achievement bookmark favorite highlight important marked ranking rating reward saved shape special',
    Stars:
      'achievement bookmark circle favorite highlight important like love marked ranking rate rating reward saved shape special',
    Start: 'arrow keyboard next right',
    StayCurrentLandscape: 'Android device hardware iOS mobile phone tablet',
    StayCurrentPortrait: 'Android device hardware iOS mobile phone tablet',
    StayPrimaryLandscape: 'Android current device hardware iOS mobile phone tablet',
    StayPrimaryPortrait: 'Android current device hardware iOS mobile phone tablet',
    StickyNote2: 'bookmark message paper text writing',
    Stop: 'arrow controls music pause player square video',
    StopCircle: 'controls music pause play square video',
    StopScreenShare:
      'Android arrow cast chrome device disabled display enabled hardware iOS laptop mac mirror monitor offline slash steam streaming web window',
    Storage: 'computer database drive memory network server',
    Store:
      'bill building business buy card cash coin company credit currency dollars e-commerce market money online payment purchase shopping storefront',
    Storefront:
      'business buy cafe commerce market merchant places restaurant retail sell shopping stall',
    StoreMallDirectory: 'building',
    Storm: 'forecast hurricane temperature twister weather wind',
    Straight: 'arrows directions maps navigation path route sign traffic up',
    Straighten: 'length measurement piano ruler size',
    Stream: 'cast connected feed live network signal wireless',
    Streetview: 'gps location maps',
    StrikethroughS:
      'alphabet character cross doc editing editor font letter out spreadsheet styles symbol text type writing',
    Stroller: 'baby care carriage children infant kid newborn toddler young',
    Style: 'booklet cards filters options tags',
    SubdirectoryArrowLeft: 'arrow down navigation',
    SubdirectoryArrowRight: 'arrow down navigation',
    Subject: 'alignment document email full justify lines list note text writing',
    Subscript:
      '2 doc editing editor gmail novitas spreadsheet style symbol text writing',
    Subscriptions: 'enroll media order playlist queue signup subscribe youtube',
    Subtitles:
      'accessibility accessible captions character closed decoder language media movies translate tv',
    SubtitlesOff:
      'accessibility accessible caption closed disabled enabled language slash translate video',
    Subway:
      'automobile bike cars maps metro rail scooter train transportation travel tunnel underground vehicle vespa',
    Summarize: 'document list menu note report summary',
    Superscript:
      '2 doc editing editor gmail novitas spreadsheet style symbol text writing',
    SupervisedUserCircle:
      'account avatar control face human parental parents people person profile supervisor',
    SupervisorAccount:
      'administrator avatar control face human parental parents people person profile supervised user',
    Support: 'assist help lifebuoy rescue safety',
    SupportAgent: 'care customer face headphone person representative service',
    Surfing:
      'athlete athletic beach body entertainment exercise hobby human people person sea social sports summer water',
    SurroundSound: 'audio circle signal speaker system volume volumn wireless',
    SwapCalls: 'arrows device direction mobile share',
    SwapHoriz: 'arrows back direction forward horizontal',
    SwapHorizontalCircle: 'arrows back direction forward',
    SwapVert: 'arrows back direction down navigation up vertical',
    SwapVerticalCircle: 'arrows back direction down horizontal up',
    Swipe: 'arrows fingers gesture hands touch',
    SwipeDown:
      'arrows direction disable enable finger hands hit navigation strike swing swpie take',
    SwipeDownAlt:
      'arrows direction disable enable finger hands hit navigation strike swing swpie take',
    SwipeLeft: 'arrows finger hand hit navigation reject strike swing take',
    SwipeLeftAlt: 'arrows finger hand hit navigation reject strike swing take',
    SwipeRight:
      'accept arrows direction finger hands hit navigation strike swing swpie take',
    SwipeRightAlt:
      'accept arrows direction finger hands hit navigation strike swing swpie take',
    SwipeUp:
      'arrows direction disable enable finger hands hit navigation strike swing swpie take',
    SwipeUpAlt:
      'arrows direction disable enable finger hands hit navigation strike swing swpie take',
    SwipeVertical:
      'arrows direction finger hands hit navigation strike swing swpie take verticle',
    SwitchAccessShortcut: 'arrows direction navigation new north star symbol up',
    SwitchAccessShortcutAdd:
      '+ arrows direction navigation new north plus star symbol up',
    SwitchAccount:
      'choices face human multiple options people person profile social user',
    SwitchCamera: 'arrows photography picture',
    SwitchLeft: 'arrows directional navigation toggle',
    SwitchRight: 'arrows directional navigation toggle',
    SwitchVideo: 'arrows camera photography videos',
    Synagogue: 'jewish religion shul spiritual temple worship',
    Sync: '360 around arrows direction inprogress loading refresh renew rotate turn',
    SyncAlt: 'arrows horizontal internet technology update wifi',
    SyncDisabled:
      '360 around arrows direction enabled inprogress loading off refresh renew rotate slash turn',
    SyncLock:
      'around arrows locked password privacy private protection renew rotate safety secure security turn',
    SyncProblem:
      '! 360 alert around arrows attention caution danger direction error exclamation important inprogress loading mark notification refresh renew rotate symbol turn warning',
    SystemSecurityUpdate:
      'Android arrow cell device down hardware iOS mobile phone tablet',
    SystemSecurityUpdateGood:
      'Android approve cell check complete device done hardware iOS mark mobile ok phone select tablet tick validate verified yes',
    SystemSecurityUpdateWarning:
      '! Android alert attention caution cell danger device error exclamation hardware iOS important mark mobile notification phone symbol tablet',
    SystemUpdate:
      'Android arrows cell device direction download hardware iOS install mobile phone tablet',
    SystemUpdateAlt: 'arrow download export',
    Tab: 'browser computer documents folder internet tabs website windows',
    TableBar: 'cafe round',
    TableChart:
      'analytics bars data diagram grid infographic measure metrics statistics tracking',
    TableRestaurant: 'bar dining',
    TableRows: 'grid layout lines stacked',
    Tablet: 'Android device hardware iOS ipad mobile web',
    TabletAndroid: 'device hardware iOS ipad mobile web',
    TabletMac: 'Android apple device hardware iOS ipad mac mobile tablet web',
    TableView: 'format grid group layout multiple',
    TabUnselected: 'browser computer documents folder internet tabs website windows',
    Tag: 'hashtag key media number pound social trend',
    TagFaces: 'emoji emotion happy satisfied smile',
    TakeoutDining: 'box container delivery food meal restaurant',
    TapAndPlay:
      'Android cell connection device hardware iOS internet mobile network nfc phone signal tablet to wifi wireless',
    Tapas: 'appetizer brunch dinner food lunch restaurant snack',
    Task: 'approve check complete data document done drive file folders mark ok page paper select sheet slide tick validate verified writing yes',
    TaskAlt:
      'approve check circle complete done mark ok select tick validate verified yes',
    TaxiAlert:
      '! attention automobile cab cars caution danger direction error exclamation important lyft maps mark notification public symbol transportation uber vehicle warning yellow',
    Telegram: 'brand call chat logo messaging voice',
    TempleBuddhist: 'buddha buddhism monastery religion spiritual worship',
    TempleHindu: 'hinduism hindus mandir religion spiritual worship',
    TenMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    Terminal: 'application code emulator program software',
    Terrain: 'geography landscape mountain',
    TextDecrease:
      '- alphabet character font letter minus remove resize subtract symbol type',
    TextFields: 'T add alphabet character font input letter symbol type',
    TextFormat: 'A alphabet character font letter square style symbol type',
    TextIncrease: '+ add alphabet character font letter new plus resize symbol type',
    TextRotateUp: 'A alphabet arrow character field font letter move symbol type',
    TextRotateVertical:
      'A alphabet arrow character down field font letter move symbol type verticle',
    TextRotationAngledown:
      'A alphabet arrow character field font letter move rotate symbol type',
    TextRotationAngleup:
      'A alphabet arrow character field font letter move rotate symbol type',
    TextRotationDown:
      'A alphabet arrow character field font letter move rotate symbol type',
    TextRotationNone:
      'A alphabet arrow character field font letter move rotate symbol type',
    Textsms: 'bubble chat comment communicate dots feedback message speech',
    TextSnippet: 'data document file notes storage writing',
    Texture: 'diagonal lines pattern stripes',
    TheaterComedy: 'broadway event movie musical places show standup tour watch',
    Theaters: 'film media movies photography showtimes video watch',
    Thermostat: 'forecast temperature weather',
    ThermostatAuto: 'A celsius fahrenheit temperature thermometer',
    ThirteenMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    ThirtyFps:
      'alphabet camera character digit font frames letter number symbol text type video',
    ThirtyFpsSelect:
      'camera digits frame frequency image numbers per rate seconds video',
    ThreeDRotation:
      '3d D alphabet arrows av camera character digit font letter number symbol text type vr',
    ThreeGMobiledata:
      'alphabet cellular character digit font letter network number phone signal speed symbol text type wifi',
    ThreeK:
      '3000 3K alphabet character digit display font letter number pixels resolution symbol text type video',
    ThreeKPlus:
      '+ 3000 3K alphabet character digit display font letter number pixels resolution symbol text type video',
    ThreeMp:
      'camera digit font image letters megapixels number quality resolution symbol text type',
    ThreeP:
      'account avatar bubble chat comment communicate face human message party people person profile speech user',
    ThreeSixty: 'arrow av camera direction rotate rotation vr',
    ThumbDown:
      'dislike downvote favorite fingers gesture hands ranking rate rating reject up',
    ThumbDownAlt:
      'bad decline disapprove dislike feedback hand hate negative no reject social veto vote',
    ThumbDownOffAlt:
      '[offline] bad decline disabled disapprove dislike enabled favorite feedback filled fingers gesture hands hate negative no on ranking rate rating reject sad slash social veto vote',
    ThumbsUpDown: 'dislike favorite fingers gesture hands rate rating vote',
    ThumbUp:
      'approve dislike down favorite fingers gesture hands ranking rate rating success upvote',
    ThumbUpAlt:
      'agreed approved confirm correct favorite feedback good hand happy like okay positive satisfaction social success vote yes',
    ThumbUpOffAlt:
      '[offline] agreed approved confirm correct disabled enabled favorite feedback fingers gesture good hands happy like okay positive ranking rate rating satisfaction slash social vote yes',
    Thunderstorm: 'cloud lightning rain weather',
    Timelapse: 'duration motion photo timer video',
    Timeline:
      'analytics chart data graph history line movement points tracking trending zigzag zigzap',
    Timer:
      'alarm alart bell clock disabled duration enabled notification off slash stopwatch wait',
    Timer10: 'digits duration numbers seconds',
    Timer10Select:
      'alphabet camera character digit font letter number seconds symbol text type',
    Timer3: 'digits duration numbers seconds',
    Timer3Select:
      'alphabet camera character digit font letter number seconds symbol text type',
    TimerOff:
      'alarm alart bell clock disabled duration enabled notification slash stopwatch',
    TimesOneMobiledata:
      'alphabet cellular character digit font letter network number phone signal speed symbol text type wifi',
    TimeToLeave:
      'automobile cars destination direction drive estimate eta maps public transportation travel trip vehicle',
    TipsAndUpdates:
      'alert announcement electricity idea information lamp lightbulb stars',
    TireRepair: 'automobile cars gauge mechanic pressure vehicle',
    Title: 'T alphabet character font header letter subject symbol text type',
    Toc: 'content format lines list reorder stacked table text titles',
    Today:
      'agenda calendar date event mark month range remember reminder schedule time week',
    ToggleOff:
      'application components configuration control design disable inable inactive interface selection settings slider switch ui ux website',
    ToggleOn:
      'application components configuration control design disable inable inactive interface off selection settings slider switch ui ux website',
    Token: 'badge hexagon mark shield sign symbol',
    Toll: 'bill booth card cash circles coin commerce credit currency dollars highway money online payment ticket',
    Tonality: 'circle editing filter image photography picture',
    Topic: 'data document drive file folder sheet slide storage',
    Tornado: 'crisis disaster natural rain storm weather wind',
    TouchApp: 'arrow command fingers gesture hand press swipe tap',
    Tour: 'destination flag places travel visit',
    Toys: 'car fan games kids windmill',
    TrackChanges: 'bullseye circle evolve lines movement radar rotate shift target',
    Traffic: 'direction light maps signal street',
    Train: 'automobile cars direction maps public rail subway transportation vehicle',
    Tram: 'automobile cars direction maps public rail subway train transportation vehicle',
    TransferWithinAStation:
      'arrows body direction human left maps people person public right route stop transit transportation vehicle walk',
    Transform: 'adjust crop editing image photo picture',
    Transgender: 'female lgbt neutral neutrual social symbol',
    TransitEnterexit: 'arrow direction maps navigation route transportation',
    Translate: 'alphabet language letter speaking speech text translator words',
    TravelExplore:
      'earth find glass global globe look magnifying map network planet search see social space web world',
    TrendingDown:
      'analytics arrow change chart data diagram infographic measure metrics movement rate rating sale statistics tracking',
    TrendingFlat: 'arrow change chart data graph metric movement rate right tracking',
    TrendingUp:
      'analytics arrow change chart data diagram infographic measure metrics movement rate rating statistics tracking',
    TripOrigin: 'circle departure',
    Troubleshoot:
      'analytics chart data diagram find glass infographic line look magnifying measure metrics search see statistics tracking',
    Try: 'bookmark bubble chat comment communicate favorite feedback highlight important marked message saved shape special speech star',
    Tsunami: 'crisis disaster flood rain storm weather',
    Tty: 'call cell contact deaf device hardware impaired mobile speech talk telephone text',
    Tune: 'adjust audio controls customize editing filters instant mix music options settings sliders switches',
    Tungsten: 'electricity indoor lamp lightbulb setting',
    TurnedIn:
      'archive bookmark favorite item label library reading remember ribbon save submit tag',
    TurnedInNot:
      'archive bookmark favorite item label library outline reading remember ribbon save submit tag',
    TurnLeft: 'arrows directions maps navigation path route sign traffic',
    TurnRight: 'arrows directions maps navigation path route sign traffic',
    TurnSharpLeft: 'arrows directions maps navigation path route sign traffic',
    TurnSharpRight: 'arrows directions maps navigation path route sign traffic',
    TurnSlightLeft: 'arrows directions maps navigation path right route sign traffic',
    TurnSlightRight: 'arrows directions maps navigation path route sharp sign traffic',
    Tv: 'device display linear living monitor room screencast stream television video wireless',
    TvOff:
      'Android chrome desktop device disabled enabled hardware iOS mac monitor slash television web window',
    TwelveMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    TwentyFourMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    TwentyOneMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    TwentyThreeMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    TwentyTwoMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    TwentyZeroMp:
      'camera digits font image letters megapixels numbers quality resolution symbol text type',
    Twitter: 'brand logo social',
    TwoK: '2000 2K alphabet character digit display font letter number pixels resolution symbol text type video',
    TwoKPlus: '+ alphabet character digit font letter number symbol text type',
    TwoMp:
      'camera digit font image letters megapixels number quality resolution symbol text type',
    TwoWheeler:
      'automobile bicycle cars direction maps moped motorbike motorcycle public ride riding scooter transportation travel twom vehicle wheeler wheels',
    Umbrella: 'beach protection rain sunny',
    Unarchive: 'arrow inbox mail store undo up',
    Undo: 'arrow backward mail previous redo repeat rotate',
    UnfoldLess:
      'arrows chevron collapse direction expandable inward list navigation up',
    UnfoldLessDouble:
      'arrows chevron collapse direction expandable inward list navigation up',
    UnfoldMore: 'arrows chevron collapse direction down expandable list navigation',
    UnfoldMoreDouble:
      'arrows chevron collapse direction down expandable list navigation',
    Unpublished:
      'approve check circle complete disabled done enabled mark off ok select slash tick validate verified yes',
    Unsubscribe:
      'cancel close email envelop esubscribe message newsletter off remove send',
    Upcoming: 'alarm calendar mail message notification',
    Update:
      'arrow backwards clock forward future history load refresh reverse rotate schedule time',
    UpdateDisabled:
      'arrow backwards clock enabled forward history load off on refresh reverse rotate schedule slash time',
    Upgrade: 'arrow export instal line replace update',
    Upload: 'arrows download drive',
    UploadFile:
      'arrow data document download drive folders page paper sheet slide writing',
    Usb: 'cable connection device wire',
    UsbOff: 'cable connection device wire',
    UTurnLeft: 'arrows directions maps navigation path route sign traffic u-turn',
    UTurnRight: 'arrows directions maps navigation path route sign traffic u-turn',
    Vaccines:
      'aid covid doctor drug emergency hospital immunity injection medical medication medicine needle pharmacy sick syringe vaccination vial',
    VapeFree:
      'disabled e-cigarette enabled never no off places prohibited slash smoke smoking tobacco vaping vapor warning zone',
    VapingRooms:
      'allowed e-cigarette never no places prohibited smoke smoking tobacco vape vapor warning zone',
    Verified:
      'approve badge burst check complete done mark ok select star tick validate yes',
    VerifiedUser:
      'approve audit certified checkmark complete done ok privacy private protection security select shield tick validate yes',
    VerticalAlignBottom:
      'alignment arrow doc down editing editor spreadsheet text type writing',
    VerticalAlignCenter:
      'alignment arrow doc down editing editor spreadsheet text type up writing',
    VerticalAlignTop:
      'alignment arrow doc editing editor spreadsheet text type up writing',
    VerticalShades: 'blinds cover curtains nest open shutter sunshade',
    VerticalShadesClosed: 'blinds cover curtains nest roller shutter sunshade',
    VerticalSplit: 'design format grid layout paragraph text website writing',
    Vibration:
      'Android alert cell device hardware iOS mobile mode motion notification phone silence silent tablet vibrate',
    VideoCall:
      '+ add camera chat conference filming hardware image motion new picture plus screen symbol videography',
    Videocam:
      'camera chat conference filming hardware image motion picture screen videography',
    VideoCameraBack: 'image landscape mountains photography picture rear',
    VideoCameraFront:
      'account face human image people person photography picture profile user',
    VideocamOff:
      'camera chat conference disabled enabled filming hardware image motion offline picture screen slash videography',
    VideoChat:
      'bubble camera comment communicate facetime feedback message speech voice',
    VideoFile: 'camera document filming hardware image motion picture videography',
    VideogameAsset:
      'console controller device gamepad gaming nintendo playstation xbox',
    VideogameAssetOff:
      'console controller device disabled enabled gamepad gaming playstation slash',
    VideoLabel: 'device item screen window',
    VideoLibrary: 'arrow collection play',
    VideoSettings:
      'change details gear information options play screen service window',
    VideoStable: 'filming recording setting stability taping',
    ViewAgenda: 'blocks cards design format grid layout website,stacked',
    ViewArray: 'blocks design format grid layout website',
    ViewCarousel: 'banner blocks cards design format grid images layout website',
    ViewColumn: 'blocks design format grid layout vertical website',
    ViewComfy: 'grid layout pattern squares',
    ViewComfyAlt: 'cozy design format layout web',
    ViewCompact: 'grid layout pattern squares',
    ViewCompactAlt: 'dense design format layout web',
    ViewCozy: 'comfy design format layout web',
    ViewDay: 'blocks calendar cards carousel design format grid layout website week',
    ViewHeadline: 'blocks design format grid layout paragraph text website',
    ViewInAr: '3d augmented cube daydream headset reality square vr',
    ViewKanban: 'grid layout pattern squares',
    ViewList: 'blocks design format grid layout lines reorder stacked title website',
    ViewModule:
      'blocks design format grid layout reorder squares stacked title website',
    ViewQuilt:
      'blocks design format grid layout reorder squares stacked title website',
    ViewSidebar: 'design format grid layout web',
    ViewStream:
      'blocks design format grid layout lines list reorder stacked title website',
    ViewTimeline: 'grid layout pattern squares',
    ViewWeek: 'bars blocks columns day design format grid layout website',
    Vignette: 'border editing effect filter gradient image photography setting',
    Villa:
      'architecture beach estate home house maps place real residence residential stay traveling vacation',
    Visibility: 'eye on password preview reveal see shown visability',
    VisibilityOff:
      'disabled enabled eye hidden invisible on password reveal see show slash view visability',
    VoiceChat:
      'bubble camera comment communicate facetime feedback message speech video',
    Voicemail: 'call device message missed mobile phone recording',
    VoiceOverOff:
      'account disabled enabled face human people person profile recording slash speaking speech transcript user',
    Volcano: 'crisis disaster eruption lava magma natural',
    VolumeDown: 'audio av control music quieter shh soft sound speaker tv',
    VolumeMute: 'audio control music sound speaker tv',
    VolumeOff:
      'audio av control disabled enabled low music mute slash sound speaker tv',
    VolumeUp: 'audio control music sound speaker tv',
    VolunteerActivism: 'donation fingers gesture giving hands heart love sharing',
    VpnKey: 'login network passcode password register security signin signup unlock',
    VpnKeyOff: '[offline] disabled enabled network on passcode password slash unlock',
    VpnLock:
      'earth globe locked network password privacy private protection safety secure security virtual world',
    Vrpano: 'angle image landscape mountains panorama photography picture view wide',
    Wallpaper: 'background image landscape photography picture',
    Warehouse: 'garage industry manufacturing storage',
    Warning:
      '! alert announcement attention caution danger error exclamation feedback important mark notification problem symbol triangle',
    WarningAmber:
      '! alert attention caution danger error exclamation important mark notification symbol triangle',
    Wash: 'bathroom clean fingers gesture hand wc',
    Watch: 'Android clock gadget iOS smartwatch time vr wearables web wristwatch',
    WatchLater: 'clock date hour minute schedule time',
    WatchOff: 'Android clock close gadget iOS shut time vr wearables web wristwatch',
    Water: 'aqua beach lake ocean river waves weather',
    WaterDamage:
      'architecture building droplet estate house leak plumbing real residence residential shelter',
    WaterfallChart:
      'analytics bar data diagram infographic measure metrics statistics tracking',
    Waves: 'beach lake ocean pool river sea swim water',
    WbAuto:
      'A W alphabet automatic balance character editing font image letter photography symbol text type white wp',
    WbCloudy: 'balance editing white wp',
    WbIncandescent: 'balance bright editing lamp lightbulb lighting settings white wp',
    WbIridescent: 'balance bright editing lighting settings white wp',
    WbShade: 'balance house lighting white',
    WbSunny: 'balance bright lighting weather white',
    WbTwilight: 'balance lighting noon sunset white',
    Wc: 'bathroom closet female gender man person restroom toilet unisex wash water women',
    Web: 'blocks browser internet page screen website www',
    WebAsset:
      '-website application browser design desktop download image interface internet layout screen ui ux video window www',
    WebAssetOff:
      'browser disabled enabled internet on screen slash webpage website windows www',
    Webhook: 'api developer development enterprise software',
    WebStories: 'google images logo',
    Weekend: 'chair couch furniture home living lounge relax room seat',
    West: 'arrow directional left maps navigation',
    WhatsApp: 'brand call chat logo messaging voice',
    Whatshot: 'arrow circle direction fire frames round trending',
    WheelchairPickup: 'accessibility accessible body handicap help human person',
    WhereToVote:
      'approve ballot check complete destination direction done election location maps mark ok pin place poll select stop tick validate verified yes',
    Widgets: 'app blocks box menu setting squares ui',
    Wifi: 'connection data internet network scan service signal wireless',
    Wifi1Bar:
      'cellular connection data internet mobile network phone scan service signal wireless',
    Wifi2Bar:
      'cellular connection data internet mobile network phone scan service signal wireless',
    WifiCalling:
      'cell connection connectivity contact device hardware mobile signal telephone wireless',
    WifiCalling3: 'cellular data internet mobile network phone speed wireless',
    WifiChannel:
      '(scan) [cellular connection data internet mobile] network service signal wireless',
    WifiFind:
      '(scan) [cellular connection data detect discover glass internet look magnifying mobile] network notice search service signal wireless',
    WifiLock:
      'cellular connection data internet locked mobile network password privacy private protection safety secure security service signal wireless',
    WifiOff:
      'connection data disabled enabled internet network offline scan service signal slash wireless',
    WifiPassword:
      '(scan) [cellular connection data internet lock mobile] network secure service signal wireless',
    WifiProtectedSetup: 'around arrows rotate',
    WifiTethering:
      'cellular connection data internet mobile network phone scan service signal speed wireless',
    WifiTetheringError:
      '! alert attention caution cellular connection danger data exclamation important internet mark mobile network notification phone rounded scan service signal speed symbol warning wireless',
    WifiTetheringOff:
      'cellular connection data disabled enabled internet mobile network offline phone scan service signal slash speed wireless',
    Window: 'close glass grid home house interior layout outside',
    WindPower: 'eco energy nest windy',
    WineBar: 'alcohol cocktail cup drink glass liquor',
    Woman: 'female gender girl lady social symbol women',
    Woman2: 'female gender girl lady social symbol women',
    Work: '-briefcase baggage business job suitcase',
    WorkHistory:
      'arrow backwards baggage briefcase business clock date job refresh renew reverse rotate schedule suitcase time turn',
    WorkOff: 'baggage briefcase business disabled enabled job on slash suitcase',
    WorkOutline: 'baggage briefcase business job suitcase',
    WorkspacePremium:
      'certification degree ecommerce guarantee medal permit ribbon verification',
    Workspaces: 'circles collaboration dot filled group outline team',
    WrapText: 'arrow doc editing editor spreadsheet type write writing',
    WrongLocation:
      'cancel close destination direction exit maps no pin place quit remove stop',
    Wysiwyg:
      'composer mode screen software system text view visibility website window',
    Yard: 'backyard flower garden home house nature pettle plants',
    YouTube: 'brand logo social video',
    YoutubeSearchedFor:
      'arrow backwards find glass history inprogress loading look magnifying refresh renew restore reverse rotate see yt',
    ZoomIn:
      'bigger find glass grow look magnifier magnifying plus scale search see size',
    ZoomInMap: 'arrows destination location maps move place stop',
    ZoomOut:
      'find glass look magnifier magnifying minus negative scale search see size smaller',
    ZoomOutMap: 'arrows destination location maps move place stop',
  };
  
  export default synonyms;