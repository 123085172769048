import {useCallback, useEffect, useRef, useState} from "react";
import {debounce} from "lodash";
import {isEmpty} from "./helper";

/**
 * genera una lista di predizioni dell'indirizzo fornito
 * @param {string} input stringa di cui bisogna identificare l'indirizzo
 * @param types vettore di tipi es: ['hospital', 'pharmacy', 'bakery', 'country']
 * @returns {{addressPredictions:[{mainText, secondaryText}], isLoading:boolean}} vettore di predizioni e un flag che indica se c'è un caricamento
 */
export default function useAddressPredictions(input, types = []) {
    const [predictions, setPredictions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    let sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    const autocomplete = useRef();

    const options = {
        // componentRestrictions: {country: "it"},
        fields: ["address_components", "name", "geometry"],
        language: '',
        strictBounds: false,
        sessionToken,
        types: types
    };

    if (!autocomplete.current) {
        autocomplete.current =
            new window.google.maps.places.AutocompleteService();
    }

    function getPlacePredictions(input) {
        if (isEmpty(input)) {
            setPredictions([]);
            setIsLoading(false);
            return;
        }
        autocomplete.current.getPlacePredictions(
            {input, ...options},
            predictions => {
                setPredictions(
                    predictions ?
                        predictions.map(prediction => {
                            return {
                                mainText: prediction.structured_formatting.main_text,
                                secondaryText: prediction.structured_formatting.secondary_text,
                                placeId: prediction.place_id
                            }
                        })
                        :
                        []
                );
                setIsLoading(false);
            }
        );
    }

    const debouncedGetPlacePredictions = useCallback(
        debounce(input => {
            getPlacePredictions(input)
        }, 500),
        []
    );

    useEffect(() => {
        setIsLoading(true);
        debouncedGetPlacePredictions(input);
    }, [input]);

    return {addressPredictions: predictions, isLoading};
}
