/**
 * valore = punti/moltiplicatore
 * @type {number}
 */
export const MULT_VALUE_TO_POINT = 100;

/**
 * filtri della tabella degli articoli
 * @type {{all: number, visible: number, hidden: number}}
 */
export const FILTERS = {all: 0, visible: 1, hidden: 2}

export const AD_TYPE = {distance: 0, country: 1, allUsers: 2}