import React from "react";
import {AttachMoney, EmojiEvents, Help, Message, MessageOutlined, NoteOutlined} from '@mui/icons-material';
import {ARTICLE_LIST_ROUTE, COUPON_LIST_ROUTE, COUPON_REDEEM_ROUTE, SHOPS_LIST_ROUTE} from "./vendors";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {Trans} from "react-i18next";

const couponRedeem = {
    name: <Trans i18nKey="couponRedeem"/>,
    url: COUPON_REDEEM_ROUTE,
    icon: <DashboardIcon/>
};

const shops = {
    name: <Trans i18nKey="shops"/>,
    url: SHOPS_LIST_ROUTE,
    icon: <ShoppingCartIcon/>
};

const articles = {
    name: <Trans i18nKey="awardsCommunity"/>,
    url: ARTICLE_LIST_ROUTE,
    icon: <EmojiEvents/>
};

const couponList = {
    name: <Trans i18nKey="voucher"/>,
    url: COUPON_LIST_ROUTE,
    icon: <NoteOutlined/>
};

const help = {
    name: <Trans i18nKey="helpMenuTab"/>,
    url: "/help",
    icon: <Help/>
};

const messages = {
    name: <Trans i18nKey="messages"/>,
    url: "/messages",
    icon: <Message/>
};


export default {
    couponRedeem,
    shops,
    articles,
    couponList,
    help,
    messages
};

