import {genderCode} from "../constants/genderCode";
import MenuItem from "@mui/material/MenuItem";
import {TextInputContainer} from "./forms/TextInput";
import React from "react";
import {useTranslation} from "react-i18next";

export default function GenderSelect({value, xs = 6, md, onTextChange, required, error, ...props}) {
    const {t} = useTranslation();

    return (
        <TextInputContainer xs={xs} md={md || xs} value={value} options={Object.values(genderCode)}
                            label={t('gender')}
                            onTextChange={onTextChange} required={required} error={error} select {...props} >
            {Object.values(genderCode).map(o => <MenuItem key={o.id || -1} value={o.id}>{t(o.value)}</MenuItem>)}
        </TextInputContainer>
    );
}