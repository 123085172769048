import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import i18next from './i18n'
import {GeoFire} from "geofire";

let config = {
    apiKey: "AIzaSyBRmqDg58qd5Xa3BlK4__eKNqABs5mYYS4",
    authDomain: "pinbike-1521450884928.firebaseapp.com",
    databaseURL: "https://pinbike-1521450884928.firebaseio.com",
    projectId: "pinbike-1521450884928",
    storageBucket: "pinbike-1521450884928.appspot.com",
    messagingSenderId: "1080799050187",
    appId: "1:1080799050187:web:e13b7347cb977648aa5e4e"
};

var testConfig = {
    apiKey: "AIzaSyA0LsHLFJxNm2Rkw8FHC07gYNQmeOtwzIM",
    authDomain: "tester-nxt.firebaseapp.com",
    databaseURL: "https://tester-nxt-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "tester-nxt",
    storageBucket: "tester-nxt.appspot.com",
    messagingSenderId: "8972695822",
    appId: "1:8972695822:web:541525630a8bb3e1203f5f"
};

var collConfig = {
    apiKey: "AIzaSyDF7CxRpxuLPSjsdy0cDhchb1aLtIFlKf8",
    authDomain: "pinbike-coll.firebaseapp.com",
    databaseURL: "https://pinbike-coll-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "pinbike-coll",
    storageBucket: "pinbike-coll.appspot.com",
    messagingSenderId: "345237933284",
    appId: "1:345237933284:web:a7fc97c0817ef6c7e9b0f1",
    measurementId: "G-98L0F7D99M"
  };

firebase.initializeApp(process.env.REACT_APP_ENVIRONMENT === "production" ? config : 
    process.env.REACT_APP_ENVIRONMENT === "coll" ? collConfig : testConfig);
//firebase.initializeApp(config);

export const firebaseAuth = firebase.auth();
export const firebaseDatabase = firebase.database();
export const firebaseStorage = firebase.storage();
export const geoFireDrinkingFountains = new GeoFire(firebaseDatabase.ref('/drinkingFountainsGeoHashed'));
export const geoFirePublicUtilityRecord = new GeoFire(firebaseDatabase.ref('/publicUtilityGeoHashed'));
export const geoFireReport = new GeoFire(firebaseDatabase.ref('/reportGeoHashed'));

export function getErrorMessage(e) {
    switch (e.code) {
        case "auth/email-already-in-use":
            return i18next.t('emailAlreadyInUse');
        case "auth/invalid-email":
            return i18next.t('emailInvalid');
        case "auth/weak-password":
            return i18next.t('weakPassword');
        case "auth/user-not-found":
            return i18next.t('userNotFound');
        case "auth/wrong-password":
            return i18next.t('wrongPassword');
        case "auth/network-request-failed":
            return i18next.t('networkRequestFailed');
        default:
            return i18next.t('internalServerError');
    }
}
