import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import CopyableLink from "../CopyableLink";
import { formatAddress } from "../../services/helper";
import { generatePublicLink } from "../../services/couponRedeemManager";

/**
 * componente passato alla proprietà text del clickable item degli shop
 * @param shop
 * @param organizations
 * @returns {JSX.Element}
 * @constructor
 */
export default function ShopElementText({ shop }) {
    const { t } = useTranslation();
    return (
        <Grid container direction={"column"}>
            <Typography noWrap variant={'h6'} sx={{ textOverflow: 'ellipsis', width: '100%' }}>
                {shop.name}
            </Typography>
            {
                !shop.isEcommerce &&
                <Typography
                    noWrap
                    variant={'caption'}
                    sx={{ marginLeft: '1.1em', textOverflow: 'ellipsis', width: '100%' }}
                >
                    <b>{t('address')}:</b> {formatAddress(shop)}
                </Typography>
            }
            <Typography
                noWrap
                variant={'caption'}
                sx={{ marginLeft: '1.1em', textOverflow: 'ellipsis', width: '100%' }}
            >
                <b>{t('linkedInitiative')}:</b>
                {shop.organizations ? shop.organizations.map(o => o.title).join(', ') : t('none')}
            </Typography>
            {
                generatePublicLink(shop) &&
                <Typography
                    noWrap
                    variant={'caption'}
                    sx={{ marginLeft: '1.1em', textOverflow: 'ellipsis', width: '100%' }}
                >
                    <b>{t('shortLink')}:</b>
                    <CopyableLink url={generatePublicLink(shop)} />
                </Typography>
            }
        </Grid>
    );
}
