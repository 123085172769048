import RenderFields from "./RenderFields";
import React from "react";
import Grid from "@mui/material/Grid";
import NTMSelect from "../../../components/NTMSelect";
import {useGetCities} from "../../../services/ContentManager";
import {CircularProgress} from "@mui/material";
import {useVendorsManagerFields} from "./settingsFields";
import {useTranslation} from "react-i18next";


export default function VendorsManager({values, setValues}){

    const {t} = useTranslation();
    let fields = useVendorsManagerFields(values, setValues)
    let cities = useGetCities();

    const istatToArray = istatString =>  istatString ? istatString.split(",") : []
    const istatToString = istatArray => istatArray.join(",")

    return <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "0.5rem"}}>

        <RenderFields fields={fields} values={values}/>

        {
            values.shopAssociationType === "2" && cities.length < 1 &&
            <CircularProgress color={'primary'}/>
        }

        {
            values.shopAssociationType === "2" && cities.length > 0 &&
            <Grid item xs={12} md={6}>
                <NTMSelect
                    color={'primary'}
                    label={t('city')}
                    items={cities.map((s) => ({value: s.istatId.toString(), text: s.city}))}
                    onChange={value => {
                        let val = istatToArray(values.shopAssociationIstat);
                        val.push(value);
                        let newValues = {
                            ...values,
                            shopAssociationIstat: istatToString(val)
                        };
                        setValues(newValues);
                    }}
                    onDelete={value => {
                        let val = istatToArray(values.shopAssociationIstat);
                        let newValues = {
                            ...values,
                            shopAssociationIstat: istatToString(val.filter(c => c !== value))
                        };
                        setValues(newValues);
                    }
                    }
                    value={istatToArray(values.shopAssociationIstat)}
                />
            </Grid>
        }

    </Grid>;
}
