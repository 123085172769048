import React, { useContext, useState } from "react";
import NTMXGrid, { timestampFormatter } from "../components/NTMXGrid";
import useGetSessionType from "../constants/sessionType";
import {useAwardTypeNames} from "../constants/awardType";
import Grid from "@mui/material/Grid";
import { Alert, Button, Divider, SvgIcon } from "@mui/material";
import CreateAchievementsModal, { StyledSvgIcon } from "../components/modals/CreateAchievementsModal";
import { ACHIEVEMENTS, AWARDS, deleteElem, getErrorMessage, ORGANIZATIONS, post, put } from "../services/Client";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import StartIconButton from "../components/buttons/StartIconButton";
import CreateIcon from "@mui/icons-material/Create";
import dayjs from "dayjs";
import RenderCell from "../components/cellRender/RenderCell";
import RenderCellAvatar from "../components/cellRender/RenderCellAvatar";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AchievementInfo from "./Achievements/AchievementInfo";
import AchievementAward from "./Achievements/AchievementAward";
import useGetUsefulValues from "../constants/rankingUsefulValues";
import useGetRankingFilter from "../constants/rankingFilter";
import { useTranslation } from "react-i18next";
import RenderCellAgeInterval from "../components/cellRender/RenderCellAgeInterval";
import ConfirmIconButton from "../components/buttons/ConfirmIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { resources } from "../services/ability";
import { AbilityContext } from "../services/Can";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import { SettingsTooltip } from "../components/forms/CustomTooltip";
import { genderCode, getGenderCodeValue } from "../constants/genderCode";
import * as mui from '@mui/icons-material';
import EditIconModal from "../components/modals/EditIconModal";
import { saveIconFirebase } from "../services/FirebaseManager";
import { useAchievementType, useAchievementTypeNames } from "constants/achievementType";
import {checkRankingFilter} from "../services/checkRankingFilter";

const storageKey = "achievements-table-columns"

export default function AchievementsManager({ achievements, loading, national = false, organizationId, customField }) {

    const { t } = useTranslation();
    const ability = useContext(AbilityContext);
    achievements = achievements.slice().sort((a, b) => a.startDate - b.startDate);
    const unexpiredAchievements = achievements !== [] && achievements.filter((e) => dayjs(e.endDate).endOf('day') >= Date.now());
    const expiredAchievements = achievements !== [] && achievements.filter((e) => dayjs(e.endDate).endOf('day') < Date.now());
    const usefulValues = useGetUsefulValues(national);
    const achievementsFilter = useGetRankingFilter(customField);
    const sessionType = useGetSessionType()
    const awardTypeLabels = useAwardTypeNames()
    const achievementType = useAchievementType()
    const achievementTypeLabels = useAchievementTypeNames()
    const { enqueueSnackbar } = useSnackbar();
    let queryClient = useQueryClient();
    let [isCreating, setIsCreating] = useState(false);
    let [isCloningAchievement, setIsCloningAchievement] = useState(false);
    let [showInfo, setShowInfo] = useState(null);
    let [showAwards, setShowAwards] = useState(null);
    const [isEditingIcon, setIsEditingIcon] = useState(null);
    const today = dayjs(new Date()).format("YYYY-MM-DD");
    const warningAchievementAlreadyStarted = t('warningAchievementAlreadyStarted')
    const infoLogoDimension = t('infoLogoDimension')
    const key = national ? ["National", ACHIEVEMENTS] : [ORGANIZATIONS, { id: organizationId }, ACHIEVEMENTS];

    const getFilterValue = (params) => {
        if (params.row["filter"] !== null && params.row["filter"] !== undefined) {
            if (params.row["filter"] === 0) {
                return sessionType.find(st => st.id == params.value)?.name       //usa == e non ==== perchè uno dei due puo' essere una stringa e l'altro un numero
            }
            if (params.row["filter"] === 1) {
                return params.value
            }
            if (params.row["filter"] === 2) {
                return t(getGenderCodeValue(params.value));
            }
        }
        return "";
    }

    const createAchievement = (values, award) => {
        if (!national) {
            values.organization = parseInt(organizationId);
        }
        enqueueSnackbar(t('saving...'), { variant: "info" });
        post(ACHIEVEMENTS, { body: values })
            .then((res) =>
                award && post(AWARDS + "/" + ACHIEVEMENTS, { body: { ...award, achievement: res.data.id } })//salva l'award solo se esiste con l'achievement collegato
            )
            .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(key));
    }

    const columns = [
        {
            headerName: t('logo'),
            field: 'logo',
            width: 130,
            renderCell: (params) => <RenderCellAvatar params={params}
                saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                folder={"organizations/achievements"} prefix={organizationId}
                label={t('dragImage')}
                infoMessage={infoLogoDimension}
                warningMessage={params.row.startDate < Date.now() && warningAchievementAlreadyStarted} />
        },
        {
            headerName: t('name'),
            field: 'name',
            width: 200,
            renderCell: (params) => <RenderCell params={params} saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                warningMessage={params.row.startDate < Date.now() && warningAchievementAlreadyStarted} />
        },
        {
            headerName: t('description'),
            field: 'description',
            width: 200,
            renderCell: (params) => <RenderCell params={params} saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                warningMessage={params.row.startDate < Date.now() && warningAchievementAlreadyStarted} />
        },
        {
            headerName: t('startDate'),
            field: 'startDate',
            width: 200,
            renderCell: (params) => <RenderCell params={params} saveEdit={params.row.startDate > Date.now() && saveEdit}
                required type="date" />,
        },
        {
            headerName: t('endDate'),
            field: 'endDate',
            width: 200,
            renderCell: (params) => <RenderCell params={params} saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                required type="date" min={today}
                warningMessage={params.row.startDate < Date.now() && warningAchievementAlreadyStarted} />,
        },
        {
            headerName: t('daysDuration'),
            field: 'duration',
            width: 200,
            renderCell: ((params) => <RenderCell params={params} saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                type="number"
                decimals={0}
                warningMessage={params.row.startDate < Date.now() && warningAchievementAlreadyStarted} />),
        },
        {
            headerName: t('value'),
            field: 'value',
            width: 200,
            valueGetter: (params) => usefulValues.find(elem => elem.id === params.value) ? usefulValues.find(elem => elem.id === params.value).name : "",
            renderCell: (params) => <RenderCell params={params} saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                type="select" options={usefulValues}
                warningMessage={params.row.startDate < Date.now() && warningAchievementAlreadyStarted} />
        },
        {
            headerName: t('target'),
            field: 'target',
            width: 200,
            renderCell: (params) => <RenderCell params={params} saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                type="number"
                warningMessage={params.row.startDate < Date.now() && warningAchievementAlreadyStarted} />
        },
        {
            headerName: t('filter'),
            field: 'filter',
            width: 200,
            valueGetter: (params) => (params.value !== null && params.value !== undefined)
                ? achievementsFilter.find(elem => checkRankingFilter(elem.id, params.value, params.row.filterValue)).name
                : "",
            renderCell: (params) => <RenderCell params={params} saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                type="select" options={[{ key: '', value: '' }, ...achievementsFilter]}
                warningMessage={params.row.startDate < Date.now() && warningAchievementAlreadyStarted}
                required />
        },
        {
            headerName: t('valueFilter'),
            field: 'filterValue',
            width: 200,
            valueGetter: (params) => getFilterValue(params),
            renderCell: (params) => {
                if (params.row["filter"] === 0) return <RenderCell params={params}
                    saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                    type="select"
                    options={sessionType}
                    warningMessage={params.row.startDate < Date.now() && warningAchievementAlreadyStarted}
                    required />

                if (params.row["filter"] === 1) return <RenderCellAgeInterval params={params}
                    saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                    warningMessage={params.row.startDate < Date.now() && warningAchievementAlreadyStarted} />

                if (params.row["filter"] === 2) return <RenderCell params={params}
                    saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                    type="select"
                    options={Object.values(genderCode).map(v => ({
                        id: v.id,
                        name: t(v.value)
                    }))}
                    warningMessage={params.row.startDate < Date.now() && warningAchievementAlreadyStarted}
                    required />
            }
        },
        {
            headerName: t('type'),
            field: 'type',
            width: 200,
            valueGetter: (params) => params.value || params.value === 0 ? achievementTypeLabels[params.value] : "",
            renderCell: (params) => <RenderCell params={params} saveEdit={dayjs(params.row.endDate).endOf('day') > Date.now() && saveEdit}
                type="select" options={achievementType}
                warningMessage={params.row.startDate < Date.now() && warningAchievementAlreadyStarted} />
        },
        {
            headerName: t('award'),
            field: 'awardValue',
            width: 200
        },
        {
            headerName: t('awardType'),
            field: 'awardType',
            width: 200,
            valueGetter: (params) => params.value || params.value === 0 ? awardTypeLabels[params.value] : ""
        },
        {
            headerName: t('usernameIcon'),
            field: 'icon',
            width: 200,
            disableExport: true,
            renderCell: ({ row, value }) => {
                return <Grid container
                    sx={{
                        '&>svg.MuiSvgIcon-root': (theme) => ({
                            color: row.color,
                        })
                    }}
                >
                    {value && <SvgIcon
                        component={mui[value]}
                        fontSize="large"
                        tabIndex={-1}
                    />}
                    <Button startIcon={<mui.Edit />} variant='text' color='primary' onClick={() => setIsEditingIcon(row)}>
                    </Button>
                </Grid>
            }
        },
        {
            headerName: ' ',
            field: "info",
            width: 210,
            renderCell: (params) => <>
                <IconButton onClick={() => setShowInfo(params.row)} size="large"> <VisibilityIcon /> </IconButton>
                <IconButton onClick={() => setShowAwards(params.row)} size="large"> <EmojiEventsIcon /> </IconButton>
                <SettingsTooltip title={t('clone')}><IconButton
                    onClick={() => setIsCloningAchievement(params.row)}><FilterNoneIcon /></IconButton></SettingsTooltip>
                {/* params.row.startDate > Date.now() */ability.can('read', resources.DELETE_ACHIEVEMENTS) &&
                    <ConfirmIconButton
                        onConfirm={() => deleteAchievement(params.row.id)}
                        title={t('confirmDeletion')}
                        text={
                            <Grid container direction={'column'} >
                                <Grid item xs={12} sx={{ p:1, paddingTop:0}}>
                                    {t('requestDefinitiveEliminationAchievement')}
                                </Grid>
                                <Grid item xs={12} sx={{ p:1}}>
                                    <Alert variant="outlined" severity="info">
                                        {t('achievementDeleteInfo')}
                                    </Alert>
                                </Grid>
                            </Grid>
                        }
                    >
                        <DeleteIcon />
                    </ConfirmIconButton>
                }
            </>
        }
    ]

    const saveEdit = (id, field, value) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        if (field === "startDate" || field === "endDate") value = new Date(value).getTime()
        put(ACHIEVEMENTS, { body: { [field]: value }, elem: id })
            .then(() => {
                enqueueSnackbar(t('saved'), { variant: "success" });
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(key));
    };

    const saveEditMultipleValues = (id, body) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        put(ACHIEVEMENTS, { body: body, elem: id })
            .then(() => {
                enqueueSnackbar(t('saved'), { variant: "success" });
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(key));
    }

    const deleteAchievement = (id) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        deleteElem(ACHIEVEMENTS, { elem: id, params: { force: false } })
            .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(key));
    }

    if (showInfo) return <AchievementInfo goBack={() => setShowInfo(null)} achievementId={showInfo.id} customField={customField} />
    if (showAwards) return <AchievementAward goBack={() => setShowAwards(null)} achievement={showAwards} />

    return <Grid>
        <NTMXGrid
            key={storageKey + "-active"}
            columns={columns}
            rows={unexpiredAchievements || []}
            title={t('unexpiredAchievements')}
            loading={loading}
            rightButton={<Grid container justifyContent={"flex-end"}>
                <StartIconButton onClick={() => setIsCreating(true)} title={t('createAchievements')}
                    startIcon={<CreateIcon />} />
            </Grid>}
        />
        <Divider />
        <NTMXGrid
            columns={columns}
            key={storageKey + "-expired"}
            rows={expiredAchievements || []}
            title={t('expiredAchievements')}
            loading={loading}
        />
        {
            (!!isCreating || !!isCloningAchievement) &&
            <CreateAchievementsModal open={!!isCreating || !!isCloningAchievement}
                onClose={() => {
                    setIsCreating(false)
                    setIsCloningAchievement(false)
                }}
                onSubmit={createAchievement}
                usefulValues={usefulValues} achievementsFilter={achievementsFilter}
                organizationId={organizationId}
                infoLogoDimension={infoLogoDimension} defaultValues={isCloningAchievement}
                cloneAward={!!isCloningAchievement}
            />
        }
        {
            isEditingIcon &&
            <EditIconModal
                icon={isEditingIcon?.icon} color={isEditingIcon?.color}
                open={!!isEditingIcon} onClose={() => setIsEditingIcon(null)} onSubmit={(icon, color) => {
                    if (!saveIconFirebase(mui, icon)) {
                        enqueueSnackbar(t('errorOccurred'), { variant: "error" })
                        return
                    }
                    saveEditMultipleValues(isEditingIcon?.id, { icon, color });
                }} ></EditIconModal>
        }
    </Grid>
}
