import RenderFields from "./RenderFields";
import React from "react";
import Grid from "@mui/material/Grid";
import NTMSelect from "../../../components/NTMSelect";
import { useGetCities } from "../../../services/ContentManager";
import { CircularProgress } from "@mui/material";
import { useAwardLimitFields, useVendorsManagerFields } from "./settingsFields";
import { useTranslation } from "react-i18next";
import { useState } from "react";


export default function AwardLimitSettings({ values, setValues, onError }) {

    let fields = useAwardLimitFields(values, setValues);
    let [error, setError] = useState({});
    const {t} = useTranslation();

    const onTextChange = (prop) => (value) => {
        let newValues = { ...values };
        newValues[prop] = value;
        setValues(newValues);
        if(value + '' === ''){
            onError(true);
            setError({...error, [prop] : t('mandatoryField')})
        }else{
            onError(false);

            setError({...error, [prop] : null})
        }
    }


    return <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "0.5rem" }}>

        <RenderFields fields={fields} values={values} error={error} onTextChange={onTextChange} />

    </Grid>;
}
