// Import necessari
import React from "react";
import { Grid } from "@mui/material";
import TextInput from "../../../components/forms/TextInput";
import PhonePrefixesSelect from "../../../components/VendorComponents/PhonePrefixesSelect";
import clsx from "clsx";
import AlghoBot from "../../../components/VendorComponents/AlghoBot";

export default function NewVendorStep1({
    user,
    data,
    handleOnChange,
    handleStep,
    errors,
    t,
    globalClasses,
}) {
    return (
        <Grid container direction="row" alignItems="flex-start" className={globalClasses.marginBottom}>
            <Grid item xs={12} className={globalClasses.marginBottom} />

            {/*--- nome  --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('firstName')}
                    type="text"
                    color={'primary'}
                    value={user ? user.firstName : (data.firstName || '')}
                    onTextChange={(text) => handleOnChange(text, 'firstName')}
                    error={errors.firstName || ''}
                    required
                    disabled={!!user}
                />
            </Grid>

            {/*--- cognome  --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('lastName')}
                    type="text"
                    color={'primary'}
                    value={user ? user.lastName : (data.lastName || '')}
                    onTextChange={(text) => handleOnChange(text, 'lastName')}
                    error={errors.lastName || ''}
                    required
                    disabled={!!user}
                />
            </Grid>

            {/*--- email  --*/}
            <Grid item xs={12} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('email')}
                    type="text"
                    color={'primary'}
                    value={user ? user.email : (data.email || '')}
                    onTextChange={(text) => handleOnChange(text, 'email')}
                    error={errors.email || ''}
                    required
                    disabled={!!user}
                />
            </Grid>

            {
                !user &&
                <>
                    {/*--- password  --*/}
                    <Grid item xs={12} md={6}
                        className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                        <TextInput
                            label={t('password')}
                            type="password"
                            color={'primary'}
                            value={data.newPassword || ''}
                            onTextChange={(text) => handleOnChange(text, 'newPassword')}
                            error={errors.newPassword || ''}
                            required
                        />
                    </Grid>
                    {/*--- ripeti password  --*/}
                    <Grid item xs={12} md={6}
                        className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                        <TextInput
                            label={t('repeatPassword')}
                            type="password"
                            color={'primary'}
                            value={data.repeatPassword || ''}
                            onTextChange={(text) => handleOnChange(text, 'repeatPassword')}
                            error={errors.repeatPassword || ''}
                            required
                        />
                    </Grid>
                </>
            }
            {/*--- telefono --*/}
            <Grid item xs={12} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <Grid container direction="row" alignItems="flex-start">
                    <Grid item xs={4}>
                        <PhonePrefixesSelect
                            label={t('areaCode')}
                            prefix={data.phonePrefix}
                            onChange={(text) => handleOnChange(text, 'phonePrefix')}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            label={t('phoneNumber')}
                            type="text"
                            color={'primary'}
                            value={data.phone || ''}
                            onTextChange={(text) => handleOnChange(text, 'phone')}
                            error={errors.phone || ''}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/*--- codice invito  --*/}
            <Grid item xs={12} md={6} className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                <TextInput
                    label={t('invitationCode')}
                    type="text"
                    color={'primary'}
                    value={user ? user.invitationCode : (data.invitationCode || '')}
                    onTextChange={(text) => handleOnChange(text, 'invitationCode')}
                    error={errors.invitationCode || ''}
                    disabled={!!user}
                />
            </Grid>
            <AlghoBot/>
        </Grid>
    );
}
