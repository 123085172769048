import BaseModal from "./BaseModal";
import React,{useState} from "react";
import Grid from "@mui/material/Grid";
import TextInput,{TextInputContainer} from "../forms/TextInput";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";

export default function GenerateCodesModal({open, onClose, onSubmit}){

    const {t} = useTranslation();
    const today = dayjs(new Date()).format("YYYY-MM-DD");
    let [values, setValues] = useState({n: 1, start: today, end: today});
    let [endError, setEndError] = useState(false);

    const save = () => {
        if(values.start > values.end){
            setEndError(true)
            return;
        }
        onSubmit(values)
        onClose()
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        newValues[name] = value.trim();
        setValues(newValues);
    }

    return <BaseModal open={open} onClose={onClose} onSave={save}>
        <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1rem"}}>

                <TextInputContainer xs={12} required label={t('numberCodesToGenerate')} value={values.n}
                           type="number"
                           min={0} step={1}
                           onTextChange={(value) => {onTextChange(value, "n")}}/>

                <TextInputContainer xs={12} required label={t('startDate')} value={values.start}
                           type="date" min={today}
                           onTextChange={(value) => {onTextChange(value, "start")}}/>

                <TextInputContainer xs={12} required label={t('endDate')} value={values.end}
                           type="date"  min={today}
                           error={endError && t('endDateLessThanStartDate')}
                           onTextChange={(value) => {onTextChange(value, "end")}}/>

        </Grid>
    </BaseModal>
}