import BaseModal from "./BaseModal";
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextInput, { TextInputContainer } from "../forms/TextInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Switch} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import useGetSessionType from "../../constants/sessionType";
import { useTranslation } from "react-i18next";
import { SettingsTooltip } from "../forms/CustomTooltip";
import GenderSelect from "../GenderSelect";
import { isEmpty } from "../../services/helper";
import {isStringAndContainsSubstring} from "../../services/checkRankingFilter";

export const repeatType = {
    NONE: "0",
    MONTH: "1",
    CUSTOM: "2"
}

const useStyles = makeStyles((theme) => ({
    radioLabel: {
        color: theme.palette.primary.light,
        display: 'flex',
        alignContent: "space-between",
    },
    previewTitle: {
        color: theme.palette.primary.light,
        height: "2.5rem",
        "white-space": "pre-line",
    },
    preview: {
        color: theme.palette.primary.light,
        "white-space": "pre-line",
        maxHeight: "15vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "10px"
        },
    },
    root: {
        '& label': {
            color: theme.palette.primary.light,
            fontStyle: "italic",
            '&.MuiInputLabel-shrink': {
                fontStyle: "normal"
            },
            '&.Mui-focused': {
                color: theme.palette.secondary.main,
            },
            '&:hover': {
                color: theme.palette.secondary.main,
            },
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            '& fieldset': {
                borderColor: theme.palette.primary.light,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
                borderWidth: "3px"
            },
        },
        "& .Mui-error": {
            color: theme.palette.error.light,
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.error.light,
            }
        },
    },
    input: {
        color: theme.palette.primary.light,
        '&:focused': {
            borderColor: theme.palette.secondary.main,
        },
        '&:hover': {
            color: theme.palette.secondary.main,
        },
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: theme.palette.secondary.main,
            opacity: 1 /* Firefox */
        },
        '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: theme.palette.secondary.main,
        },
        '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: theme.palette.secondary.main,
        }
    }
}));

export default function CreateRankModal({ open, onClose, onSubmit, usefulValues, rankingFilter }) {

    const { t } = useTranslation();
    let [values, setValues] = useState({ repeatType: repeatType.NONE });
    let [error, setError] = useState({});
    let classes = useStyles();
    const sessionType = useGetSessionType();

    const getPreview = () => {
        const error = findErrorInPreview();
        if (error) {
            return t('insert') + " " + error.label + ", " + error.error;
        } else {
            let preview = ""
            if (values.repeatType === repeatType.NONE) preview += "1. " + dayjs(Date.parse(values["startDate"])).format('DD/MM/YYYY') + " - " + dayjs(Date.parse(values["endDate"])).format('DD/MM/YYYY');
            if (values.repeatType === repeatType.MONTH) {
                let year = values.startMonth.getFullYear();
                let month = values.startMonth.getMonth();
                for (let i = 0; i <= values.repeatNum; i++) {
                    preview += i + 1 + ". " + dayjs(new Date(year, month, 1)).format('DD/MM/YYYY') + " - ";
                    month++;
                    preview += dayjs(new Date(year, month, 0)).format('DD/MM/YYYY') + "\n";
                }
            }
            if (values.repeatType === repeatType.CUSTOM) {
                let startDate = new Date(values["startDate"])
                for (let i = 0; i <= values.repeatNum; i++) {
                    preview += i + 1 + ". " + dayjs(startDate).format('DD/MM/YYYY') + " - ";
                    startDate.setDate(startDate.getDate() + parseInt(values.dayLife) - 1);
                    preview += dayjs(startDate).format('DD/MM/YYYY') + "\n"
                    startDate.setDate(startDate.getDate() + 1);
                }
            }
            return preview;
        }
    }

    const findErrorInPreview = () => {
        let convertedValues = { ...values };
        convertedValues["startDate"] = Date.parse(values["startDate"])
        convertedValues["endDate"] = Date.parse(values["endDate"])

        if (convertedValues.repeatType === repeatType.NONE) {
            if (!convertedValues.startDate) return {
                error: t('mandatoryField'),
                field: "startDate",
                label: t('startDate')
            }
            if (!convertedValues.endDate) return { error: t('mandatoryField'), field: "endDate", label: t('endDate') }
            if (convertedValues.startDate > convertedValues.endDate) return {
                error: t('endDateLessThanStartDate'),
                field: "endDate",
                label: t('endDate')
            }
        }

        if (convertedValues.repeatType === repeatType.MONTH) {
            if (!convertedValues.startMonth) return {
                error: t('mandatoryField'),
                field: "startMonth",
                label: t('startMonth')
            }
            if (!convertedValues.repeatNum) return {
                error: t('mandatoryField'),
                field: "repeatNum",
                label: t('repeatNum')
            }
            if (convertedValues.repeatNum < 1) return {
                error: t('invalidValue'),
                field: "repeatNum",
                label: t('repeatNum')
            }
        }

        if (convertedValues.repeatType === repeatType.CUSTOM) {
            if (!convertedValues.startDate) return {
                error: t('mandatoryField'),
                field: "startDate",
                label: t('startDate')
            }
            if (!convertedValues.dayLife) return {
                error: t('mandatoryField'),
                field: "dayLife",
                label: t('durationInDays')
            }
            if (!convertedValues.repeatNum) return {
                error: t('mandatoryField'),
                field: "repeatNum",
                label: t('repeatNum')
            }
            if (convertedValues.dayLife < 1) return { error: t('invalidValue'), field: "dayLife", label: t('repeatNum') }
            if (convertedValues.repeatNum < 1) return {
                error: t('invalidValue'),
                field: "repeatNum",
                label: t('repeatNum')
            }
        }

        return false;
    }

    const save = () => {

        let convertedValues = { ...values };
        convertedValues["startDate"] = Date.parse(values["startDate"])
        convertedValues["endDate"] = Date.parse(values["endDate"])

        if (!convertedValues.title) {
            setError({ title: t('mandatoryField') });
            return;
        }
        const error = findErrorInPreview();
        if (error) {
            setError({ [error.field]: error.error })
            return;
        }
        if (convertedValues.value === null || convertedValues.value === undefined) {
            setError({ value: t('mandatoryField') });
            return;
        }
        if (convertedValues.filter !== null && (convertedValues.filter === 0 || convertedValues.filter === 2) && (convertedValues.filterValue === null || convertedValues.filterValue === undefined)) {
            setError({ filterValue: t('mandatoryField') });
            return;
        }
        if (convertedValues.filter !== null && convertedValues.filter === 1) {
            if (!convertedValues.minAge) {
                setError({ minAge: t('mandatoryField') });
                return;
            }
            if (convertedValues.minAge < 1) {
                setError({ minAge: t('invalidValue') });
                return;
            }
            if (!convertedValues.maxAge) {
                setError({ maxAge: t('mandatoryField') });
                return;
            }
            if (convertedValues.maxAge < convertedValues.minAge) {
                setError({ maxAge: t('maxAgeLessThanMinAge') });
                return;
            }
            convertedValues.filterValue = convertedValues.minAge + " - " + convertedValues.maxAge
            delete convertedValues.minAge
            delete convertedValues.maxAge
        }
        if (convertedValues.filter === '') delete convertedValues.filter
        if (convertedValues.repeatType === repeatType.MONTH) {
            let startMontJs = dayjs(convertedValues.startMonth).utc(false);//.year(year).month(month)
            convertedValues.startDate = startMontJs.startOf("month").valueOf()//  Date.parse(new Date(year, month, 1))
            convertedValues.endDate = startMontJs.endOf("month").valueOf() // Date.parse(new Date(year, month +1, 0))
            delete convertedValues.startMonth
        }
        if (convertedValues.repeatType === repeatType.CUSTOM) {
            let endDate = new Date(values["startDate"])
            convertedValues.endDate = dayjs(endDate).add(parseInt(convertedValues.dayLife) - 1, 'day').valueOf()
            delete convertedValues.dayLife;
        }
        if (convertedValues.repeatType !== repeatType.NONE && convertedValues.isAwardLimit) {
            if(isEmpty(convertedValues.awardsLimitForGroup))
            {
                setError({ awardsLimitForGroup: t('mandatoryField') });
                return;
            }

        } else {
            delete convertedValues.awardsLimitForGroup;
        }
        onSubmit(convertedValues);
        close()
    }

    const close = () => {
        setValues({ repeatType: repeatType.NONE })
        setError({});
        onClose()
    }

    const onTextChange = (value, name) => {
        let newValues = { ...values };
        if (name === "filter") delete newValues.filterValue
        if (name === "repeatType") {
            if ((values[name] === repeatType.NONE && value === repeatType.MONTH) || (values[name] === repeatType.MONTH && value === repeatType.NONE)) {
                delete newValues.startDate
                delete newValues.repeatNum
            }
            if ((values[name] === repeatType.NONE && value === repeatType.CUSTOM) || (values[name] === repeatType.CUSTOM && value === repeatType.NONE)) {
                delete newValues.repeatNum
            }
            if ((values[name] === repeatType.CUSTOM && value === repeatType.MONTH) || (values[name] === repeatType.MONTH && value === repeatType.CUSTOM)) {
                delete newValues.startDate
            }
            delete newValues.endDate
            delete newValues.startMonth
            delete newValues.dayLife
            if (value === repeatType.MONTH) newValues.startMonth = new Date();
        }
        if (name === "filter" && isStringAndContainsSubstring(value, "cf-")) {
            newValues.filter = 3; //valore corrispondente a ENUM JOLLY che racchiude tutti i cluster
            newValues.filterValue = value.split("-")[1]; //inserisco l'id del custom_field come filterValue
        } else {
            newValues[name] = value;
        }
        setError({});
        setValues(newValues);
    }

    return (
        <BaseModal open={open} onClose={close} onSave={save}>
            <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "1rem" }}
                justifyContent={"center"}>

                <TextInputContainer xs={12} label={t('title')} value={values.title}
                    type="string" required
                    onTextChange={(value) => {
                        onTextChange(value, "title")
                    }}
                    error={error.title} />

                <Grid item xs={12} style={{ padding: "16px" }}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" className={classes.radioLabel}
                            sx={{ color: "primary.light", '&.Mui-focused': { color: "primary.light" } }}>
                            {t('repetition')}
                        </FormLabel>
                        <RadioGroup row value={values.repeatType}
                            onChange={(event) => onTextChange(event.target.value, "repeatType")}
                            className={classes.radioLabel}>
                            <FormControlLabel value={repeatType.NONE} control={<Radio
                                sx={{ color: "primary.light", '&.Mui-checked': { color: "secondary.main" } }} />}
                                label={t('none')} />
                            <FormControlLabel value={repeatType.MONTH} control={<Radio
                                sx={{ color: "primary.light", '&.Mui-checked': { color: "secondary.main" } }} />}
                                label={t('monthly')} />
                            <FormControlLabel value={repeatType.CUSTOM} control={<Radio
                                sx={{ color: "primary.light", '&.Mui-checked': { color: "secondary.main" } }} />}
                                label={t('custom')} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {
                    (values.repeatType === repeatType.NONE || values.repeatType === repeatType.CUSTOM) &&
                    <TextInputContainer xs={12} required label={t('startDate')} value={values.startDate}
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onTextChange={(value) => {
                            onTextChange(value, "startDate")
                        }}
                        error={error.startDate} />
                }
                {
                    values.repeatType === repeatType.NONE &&
                    <TextInputContainer xs={12} required label={t('endDate')} value={values.endDate}
                        type="date" min={values.startDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onTextChange={(value) => {
                            onTextChange(value, "endDate")
                        }}
                        error={error.endDate} />
                }
                {values.repeatType === repeatType.MONTH &&
                    <DatePicker
                        fullWidth
                        inputVariant="outlined"
                        classes={{ root: classes.root }}
                        inputProps={{ className: classes.input }}
                        views={["year", "month"]}
                        label={t('startMonth')}
                        value={values.startMonth}
                        onChange={(value) => onTextChange(value, "startMonth")}
                        renderInput={(params) => <TextInputContainer xs={12} {...params} helperText={null} />} />
                }
                {
                    values.repeatType === repeatType.CUSTOM &&
                    <TextInputContainer xs={12} required label={t('durationInDays')} value={values.dayLife}
                        type="number"
                        min={1} step={1}
                        onTextChange={(value) => {
                            onTextChange(value, "dayLife")
                        }}
                        error={error.dayLife} />
                }
                {
                    (values.repeatType === repeatType.MONTH || values.repeatType === repeatType.CUSTOM) &&
                    <TextInputContainer xs={12} required label={t('repeatNum')} value={values.repeatNum}
                        type="number"
                        min={1} step={1}
                        onTextChange={(value) => {
                            onTextChange(value, "repeatNum")
                        }}
                        error={error.repeatNum} />
                }
                {

                    (values.repeatType === repeatType.MONTH || values.repeatType === repeatType.CUSTOM) &&
                    <Grid item xs={12} style={{ padding: "16px" }}>
                        <FormGroup>
                            <FormControlLabel control={<Switch color="secondary" />} label={t('isAwardLimit')} onChange={(e) => onTextChange(!values.isAwardLimit, "isAwardLimit")} />
                        </FormGroup>
                        {values.isAwardLimit &&
                            <TextInputContainer xs={12} required label={t('awardLimit')} value={values.awardsLimitForGroup}
                                type="number"
                                min={0} step={1}
                                onTextChange={(value) => {
                                    onTextChange(value, "awardsLimitForGroup")
                                }}
                                error={error.awardsLimitForGroup} />
                        }
                    </Grid>
                }

                <TextInputContainer xs={12} required label={t('value')} value={values.value}
                    options={usefulValues} select
                    onTextChange={(value) => {
                        onTextChange(value, "value")
                    }}
                    error={error.value}>
                    {usefulValues.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
                </TextInputContainer>

                <TextInputContainer xs={12} label={t('filterCategory')} value={values.filter}
                    options={rankingFilter} select
                    onTextChange={(value) => {
                        onTextChange(value, "filter")
                    }}
                    error={error.filter}>
                    <MenuItem key='' value=''>
                        <br />
                    </MenuItem>
                    {rankingFilter.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
                </TextInputContainer>
                {
                    values.filter !== null && values.filter === 0 &&
                    <TextInputContainer xs={12} required label={t('valueFilter')} value={values.filterValue}
                        options={sessionType} select
                        onTextChange={(value) => {
                            onTextChange(value, "filterValue")
                        }}
                        error={error.filterValue}>
                        {sessionType.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
                    </TextInputContainer>
                }
                {
                    values.filter !== null && values.filter === 1 &&
                    <>
                        <TextInputContainer xs={6} required label={t('minAge')} value={values.minAge}
                            type="number"
                            min={1} step={1}
                            onTextChange={(value) => {
                                onTextChange(value, "minAge")
                            }}
                            error={error.minAge} />

                        <TextInputContainer xs={6} required label={t('maxAge')} value={values.maxAge}
                            type="number"
                            min={1} step={1}
                            onTextChange={(value) => {
                                onTextChange(value, "maxAge")
                            }}
                            error={error.maxAge} />

                    </>
                }
                {
                    values.filter !== null && values.filter === 2 &&
                    <GenderSelect
                        xs={12} required value={values.filterValue}
                        error={error.filterValue}
                        onTextChange={(value) => {
                            onTextChange(value, "filterValue")
                        }}
                    />
                }
                <Grid item xs={8} className={classes.previewTitle}>
                    {t('ranksPreview')}:
                </Grid>
                <Grid item xs={8} className={classes.preview}>
                    {getPreview()}
                </Grid>
            </Grid>
        </BaseModal>
    );
}
