import {CssBaseline} from "@mui/material";
import CouponRedeem from "./Vendor/CouponRedeem/CouponRedeem";
import Box from "@mui/material/Box";
import React from "react";

export function PaddingContainer({children}) {
    return <Box sx={{
        padding: {
            md: 4,
            sm: 3,
            xs: 2
        }
    }}>
        <CssBaseline/>
        <CouponRedeem auto/>
    </Box>
}