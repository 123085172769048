import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useCouponsExpirationDateSettingsFields, useUrbanPointsSettingsFields } from "./settingsFields";
import RenderFields from "./RenderFields";
import { useTranslation } from "react-i18next";
import { TextInputContainer } from "../../../components/forms/TextInput";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import StartIconButton from "../../../components/buttons/StartIconButton";
import AddIcon from "@mui/icons-material/Add";

export default function CouponsExpirationDateSettings({ values, setValues, onError }) {

    const { t } = useTranslation();
    let [error, setError] = useState({});
    let fields = useCouponsExpirationDateSettingsFields(values, setValues, error, setError, onError)

    const onTextChange = (prop) => (value) => {
        let newValues = { ...values };
        newValues[prop] = value;
        setValues(newValues);
    }

    return <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "0.5rem" }}>

        <RenderFields fields={fields} values={values} error={error} onTextChange={onTextChange} />

    </Grid>
}