import React, {useContext, useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Sidebar from "../components/layout/Sidebar";
import {setMenu, setMenuVendor, useMenuVendor} from "../services/MenuManager";
import {CssBaseline} from "@mui/material";
import Header from "../components/layout/Header";
import {Redirect, Route, Switch} from "react-router-dom";
import Page404 from "../components/pages/Page404";
import {resources, roles} from "../services/ability";
import {AbilityContext} from "../services/Can";
import {UserContext} from "./App";
import VendorRoutes from "./Vendor/VendorRoutes";
import VendorProfile from "./Vendor/VendorProfile";
import ConfirmEmail from "../components/pages/ConfirmEmail";
import {VENDOR_ROUTE} from "../constants/vendors";
import FormNewVendor from "./Vendor/NewVendor/FormNewVendor";
import {Help} from "@mui/icons-material";
import HelpPage from "./Vendor/HelpPage";
import CouponRedeem from "./Vendor/CouponRedeem/CouponRedeem";
import { useGetVendorUnreadMessages } from '../services/ContentManager';
import VendorMessages from './Vendor/VendorMessages';
import AlghoBot from "../components/VendorComponents/AlghoBot";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        maxWidth:'100vw'
    },
}));

export default function MainVendor() {

    const ability = useContext(AbilityContext);
    const user = useContext(UserContext);
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    let menu = useMenuVendor(ability, user.uid);

    return (
        <>
            <div className={classes.root}>
                <CssBaseline/>
                <Header menuOpen={open} toggleMenu={setOpen}/>
                <Sidebar open={open} setOpen={setOpen} items={menu}/>

                <main className={classes.content}>
                    <div className={classes.toolbar}/>
                    {
                        user.userType === roles.PINBIKER ?
                            <FormNewVendor user={user}/>
                            :
                            <Switch>
                                <Route path={VENDOR_ROUTE}>
                                    {ability.can('read', resources.VENDOR) || ability.can('read', resources.NEW_VENDOR) ?
                                        <VendorRoutes/> :
                                        <Redirect exact to="/unutorized"/>
                                    }
                                </Route>
                                <Route path={"/profile/:uid"}>
                                    {ability.can('read', resources.VENDOR_PROFILE) ?
                                        <VendorProfile/> :
                                        <Redirect exact to="/unutorized"/>
                                    }
                                </Route>
                                <Route path={"/help"}>
                                    {ability.can('read', resources.VENDOR) ?
                                        <HelpPage/> :
                                        <Redirect exact to="/unutorized"/>
                                    }
                                </Route>
                                <Route path={"/messages"}>
                                    {ability.can('read', resources.VENDOR) ?
                                        <VendorMessages/> :
                                        <Redirect exact to="/unutorized"/>
                                    }
                                </Route>
                                <Redirect exact from="/" to="/vendor"/>
                                <Route path={"/"}>
                                    <Page404/>
                                </Route>

                            </Switch>
                    }
                </main>
            </div>
            <AlghoBot/>
        </>
    );
}
