/**
 * calcola il raggio delle fontanelle da caricare
 * @param center {Array} [lat, lng]
 * @param zoom
 * @param mapWidth
 * @param mapHeight
 * @returns {number}
 */
import {useCallback, useEffect, useState} from "react";
import {geoFireDrinkingFountains} from "../firebase";
import {debounce} from "lodash";

export function getRadius(center, zoom, mapWidth, mapHeight) {
    return (metersPerPixels(center[0], zoom) * (getHypotenuse(mapWidth, mapHeight) / 2)) / 1000;
}

function getHypotenuse(w, h) {
    return Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2));
}

function metersPerPixels(lat, zoom) {
    return 156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom);
}

export function useGeoQuery(queryCriteria, deps, geoFire) {
    const [geoQuery, setGeoQuery] = useState(geoFire.query(queryCriteria));

    const handleUpdate = useCallback(
        debounce((q) => {
            geoQuery.updateCriteria(queryCriteria);
        }, 500),
        [queryCriteria]
    );

    useEffect(() => {
        handleUpdate(queryCriteria);
        //geoQuery.updateCriteria(queryCriteria);
    }, [queryCriteria, ...deps]);

    useEffect(() => {
        geoQuery.cancel();
        setGeoQuery(geoFire.query(queryCriteria));
    }, [geoFire])

    return geoQuery;
}
