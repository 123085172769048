import Bugsnag from "@bugsnag/js";
import axios from "axios";
import dayjs from "dayjs";
import 'dayjs/locale/en';
import 'dayjs/locale/et';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';
import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import ConfirmEmail from "../components/pages/ConfirmEmail";

import {LoginPage} from "../components/pages/LoginPage";
import ResetPassword from "../components/pages/ResetPassword";

import {firebaseAuth, getErrorMessage} from "../firebase";
import i18next from '../i18n';
import ability, {roles} from "../services/ability";
import {AbilityContext} from "../services/Can";

import {get, getUrl, put, USERS} from "../services/Client";
import {EmailConfirmedPage} from "./EmailConfirmedPage";
import FirebaseActionHandler from "./FirebaseActionHandler";
import ConfirmFormComplete from "./Forms/ConfirmFormComplete";
import Main from "./Main";
import MainVendor from "./MainVendor";
import OpenDataPage from './OpenData/OpenDataPage';
import {PaddingContainer} from "./PaddingContainer";
import CouponRedeem from "./Vendor/CouponRedeem/CouponRedeem";
import NewVendor from "./Vendor/NewVendor/NewVendor";
import OpenDataUser from "./OpenData/OpenDataUser";
import MainPinbiker from "./MainPinbiker";

export const UserContext = React.createContext({});

function checkUser(user, setUser, setError) {
	if(user && user.uid) {
		get(USERS, {elem: user.uid}).then(user => {
			if(!firebaseAuth.currentUser.emailVerified) {
				setUser({isLogged: false, ready: true});
				setError(i18next.t('verifyEmail'));
				firebaseAuth.signOut();
			} else {
				put(USERS, {
					elem: user.uid,
					body: {lastLoggedIn: new Date().getTime(), resetPasswordRequired: false}
				}).catch(console.log);
				Bugsnag.setUser(user.uid, user.email, user.username);
				setUser({...user, isLogged: true, ready: true});
			}
		});
	} else {
		setUser({isLogged: false, ready: true});
	}
}

function App() {
	dayjs.locale(i18next.resolvedLanguage);

	const {t} = useTranslation();
	let [user, setUser] = useState({ready: false});
	let [error, setError] = useState(null);
	let [username, setUsername] = useState("");
	let history = useHistory();

	useEffect(() => {
		firebaseAuth.languageCode = i18next.language || 'en';
	}, [i18next.language]);

	useEffect(() => firebaseAuth.onAuthStateChanged(user => {
		checkUser(user, setUser, setError);
	}), []);

	function onLogin(username, password) {
		firebaseAuth.signInWithEmailAndPassword(username, password)
			.then(user => checkUser(user, setUser, setError))
			.catch(e => {
				axios.get(getUrl(`${USERS}/${username}/reset-password`))
					.then(r => {
						if(r.data) {
							firebaseAuth.sendPasswordResetEmail(username)
								.then(() => {
									setUsername(username);
									history.push("/resetPassword");
								})
								.catch(() => setError(t('internalServerError')));
						} else {
							setError(getErrorMessage(e));
						}
					})
					.catch(() => setError(getErrorMessage(e)));
			});

	}

	function onResetPassword(username) {
		firebaseAuth.sendPasswordResetEmail(username)
			.then(() => {
				setUsername(username);
				history.push("/resetPassword");
			})
			.catch(e => setError(getErrorMessage(e)));
	}

	if(!user.ready) return <div/>;

	return (
		<UserContext.Provider value={user}>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>Pin Bike</title>
				<meta name="description" content="Pin Bike application"/>
			</Helmet>
			<Switch>
				<UnloggedRoute path="/login" setError={setError} redirect>
					<LoginPage onSubmit={onLogin} onResetPassword={onResetPassword}
					           error={error}/>
				</UnloggedRoute>
				<UnloggedRoute path="/resetPassword">
					<ResetPassword username={username}/>
				</UnloggedRoute>
				<UnloggedRoute path="/newVendor" setError={setError} redirect>
					<NewVendor/>
				</UnloggedRoute>
				<UnloggedRoute path="/confirmEmail" setError={setError} redirect>
					<ConfirmEmail/>
				</UnloggedRoute>
				<UnloggedRoute path="/confirmFormComplete/:aid" setError={setError}>
					<ConfirmFormComplete/>
				</UnloggedRoute>
				<UnloggedRoute path="/emailValidated" setError={setError}>
					<EmailConfirmedPage/>
				</UnloggedRoute>
				<UnloggedRoute path="/action" setError={setError}>
					<FirebaseActionHandler/>
				</UnloggedRoute>
				<UnloggedRoute path="/riscatta/:publicLink" setError={setError}>
					<PaddingContainer><CouponRedeem auto/></PaddingContainer>
				</UnloggedRoute>
				<UnloggedRoute path="/open/:id">
					<OpenDataPage/>
				</UnloggedRoute>
				{/* <UnloggedRoute path="/openUser/:id">
					<OpenDataUser/>
				</UnloggedRoute> */}
				<AbilityContext.Provider value={ability(user)}>
					<PrivateRoute path="/">
						{
							user.userType === roles.VENDOR ?
								<MainVendor/>
								:
							user.userType === roles.PINBIKER ?
								<MainPinbiker/>
								:
								<Main/>
						}
					</PrivateRoute>
				</AbilityContext.Provider>
			</Switch>
		</UserContext.Provider>
	);
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({children, ...rest}) {
	let user = useContext(UserContext) || {};

	return (
		<Route
			{...rest}
			render={({location}) =>
				user.isLogged ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: {from: location}
						}}
					/>
				)
			}
		/>
	);
}

function UnloggedRoute({redirect, children, setError, ...rest}) {
	const user = useContext(UserContext) || {};

	return (
		<Route
			{...rest}
			render={() =>
				user.isLogged && redirect ? (
					<Redirect
						to={{
							pathname: "/"
						}}
					/>
				) : (
					children
				)
			}
		/>
	);
}

export default App;
