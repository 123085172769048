import {useParams} from "react-router-dom";
import {useGetOrganizationRecords} from "../../../services/ContentManager";
import React,{useEffect,useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import TextInput from "../../../components/forms/TextInput";
import {Divider,IconButton, MenuItem, Grid} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import StartIconButton from "../../../components/buttons/StartIconButton";
import AddIcon from "@mui/icons-material/Add";
import {deleteElem,ORGANIZATIONS,post,put,RECORDS} from "../../../services/Client";
import {useQueryClient} from "react-query";
import {DetailsTitle} from "./Details";
import {useTranslation} from "react-i18next";
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    detail : {
        padding : theme.spacing(2),
    },
    header: {
        fontWeight: "bold",
        color: theme.palette.primary.main
    }
}));

const recordType = [
    {
        id: 0,
        name: <Trans i18nKey="ranks" />,
    },
    {
        id: 1,
        name: <Trans i18nKey="achievements" />,
    },
    {
        id: 2,
        name: <Trans i18nKey="refund" />,
    },
    {
        id: 3,
        name: <Trans i18nKey="position" />,
    }
]



export default function Records({isEditable, saving, saved}) {

    let classes = useStyles();
    let {id} = useParams();
    let {records} = useGetOrganizationRecords(id);
    let [localRecords, setLocalRecords] = useState([]);
    let [showUpdate, setShowUpdate] = useState("")
    let queryClient = useQueryClient();
    const {t} = useTranslation();

    useEffect(() => {
        if(saving) {
            if(JSON.stringify(records) !== JSON.stringify(localRecords)) {
                setShowUpdate("saving")
                for(let i = 0; i < (localRecords.length < records.length ? records.length : localRecords.length); i++){
                    if(!records[i] && !!localRecords[i]) {
                        post(ORGANIZATIONS+"/"+id+"/"+RECORDS, {body: localRecords[i]})
                            .then(() => {
                                if(showUpdate !== "error") setShowUpdate("saved")
                            }).catch(() => setShowUpdate("error"))
                    }
                    else if(!!records[i] && !localRecords[i]) {
                        deleteElem(ORGANIZATIONS+"/"+id+"/"+RECORDS+"/"+records[i].id)
                            .then(() => {
                                if(showUpdate !== "error") setShowUpdate("saved")
                            }).catch(() => setShowUpdate("error"))
                    }
                    else if(JSON.stringify(records[i]) !== JSON.stringify(localRecords[i])) {
                        put(ORGANIZATIONS+"/"+id+"/"+RECORDS+"/"+records[i].id, {body: localRecords[i]})
                            .then(() => {
                                if(showUpdate !== "error") setShowUpdate("saved")
                            }).catch(() => setShowUpdate("error"))
                    }
                }

            } else saved()
        }
    }, [saving])

    useEffect(() => {
        if(showUpdate === "saved" || showUpdate === "error") {
            if(showUpdate === "saved") saved();
            setTimeout(() => {
                setShowUpdate("")
                queryClient.invalidateQueries(ORGANIZATIONS,{id : id + "/" + RECORDS})
            }, 7000);
        }
    }, [showUpdate])

    useEffect(() => {
        setLocalRecords(JSON.parse(JSON.stringify(records)))
    }, [isEditable])

    const onTextChange = (index, prop) => (value) => {
        let newLocalRecords = [...localRecords];
        newLocalRecords[index][prop] = value;
        setLocalRecords(newLocalRecords)
    }

    const deleteField = (index) => {
        let newLocalRecords = [...localRecords];
        newLocalRecords.splice(index, 1)
        setLocalRecords(newLocalRecords)
    }

    const addField = () => {
        let newLocalRecords = [...localRecords];
        newLocalRecords.push({type: 0, tempId: new Date()})
        setLocalRecords(newLocalRecords)
    }

    return <Grid item className={classes.detail}>
        <DetailsTitle statusField={showUpdate} title={t('records')}/>

        {
            !isEditable ?
                records.length === 0 ? "-" :

                    <Grid container direction="column" style={{maxWidth: "70rem"}}>
                        <Grid container direction="row">
                            <Grid item xs={3} className={classes.header}>{t('title')}</Grid>
                            <Grid item xs={6} className={classes.header}>{t('description')}</Grid>
                            <Grid item xs={3} className={classes.header}>{t('type')}</Grid>
                        </Grid>
                        <Divider/>
                        {records.map(r => <><Grid container direction="row" key={r.id}>
                            <Grid item xs={3}>{r.title}</Grid>
                            <Grid item xs={6}>{r.description}</Grid>
                            <Grid item xs={3}>{recordType.find(rt => rt.id === r.type).name}</Grid>
                        </Grid> <Divider/></>)}
                    </Grid>

                :

                <Grid container spacing={4} style={{margin : 0,width : "100%",marginBottom : "0.5rem"}}>
                    {localRecords.map((lr,i) => <>
                        <Grid item xs={3} key={(lr.id || lr.tempId) + "title"}>
                            <TextInput label={t('title')}
                                       value={lr.title} color={"dark"}
                                       whiteBackground required
                                       onTextChange={onTextChange(i,"title")}
                                       InputLabelProps={{
                                           shrink : true,
                                       }}/>

                        </Grid>
                        <Grid item xs={4} key={(lr.id || lr.tempId) + "description"}>
                            <TextInput label={t('description')}
                                       value={lr.description} color={"dark"}
                                       whiteBackground required
                                       onTextChange={onTextChange(i,"description")}
                                       InputLabelProps={{
                                           shrink : true,
                                       }}/>

                        </Grid>
                        <Grid item xs={3} key={(lr.id || lr.tempId) + "type"}>
                            <TextInput label={t('type')}
                                       value={lr.type} color={"dark"}
                                       whiteBackground required
                                       onTextChange={onTextChange(i,"type")}
                                       select
                                       InputLabelProps={{
                                           shrink : true,
                                       }}>
                                {recordType.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
                            </TextInput>

                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={() => deleteField(i)} color={"primary"}>
                                <DeleteIcon/>
                            </IconButton>
                        </Grid>
                    </>)}

                    <Grid container justifyContent={"center"}>
                        <StartIconButton onClick={() => addField()} color={"primary"} startIcon={<AddIcon/>}
                                         title={t('addRecord')}/>
                    </Grid>
                </Grid>
        }
    </Grid>
}
