import {
	Divider,
	FormControlLabel,
	FormLabel,
	Grid,
	InputAdornment,
	MenuItem,
	Radio,
	RadioGroup,
	Switch,
	Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import makeStyles from '@mui/styles/makeStyles';
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useGetBikeType} from "../../constants/bikeType";
import {useGetProfileUserEnrollments} from "../../services/UserDataManager";
import {SettingsTooltip} from "../forms/CustomTooltip";
import TextInput from "../forms/TextInput";
import BaseModal from "./BaseModal";

const useStyles = makeStyles(theme => ({
	switchLabel: {
		color: theme.palette.secondary.light,
		paddingLeft: theme.spacing(2),
		'&.Mui-focused': {
			color: theme.palette.secondary.light,
		}
	},
	divider: {
		color: theme.palette.secondary.main,
		marginBottom: "0.4rem"
	}
}));

export const repeatType = {
	NONE: "0",
	DAILY: "1",
	ROUND_TRIP: "2"
};

export default function OldCreateSessionModal({open, onClose, onSubmit, session = {}, type = "create", multipleSessions = []}) {
	const {t} = useTranslation();
	let classes = useStyles();
	const bikeType = useGetBikeType();

	let [defaultValues, setDefaultValues] = useState({
		startTime: dayjs(session.startTime || new Date()).format('YYYY-MM-DDTHH:mm'),
		endTime: dayjs(session.endTime || new Date()).format('YYYY-MM-DDTHH:mm'),
		distance: session.nationalKm || 1.0,
		type: session.type,
		bikeType: session.bikeType
	});

	const {uid} = useParams();
	const {enrollments = []} = useGetProfileUserEnrollments(uid || session.uid);
	let [values, setValues] = useState(defaultValues);
	let [error, setError] = useState({});
	let [activeOrganizations, setActiveOrganizations] = useState([]);
	let [activeOrganizationsForMultipleSessions, setActiveOrganizationsForMultipleSessions] = useState([]);

	useEffect(() => {
		if(open) {
			let activeEnrollments = enrollments.filter(e => e.endDate >= session.endTime && e.startDate <= session.endTime);
			if(type === "create" || type === "massiveEdit") {
				activeEnrollments = [...new Map(enrollments.map(e => [e.organization,( {
					organization : e.organization,
					organizationTitle : e.organizationTitle
				} )])).values()]
			}
			if(JSON.stringify(activeEnrollments) !== JSON.stringify(activeOrganizations)) {
				setActiveOrganizations(activeEnrollments);
			}

			const compressedEnrollments = [...new Map(enrollments.map(e => [e.organization,( {
				organization : e.organization,
				organizationTitle : e.organizationTitle
			} )])).values()]
			activeEnrollments = compressedEnrollments.filter(ce => {
				let toUse = 0
				enrollments.filter(e => e.organization === ce.organization).forEach(e => {
					multipleSessions.forEach(s => {
						if(e.endDate >= s.endTime && e.startDate <= s.endTime) {
							toUse++
						}
					})
				})
				//logica basata sul fatto che un utente non puo' essere
				// iscritto più volte alla stessa organizzazione nello stesso periodo
				return toUse === multipleSessions.length;
			})
			setActiveOrganizationsForMultipleSessions(activeEnrollments)
		}
	}, [open, enrollments]);

	useEffect(() => {
		if(open) {
			let newDefaultValues = {
				...defaultValues,
				startTime: dayjs(session.startTime || new Date()).format('YYYY-MM-DDTHH:mm'),
				endTime: dayjs(session.endTime || new Date()).format('YYYY-MM-DDTHH:mm'),
				distance: session.nationalKm || 1.0,
				id: session.id,
				uid: session.uid,
				type: session.type,
				bikeType: session.bikeType
			};
			if(type === "create") newDefaultValues.repeatType = 0;
			newDefaultValues.sessionPoints = [];
			activeOrganizations.map(a => {
				let v = (session.sessionPoints || []).find(sp => sp.organizationId === a.organization);
				newDefaultValues.sessionPoints.push({
					organizationId: a.organization,
					distance: v ? v.distance : 0,
					homeWorkPath: v && v.homeWorkDistance ? v.homeWorkDistance > 0 : false
				});
			});
			setDefaultValues(newDefaultValues);
			setValues(newDefaultValues);
		}
	}, [open, activeOrganizations]);

	const save = () => {

		let toSaveValues = JSON.parse(JSON.stringify(values))

		if(toSaveValues.startTime > toSaveValues.endTime) {
			setError({endTime: t('invalidField')});
			return;
		}
		if(toSaveValues.distance === undefined) {
			setError({distance: t('requiredField')});
			return;
		}
		if(toSaveValues.distance < 0) {
			setError({distance: t('invalidField')});
			return;
		}
		if(toSaveValues.repeatType === repeatType.DAILY && (!toSaveValues.repetitions || toSaveValues.repetitions < 0)) {
			setError({repetitions: t('invalidField')});
			return;
		}
		if(toSaveValues.repeatType === repeatType.ROUND_TRIP && toSaveValues.startTimeGoBack > toSaveValues.endTimeGoBack) {
			setError({endTimeGoBack: t('invalidField')});
			return;
		}

		toSaveValues.nationalKm = toSaveValues.distance;
		delete toSaveValues.distance;
		toSaveValues.startTime = dayjs(toSaveValues.startTime, "YYYY-MM-DDTHH:mm").valueOf();
		toSaveValues.endTime = dayjs(toSaveValues.endTime, "YYYY-MM-DDTHH:mm").valueOf();

		if(toSaveValues.sessionPoints) {
			let enrollmentInactiveId = -1
			toSaveValues.sessionPoints.forEach(sp => {
				if(type === "massiveEdit") {
					if(parseInt(sp.distance) > 0 && !activeOrganizationsForMultipleSessions.find(a => a.organization === sp.organizationId))
						enrollmentInactiveId = sp.organizationId
				} else if(parseInt(sp.distance) > 0 && !enrollments.find(e => e.organization === sp.organizationId && e.startDate <= toSaveValues.startTime && e.endDate >= toSaveValues.endTime)) {
					enrollmentInactiveId = sp.organizationId
				}
				if(sp.homeWorkPath) {
					sp.homeWorkDistance = toSaveValues.nationalKm;
				} else {
					sp.homeWorkDistance = 0;
				}
				delete sp.homeWorkPath;
			});
			if(enrollmentInactiveId !== -1) {
				setError({[`${enrollmentInactiveId}Distance`]: t(type === "massiveEdit" ? 'almostOneSessionWithEnrollmentInactive' : 'enrollmentInactiveInThatPeriod')})
				return
			}
			// toSaveValues.sessionPoints = toSaveValues.sessionPoints.filter(sp => parseFloat(sp.distance) > 0)
		}

		if(toSaveValues.repeatType === repeatType.ROUND_TRIP) {
			toSaveValues.startTimeGoBack = dayjs(toSaveValues.startTimeGoBack, "YYYY-MM-DDTHH:mm").valueOf();
			toSaveValues.endTimeGoBack = dayjs(toSaveValues.endTimeGoBack, "YYYY-MM-DDTHH:mm").valueOf();
		}
		if(session && session.startTime) {
			toSaveValues.startTime += session.startTime - dayjs(dayjs(session.startTime).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm').valueOf();
		}

		if(type !== "edit") delete toSaveValues.id;
		if(type === "massiveEdit") {
			delete toSaveValues.startTime;
			delete toSaveValues.endTime;
		}
		onSubmit(toSaveValues);
		close();
	};

	const onTextChange = (value, name, organizationId) => {
		setValues(values => {
			let newValues = (JSON.parse(JSON.stringify(values)));
			if(!organizationId) {
				newValues[name] = value;
				if(name === "repeatType") {
					if(value === repeatType.NONE) {
						delete newValues.repetitions;
						delete newValues.startTimeGoBack;
						delete newValues.endTimeGoBack;
					} else if(value === repeatType.DAILY) {
						newValues.repetitions = 1;
						delete newValues.startTimeGoBack;
						delete newValues.endTimeGoBack;
					} else {
						newValues.startTimeGoBack = newValues.startTime;
						newValues.endTimeGoBack = newValues.endTime;
						delete newValues.repetitions;
					}
				}
			} else {
				newValues.sessionPoints.find(sp => sp.organizationId === organizationId)[name] = value;
			}
			setError({});
			return newValues;
		});
	};

	const close = () => {
		setValues({});
		setError({});
		onClose();
	};

	return <BaseModal open={open} onClose={close} onSave={save}>
		<Grid container spacing={4} sx={{padding: "12px"}}>
			<Grid item xs={12}>
				{
					type === "create" && <Typography>{t('createNewSession')}</Typography>
				}
				{
					type === "edit" && <Typography>{t('editSession')} "{session.id}"</Typography>
				}
				{
					type === "clone" && <Typography>{t('cloneSession')} "{session.id}"</Typography>
				}
				{
					type === "massiveEdit" && <Typography>{t('editSessions')}: {multipleSessions.length}</Typography>
				}
			</Grid>
			{type !== "massiveEdit" &&
				<>
					<Grid item xs={6}>
						<TextInput label={t('start')} value={values.startTime}
						           type="datetime-local" required
						           onTextChange={(value) => {
							           onTextChange(value, "startTime");
						           }}
						           error={error.startTime}/>
					</Grid>
					<Grid item xs={6}>
						<TextInput label={t('end')} value={values.endTime}
						           type="datetime-local" required
						           onTextChange={(value) => {
							           onTextChange(value, "endTime");
						           }}
						           error={error.endTime}/>
					</Grid>
					<Grid item xs={12}>
						<TextInput label={t('bikeType')}
						           value={values.type}
						           select
						           onTextChange={(value) => {
							           onTextChange(value, "type");
							           onTextChange(value ? Object.values(bikeType).find(t => t.type === value).bikeType : null, "bikeType");
						           }}
						           error={error.type}>
							<MenuItem key="" value="">
								<br/>
							</MenuItem>
							{Object.values(bikeType).map(t =>
								<MenuItem
									key={t.type}
									value={t.type}
								>
									{t.bikeType}
								</MenuItem>)
							}
						</TextInput>
					</Grid>
				</>
			}
			<Grid item xs={12}>
				<TextInput label={t('kilometersTraveled')} value={values.distance}
				           type="number" required step={1} min={0}
				           startAdornment={<InputAdornment position="start">km</InputAdornment>}
				           onTextChange={(value) => {
					           onTextChange(value, "distance");
				           }}
				           error={error.distance}/>
			</Grid>
			{
				values.repeatType !== undefined && <>
					<Grid item xs={12}>
						<FormControl component="fieldset">
							<FormLabel component="legend"
							           sx={{color: "text.primary", '&.Mui-focused': {color: "text.primary"}}}>
								{t('repetition')}
							</FormLabel>
							<RadioGroup row value={values.repeatType}
							            onChange={(event) => onTextChange(event.target.value, "repeatType")}>
								<FormControlLabel value={repeatType.NONE} label={t('none')}
								                  control={<Radio sx={{
									                  color: "primary.light",
									                  '&.Mui-checked': {color: "secondary.main"}
								                  }}/>}/>
								<FormControlLabel value={repeatType.DAILY} label={t('daily')}
								                  control={<Radio sx={{
									                  color: "primary.light",
									                  '&.Mui-checked': {color: "secondary.main"}
								                  }}/>}/>
								<FormControlLabel value={repeatType.ROUND_TRIP} label={t('roundTrip')}
								                  control={<Radio sx={{
									                  color: "primary.light",
									                  '&.Mui-checked': {color: "secondary.main"}
								                  }}/>}/>
							</RadioGroup>
						</FormControl>
					</Grid>
					{
						values.repeatType === repeatType.DAILY &&
						<Grid item xs={12}>
							<SettingsTooltip title={t('oneRepetitionImpliesTwoSessions')}>
								<div>
									<TextInput label={t('repeatNum')} value={values.repetitions}
									           type="number" required step={1} min={0}
									           onTextChange={(value) => {
										           onTextChange(value, "repetitions");
									           }}
									           error={error.repetitions}/>
								</div>
							</SettingsTooltip>
						</Grid>
					}
					{
						values.repeatType === repeatType.ROUND_TRIP &&
						<>
							<Grid item xs={6}>
								<TextInput label={t('startGoBack')} value={values.startTimeGoBack}
								           type="datetime-local" required
								           onTextChange={(value) => {
									           onTextChange(value, "startTimeGoBack");
								           }}
								           error={error.startTimeGoBack}/>
							</Grid>
							<Grid item xs={6}>
								<TextInput label={t('endGoBack')} value={values.endTimeGoBack}
								           type="datetime-local" required
								           onTextChange={(value) => {
									           onTextChange(value, "endTimeGoBack");
								           }}
								           error={error.endTimeGoBack}/>
							</Grid>
						</>
					}
				</>
			}
			{
				values && values.sessionPoints && values.sessionPoints.map(sp => {
					let organizationTitle = activeOrganizations.find(e => e.organization === sp.organizationId).organizationTitle;
					return <>
						<Grid item xs={12}>
							<Divider className={classes.divider}/>
							<Typography>{organizationTitle}</Typography>
						</Grid>
						<Grid item xs={6}>
							<TextInput label={t('kilometersTraveled')} value={sp.distance}
							           type="number" required step={1} min={0}
							           startAdornment={<InputAdornment position="start">km</InputAdornment>}
							           onTextChange={(value) => {
								           onTextChange(value, "distance", sp.organizationId);
							           }}
									   error={error[`${sp.organizationId}Distance`]}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormControlLabel
								className={classes.switchLabel}
								control={<Switch checked={sp.homeWorkPath}
								                 onChange={event => onTextChange(event.target.checked, "homeWorkPath", sp.organizationId)}
								                 color="secondary"/>}
								label={t('homeWorkPath')}
							/>
							{sp.homeWorkPath && <Typography variant='caption'>
								{t('homeWorkPathDescription')}
							</Typography>}
						</Grid>
					</>;
				})
			}
		</Grid>
	</BaseModal>;
}
