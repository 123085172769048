import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import NTMSelect from "../../../components/NTMSelect";
import {useTranslation} from "react-i18next";
import {languages} from "../../../constants/lenguages";
import {currency} from "../../../constants/currency";
import {useGeneralSettingsManagerFields} from "./settingsFields";
import RenderFields from "./RenderFields";


export default function GeneralSettingsManager({values, setValues}){

    const {t} = useTranslation()
    let [error, setError] = useState({});
    let fields = useGeneralSettingsManagerFields(values, setValues)

    return <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "0.5rem"}}>

        <Grid item xs={12} md={6} style={{padding: "16px"}}>
            <NTMSelect
                color={'primary'}
                label={t('locale')}
                items={languages.map(l => ({value: l.alternativeCode, text: l.label}))}
                onChange={value => {
                    let newValues = {
                        ...values,
                        locale: value
                    }
                    setValues(newValues);
                }}
                value={values.locale}
            />
        </Grid>
        <Grid item xs={12} md={6} style={{padding: "16px"}}>
            <NTMSelect
                color={'primary'}
                label={t('currencyLabel')}
                items={currency.map(l => ({value: l.alternativeCode, text: l.label}))}
                onChange={value => {
                    let newValues = {
                        ...values,
                        currency: value
                    }
                    setValues(newValues);
                }}
                value={values.currency}
            />
        </Grid>
        <Grid item xs={12} md={6} style={{padding: "16px"}}>
            <NTMSelect
                color={'primary'}
                label={t('timezone')}
                items={Intl.supportedValuesOf('timeZone').map(l => ({value: l, text: l}))}
                onChange={value => {
                    let newValues = {
                        ...values,
                        timezone: value
                    }
                    setValues(newValues);
                }}
                value={values.timezone}
            />
        </Grid>

        <RenderFields fields={fields} values={values} error={error}/>

    </Grid>;
}
