import HoverableButton from "../layout/HoverableButton";
import EditIcon from '@mui/icons-material/Edit';
import React,{useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import EditVerificationRequiredModal from "../modals/EditVerificationRequiredModal";
import {useSnackbar} from "notistack";
import {getErrorMessage,MESSAGES,post,SEND} from "../../services/Client";
import {useGetVerificationRequiredStrings} from "../tables/SessionTable";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    }
}));

export default function RenderVerificationRequiredCell({params, saveEdit}) {

    let classes = useStyles();
    let [modal, setModal] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const verificationRequiredStrings = useGetVerificationRequiredStrings();

    const save = (newValue) => {

        //salva valore nella sessione
        let saveValue = null
        if(newValue.verificationRequired === verificationRequiredStrings[0]) saveValue = true;
        else if(newValue.verificationRequired === verificationRequiredStrings[1]) saveValue = false;
        saveEdit(params.row.id,params.field, saveValue);

        //invia messaggio all'utente
        if(newValue.isSendingMessage) {
            let body = {
                title: newValue.title,
                body: newValue.body,
                sendTo: [
                    params.row.uid
                ]
            }
            post(MESSAGES+"/"+SEND, {body: body})
                .then().catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
        }
    }

    return <div>
        <HoverableButton
            item={{
                icon: <EditIcon/>,
                name: params.value || ""
            }}
            onClick={() => setModal(true)}
            classes={{root: classes.root}}
        />
        <EditVerificationRequiredModal open={modal} onClose={() => setModal(false)} onSubmit={save} defaultValue={params.value}/>
    </div>
}