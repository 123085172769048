import dayjs from "dayjs";
import Grid from "@mui/material/Grid";
import NTMXGrid from "../../components/NTMXGrid";
import React, { useState } from "react";
import { Avatar, Box, Container, CssBaseline, FormControl, List, ListItem, ListItemAvatar, ListItemText, useTheme } from "@mui/material";
import { Divider, IconButton, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RenderCell from "../../components/cellRender/RenderCell";
import useGetUsefulValues from "../../constants/rankingUsefulValues";
import { useTranslation } from "react-i18next";
import OpenDataRankInfo from "./OpenDataRankInfo";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import makeStyles from "@mui/styles/makeStyles";
import logo from "../../images/pinbike.gif";
import userImage from "../../images/top_bar-profilo-over.svg";
import LanguageSelect from "components/LanguageSelect";
import { useGetPublicUser, useGetPublicUserAchievements, useGetPublicUserRankings } from "services/ContentOpenManager";
import { useParams } from "react-router-dom";
import { upperCase } from "lodash";
import CircularLoading from "components/CircularLoading";

const useStyles = makeStyles(theme => ({
	logo: {
		height: "1.8rem"
	},
	notificationLogo: {
		maxHeight: "70px",
		maxWidth: "100%",
		color: theme.palette.primary.main,
		fontSize: 60
	},
	overlineText: {
		lineHeight: "1rem"
	},
	userAvatar: {
		border: "2px solid white",
		background: "white",
		margin: "auto",
		marginTop: "-40px",
		height: 124,
		width: 124,
	}
}));

const formatDate = (date) => {
	return dayjs(date).format("DD/MM/YYYY");
}

export default function OpenDataUser() {
	const { t, i18n } = useTranslation();
	const classes = useStyles();
	const theme = useTheme();
	let { id } = useParams();
	let { user = {}, status: userStatus } = useGetPublicUser(id);
	let { ranks = [], status: rankingsStatus } = useGetPublicUserRankings(id);
	let { achievements = [], status: achievementsStatus } = useGetPublicUserAchievements(id);
	achievements = achievements.slice().sort((a, b) => b.startDate - a.startDate);
	const filteredRanks = ranks;

	let [showInfo, setShowInfo] = useState(null);
	const usefulValues = useGetUsefulValues(false);
	// const result = ranks.groupBy(rank => {
	// 	return rank.organizationId;
	//   });
	// console.log(result)

	const defaultColumns = [
		{
			headerName: t('title'),
			field: 'title',
			width: 400,
			renderCell: (params) => <RenderCell params={params} />
		},
		{
			headerName: t('value'),
			field: 'value',
			width: 200,
			valueGetter: (params) => usefulValues.find(elem => elem.id === params.value) ? usefulValues.find(elem => elem.id === params.value)?.name : "",
			renderCell: (params) => <RenderCell params={params} />
		},
		{
			headerName: t('startDate'),
			field: 'startDate',
			width: 200,
			renderCell: (params) => <RenderCell params={params} type="date" />,
		},
		{
			headerName: t('endDate'),
			field: 'endDate',
			width: 200,
			renderCell: (params) => <RenderCell params={params} type="date" />,
		},
		{
			headerName: ' ',
			field: "info",
			width: 64,
			align: 'center',
			renderCell: (params) => <>
				<IconButton onClick={() => setShowInfo(params.row)} size="large"> <VisibilityIcon /> </IconButton>
			</>
		}
	];

	if (showInfo) return <OpenDataRankInfo goBack={() => setShowInfo(null)} rankId={showInfo.id} />

	const StatisticText = ({ code, value }) => {
		return <>
			<Typography variant="overline" className={classes.overlineText}>
				{t(code)}
			</Typography>
			<Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main }}>
				{value}
			</Typography>
		</>;
	}

	const EnrollmentComponent = () => {
		return <>
			<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
				{userStatus === 'success' ?
					user.enrollments.map((enrollment) => (
						<ListItem key={enrollment.organizationId} alignItems="flex-start" sx={{ padding: 0 }}>
							<ListItemAvatar>
								<Avatar src={enrollment.notificationLogo} >
									<AccountBalanceIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={
									<>
										<Typography variant="h5" sx={{
											color: theme.palette.primary.main,
											textTransform: "uppercase",
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap"
										}} title={enrollment.title.toUpperCase()}>{enrollment.title}
										</Typography>
									</>
								}
								secondary={
									<Grid container sx={{ pb: "16px" }}>
										<Grid item xs={6}>
											<StatisticText code={"points"} value={(enrollment.points ? enrollment.points : 0)} />
										</Grid>
										<Divider orientation="vertical" flexItem sx={{ ml: "-1px" }}></Divider>
										<Grid item xs={6} sx={{ pl: "8px" }}>
											<StatisticText code={"km"} value={(enrollment.urbanKm ? enrollment.urbanKm.toFixed(2) : 0) + " km"} />
										</Grid>
									</Grid>
								}
							/>
						</ListItem>
					)) : <></>
				}
			</List>
		</>;
	}

	const AchievemtsComponent = () => {
		return <>
			<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
				{achievementsStatus === 'success' ?
					achievements.map((achievement) => (
						<ListItem key={achievement.id} alignItems="flex-start" sx={{ padding: 0, marginBottom: 1 }}>
							<ListItemAvatar>
								<Avatar src={achievement.logo} >
									<EmojiEventsIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={
									<>
										<Typography variant="caption" sx={{
											lineHeight: "1"
										}}>
											{formatDate(achievement.startDate) + ' - ' + formatDate(achievement.endaDate) + "  " + achievement.organizationTitle ?? achievement.organizationTitle}
										</Typography>
										<Typography variant="h6" sx={{
											color: theme.palette.primary.main,
											textTransform: "uppercase",
											lineHeight: "1",
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											marginBottom: "4px"
										}} title={achievement.name.toUpperCase()}>
											{achievement.name}
										</Typography>
									</>
								}
								secondary={
									<>
										<Typography variant="body1">
											{achievement.target}
											{usefulValues.find(elem => elem.id === achievement.value) ? " " + usefulValues.find(elem => elem.id === achievement.value).name : ""}
										</Typography>
										<Typography component={'span'} variant="body2">
											{achievement.organizationTitle}
										</Typography>
									</>
								}
							/>
						</ListItem>
					)) : <CircularLoading />
				}
			</List>
		</>;
	}

	return (
		<Grid container sx={{
			display: "flex",
			minHeight: "100vh",
			maxWidth: "100vw"
		}}>
			<CssBaseline />
			<main style={{
				flexGrow: 1,
				minHeight: "100%",
			}}>
				<header>
					<Box
						sx={{
							backgroundColor: theme.palette.secondary.main,
							display: "flex",
							marginX: "0",
							padding: "16px 24px",
							position: "relative",
							textAlign: "center",
							width: "100%",
						}}
					>
						<div style={{ flexGrow: 1 }} />
						<img className={classes.logo} alt={"home"} src={logo} />
						<div style={{ flexGrow: 1 }} />
						<FormControl sx={{ minWidth: 120, display: { sm: 'inherit', xs: 'none' } }} size="small">
							<LanguageSelect value={i18n.language} onChange={({ target }) => i18n.changeLanguage(target.value)} />
						</FormControl>
					</Box>
				</header>
				<Container>
					<Grid container sx={{ mt: "8px" }}>
						<Grid item xs={3} sx={{ textAlign: "center" }}>
							<Box ml={2} mr={2}>
								<Avatar src={user.avatarUrl ? user.avatarUrl : userImage} alt="userImage" className={classes.userAvatar} />
								<Typography variant="h4" mt={2} gutterBottom sx={{ fontWeight: "bold", pb: "24px" }}>
									{user.username && user.username}
								</Typography>

								<Typography variant="h6" mt={2} gutterBottom sx={{
									fontWeight: "bold", pb: "8px"
								}}>
									{t("statistics")}
								</Typography>

								<StatisticText code={"nationalPoints"} value={(user.nationalPoints ? user.nationalPoints : 0)} />
								<StatisticText code={"nationalKm"} value={(user.nationalKm ? user.nationalKm.toFixed(2) : 0) + " km"} />
								<StatisticText code={"avgKmSessions"} value={(user.sessionDistanceAvg ? user.sessionDistanceAvg.toFixed(2) : 0) + " km"} />
								<StatisticText code={"euro"} value={(user.earnedEuro ? user.earnedEuro : 0) + t('currency')} />
								<StatisticText code={"totalCo2"} value={(user.co2 ? user.co2.toFixed(2) : 0)} />
							</Box>
						</Grid>
						<Divider orientation="vertical" flexItem sx={{ mr: "-1px" }}></Divider>
						<Grid item xs={6}>
							<Container sx={{ textAlign: "center" }}>
								<AchievemtsComponent></AchievemtsComponent>
							</Container>

							{/* <Container sx={{ textAlign: "center" }}>
								<NTMXGrid
									columns={defaultColumns}
									rows={filteredRanks || []}
									title={t('unexpiredRanks')}
									loading={rankingsStatus === "loading"}
									disableToolbar={true}
								/>
							</Container> */}
						</Grid>
						<Grid item xs={3}>
							<Box>
								<Typography variant="h6" mt={2} gutterBottom sx={{
									fontWeight: "bold", pb: "8px"
								}}>
									{t("initiatives")}
								</Typography>

								<EnrollmentComponent></EnrollmentComponent>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</main>
		</Grid >
	);

}

