import React from "react";
import {
    BarChart,
    Bar,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis,
    ZAxis,
    Legend
} from "recharts";
import { useTheme } from "@mui/material/styles";
import ResponsiveCircularLoading from "../ResponsiveCircularLoading";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import { value } from "lodash/seq";
import { useTranslation } from "react-i18next";

/**
 * Crea un chart a bolle
 * @param data {{}} {val1:valore,val2:valore,...}
 * @param loading
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
export default function NTMLinearBar({ data, loading, labels, title }) {
    const theme = useTheme();
    data = data ?? {};
    if (loading) return <ResponsiveCircularLoading />;

    let total = Object.values(data).reduce((a, b) => a + b, 0);

    function TooltipContent({active, payload, label}) {
        const {t} = useTranslation();
    
        return <Paper sx={{p: 1}}>
            {
                payload.map( (p, k) => 
                <Typography> <strong>{labels[k]}:</strong> {p.value} </Typography>)
            }
            
            {payload && payload.find(p => p.name === 'value') && payload.find(p => p.name === 'value').value}
        </Paper>
    }

    return (<Grid container>
        <Grid item sx={{ mb: 2 }}>
            <Typography variant={'h6'}>{title}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '-9px' }}>
            <Grid container columnGap={1}>
                {
                    Object.values(data).map((value, k) =>
                        <Grid item flexGrow={value}>
                            <Typography width={'100%'}
                                align={k === 0 ? 'left' : k === Object.keys(data).length - 1 ? 'right' : 'center'}>
                                {labels[k]} {!isNaN((value / total)) ? ((value / total) * 100).toFixed(2) : 0}%
                            </Typography>
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
        <Grid item xs={12}>
            {loading && <ResponsiveCircularLoading />}
            {!loading && <Box sx={{
                '& .recharts-rectangle.recharts-tooltip-cursor': {
                    fill: 'none'
                }
            }}>
                <ResponsiveContainer width={"100%"} height={30}>
                    <BarChart
                        layout="vertical"
                        data={[{ ...data, index: 1 }]}
                        margin={{ top: 10, right: 0, bottom: 0, left: 0, }}
                    >
                        <XAxis type={'number'} hide domain={[0, Object.values(data).reduce((a,b) => a+b, 0)]} />
                        <YAxis
                            type={'category'}
                            dataKey={'index'}
                            hide
                        />
                        {
                            Object.keys(data).filter(k => k !== 'index').map((key, id) =>
                                <Bar
                                    key={key}
                                    stackId="a"
                                    dataKey={key}
                                    barSize={12}
                                    fill={theme.palette.chartColors[id === Object.keys(data).length - 1 ? theme.palette.chartColors.length-1 : id ]}
                                />
                            )
                        }
                        <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }}
                            content={<TooltipContent/>} />
                    </BarChart>
                </ResponsiveContainer>
            </Box>}
        </Grid>
    </Grid>);
}