import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import SettingsPills from "../../components/SettingsPills"
import { getErrorMessage, put, SETTINGS } from "../../services/Client";
import { useGetSettings } from "../../services/ContentManager";
import { isEmpty } from "../../services/helper";
import RenderFields from "../Dashboard/Settings/RenderFields";
import { useAlgorithmFields, useKmToPointCoefficientFields, useVisualizationsCostFields } from "./GeneralSettingsFields";
import ResponsiveCircularLoading from "../../components/ResponsiveCircularLoading";


const replacer = (key, value) => {          //funzione usata per il confronto degli oggetti con JSON.stringify
    if (typeof value === 'boolean') return String(value);
    return value;
}

export function GeneralSettings() {
    const { t } = useTranslation();
    const [values, setValues] = useState({});
    const [originalSettings, setOriginalSettings] = useState({});
    let [errors, setErrors] = useState({});
    let { settings = [], status } = useGetSettings();
    const { enqueueSnackbar } = useSnackbar();
    let queryClient = useQueryClient();

    useEffect(() => {
        if (status !== 'loading') {
            let newValues = {};
            settings.forEach(setting => {
                newValues[setting.name] = setting.value
            })
            setValues(newValues);
            setOriginalSettings(newValues);
        }
    }, [settings])

    const pills = [
        {
            title: t('algorithmValues'),
            component: <AlgorithmValues values={values} setValues={setValues}
                onError={(value) => setErrors({ ...errors, algorithmValuesErrors: value })} />
        },
        {
            title: t('advertisedArticles'),
            component: <VisualizationsCost values={values} setValues={setValues}
                onError={(value) => setErrors({ ...errors, visualizationsCostErrors: value })} />
        },
        {
            title: t('pointcoefficient'),
            component: <KmToPointCoefficient values={values} setValues={setValues}
                onError={(value) => setErrors({ ...errors, kmToPointcoefficientErrors: value })} />
        }
    ];

    const checkErrors = () => {
        return !!(errors.algorithmValuesErrors || errors.visualizationsCostErrors);
    }

    const save = () => {
        enqueueSnackbar(t('saving...'), { variant: "info" });

        if (JSON.stringify(originalSettings, replacer) !== JSON.stringify(values, replacer)) {
            let settings = [];
            let newValues = { ...values };

            Object.entries(newValues).forEach(setting => {
                settings.push({ name: setting[0], value: setting[1] });
            })
            put(SETTINGS, { body: settings })
                .then(() => {
                    enqueueSnackbar(t('saved'), { variant: "success" })
                    setValues(newValues)
                })
                .catch(e => {
                    enqueueSnackbar(getErrorMessage(e), { variant: "error" })
                })
                .finally(() => queryClient.invalidateQueries([SETTINGS]));
        }
    }

    return (
        status === 'loading' ? <ResponsiveCircularLoading /> :
            <SettingsPills
                onRestore={() => {
                    setValues({ ...originalSettings })
                }}
                disabled={JSON.stringify(originalSettings, replacer) === JSON.stringify(values, replacer)}
                onSave={save}
                checkErrorsFunc={checkErrors}
                pills={pills}
            />
    )
}

function AlgorithmValues({ values, setValues, onError }) {
    const { t } = useTranslation();
    let fields = useAlgorithmFields(values, setValues);
    let [error, setError] = useState({});

    const onTextChange = (prop) => (value) => {
        let newValues = { ...values };
        newValues[prop] = value;
        setValues(newValues);
        checkError(prop, value)
    }

    const checkError = (prop, value) => {
        if (error && error[prop]) {
            delete error[prop]
            if (Object.keys(error).length === 0) onError(false)
        }
        if (isEmpty(value)) {
            onError(true);
            setError({ ...error, [prop]: t('mandatoryField') })
        }
    }

    return <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "0.5rem" }}>
        <RenderFields fields={fields} values={values} error={error} onTextChange={onTextChange} />
    </Grid>;
}

function VisualizationsCost({ values, setValues, onError }) {
    const { t } = useTranslation();
    let fields = useVisualizationsCostFields(values, setValues);
    let [error, setError] = useState({});

    const onTextChange = (prop) => (value) => {
        let newValues = { ...values };
        newValues[prop] = value;
        setValues(newValues);
        checkError(prop, value)
    }

    const checkError = (prop, value) => {
        if (error && error[prop]) {
            delete error[prop]
            if (Object.keys(error).length === 0) onError(false)
        }
        if (isEmpty(value)) {
            onError(true);
            setError({ ...error, [prop]: t('mandatoryField') })
        }
    }

    return <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "0.5rem" }}>
        <RenderFields fields={fields} values={values} error={error} onTextChange={onTextChange} />
    </Grid>;
}

function KmToPointCoefficient({ values, setValues, onError }) {
    const { t } = useTranslation();
    let [error, setError] = useState({});

    const onTextChange = (prop) => (value) => {
        let newValues = { ...values };
        newValues[prop] = value;
        setValues(newValues);
        checkError(prop, value)
    }

    const checkError = (prop, value) => {
        if (error && error[prop]) {
            delete error[prop]
            if (Object.keys(error).length === 0) onError(false)
        }
        if (isEmpty(value)) {
            onError(true);
            setError({ ...error, [prop]: t('mandatoryField') })
        }
    }

    let fields = useKmToPointCoefficientFields(values, setValues, checkError);

    return <Grid container spacing={4} style={{ margin: 0, width: "100%", marginBottom: "0.5rem" }}>
        <RenderFields fields={fields} values={values} error={error} onTextChange={onTextChange} />
    </Grid>;
}