import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import HoverableButton from "../layout/HoverableButton";
import EditIcon from "@mui/icons-material/Edit";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import BaseModal from "../modals/BaseModal";
import {Grid} from "@mui/material";
import SelectInputBoolean from "../forms/SelectInputBoolean";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    icon: {
        display: "flex",
        justifyContent: "center",
        width: '100%',
    }
}));

/**
 * mostra un pallino verde o rosso in base al boolean
 * @param params
 * @param inputLabel
 * @param saveEdit {function(id,field,value,row)} Callback function per salvare la modifica
 * @param overrideValue sovrascrive il valore dato nei params
 * @returns {JSX.Element|string}
 * @constructor
 */
export default function RenderBoolean({params, inputLabel, saveEdit, overrideValue = null}) {
    let classes = useStyles();
    let [modal, setModal] = useState(false);
    let [value, setValue] = useState((overrideValue !== null ? overrideValue : params.value));
    let color = ((overrideValue !== null ? overrideValue : params.value) || false).toString() === "true" ? "#43ff00" : "#ff0000";

    function onChange(newValue) {
        setValue(newValue);
    }

    function submit() {
        saveEdit(params.row.id, params.field, value, params.row)
        setModal(false);
    }

    if (!saveEdit) {
        return (overrideValue !== null ? overrideValue : params.value) != null ?
            <Grid style={{width: '100%'}}><FiberManualRecordIcon className={classes.icon}
                                                                 style={{color: color}}/></Grid> : ""
    }

    return (
        <div>
            <HoverableButton
                item={{
                    icon: <EditIcon/>,
                    name: (overrideValue !== null ? overrideValue : params.value) != null ?
                        <FiberManualRecordIcon style={{color: color}}/> : ""
                }}
                onClick={() => setModal(true)}
                classes={{root: classes.root}}
            />
            <BaseModal open={modal} onClose={() => setModal(false)} onSave={submit}>
                <SelectInputBoolean value={value} label={inputLabel} onChange={onChange}/>
                <div style={{height: "2rem", width: "15rem"}}/>
            </BaseModal>
        </div>
    );

}
