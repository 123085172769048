import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {useGetShops, useGetVendors} from "../../../services/ContentManager";
import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {isAdmin as getIsAdmin} from "../../../services/PermissionManager";
import {UserContext} from "../../App";
import CircularLoading from "../../../components/CircularLoading";
import {post, put, SHOPS, VENDORS} from "../../../services/Client";
import {removeImagesFirebase} from "../../../services/FirebaseManager";
import {formatAddress} from "../../../services/helper";
import ShopData from "../../../components/VendorComponents/ShopData";
import {generatePublicLink, urlEncode} from "../../../services/couponRedeemManager";

/**
 * @param uid
 * @param oid
 * @param isFirstShop
 * @returns {JSX.Element}
 * @constructor
 */
export default function Shop({uid, isFirstShop = false}) {
    const history = useHistory();
    const isAdmin = getIsAdmin(useContext(UserContext));
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    const {vendors: vendor, vendorStatus} = useGetVendors(uid);
    const {shops, status} = useGetShops(uid);

    const {id} = useParams();
    let defShop = null;
    if (!id) {
        //il primary Shop e' utilizzato come valore di default per gli Shop nuovi
        defShop = shops.find(s => s.isPrimary === true)
    }

    const [shop, setShop] = useState(null);
    useEffect(() => {
        if (status === 'success') {
            //se non c'é un id allora é una new
            let newShop = {};
            if (id) {
                newShop = shops.find(s => s.id + '' === id);
            }

            if (!newShop) {
                history.goBack();
            }
            const def = {
                images: [],
                categories: [],
                accountHolder: vendor.businessName,
                addressInput: formatAddress(newShop),
                publicLink: newShop.publicLink ?? urlEncode(newShop.name),
                oldPublicLink: generatePublicLink(newShop)
            };//valori di default
            setShop({...def, ...newShop});
        }
    }, [status]);

    //attendi che siano caricati i vendors
    if (status === 'loading' || vendorStatus === 'loading' || !shop)
        return <CircularLoading/>

    const goBack = async () => {
        await queryClient.invalidateQueries([SHOPS, {vendor: uid}]);
        history.goBack();
    }

    function save(newShop, stopLoading) {
        newShop.isPrimary = isFirstShop;
        newShop.visible = newShop.visible ? newShop.visible : true;
        if (newShop.isEcommerce) {
            newShop.address = null;
            newShop.number = null;
            newShop.city = null;
        }
        (newShop.id ? put : post)(VENDORS + '/' + uid + '/' + SHOPS, {
            body: newShop,
            elem: newShop.id || ''
        })
            .then(() => {
                //rimuovi le immagini definitivamente da firebase
                removeImagesFirebase(newShop.imagesToDelete);
                queryClient.invalidateQueries([SHOPS, {vendor: uid}]).then(() => {
                    stopLoading();
                    goBack();
                });
            })
            .catch(() => {
                enqueueSnackbar(t('errorSavingData'), {variant: "error"});
                stopLoading();
            });
    }

    return <ShopData isFirstShop={isFirstShop} shop={shop} onSave={save} goBack={goBack}
                     setShop={setShop}
                     defShop={defShop}
                     disabled={isAdmin}
                     vendor={vendor}
    />

}
