import {GridCellExpand} from "./renderCellExpand";
import {formatAddress} from "../../services/helper";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";

export default function RenderUserAddressHistory({addresses, width, showOrganizationName = true}){

    const {t} = useTranslation();
    let values = []
    const active = addresses.filter(a => !a.endAssociation)
    const expired = addresses.filter(a => !!a.endAssociation)

    if(active.length !== 0) {

        active.forEach(a => {
            let text = `- ${formatAddress(a)} (${a.latitude}-${a.longitude})`
            if(a.startAssociation) text += `\n  dal ${dayjs.unix(a.startAssociation).format("DD/MM/YYYY")}`
            if(a.organization && showOrganizationName) text += `\n ${t('initiative')}: ${a.organizationName}`
            values.push(text);
        })
    }

    if(expired.length !== 0) {
        values.push(" ")
        values.push(t('deleted') + ":")
        expired.forEach(a => {
            let text = `- ${formatAddress(a)} (${a.latitude}-${a.longitude})`
            if(a.startAssociation && a.endAssociation) text += `\n  dal ${dayjs.unix(a.startAssociation).format("DD/MM/YYYY")} al ${dayjs.unix(a.endAssociation).format("DD/MM/YYYY")}`
            if(a.organization && showOrganizationName) text += `\n ${t('initiative')}: ${a.organizationName}`
            values.push(text);
        })
    }

    return <GridCellExpand value={values.length !== 0 ? values.map(v => {
        return {showValue: v}
    }) : ""} width={width}/>

}
