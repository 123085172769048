import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    DataGridPro
} from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        border: 0,
        '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': {
            backgroundColor: theme.palette.primary.main,
            color: "#FFF",
            textTransform: "uppercase",
        },
        '& .MuiIconButton-root': {
            color: theme.palette.secondary.main
        },
        '& .Mui-checked' : {
            color: theme.palette.text.primary
        }
    },
    title: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "center"
    }
}))

const format = (row, field, timezone) => {
    if (!row) return
    let value, template;
    if (field.includes("_")) {
        let [name, type] = field.split("_")
        value = row[name]
        if (type.toLocaleLowerCase() === 'date') template = "DD/MM/YYYY"
        else template = "HH:mm:ss"
    } else {
        value = row[field]
        template = "HH:mm:ss DD/MM/YYYY"
    }

    if (!value) return ""
    else if(!timezone) return dayjs(new Date(value)).format(template)
    else return dayjs.tz(new Date(value), timezone).format(template)
}

export const timestampFormatter = {
    valueGetter: ({row, field}) => format(row, field),
    sortComparator: (v1, v2) => sortComparator(v1, v2)
}

export const timestampFormatterWithTimezone = {
    valueGetter: ({row, field}) => {
        if(row.timezone) {
            const formattedDate = format(row,field,row.timezone);
            return formattedDate ? `${format(row,field,row.timezone)}  (${row.timezone})` : "";
        }
        else return format(row, field)
    },
    sortComparator: (v1, v2) => sortComparator(v1, v2)
}

const sortComparator =  (v1, v2) => {
    if (v1.includes(" ") && v2.includes(" ")) {
        let [t1, d1] = v1.split(" ")
        let [t2, d2] = v2.split(" ")
        let comparedDate = compareDate(d1, d2)
        if (comparedDate === 0) return t1.localeCompare(t2)
        else return comparedDate
    } else if (v1.includes("/")) {
        return compareDate(v1, v2);
    } else {
        return v1.localeCompare(v2)
    }

}

const compareDate = (v1, v2) => v1.split("/").reverse().join("").localeCompare(v2.split("/").reverse().join(""))

export const secondsToDuration = {
    valueGetter: ({row, field}) => {
        if (!row) return
        return row[field] ? dayjs.duration(row[field] * 1000).format("HH:mm:ss") : ""
    },
    sortComparator: (v1, v2) => v1.split(":").join("").localeCompare(v2.split(":").join(""))
}

export const numberFormatter = {
    type: "number",
    headerAlign: 'left',
    align: "left",
    valueFormatter: ({value}) => {
        return value === 0 || value ? parseFloat(value).toFixed(2)
            .replace(/\.00$/,'').replace('.', ',')
            : ""
    }
}

function CustomToolbar({title, rightButton}) {

    const classes = useStyles();

    return (
        <Toolbar>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} lg={5}>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton/>
                    <GridToolbarExport csvOptions={{delimiter: ";", fileName: title}} printOptions={{ disableToolbarButton: true }}/>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={5}>
                    {rightButton}
                </Grid>
            </Grid>
        </Toolbar>
    );
}

//https://github.com/mui-org/material-ui-x/blob/18cd8e182b74a2cfa5889c7b9fba8d7d5016441d/packages/grid/_modules_/grid/models/api/gridColumnApi.ts
export default function NTMXGrid({
                                     columns,
                                     rows,
                                     title,
                                     key,
                                     options = {},
                                     rightButton,
                                     getRowId,
                                     density = "standard",
                                     apiRef,
                                     checkboxSelection,
                                     onSelectionModelChange,
                                     initialState,
                                     loading,
                                     selectionModel,
                                     isRowSelectable,
                                     disableToolbar,
                                     autoHeight,
                                     defaultPageSize = 25,
                                     rowsPerPageOptions,
                                     height,
                                     onRowClick,
                                     minHeight = undefined,
                                     onFilterModelChange,
                                     filterMode,
                                     onSortModelChange,
                                     sortingMode,
                                     onPageChange,
                                     onPageSizeChange,
                                     paginationMode,
                                     rowCount,
                                 }) {

    const classes = useStyles();
    const {t} = useTranslation();
    let [pageSize, setPageSize] = useState(defaultPageSize);
    let [columnVisibilityModel, setColumnVisibilityModel] = useState(getVisibilityModel())

    const GRID_DEFAULT_LOCALE_TEXT = {
        // Root
        rootGridLabel: 'grid',
        noRowsLabel: t('noAvailableRows'),
        errorOverlayDefaultLabel: t('errorOccurred'),

        // Density selector toolbar button text
        toolbarDensity: t('density'),
        toolbarDensityLabel: t('density'),
        toolbarDensityCompact: t('densityCompact'),
        toolbarDensityStandard: t('densityStandard'),
        toolbarDensityComfortable: t('densityComfortable'),

        // Columns selector toolbar button text
        toolbarColumns: t('columns'),
        toolbarColumnsLabel: t('selectColumns'),

        // Filters toolbar button text
        toolbarFilters: t('filters'),
        toolbarFiltersLabel: t('showFilters'),
        toolbarFiltersTooltipHide: t('hideFilters'),
        toolbarFiltersTooltipShow: t('showFilters'),
        toolbarFiltersTooltipActive: (count) =>
            count !== 1 ? `${count} ${t('filtersActive')}` : `${count} ${t('filterActive')}`,

        // Export selector toolbar button text
        toolbarExport: t('export'),
        toolbarExportLabel: t('export'),
        toolbarExportCSV: t('exportCSV'),

        // Columns panel text
        columnsPanelTextFieldLabel: t('searchColumn'),
        columnsPanelTextFieldPlaceholder: t('columnTitle'),
        columnsPanelDragIconLabel: t('reorderColumns'),
        columnsPanelShowAllButton: t('showAll'),
        columnsPanelHideAllButton: t('hideAll'),

        // Filter panel text
        filterPanelAddFilter: t('addFilter'),
        filterPanelDeleteIconLabel: t('cancel'),
        filterPanelOperators: t('operators'),
        filterPanelOperatorAnd: t('operatorAnd'),
        filterPanelOperatorOr: t('operatorOr'),
        filterPanelColumns: t('columns'),
        filterPanelInputLabel: t('value'),
        filterPanelInputPlaceholder: t('filterValue'),

        // Filter operators text
        filterOperatorContains: t('contains'),
        filterOperatorEquals: t('equals'),
        filterOperatorStartsWith: t('startsWith'),
        filterOperatorEndsWith: t('endsWith'),
        filterOperatorIs: t('is'),
        filterOperatorIsEmpty: t('isEmpty'),
        filterOperatorIsNotEmpty: t('isNotEmpty'),
        filterOperatorNot: t('isNot'),
        filterOperatorAfter: t('isAfter'),
        filterOperatorOnOrAfter: t('isOnOrAfter'),
        filterOperatorBefore: t('isBefore'),
        filterOperatorOnOrBefore: t('isOnOrBefore'),

        // Filter values text
        filterValueAny: t('all'),
        filterValueTrue: t('true'),
        filterValueFalse: t('false'),

        // Column menu text
        columnMenuLabel: t('menu'),
        columnMenuShowColumns: t('showColumns'),
        columnMenuFilter: t('filterAction'),
        columnMenuHideColumn: t('hide'),
        columnMenuUnsort: t('unsort'),
        columnMenuSortAsc: t('sortAsc'),
        columnMenuSortDesc: t('sortDesc'),

        // Column header text
        columnHeaderFiltersTooltipActive: (count) =>
            count !== 1 ? `${count} ${t('filtersActive')}` : `${count} ${t('filterActive')}`,
        columnHeaderFiltersLabel: t('showFilters'),
        columnHeaderSortIconLabel: t('reorder'),

        // Rows selected footer text
        footerRowSelected: (count) =>
            count !== 1
                ? ``
                : ``,

        // Total rows footer text
        footerTotalRows: t('totalRows'),

        checkboxSelectionHeaderName: t('checkboxSelectionColumnName')
    };

    function getVisibilityModel() {
        let jsonModel = localStorage.getItem(key || title)
        if(jsonModel) return JSON.parse(jsonModel)
        else return columns.map(({headerName, hideable}) => ({[headerName]: hideable!==false}))
    }

    function updateVisibilityModel(newModel) {
        localStorage.setItem(key || title, JSON.stringify(newModel))
        setColumnVisibilityModel(newModel)
    }

    function handlePageSizeChange(newPageSize) {
        if (onPageSizeChange) {
            onPageSizeChange(newPageSize);
        }
        setPageSize(newPageSize);
    }

    return (
        <div style={{height: height || '75vh', width: '100%', minHeight: minHeight}}>
            <DataGridPro
                onRowClick={onRowClick}
                apiRef={apiRef}
                disableColumnMenu
                pagination
                checkboxSelection={checkboxSelection}
                pageSize={pageSize}
                rowsPerPageOptions={rowsPerPageOptions || [25, 50, 100]}
                className={classes.root}
                getRowId={getRowId}
                columns={columns}
                density={density} //comfortable, standard, compact
                rows={rows}
                loading={loading}
                onSelectionModelChange={onSelectionModelChange}
                selectionModel={selectionModel}
                isRowSelectable={isRowSelectable}
                localeText={GRID_DEFAULT_LOCALE_TEXT}
                components={{
                    Toolbar: () => !disableToolbar && <CustomToolbar title={title} rightButton={rightButton}/>
                }}
                autoHeight={autoHeight}
                initialState={initialState}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    updateVisibilityModel(newModel)
                }
                {...options}
                onFilterModelChange={onFilterModelChange}
                filterMode={filterMode ? filterMode : "client"}
                onSortModelChange={onSortModelChange}
                sortingMode={sortingMode ? sortingMode : "client"}
                onPageChange={onPageChange}
                onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
                paginationMode={paginationMode ? paginationMode : "client"}
                rowCount={rowCount}
            />
        </div>
    );
}
