import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";
import { Avatar } from "@mui/material";
import NTMXGrid from "../../components/NTMXGrid";
import userImage from "../../images/top_bar-profilo-over.svg";
import { useTranslation } from "react-i18next";
import useGetUsefulValues from "../../constants/rankingUsefulValues";
import InfoGrid from "../../components/InfoGrid";
import { useGetPublicRank } from "services/ContentOpenManager";
import RenderCell from 'components/cellRender/RenderCell';

const useStyles = makeStyles(theme => ({
    backButton: {
        color: theme.palette.primary.main,
    },
    backButtonIcon: {
        margin: theme.spacing(1)
    },
    title: {
        padding: theme.spacing(1),
        fontWeight: "bold",
        color: theme.palette.primary.main,
        fontSize: 35,
        textAlign: "center"
    },
    text: {
        paddingLeft: theme.spacing(1),
        fontWeight: "bold",
        color: theme.palette.text.primary
    },
    box: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        borderBottom: `${theme.palette.secondary.main} 1px solid`,
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        flexGrow: 1,
    },
}));

export default function OpenDataRankInfo({ goBack, rankId }) {

    const { t } = useTranslation();
    let classes = useStyles();
    let { rank = {}, status } = useGetPublicRank(rankId);
    const usefulValues = useGetUsefulValues();

    const defaultColumns = [
        {
            headerName: t('position'),
            field: 'position',
            width: 200
        },
        {
            headerName: t('image'),
            field: 'avatarUrl',
            width: 200,
            renderCell: (params) => <Avatar src={params.value || userImage} alt="userImage" />
        },
        {
            headerName: t('username'),
            field: 'username',
            width: 200,
        },
        {
            headerName: t('points'),
            field: 'points',
            width: 200,
        },
        {
            headerName: t('award'),
            field: 'award',
            width: 200,
            renderCell: (params) => {
                let value = "";
                if (params.row.award){
                    const award = params.row.award;
                    console.log(award)
                    value = award.value + " ";
                    value += (award.type == 0 || award.type == 3) ? (award.currency ? award.currency : t('euro')) : t('points')
                }
                return value;
            }
        }
    ];

    const infos = [
        {
            isDate: true,
            field: 'startDate',
            label: t('startDate'),
        }, {
            isDate: true,
            field: 'endDate',
            label: t('endDate'),
        }, {
            findValueOptions: usefulValues,
            field: 'value',
            label: t('value'),
        }
    ]

    return (
        <Grid container justifyContent="space-between">
            {goBack && <Grid container justifyContent="space-between">
                <Button onClick={goBack} className={classes.backButton}>
                    <ArrowBackIcon className={classes.backButtonIcon} />
                    {t('goBack')}
                </Button>
            </Grid>}
            {rank.startDate &&
                <>
                    <Grid xs={12}>
                        <div className={classes.title}>{rank.title}</div>
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "48px" }}>
                        <InfoGrid infos={infos} data={rank}>
                        </InfoGrid>
                    </Grid>
                </>
            }

            <NTMXGrid
                key={"ranking-info-table"}
                columns={defaultColumns}
                loading={status === "loading"}
                rows={rank.rankingPositions || []}
                getRowId={(row) => rank && row.position}
                disableToolbar={true}
            />
        </Grid>
    );
}
