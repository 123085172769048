import Articles from "./Article/Articles";
import React, {useState} from "react";
import {useGetVendorCoupons} from "../../services/ContentManager";
import CircularLoading from "../../components/CircularLoading";
import {useHistory} from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import {BottomNavigation, BottomNavigationAction, Button, Grid} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ShoppingCart, Store, WebAsset} from "@mui/icons-material";
import CouponList from "../../components/VendorComponents/CouponList";
import Shops from "./Shop/Shops";

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(2)
    }, bottomNavigation: {
        width: '100%', position: "fixed", bottom: 0, left: 0, zIndex: 100
    }, backButton: {
        color: theme.palette.primary.main,
    }, backButtonIcon: {
        margin: theme.spacing(1)
    }, spacing: {
        width: '100%', height: '5em'
    },

}));

export default function VendorInfo({uid}) {
    const classes = useStyles();
    const history = useHistory();
    const [navigationValue, setNavigationValue] = useState('articles');

    function handleChangeNavigation(event, newValue) {
        setNavigationValue(newValue);
    }

    let {coupons, status: statusCoupons} = useGetVendorCoupons(uid);
    if (statusCoupons === 'loading') return <CircularLoading/>;

    return (
        <Grid>
            <Grid container justifyContent="space-between" className={classes.marginBottom}>
                <Button
                    onClick={() => history.goBack()}
                    className={classes.backButton}
                >
                    <ArrowBackIcon className={classes.backButtonIcon}/>
                    Torna indietro
                </Button>
            </Grid>
            {
                navigationValue === 'articles' &&
                (
                    <Articles
                        uid={uid}
                        urlPrefix={'/vendors/' + uid}
                    />
                )
            }

            {
                navigationValue === 'shops' &&
                (
                    <Shops
                        uid={uid}
                        urlPrefix={'/vendors/' + uid}
                    />
                )
            }

            {
                navigationValue === 'coupons' &&
                (
                    <CouponList
                        coupons={coupons}
                        title={'Voucher'}
                    />
                )
            }

            <Grid className={classes.spacing}>
            </Grid>

            <Grid container>
                <BottomNavigation value={navigationValue} onChange={handleChangeNavigation}
                                  className={classes.bottomNavigation}
                                  showLabels>
                    <BottomNavigationAction
                        label="Premi a catalogo"
                        value="articles"
                        icon={<ShoppingCart/>}
                    />
                    <BottomNavigationAction label="Negozi" value="shops" icon={<Store/>}/>
                    <BottomNavigationAction label="Voucher" value="coupons" icon={<WebAsset/>}/>
                </BottomNavigation>
            </Grid>
        </Grid>
    );
}
