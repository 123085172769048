import {PHONE_PREFIXES} from "../../constants/phonePrefixes";
import NTMSelect from "../NTMSelect";
import React from "react";
import {useTranslation} from "react-i18next";
import {DEFAULT_PREFIX} from "../../constants/vendors";

export default function PhonePrefixesSelect({prefix, onChange, disabled, label, error}) {
    const {t} = useTranslation();
    let prefixes = [{
        "name": t('checkboxSelectionColumnName'),
        "dial_code": '',
        "code": ''
    }, ...PHONE_PREFIXES];
    return (
        <NTMSelect
            value={prefix || DEFAULT_PREFIX} label={label ? label : t('phonePrefix')}
            onChange={(text) => onChange(text)}
            items={prefixes.map(p => ({
                value: p.dial_code,
                text: p.name + (p.dial_code ? (' (' + p.dial_code + ')') : '')
            }))}
            disabled={disabled}
            error={error}
        />
    );
}
