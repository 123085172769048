import { useParams } from "react-router-dom";
import { useGetInviteFriends } from "../../services/ContentManager";
import NTMXGrid, { timestampFormatterWithTimezone } from "../../components/NTMXGrid";
import React from "react";
import RenderUserRedirect from "../../components/cellRender/RenderUserRedirect";
import { useAwardTypeNames } from "../../constants/awardType";
import { useTranslation } from "react-i18next";

export default function InviteFriends() {

    const { t } = useTranslation();
    let { id } = useParams();
    let { inviteFriends, status } = useGetInviteFriends(id);
    const typeLabels = useAwardTypeNames()

    const inviteFriendsStatus = [
        {
            id: 0,
            name: t('inviteFriendsStatus0')
        }, {
            id: 1,
            name: t('inviteFriendsStatus1')
        }, {
            id: 2,
            name: t('inviteFriendsStatus2')
        }, {
            id: 3,
            name: t('inviteFriendsStatus3')
        }, {
            id: 4,
            name: t('inviteFriendsStatus4')
        }
    ]

    const defaultColumns = [
        {
            headerName: t('code'),
            field: 'invitationCode',
            width: 160,
        }, {
            headerName: t('userID'),
            field: 'uid',
            width: 280
        }, {
            headerName: t('username'),
            field: 'username',
            width: 200,
            renderCell: (params) => <RenderUserRedirect value={params.value} url={params.row.uid} />
        }, {
            headerName: t('email'),
            field: 'email',
            width: 220,
        }, {
            headerName: t('invitedUserUid'),
            field: 'invitedUserUid',
            width: 280
        }, {
            headerName: t('invitedUserEmail'),
            field: 'invitedUserEmail',
            width: 220,
        }, {
            field: 'timestamp',
            headerName: t('winDate'),
            width: 280,
            ...timestampFormatterWithTimezone,
        }, {
            headerName: t('status'),
            field: 'status',
            width: 160,
            valueGetter: (params) => params.value !== undefined && inviteFriendsStatus.find(s => s.id === params.value).name,
        }, {
            headerName: t('type'),
            field: 'awardType',
            width: 160,
            valueGetter: (params) => params.value || params.value === 0 ? typeLabels[params.value] : ""
        }, {
            headerName: t('value'),
            field: 'couponValue',
            width: 160,
        }, {
            headerName: t('description'),
            field: 'couponDescription',
            width: 220,
        },
    ];

    return <div>
        <NTMXGrid
            key={"manager-invite-friends-tables"}
            columns={defaultColumns}
            rows={inviteFriends || []}
            getRowId={(row) => inviteFriends && row.id}
            title={t('inviteFriends')}
            loading={status === "loading"}
        />
    </div>
}
