import { Send } from "@mui/icons-material";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import StartIconButton from "../../components/buttons/StartIconButton";
import SelectUsersByEmailModal from "../../components/modals/SelectUsersByEmailModal";
import SendMessageModal from "../../components/modals/SendMessageModal";
import NTMXGrid, { timestampFormatter } from "../../components/NTMXGrid";
import { Pill } from "../../components/SettingsPills";
import { messagesFirebaseRef } from "../../constants/messages";
import { firebaseAuth } from "../../firebase";
import { get, getErrorMessage, MESSAGES, ORGANIZATIONS, post, USERS, VENDORS } from "../../services/Client";
import { useGetOrganizationMessages, useGetVendorMessages } from "../../services/ContentManager";
import { unixToString, unixToStringHumanReadable } from "../../services/helper";
import { useGetOrganizationUsers } from "../../services/UserDataManager";
import MessageInfo from "../Dashboard/Messages/MessageInfo";

export default function VendorMessages() {
    const { t } = useTranslation();
    let uid = firebaseAuth.currentUser.uid;
    let { messages, status } = useGetVendorMessages(uid);
    const queryClient = useQueryClient();

    const setRead = (message) => {
        if (!message.read)
            get(`${USERS}/${uid}/${MESSAGES}`, { elem: message.message })
            .then(
                ()=> queryClient.invalidateQueries([VENDORS, { uid }, MESSAGES])
            )
        //questa get setta il messaggio come letto
    }

    return (
        <>
            <Grid container>
                {
                    messages.map(
                        m => <Pill
                            index={m.id}
                            pill={{
                                title: <Grid container sx={m.read && { fontWeight: 'normal' }}>
                                    <Grid item >{unixToStringHumanReadable(m.createdDate)}</Grid>
                                    <Grid item flexGrow={1} textAlign='center'>{m.title}</Grid>
                                    <Grid item visibility='hidden' >{unixToStringHumanReadable(m.createdDate)}</Grid>
                                </Grid>,
                                component: <>
                                    <Divider sx={{ mb: 3 }}></Divider>
                                    <Typography sx={{ px: 2 }}>{m.body}</Typography>
                                </>
                            }}
                            onClick={() => setRead(m)}
                        ></Pill>
                    )
                }
            </Grid>
        </>
    )
}
