import BaseModal from "./BaseModal";
import React,{useState} from "react";
import Grid from "@mui/material/Grid";
import TextInput,{TextInputContainer} from "../forms/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import {useGetCities} from "../../services/ContentManager";
import NTMSelect from "../NTMSelect";
import {useTranslation} from "react-i18next";
import MenuItem from "@mui/material/MenuItem";

export default function CreateSeatModal({open, onClose, onSubmit}){

    const {t} = useTranslation();
    let cities = useGetCities();
    let [values, setValues] = useState({});
    let [error, setError] = useState({});

    const save = () => {
        if(!values.name) {
            setError({name : t('mandatoryField')});
            return;
        }
        if(!values.address) {
            setError({address : t('mandatoryField')});
            return;
        }
        if(!values.number) {
            setError({number : t('mandatoryField')});
            return;
        }
        if(!values.cityName) {
            setError({cityName : t('mandatoryField')});
            return;
        }
        if(!cities.find(c => c.city.toLowerCase() === values.cityName.toLowerCase())){
            setError({cityName : t('cityDoesNotExist')});
            return;
        }
        if(!values.destinationTolerance) {
            setError({destinationTolerance : t('mandatoryField')});
            return;
        }
        if(values.destinationTolerance < 0) {
            setError({destinationTolerance : t('invalidValue')});
            return;
        }
        values.destinationTolerance /= 1000
        onSubmit(values)
        close()
    }

    const close = () => {
        setValues({})
        setError({});
        onClose()
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        newValues[name] = value;
        setError({});
        setValues(newValues);
    }

    const getFormattedCities = () => {
        let formattedCities = []
        cities.map(c => formattedCities.push({value : c.city, text: c.city}))
        return formattedCities
    }

    return <BaseModal open={open} onClose={close} onSave={save}>
        <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1rem"}}>

                <TextInputContainer xs={6} label={t('name')} value={values.name}
                           type="string" required
                           onTextChange={(value) => {onTextChange(value, "name")}}
                           error={error.name}/>

                <TextInputContainer xs={6} label={t('address')} value={values.address}
                           type="string" required
                           onTextChange={(value) => {onTextChange(value, "address")}}
                           error={error.address}/>

                <TextInputContainer xs={6} label={t('houseNumber')} value={values.number}
                           type="number" required
                           onTextChange={(value) => {onTextChange(value, "number")}}
                           error={error.number}/>

                <Grid container xs={6} sx={{padding: "12px"}}>
                    <NTMSelect label={t('city')} value={values.cityName || ""} maxItemsSize={3}
                               required items={getFormattedCities()} color={"light"}
                               onChange={(value) => {onTextChange(value, "cityName")}}
                               error={error.cityName}/>
                </Grid>

                <TextInputContainer xs={6} label={t('tolerance')} value={values.destinationTolerance}
                           type="number" required min={0} step={0.01}
                           startAdornment={<InputAdornment position="start">m</InputAdornment>}
                           onTextChange={(value) => {onTextChange(value, "destinationTolerance")}}
                           error={error.destinationTolerance}/>
        </Grid>
    </BaseModal>
}
