import React, {useContext} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {Link} from "react-router-dom";
import clsx from "clsx";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import HoverableButton from "./HoverableButton";
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import logo from "../../images/pinbike.gif"
import {firebaseAuth} from "../../firebase";
import {useGetProfileUser} from "../../services/UserDataManager";
import HoverableProfileButton from "./HoverableProfileButton";
import {UserContext} from "../../containers/App";
import {useTranslation} from "react-i18next";
import {FormControl, MenuItem, Select} from "@mui/material";
import LanguageSelect from "../LanguageSelect";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
        height: "4rem",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(
            ['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
    },
    menuButton: {
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(4),
        }
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    logo: {
        height: '1.8rem',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'unset',
            maxWidth: 200
        }
    },
    text: {
        color: theme.palette.primary.contrastText,
        padding: 0
    }
}));

export default function Header({menuOpen, toggleMenu, noMenu = false}) {
    const classes = useStyles();
    const {i18n} = useTranslation()
    const userData = useContext(UserContext);
    const {t} = useTranslation();
    let {user = {}} = useGetProfileUser(userData.uid);

    return (
        <AppBar
            position="fixed"
            color="secondary"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: menuOpen,
            })}
        >
            <Toolbar>
                {
                    !noMenu &&
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => toggleMenu(!menuOpen)}
                        edge="start"
                        className={clsx(classes.menuButton)}
                        size="large">
                        <MenuIcon/>
                    </IconButton>

                }
                <div style={{flexGrow: 0.023}}/>
                <Link to="/">
                    <img className={classes.logo} alt={"home"} src={logo}/>
                </Link>
                <div style={{flexGrow: 1}}/>
                <FormControl sx={{m: 1, minWidth: 120, display: { sm: 'inherit', xs: 'none' }}} size="small">
                    <LanguageSelect value={i18n.language} onChange={({target}) => i18n.changeLanguage(target.value)}/>
                </FormControl>
                <Link to={"/profile/" + user.uid} style={{textDecoration: "none"}}>
                    {
                        <HoverableProfileButton
                            item={{
                                name: `${user.username}`,
                                icon: <PersonIcon/>,
                                avatarUrl: user.avatarUrl
                            }}
                        />}
                </Link>
                <div style={{flexGrow: 0.01}}/>
                <HoverableButton
                    item={{
                        name: t('logOut'),
                        icon: <ExitToAppIcon sx={{color: "#fff"}}/>
                    }}
                    onClick={() => firebaseAuth.signOut()}
                    classes={{text: classes.text}}
                />
            </Toolbar>
        </AppBar>
    );
}
