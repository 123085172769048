import { Card, CardActions, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import ResponsiveCircularLoading from "../ResponsiveCircularLoading";
import Grid from "@mui/material/Grid";
import { numberWithSpaces } from "../../services/helper";
import Info from "../Info";

export default function StatsCard({ title, value, unit, precision, loading, multiplier = 1, info }) {
    value = (value ?? 0) * multiplier;
    return (
        <Card sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%" }}>
                <Grid container direction={'column'} justifyContent={'space-between'} height={'100%'}>
                    <Grid item>
                        <Grid container alignItems={'center'} justifyContent={'space-between'}>
                            <Grid>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid >
                                {info && <Info>{info}</Info>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {
                            loading && <ResponsiveCircularLoading />
                        }
                        {
                            !loading &&
                            <Typography variant="h5" component="div">
                                {numberWithSpaces(precision ? value.toFixed(precision) : value)} {unit ?? ''}
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}