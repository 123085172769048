import Grid from "@mui/material/Grid";
import TextInput, {TextInputContainer} from "../forms/TextInput";
import BaseModal from "./BaseModal";
import React, {useState} from "react";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {Button, Typography} from "@mui/material";
import CircularLoading from "../CircularLoading";
import {useSnackbar} from "notistack";
import {getErrorMessage, USERS, get} from "../../services/Client";
import makeStyles from "@mui/styles/makeStyles";
import {isEmpty, unixToString} from "../../services/helper";
import {genderCode, getGenderCodeValue} from "../../constants/genderCode";
import GenderSelect from "../GenderSelect";

export default function AddUserModal({open, onClose, onSave}) {
    const {t} = useTranslation();
    const today = dayjs(new Date()).format("YYYY-MM-DD");
    let [values, setValues] = useState({});
    let [error, setError] = useState({});
    const [showEmail, setShowEmail] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const genderOptions = [
        {
            id: "Maschio",
            name: t('male')
        },
        {
            id: "Femmina",
            name: t('female')
        }
    ]

    const close = () => {
        setValues({});
        setError({});
        setShowEmail(true);
        onClose();
    }

    const save = () => {
        if (!values.uid) {
            if (isEmpty(values.password)) {
                setError({...error, password: t('mandatoryField')});
                return;
            }
            if (isEmpty(values.firstName)) {
                setError({...error, firstName: t('mandatoryField')});
                return;
            }
            if (isEmpty(values.lastName)) {
                setError({...error, lastName: t('mandatoryField')});
                return;
            }
        }
        onSave({...values, gender: getGenderCodeValue(values.genderCode)});
        close();
    }

    function findUser() {
        if (isEmpty(values.email)) {
            setError({email: t('mandatoryField')});
            return;
        }
        setIsLoading(true);
        get(`${USERS}/${values.email}/overview`).then((user) => {
            setValues({...user, email: values.email, birthDate: unixToString(user.birthDate)});
            setShowEmail(false);
            setIsLoading(false);
        }).catch(e => {
            enqueueSnackbar(getErrorMessage(e), {variant: "error"});
            setIsLoading(false);
            onClose();
        });
    }

    const onTextChangeNoSpace = (value, name) => {
        let newValues = {...values};
        setError({});
        newValues[name] = value.trim();
        setValues(newValues);
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        setError({});
        newValues[name] = value;
        setValues(newValues);
    }

    let content = <></>

    //Mostra prima il campo email dell'utente per far si che se esiste non ne crea uno nuovo
    if (showEmail)
        content = <>
            <TextInputContainer xs={12} required label={t('email')} value={values.email}
                                onTextChange={(value) => {
                                    onTextChangeNoSpace(value, "email")
                                }}
                                error={error.email}/>
            <Grid item xs={12} style={{padding: "16px"}}>
                <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={findUser}
                    disabled={isLoading}
                >
                    {t('continue')}
                </Button>
            </Grid>
        </>
    else//se esiste l'utente mostra i campi già valorizzati non modificabili
        content = <>
            <Grid item xs={12}>
                <Typography variant={'h6'} align={'center'}>
                    {values.uid ? t('promote') : t('crateUser')} {values.email} {values.uid ? t('toManager') : ''}
                </Typography>
            </Grid>
            <TextInputContainer xs={6} label={t('firstName')} value={values.firstName}
                                type="string"
                                onTextChange={(value) => {
                                    onTextChange(value, "firstName")
                                }}
                                disabled={!!values.uid}
                                required
                                error={error.firstName}
            />

            <TextInputContainer xs={6} label={t('lastName')} value={values.lastName}
                                type="string"
                                onTextChange={(value) => {
                                    onTextChange(value, "lastName")
                                }}
                                disabled={!!values.uid}
                                required
                                error={error.lastName}
            />

            <TextInputContainer xs={6} label={t('username')} value={values.username}
                                type="string"
                                onTextChange={(value) => {
                                    onTextChange(value, "username")
                                }}
                                disabled={!!values.uid}
            />

            {
                values.uid ?
                    <TextInputContainer xs={6} label={t('gender')} value={t(getGenderCodeValue(values.genderCode))}
                                        type="string"
                                        disabled/>
                    :
                    <GenderSelect value={values.genderCode}
                                  xs={6}
                                  onTextChange={(value) => {
                                      onTextChange(value, "genderCode")
                                  }}
                    />
            }

            <TextInputContainer xs={6} label={t('phoneNumber')} value={values.phoneNumber}
                                type="number" min={0} step={1}
                                onTextChange={(value) => {
                                    onTextChangeNoSpace(value, "phoneNumber")
                                }}
                                disabled={!!values.uid}
            />

            <TextInputContainer xs={6} label={t('birthDate')} value={values.birthDate}
                                type="date" max={today}
                                onTextChange={(value) => {
                                    onTextChangeNoSpace(value, "birthDate")
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={!!values.uid}
            />

            {
                !values.uid && <TextInputContainer xs={6} required label={t('password')} value={values.password}
                                                   type="password"
                                                   onTextChange={(value) => {
                                                       onTextChangeNoSpace(value, "password")
                                                   }} showPasswordIcon
                                                   error={error.password}/>
            }
        </>

    return <BaseModal open={open} onClose={close} onSave={save}>
        <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1.5rem"}}>

            {
                isLoading && <CircularLoading/>
            }
            {
                content
            }

        </Grid>
    </BaseModal>
}