import makeStyles from '@mui/styles/makeStyles';
import {CircularProgress, Grid, IconButton} from "@mui/material";
import React from "react";
import clsx from "clsx";
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles(theme => ({
    container: {
        zIndex: 100,
        background: "rgba(255, 255, 255, 0.4)",
        width: '100%',
        height: '100%'
    },
}));

const buttonSX = {
    padding: 0.5,
    backgroundColor: 'secondary.light',
    position: 'absolute',
    zIndex: 1,
    top: '4px',
    left: '4px'
};

/**
 * icona di loading circolare al centro dello schermo, se stoppable c'è una x al centro
 * @returns {JSX.Element}
 * @constructor
 */
export default function ResponsiveCircularLoading({style, className, stoppable, onStop, width}) {
    const classes = useStyles();
    return (
        <Grid style={style} className={clsx(classes.container, className)} container alignItems='center'
              alignContent={"center"} direction={'column'}>
            <Grid item xs={12}>
                <Grid container alignItems={"center"} alignContent={"center"} direction={"row"}
                      style={{width: '100%', height: '100%'}}>
                    <Grid item xs={12} sx={{position: 'relative'}}>
                        {stoppable &&
                            <IconButton
                                sx={buttonSX}
                                onClick={onStop}
                            >
                                <ClearIcon/>
                            </IconButton>}
                        <CircularProgress size={width}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}