import BaseModal from "./BaseModal";
import React,{useState} from "react";
import Grid from "@mui/material/Grid";
import TextInput,{TextInputContainer} from "../forms/TextInput";
import dayjs from "dayjs";
import {MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function EditCodesModal({open, onClose, onSubmit}) {

    const {t} = useTranslation();
    const today = dayjs(new Date()).format("YYYY-MM-DD");

    const fields = [
        {
            name: t('startDate'),
            value: "start"
        },{
            name: t('endDate'),
            value: "end"
        }
    ]

    let [values, setValues] = useState({field: fields[1].value, value: today});

    const save = () => {
        onSubmit({[values.field]: values.value});
        onClose();
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        newValues[name] = value;
        setValues(newValues);
    }

    return <BaseModal open={open} onClose={onClose} onSave={save}>
        <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1rem"}}>
            <TextInputContainer value={values.field} select label={t('fieldToEdit')} xs={12}
                       onTextChange={(value) => onTextChange(value, "field")}>
                {fields.map(o => <MenuItem key={o.value} value={o.value}>{o.name}</MenuItem>)}
            </TextInputContainer>

            <TextInputContainer required label={t('newValue')} value={values.value} xs={12}
                       type="date" onTextChange={(value) => {onTextChange(value, "value")}}/>
        </Grid>
    </BaseModal>
}