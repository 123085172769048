import {SHOP_ROUTE_ADMIN} from "../constants/vendors";

/**
 * rimpiazza il :uid dal url e imposta l'uid dato in iput
 * @param route
 * @param uid
 * @returns {*}
 */
export function setRouteUid(route, uid){
    return route.replace(":uid", uid);
}

/**
 * genera l'errore della richiesta axios da mostrare all'utente
 * @param e
 * @returns {string|*}
 */
export function getRequestError(e){
    console.log(e);
    if(!e.response){
        return "Errore interno"
    }

    if (e.response.data.status >= 500) {
        return "Errore interno al server"
    } else {
        //mostro l'errore
        return e.response.data.message || e.response.data.error;
    }
}