import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import FileInput from "../../../components/forms/FileInput";
import { ORGANIZATIONS, put } from "../../../services/Client";
import { useQueryClient } from "react-query";
import StartIconButton from "../../../components/buttons/StartIconButton";
import { useGetOrganization, useGetPlafond } from "../../../services/ContentManager";
import { useParams } from "react-router-dom";
import { CircularProgress, Typography, Grid, InputBase, IconButton, Link } from "@mui/material";
import Records from "./Records";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Trans, useTranslation } from "react-i18next";
import NTMLinearBar from "../../../components/charts/NTMLinearBar";
import ResponsiveCircularLoading from "../../../components/ResponsiveCircularLoading";
import Container from "@mui/material/Container";

const useStyles = makeStyles(theme => ({
    logo: {
        maxWidth: "80vw",
        maxHeight: "25vh"
    },
    title: {
        fontWeight: "bold",
        color: theme.palette.primary.main,
        fontSize: 35
    },
    text: {
        paddingBottom: theme.spacing(1),
        fontWeight: "bold",
        color: theme.palette.text.primary,
        size: "1.2rem",
    },
    detail: {
        padding: theme.spacing(2),
    },
    textFieldTitle: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        border: `${theme.palette.secondary.main} 1px solid`,
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        width: "50vw",
        flexGrow: 1
    },
    textField: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        border: `${theme.palette.secondary.main} 1px solid`,
        color: theme.palette.text.primary,
        width: "50vw",
        flexGrow: 1
    },
    textFieldRegulation: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        border: `${theme.palette.secondary.main} 1px solid`,
        width: "70vw",
        height: "6rem",
        flexGrow: 1
    },
}));

export function DetailsTitle({ statusField, title }) {
    let classes = useStyles();

    const getStatus = () => {
        if (statusField) {
            if (statusField === "saving") {
                return <>
                    <Grid item> <CircularProgress size={"1rem"} /> </Grid>
                    <Grid item> <Typography color="primary"> <Trans i18nKey="saving..." /> </Typography> </Grid>
                </>
            } else if (statusField === "saved") {
                return <>
                    <Grid item> <CheckCircleIcon style={{ fontSize: "1rem", color: "#0da800" }} /> </Grid>
                </>
            } else {
                return <>
                    <Grid item> <WarningIcon style={{ fontSize: "1rem" }} color={"primary"} /> </Grid>
                    <Grid item> <Typography color="primary"> {statusField} </Typography> </Grid>
                </>
            }
        }
    }

    return <Grid container direction="row" className={classes.text} spacing={1}>
        <Grid item> {title} </Grid>
        {getStatus()}
    </Grid>
}

export default function Details() {

    let [isEditable, setIsEditable] = useState(false);
    let [editOrganization, setEditOrganization] = useState({});
    let [editingFields, setEditingFields] = useState({});
    let [saving, setSaving] = useState(0);
    let [recordsSaved, setRecordsSaved] = useState(false);
    let { id } = useParams();
    let { organization } = useGetOrganization(id);
    let classes = useStyles();
    let queryClient = useQueryClient();
    const { t } = useTranslation();
    let { status: plafondStatus, plafond } = useGetPlafond(id);

    useEffect(() => {
        if (saving === 2 && recordsSaved) {
            setIsEditable(false)
            setSaving(0)
            setRecordsSaved(false)
        }
    }, [recordsSaved, saving])

    const edit = () => {
        setEditOrganization({ ...organization });
        setIsEditable(true);
    }

    const onChange = (prop) => (event) => {
        setEditOrganization({ ...editOrganization, [prop]: event.target.value });
    }

    const saveEdit = () => {
        setSaving(1)
        let newEditingFields = { ...editingFields };
        ["title", "initiativeLogo", "notificationLogo", "termsConditions", "regulation", "regulationLink"]
            .map(f => {
                if (organization[f] !== editOrganization[f]) {
                    newEditingFields[f] = "saving"
                    setEditingFields(newEditingFields)
                }
            })
        put(ORGANIZATIONS, { body: editOrganization, elem: organization.id })
            .then(() => {
                Object.keys(newEditingFields).forEach((key) => {
                    newEditingFields[key] = "saved"
                });
                setEditingFields(newEditingFields)
                setTimeout(() => {
                    setEditingFields({})
                }, 7000);
                setSaving(2)
            })
            .catch(e => {
                Object.keys(newEditingFields).forEach((key) => {
                    newEditingFields[key] = e.message
                });
                setEditingFields(newEditingFields)
                setTimeout(() => {
                    setEditingFields({})
                }, 7000);
                setSaving(3)
            })
            .finally(() => {
                queryClient.invalidateQueries(ORGANIZATIONS, { id: organization.id })
            });
    }

    return <div>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
            {isEditable ?
                <Grid>
                    <IconButton onClick={saveEdit}>
                        <CheckIcon />
                    </IconButton>
                    <IconButton onClick={() => setIsEditable(false)}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                :
                <IconButton onClick={edit}>
                    <EditIcon />
                </IconButton>
            }
        </Grid>

        <Grid item className={classes.detail}>
            <DetailsTitle statusField={editingFields["title"]} title={t('title')} />
            {isEditable ?
                <InputBase
                    className={classes.textFieldTitle}
                    value={editOrganization["title"]}
                    onChange={onChange("title")}
                    placeholder={t('title')} />
                :
                organization.title ? <div className={classes.title}>{organization.title}</div> : "-"
            }
        </Grid>

        <Grid container direction={isEditable ? "row" : "column"}>

            <Grid item className={classes.detail}>
                <DetailsTitle statusField={editingFields["initiativeLogo"]} title={t('initiativeLogo')} />
                {isEditable ?
                    <div style={{ width: "13rem" }}>
                        <FileInput folder="organizations/logos" prefix={organization.id}
                            onRequestSave={(e) => setEditOrganization({
                                ...editOrganization,
                                "initiativeLogo": e
                            })} />
                        <Typography variant={'caption'}>
                            {t('imageRatio')} 11:2 {t('imageDimensions')} 803px x 146px
                        </Typography>
                    </div>
                    :
                    organization.initiativeLogo ?
                        <img className={classes.logo} src={organization.initiativeLogo} /> : "-"
                }
            </Grid>

            <Grid item className={classes.detail}>
                <DetailsTitle statusField={editingFields["notificationLogo"]} title={t('notificationStandardLogo')} />
                {isEditable ?
                    <div style={{ width: "13rem" }}>
                        <FileInput folder="organizations/logos" prefix={organization.id}
                            onRequestSave={(e) => setEditOrganization({
                                ...editOrganization,
                                "notificationLogo": e
                            })} />
                        <Typography variant={'caption'}>
                            {t('imageRatio')} 1:1 {t('imageDimensions')} 256px x 256px
                        </Typography>
                    </div>
                    :
                    organization.notificationLogo ?
                        <img className={classes.logo} src={organization.notificationLogo} /> : "-"
                }
            </Grid>

            <Grid item className={classes.detail}>
                <DetailsTitle statusField={editingFields["termsConditions"]} title={t('termsConditionsPage')} />
                {isEditable ?
                    <div style={{ width: "13rem" }}>
                        <FileInput folder="organizations/termsConditions" prefix={organization.id}
                            onRequestSave={(e) => setEditOrganization({
                                ...editOrganization,
                                "termsConditions": e
                            })}
                            acceptedFileTypes={['application/doc', 'application/pdf', '.docx']} />
                    </div>
                    :
                    organization.termsConditions ? <StartIconButton title={t('openPdf')} startIcon={<PictureAsPdfIcon />}
                        href={organization.termsConditions}
                        target="_blank" /> : "-"
                }
            </Grid>

        </Grid>

        <Grid item className={classes.detail}>
            <DetailsTitle statusField={editingFields["regulation"]} title={t('regulation')} />
            {isEditable ?
                <InputBase
                    multiline
                    rows={5}
                    className={classes.textFieldRegulation}
                    value={editOrganization["regulation"]}
                    onChange={onChange("regulation")}
                    placeholder={t('regulation')}
                />
                :
                organization.regulation ?
                    <Typography style={{ whiteSpace: 'pre-line' }}>{organization.regulation}</Typography> : "-"}
        </Grid>

        <Grid item className={classes.detail}>
            <DetailsTitle statusField={editingFields["regulation"]} title={t('regulationLink')} />
            {isEditable ?
                <InputBase
                    className={classes.textField}
                    value={editOrganization["regulationLink"]}
                    onChange={onChange("regulationLink")}
                    placeholder={t('regulationLink')}
                />
                :
                organization.regulationLink ?
                    <Link href={organization.regulationLink} target="_blank" style={{ whiteSpace: 'pre-line' }}>{organization.regulationLink}</Link> : "-"}
        </Grid>

        <Grid item className={classes.detail}>
            <DetailsTitle statusField={editingFields["welcomeMessage"]} title={t('welcomeMessage')} />
            {isEditable ?
                <InputBase
                    multiline
                    rows={5}
                    className={classes.textFieldRegulation}
                    value={editOrganization["welcomeMessage"]}
                    onChange={onChange("welcomeMessage")}
                    placeholder={t('welcomeMessage')}
                />
                :
                organization.welcomeMessage ?
                    <Typography style={{ whiteSpace: 'pre-line' }}>{organization.welcomeMessage}</Typography> : "-"}
        </Grid>

        <Records isEditable={isEditable} saving={saving === 1} saved={() => setRecordsSaved(true)} />

        {plafond && plafond.plafond > 0 && <>
            <Grid item sx={{ mt: 3 }}>
                <Typography align={'center'} variant={'h5'}>{t('plafond')}</Typography>
            </Grid>
            <Grid item className={classes.detail}>
                <Container maxWidth={"md"}>
                    <Grid item sx={{ p: 2, px: 4 }}>
                        <NTMLinearBar
                            data={plafond &&
                            {
                                spent: plafond.spent,
                                available: plafond.available,
                            }
                            }
                            loading={plafondStatus === 'loading'}
                            labels={[t('plafondSpent'), t('plafondAvailable')]}
                        />
                    </Grid>
                </Container>
            </Grid>
        </>}

    </div>
}
