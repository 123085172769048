import React, {useContext, useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Sidebar from "../components/layout/Sidebar";
import {useSetMenu} from "../services/MenuManager";
import {CssBaseline, Paper} from "@mui/material";
import Header from "../components/layout/Header";
import {Redirect, Route, Switch} from "react-router-dom";
import ProfilePage from "./Users/ProfilePage";
import Page404 from "../components/pages/Page404";
import {resources, roles} from "../services/ability";
import {AbilityContext} from "../services/Can";
import {UserContext} from "./App";
import {useQueryClient} from "react-query";
import {get, ORGANIZATIONS, USERS,} from "../services/Client";
import {useSynchronizeCities} from "../services/ContentManager";
import {synchronizeUsers} from "../services/UserDataManager";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
        maxWidth:'100vw'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default function Main() {

    const ability = useContext(AbilityContext);
    const loggedUser = useContext(UserContext);
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const queryClient = useQueryClient()

    useEffect(() => {
        if (ability?.can('read', resources.ALL_USERS)) {
            synchronizeUsers()
        }
    }, [ability])

    useEffect(() => {
        console.log(loggedUser)
        if (!loggedUser.organization) return;
        queryClient.prefetchQuery([USERS, {oid: loggedUser.organization}], () => get(USERS, {params: {oid: loggedUser.organization}}))
    }, [loggedUser, queryClient])

    useSynchronizeCities();
    // queryClient.prefetchQuery(ORGANIZATIONS, () => get(ORGANIZATIONS))
    let menu = useSetMenu(ability, loggedUser);

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Header menuOpen={open} toggleMenu={setOpen}/>

            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Switch>

                    <Route path={"/profile/:uid/:filter"}>
                        <ProfilePage/>
                    </Route>
                    <Redirect from="/profile/:uid" to="/profile/:uid/enrollments"/>
                    <Redirect from="/profile" to={"/profile/" + loggedUser.uid + "/enrollments"}/>

                    <Redirect exact from="/" to="/profile"/>
                    <Route path={"/"}>
                        <Page404/>
                    </Route>

                </Switch>
            </main>
        </div>
    );
}
