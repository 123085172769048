import { useGetRank } from "../../services/ContentManager";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useState } from "react";
import { Avatar } from "@mui/material";
import NTMXGrid from "../../components/NTMXGrid";
import userImage from "../../images/top_bar-profilo-over.svg";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AchievementAward from "../Achievements/AchievementAward";
import { genderCode } from "../../constants/genderCode";
import useGetUsefulValues from "../../constants/rankingUsefulValues";
import useGetRankingFilter from "../../constants/rankingFilter";
import useGetSessionType from "../../constants/sessionType";
import InfoGrid from "../../components/InfoGrid";
import ConfirmButton from "../../components/buttons/ConfirmButton";
import { useSnackbar } from "notistack";
import { Savings } from "@mui/icons-material";
import { post, RANKINGS } from "../../services/Client";
import { getErrorMessage } from "../../firebase";
import { useQueryClient } from "react-query";

const useStyles = makeStyles(theme => ({
    backButton: {
        color: theme.palette.primary.main,
    },
    backButtonIcon: {
        margin: theme.spacing(1)
    },
    title: {
        padding: theme.spacing(1),
        fontWeight: "bold",
        color: theme.palette.primary.main,
        fontSize: 35,
        textAlign: "center"
    },
    text: {
        paddingLeft: theme.spacing(1),
        fontWeight: "bold",
        color: theme.palette.text.primary
    },
    box: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        borderBottom: `${theme.palette.secondary.main} 1px solid`,
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        flexGrow: 1,
    },
}));

export default function RankInfo({ goBack, rankId, national, customField, generalRank, queryClientKey }) {

    const { t } = useTranslation();
    let classes = useStyles();
    let { rank = {}, status } = useGetRank(rankId, !generalRank);
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    // {rank = {}, status} = useGetGeneralRank(!generalRank); toDo:fare classifica globale
    const [showAwards, setShowAwards] = useState(null);
    const usefulValues = useGetUsefulValues(national);
    const rankingFilter = useGetRankingFilter(customField);
    const sessionType = useGetSessionType();

    const formatWinDateAward = (row, timezone) => {
        if (!row || !row.award || !row.award.timestamp) return
        let value = row.award.timestamp;
        let template = "HH:mm:ss DD/MM/YYYY";
        if (!value) return ""
        else if(!timezone) return dayjs(new Date(value)).format(template)
        else return dayjs.tz(new Date(value), timezone).format(template) + " (" + timezone + ")";
    }

    const defaultColumns = [
        {
            headerName: t('position'),
            field: 'position',
            width: 160
        },
        {
            headerName: t('image'),
            field: 'avatarUrl',
            width: 160,
            renderCell: (params) => <Avatar src={params.value || userImage} alt="userImage" />
        },
        {
            headerName: t('userID'),
            field: 'uid',
            width: 280,
        },
        {
            headerName: t('username'),
            field: 'username',
            width: 200,
        },
        {
            headerName: t('points'),
            field: 'points',
            width: 160
        },
        {
            headerName: t('award'),
            field: "info",
            width: 160,
            valueGetter: (params) => params.row.award? params.row.award.name : null,
            renderCell: (params) => <>
                {
                    params.row.award &&
                    <IconButton onClick={() => setShowAwards(params.row.award)} size="large"> <EmojiEventsIcon />
                    </IconButton>
                }
            </>
        },
        {
            headerName: t('winDate'),
            field: 'timestamp',
            width: 280,
            valueGetter: ({ row, field }) => {
                if (!row.award) return "";
                else if (row.award.timezone) return formatWinDateAward(row, row.award.timezone)
                else return formatWinDateAward(row)
            },
        },
    ];

    const infos = [
        {
            isDate: true,
            field: 'startDate',
            label: t('startDate'),
        }, {
            isDate: true,
            field: 'endDate',
            label: t('endDate'),
        }, {
            findValueOptions: usefulValues,
            field: 'value',
            label: t('value'),
        },
        {
            field: 'repeatNum',
            label: t('repetitions'),
        },
        {
            field: 'awardsLimitForGroup',
            label: t('awardLimit'),
        },
        {
            findValueOptions: rankingFilter,
            field: 'filter',
            label: t('filter'),
        },
        rank.filter !== null && rank.filter === 0 && {
            findValueOptions: sessionType,
            field: 'filterValue',
            label: t('valueFilter'),
        },
        rank.filter !== null && rank.filter === 1 && {
            field: 'filterValue',
            label: t('valueFilter'),
        },
        rank.filter !== null && rank.filter === 2 && {
            findValueOptions: Object.values(genderCode).map(c => ({ ...c, name: t(c.value) })),
            field: 'filterValue',
            label: t('gender'),
        },
        {
            field: 'awardRankingLimit',
            label: t('rankingAwardLimit'),
        }
    ]

    if (showAwards) return <AchievementAward goBack={() => setShowAwards(null)} overrideAwards={[showAwards]} />

    const assignPoints = () => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        post(`${RANKINGS}/${rankId}/assign-awards`,)
            .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries(queryClientKey));
    }

    return (
        <Grid container justifyContent="space-between">
            {goBack && <Grid container justifyContent="space-between">
                <Button onClick={goBack} className={classes.backButton}>
                    <ArrowBackIcon className={classes.backButtonIcon} />
                    {t('goBack')}
                </Button>
            </Grid>}
            {rank.startDate &&
                <>
                    <Grid xs={12}>
                        <div className={classes.title}>{rank.title}</div>
                    </Grid>
                    <Grid item xs={12}>
                        <InfoGrid infos={infos} data={rank}>
                            {<ConfirmButton
                                onConfirm={assignPoints}
                                title={t('confirmAssignPointsRank')}
                                text={t('confirmAssignPointsRankText')}
                                buttonProps={{
                                    title: t('assignPointsRank'),
                                    startIcon: <Savings />,
                                    disabled: rank.awardsAssigned
                                }}
                            />}
                        </InfoGrid>
                    </Grid>
                </>
            }

            <NTMXGrid
                key={"ranking-info-table"}
                columns={defaultColumns}
                loading={status === "loading"}
                rows={rank.rankingPositions || []}
                getRowId={(row) => rank && row.position}
            />
        </Grid>
    );
}
