import { useHistory, useParams } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import { ButtonGroup, Paper, Link } from "@mui/material";
import { TextItem } from "../../components/TextItem";
import Button from "@mui/material/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MapSession from "../../components/mapview/MapSession";
import { get, getErrorMessage, put, SESSIONS, USERS } from "../../services/Client";
import React, { useEffect, useState } from "react";
import RenderCell from "../../components/cellRender/RenderCell";
import RenderBoolean from "../../components/cellRender/RenderBoolean";
import NTMXGrid, { numberFormatter, timestampFormatter } from "../../components/NTMXGrid";
import StartIconButton from "../../components/buttons/StartIconButton";
import ReplayIcon from '@mui/icons-material/Replay';
import PartialModal from "../../components/modals/PartialModal";
import { useSnackbar } from "notistack";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useGetSessionInfo } from "../../services/ContentManager";
import dayjs from "dayjs";
import { partialType, useGetPartialTypeName } from "../../constants/partialType";
import FilterListIcon from "@mui/icons-material/FilterList";
import ListIcon from "@mui/icons-material/List";
import RoomIcon from '@mui/icons-material/Room';
import EditRoad from '@mui/icons-material/EditRoad';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import refundStatus from "../../constants/refundStatus";
import { useTranslation } from "react-i18next";
import useGetRefundStatus from "../../constants/refundStatus";
import { Helmet } from "react-helmet";
import { useQueryClient } from "react-query";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OldMapSession from "../../components/mapview/OldMapSession";
import { LegTable } from "./LegTable";
import PartialTable from "./PartialTable";
import { useMemo } from "react";
import { roundUp } from "services/helper";
import { Box, IconButton, Tooltip } from "../../../node_modules/@mui/material/index";
import { SettingsTooltip } from "components/forms/CustomTooltip";
import { Edit } from "../../../node_modules/@mui/icons-material/index";
import CreateSessionModal from "components/modals/CreateSessionModal";
import { genericSaveSession } from "components/tables/SessionTable";

const useStyles = makeStyles(theme => ({
    page: {
        padding: theme.spacing(2)
    },
    container: {
        marginBottom: theme.spacing(2)
    },
    mapContainer: {
        width: "100%",
        height: "60vh",
    },
    box: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        borderBottom: `${theme.palette.secondary.main} 1px solid`,
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        flexGrow: 1,
    },
    backButton: {
        color: theme.palette.primary.main,
    },
    backButtonIcon: {
        margin: theme.spacing(1)
    },
    button: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    }
}));

export default function SessionInfo() {

    let classes = useStyles();
    let { id } = useParams();
    let history = useHistory();
    const { t } = useTranslation();
    let { session, status } = useGetSessionInfo(id);
    let [values, setValues] = useState();
    let [homePoints, setHomePoints] = useState();
    let [workPoints, setWorkPoints] = useState();
    let [username, setUsername] = useState();
    let [isChangingParameters, setIsChangingParameters] = useState(false);
    let [polylineType, setPolylineType] = useState("polyline");
    let [isShowingPartialPoints, setIsShowingPartialPoints] = useState(true);
    let [isCalculatingPath, setIsCalculatingPath] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const refundStatus = useGetRefundStatus()
    const queryClient = useQueryClient()
    let [isShowingOrganizationArea, setIsShowingOrganizationArea] = useState(false)
    let [isEditingSession, setIsEditingSession] = useState(false)

    useEffect(() => {
        if (session) session.sessionPoints = session.sessionPoints || []
        setValues(session)
        session && get(`users/${session.uid}/home-addresses`, { params: { activeAt: session.endTime } }).then(r => {
            let newValues = [];
            r.map(ha => newValues.push({ lat: ha.latitude, lng: ha.longitude, tolerance: ha.tolerance * 1000, organization: ha.organizationName }))
            setHomePoints(newValues)
        })
        session && get(`users/${session.uid}/work-addresses`, { params: { activeAt: session.endTime } }).then(r => {
            let newValues = [];
            r.map(wa => newValues.push({ lat: wa.latitude, lng: wa.longitude, tolerance: wa.tolerance * 1000 }))
            setWorkPoints(newValues)
        })
        session && get(USERS, { elem: session.uid }).then(u => setUsername(u.username))
    }, [session])

    let gyroDistance = values && values.gyroDistance ? values.gyroDistance + " Km" : "";
    let gpsDistance = values && values.gpsDistance ? values.gpsDistance + " Km" : "";
    let gmapsDistance = values && values.gmapsDistance ? values.gmapsDistance + " Km" : "";

    const reloadPartials = (parameters) => {
        let newParameters = { ...parameters }
        newParameters.partialQty = parameters.partialQty / 100;
        newParameters.partialDeviation = parameters.partialDeviation / 100;
        enqueueSnackbar(t('reloading...'), { variant: "info" });
        put(SESSIONS, { params: newParameters, elem: id + "/validate" })
            .then(({ data }) => {
                setValues(data);
                enqueueSnackbar(t('saved'), { variant: "success" });
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
    }

    const calculatePath = () => {
        setIsCalculatingPath(true)
        get(`${SESSIONS}/${id}/here-path`)
            .then(() => queryClient.invalidateQueries([SESSIONS, { id }]))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: 'error' }))
            .finally(() => {
                setIsCalculatingPath(false)
            });
    }

    const getDateWithTimezone = (timestamp, timezone) => {
        const date = dayjs.tz(new Date(timestamp), timezone).format("HH:mm:ss DD/MM/YYYY")
        if (timezone) return `${date}  (${timezone})`
        else return date
    }

    const revalidateUsingSameValues = async () => {
        enqueueSnackbar("revalidating...", { variant: "info" });

        await get(`${SESSIONS}/${id}/revalidateUsingSameValues`)
            .then(() => enqueueSnackbar(t('revalidated'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }));
    }

    const saveSession = (values) => {
        return genericSaveSession(values, [SESSIONS, { id: id }], enqueueSnackbar, queryClient, t);
    }

    return (
        <>
            <Paper className={classes.page}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Dettaglio Sessione | Pin Bike</title>
                    <meta name="description" content="Dettaglio sessione effettuata tramite app Pin Bike" />
                </Helmet>

                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Button onClick={() => history.replace("/sessions")} className={classes.backButton}>
                            <ArrowBackIcon className={classes.backButtonIcon} />
                            {t('goBack')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Box display="flex">
                            <Tooltip title="Esegue la rivalidazione della sessione andando ad attribuire punti ed incentivi alle sessioni casa-lavoro">
                                <IconButton variant="outlined" color="primary" onClick={revalidateUsingSameValues}>
                                    <PublishedWithChangesIcon />
                                </IconButton>
                            </Tooltip>
                            <StartIconButton title={t('changeParameters')} onClick={() => setIsChangingParameters(true)} startIcon={<ReplayIcon />} />
                            {/* <StartIconButton title={t('edit')} onClick={() => setIsEditingSession(session)} startIcon={<Edit />} /> */}
                        </Box>
                    </Grid>
                </Grid>

                <Grid container alignItems={"flex-end"} className={classes.container}>
                    <TextItem value={values && values.id} label={t('sessionID')} />
                    <TextItem value={values && <Link href={"/profile/" + values.uid} target="_blank" underline='none'>{username}</Link>} label={t('username')} />
                    <TextItem value={values && getDateWithTimezone(values.startTime, values.timezone)} label={t('startDate')} />
                    <TextItem value={values && getDateWithTimezone(values.endTime, values.timezone)} label={t('endDate')} />
                    <TextItem value={gyroDistance} label={t('gyroDistance')} />
                    <TextItem value={gpsDistance} label={t('gpsDistance')} />
                    <TextItem value={gmapsDistance} label={t('googleMapsDistance')} />
                    <TextItem value={values && <FiberManualRecordIcon style={{ color: values.valid ? "#43ff00" : "#ff0000" }} />} label={t('valid')} />
                    <TextItem value={values && <FiberManualRecordIcon style={{ color: values.homeWorkPath ? "#43ff00" : "#ff0000" }} />} label={t('homeWorkSchoolPath')} />
                    <TextItem value={values && <FiberManualRecordIcon style={{ color: values.certificated ? "#43ff00" : "#ff0000" }} />} label={t('certificated')} />
                </Grid>
                <Grid container alignItems={"center"} className={classes.container}>
                    {
                        values && values.sessionPoints && values.sessionPoints.map(sp => <>
                            <Grid item xs={12} md={3} style={{ display: "flex" }}>
                                <TextItem value={`${(sp.distance || 0).toFixed(2)} km`} label={`${t('distance')} - ${sp.organizationTitle}`} />
                            </Grid>
                            <Grid item xs={12} md={3} style={{ display: "flex" }}>
                                <TextItem value={roundUp(sp.points || 0, 2)} label={`${t('points')} - ${sp.organizationTitle}`} />
                            </Grid>
                            <Grid item xs={12} md={3} style={{ display: "flex" }}>
                                <TextItem value={`${(sp.euro || 0).toFixed(2)} ${t('currency')}`} label={`${t('euro')} - ${sp.organizationTitle}`} />
                            </Grid>
                            <Grid item xs={12} md={3} style={{ display: "flex" }}>
                                <TextItem value={refundStatus.find(rs => rs.id === sp.refundStatus) ? refundStatus.find(rs => rs.id === sp.refundStatus).name : "-"} label={t('refundStatus')} />
                            </Grid>
                        </>)
                    }
                </Grid>

                <Grid container justifyContent="center">
                    {values && values.partials &&
                        <Grid container md={4} justifyContent={"center"}>
                            <StartIconButton title={isShowingPartialPoints ? t('hidePartials') : t('showPartials')}
                                onClick={() => setIsShowingPartialPoints(old => !old)}
                                startIcon={<RoomIcon />} />
                        </Grid>
                    }

                    {session && session.sessionPoints && session.sessionPoints.length > 0 &&
                        <Grid container md={4} justifyContent={"center"}>
                            <StartIconButton title={isShowingOrganizationArea ? t('hideInitiativeArea') : t('showInitiativeArea')}
                                onClick={() => setIsShowingOrganizationArea(prevState => !prevState)}
                                startIcon={isShowingOrganizationArea ? <VisibilityOff /> : <Visibility />} />
                        </Grid>
                    }

                    <Grid container md={4} justifyContent={"center"}>
                        <StartIconButton title={t('esteemRouteByRoad')} onClick={calculatePath}
                            startIcon={<EditRoad />} disabled={isCalculatingPath} />
                    </Grid>

                    <Grid container md={8} justifyContent={"center"}>
                        <ButtonGroup color="secondary" aria-label="outlined secondary button group" className={classes.button}>
                            <Button variant="outlined" color={polylineType === "polyline" ? "secondary" : "primary"}
                                onClick={() => setPolylineType("polyline")}>
                                {t('pathViewedByUser')}
                            </Button>
                            <Button variant="outlined" color={polylineType === "rawPolyline" ? "secondary" : "primary"}
                                onClick={() => setPolylineType("rawPolyline")}>
                                {t('directConnection')}
                            </Button>
                            <Button variant="outlined" color={polylineType === "gmapsPolyline" ? "secondary" : "primary"}
                                onClick={() => setPolylineType("gmapsPolyline")}>
                                {t('googleMapsPath')}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>

                <Grid container className={classes.mapContainer}>
                    {
                        values &&
                        <MapSession
                            polylines={{ polyline: values.polyline, rowPolyline: values.rowPolyline, gmapsPolyline: values.gmapsPolyline }}
                            zoom={15} points={(values.partials || []).filter(p => p.type < partialType.SESSION || p.type === partialType.SKIPPED)}
                            polylineType={polylineType} isShowingPartialPoints={isShowingPartialPoints}
                            homePoints={homePoints} workPoints={workPoints}
                            sessionPointsForArea={isShowingOrganizationArea ? session.sessionPoints : []}
                            version={values.version}
                            legs={values.legs}
                        />


                    }
                </Grid>

                {
                    (values?.version === 2 || values?.legs !== null) && <LegTable session={session} loading={status === "loading"}></LegTable>
                }
                {
                    values && <PartialTable session={session} loading={status === "loading"} showLegs={values?.version === 2 || values?.legs !== null}></PartialTable>
                }

                {values && <PartialModal open={!!isChangingParameters} onClose={() => setIsChangingParameters(false)} onSubmit={reloadPartials} />}

            </Paper>
            {
                (!!isEditingSession) &&
                <CreateSessionModal
                    open={!!isEditingSession}
                    onClose={() => {
                        setIsEditingSession(false)
                    }}
                    onSubmit={saveSession}
                    session={isEditingSession}
                    type={"edit"}
                />
            }
        </>
    );
}
