import {DEFAULT_FIELD, FORM_TYPE} from "../constants/FormBuilder";
import TextInput from "./forms/TextInput";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {
    Button, Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Radio,
    RadioGroup, Switch,
    Typography
} from "@mui/material";
import useGlobalStyles from "../services/useGlobalStyles";
import {TextItem} from "./TextItem";
import AutocompleteAddress from "./AutocompleteAddress";
import {EditCoordinates} from "./modals/EditCoordinatesModal";
import {isEmpty, unixToString} from "../services/helper";
import {useGetErrorMessages} from "../constants/errorMessages";
import PhonePrefixesSelect from "./VendorComponents/PhonePrefixesSelect";
import NTMImageInput from "./NTMImageInput";
import {validateForm} from "../services/ValidationFormManager";
import NTMSelect from "./NTMSelect";
import {generateValidations, getAddressComponents} from "../services/FormBuilderManager";
import CircularLoading from "./CircularLoading";
import {SettingsTooltip} from "./forms/CustomTooltip";
import ResponsiveCircularLoading from "./ResponsiveCircularLoading";

/**
 * genera un form in base ai campi in input
 * @param fields {[{key,label,type,disabled,required?,validation?,customErrorMessage?,params?,customValue?,customElement?,customFields?,xs?,md?,hidden?}]}se un campo è nascosto non viene validato
 * @param formData {{}}
 * @param setData funzione per modificare l formData se il componente è controllato
 * @param formError se non è presente l'onSubmit la gestione degli errori deve essere gestita dall'esterno
 * @param getId {({})=>{id}} funzione per selezionare l'id dell' elemento data
 * @param onSubmit se settato mostra il pulsante di salva, prima di salvare viene effettuato il controllo sui campi
 * @param onError eseguito se c'è un errore del form
 * @constructor
 */
export default function FormBuilder({
                                        fields = [],
                                        formData = {},
                                        setFormData,
                                        formError,
                                        getId = (data) => data.id,
                                        onSubmit = null,
                                        onError = null,
                                        widthAdjust,
                                        widthAdjustMd,
                                    }) {
    const {t} = useTranslation();
    const globalClasses = useGlobalStyles();
    const errorMessages = useGetErrorMessages();

    let [data, setData] = useState(formData);
    if (setFormData) {
        data = formData;
        setData = setFormData;
    }
    const [errors, setErrors] = useState({});
    const [loadingCoordinates, setLoadingCoordinates] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [placesService] = useState(new window.google.maps.places.PlacesService(document.createElement('div')));
    //
    // useEffect(() => {
    //     setData(formData);
    // }

    useEffect(() => {
        setErrors(formError);
    }, [formError]);

    const handleOnChange = (text, field) => {
        setData((data) => ({...data, [field]: text}));
    };

    function onAddressInputChange(input, key) {
        //handleOnChange(null,'address');//azzera l'indirizzo nel metre viene scritto per evitare che si salvi senza aver selezionato un indirizzo trovato da google
    }

    function onAddressChange(address, placeId, key, callBack) {
        setErrors((error) => ({...error, address: null}));
        handleOnChange(address, key, callBack);//quando viene selezionato un address specifico allora lo salvo nello stato
        if (isEmpty(address)) {
            setErrors((error) => ({...error, key: errorMessages.MANDATORY}));
            setLoadingCoordinates(false);
            return;
        }
        searchCoordinates(placeId, key, callBack);
    }

    function searchCoordinates(placeId, key, callBack) {
        setLoadingCoordinates(true);
        let sessionToken = new window.google.maps.places.AutocompleteSessionToken();

        placesService.getDetails({placeId, sessionToken}, (place) => {
            if (!place) {
                setErrors((error) => ({...error, [key]: t('internalServerError')}));
                return;
            }
            let addressComponents = getAddressComponents(place);
            setData((data) => ({
                ...data,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                number: addressComponents('street_number'),
                city: addressComponents('locality') || addressComponents('political'),
                address: addressComponents('route') /*+ ' ,' + addressComponents('country')*/

            }));
            callBack({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()})
            setLoadingCoordinates(false);
        });
    }

    async function onDeleteImageSingle(image, index, field, callBack) {
        if (await callBack(image, index)) {
            setData((data) => ({...data, [field]: null}));
        }
    }

    async function onAddImageSingle(image, field, callBack) {
        if (await callBack(image)) {
            setData(data => ({...data, [field]: image}));
        }
    }

    function onDeleteImage(image, index, field, callBack) {
        if (callBack(image, index)) {
            setData(data => {
                let newData = {...data};
                newData[field] = (newData[field] ?? []).filter((img, i) => i !== index);
                return newData;
            });
        }
    }

    function onAddImage(image, field, callBack) {
        if (callBack(image)) {
            setData(data => {
                let newData = {...data};
                newData[field] = [...newData[field] ?? []];
                newData[field].push(image);
                return newData;
            });
        }
    }

    const handleOnChangeCheckbox = (e, category, field, onChange) => {
        setData(data => {
            let newData = {...data};
            newData[field] = [...(newData[field] ?? [])];
            //se selezionato aggiungi la categoria alla lista
            if (e.target.checked) {
                //se giá esiste non fare nulla
                if (!newData[field].find((s) => s === category.id))
                    newData[field].push(category.id);
            } else
                //se non selezionato rimuovi dalla lista
                newData[field] = newData[field].filter((s) => s !== category.id);
            onChange && onChange(newData[field])
            return newData;
        });
    };

    function addElementMultiselect(value, key) {
        setData((data) => ({...data, [key]: [...(data[key] || []), value]}));
    }

    function removeElementMultiselect(value, key) {
        setData((data) => {
            let newData = {...data};
            newData[key] = (newData[key] || []).filter(v => v !== value);
            return newData;
        });
    }

    function save() {
        setIsLoading(() => true);
        let newData = {...data};

        let validations = [];
        generateValidations(fields, validations, DEFAULT_FIELD);

        //--------controlli----------------------
        let [errors, valid] = validateForm(newData, validations);
        setErrors(errors);

        if (!valid) {
            setIsLoading(() => false);
            onError && onError(newData, errors, valid);
            return;
        }
        //-----------

        onSubmit(newData, () => setIsLoading(false));
    }

    function mapElements(field) {
        if (field.hidden || !field)
            return;
        field = {...DEFAULT_FIELD, ...field};
        switch (field.type) {
            default:
            case FORM_TYPE.textInput:
                return (
                    <Grid key={field.key} item xs={field.xs} md={field.md}
                          className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                        <SettingsTooltip title={field.tooltip} showTooltip={!!field.tooltip}>
                            <div>
                                <TextInput
                                    label={typeof field.label === 'function' ? field.label(data) : field.label}
                                    type={"text"}
                                    color={'primary'}
                                    value={field.customValue ? field.customValue(data) : data[field.key] || ''}
                                    onTextChange={(text) => !field.disabled && handleOnChange(field.editValueBeforeChange(text), field.key)}
                                    error={errors && errors[field.key]}
                                    required={field.required}
                                    disabled={field.disabled}
                                    {...field.params}
                                />
                            </div>
                        </SettingsTooltip>
                    </Grid>
                );
            case FORM_TYPE.textItem:
                return (
                    <Grid key={field.key} item xs={field.xs} md={field.md}
                          className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                        <TextItem value={field.customValue ? field.customValue(data) : data[field.key] || ''}
                                  className={globalClasses.textItem}
                                  label={typeof field.label === 'function' ? field.label(data) : field.label}
                                  xs={12}
                                  color={'light'}
                                  {...field.params}
                        />
                    </Grid>
                );
            case FORM_TYPE.addressAutocompleteWithMap:
            case FORM_TYPE.addressAutocomplete:
                //value = {addressInput, latitude,longitude,city,number,address}
                //viene mostrato addressInput, latitude,longitude usati per la mappa gli altri vengono impostati quando si fa una ricerca
                return (
                    <Grid key={field.key} item xs={field.xs} md={field.md}>
                        <Grid container direction={'column'}>
                            {/*--- indirizzo --*/}
                            <Grid item xs={12}
                                  className={!field.noGlobalClasses && clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                                <AutocompleteAddress
                                    label={typeof field.label === 'function' ? field.label(data) : field.label}
                                    value={data[field.key] || null}
                                    error={errors && errors[field.key]}
                                    disabled={field.disabled}
                                    onAddressInputChange={(address) => !field.disabled && onAddressInputChange(address, field.key)}
                                    onAddressSelect={(address, placeId) => !field.disabled && onAddressChange(address, placeId, field.key, field.onCoordinatesFetched)}
                                />
                            </Grid>
                            {
                                field.type === FORM_TYPE.addressAutocompleteWithMap &&
                                <Grid item xs={12}
                                      className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                                    <EditCoordinates
                                        defaultLat={data.latitude}
                                        defaultLng={data.longitude}
                                        radius={field.radius}
                                        onSubmit={(lat, lng) => {
                                            setData((data) => ({
                                                    ...data,
                                                    latitude: lat,
                                                    longitude: lng
                                                })
                                            );
                                        }}
                                        loading={loadingCoordinates}
                                        disabled={field.disabled}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                );
            case FORM_TYPE.phone:
                return (
                    <Grid key={field.key} item xs={field.xs} md={field.md}
                          className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                        <Grid container
                              direction="row"
                              alignItems="flex-start">
                            <Grid item xs={4}>
                                <PhonePrefixesSelect
                                    prefix={data.phonePrefix}
                                    onChange={(text) => !field.disabled && handleOnChange(text, 'phonePrefix')}
                                    disabled={field.disabled}
                                    error={errors && errors[field.key]}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextInput
                                    label={typeof field.label === 'function' ? field.label(data) : field.label}
                                    type="text"
                                    color={'primary'}
                                    value={field.customValue ? field.customValue(data) : data[field.key] || ''}
                                    onTextChange={(text) => !field.disabled && handleOnChange(text, field.key)}
                                    error={!!errors && errors[field.key]}
                                    required={field.required}
                                    disabled={field.disabled}
                                    {...field.params}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            case FORM_TYPE.customElement:
                return typeof field.customElement === 'function' ? field.customElement(data, setData) : field.customElement;
            case FORM_TYPE.Images:
            case FORM_TYPE.singleImage:
                return (
                    <NTMImageInput
                        key={field.key}
                        title={t(field.key)}
                        images={data[field.key]}
                        isSingleImage={field.type === FORM_TYPE.singleImage}
                        prefix={getId(data)}
                        folder={field.params.folder || '/iamges'}
                        onDeleteImage={(image, index) => {
                            !field.disabled && (field.type === FORM_TYPE.singleImage ? onDeleteImageSingle : onDeleteImage)(image, index, field.key, field.params.onDeleteImage)
                        }}
                        onAddImage={(image) => {
                            !field.disabled && (field.type === FORM_TYPE.singleImage ? onAddImageSingle : onAddImage)(image, field.key, field.params.onAddImage)
                        }}
                        error={errors && errors[field.key]}
                        disabled={field.disabled}
                        widthAdjust={widthAdjust}
                        widthAdjustMd={widthAdjustMd}
                        imageRatioLabel={field.params.imageRatioLabel}
                        imageDimensionLabel={field.params.imageDimensionLabel}
                    />
                );
            case FORM_TYPE.radioButtonSwitchContainer:
                return (
                    <>
                        <Grid key={field.key} item xs={field.xs} md={field.md}
                              className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                            <Grid container>
                                <Grid item xs={12} className={globalClasses.marginBottom}>
                                    <FormControl component="fieldset" className={globalClasses.formControl}>
                                        <RadioGroup value={data[field.key] ? '1' : '0'}
                                                    onChange={(e) => !field.disabled && handleOnChange(e.target.value === '1', field.key)}>
                                            <FormControlLabel value={'1'} control={<Radio disabled={field.disabled}/>}
                                                              label={typeof field.params.labelTrue === 'function' ? field.params.labelTrue(data) : field.params.labelTrue}/>
                                            <FormControlLabel value={'0'} control={<Radio disabled={field.disabled}/>}
                                                              label={typeof field.params.labelFalse === 'function' ? field.params.labelFalse(data) : field.params.labelFalse}/>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        field.params.isInnerElementVisible(data[field.key]) &&
                                        (
                                            <Grid container>
                                                {field.customFields.map(mapElements)}
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                );
            case FORM_TYPE.switchContainer:
                return (
                    <>
                        <Grid key={field.key} item xs={field.xs} md={field.md}
                              className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                            <Grid item xs={12} className={globalClasses.marginBottom}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={data[field.key]}
                                            onChange={(e) => handleOnChange(e.target.checked, field.key)}
                                            color="primary"
                                        />
                                    }
                                    label={typeof field.label === 'function' ? field.label(data) : field.label}
                                />
                            </Grid>
                            {
                                field.params.isInnerElementVisible(data[field.key]) &&
                                <Grid item xs={12}>
                                    {
                                        field.params.isInnerElementVisible(data[field.key]) &&
                                        (
                                            <Grid container>
                                                {field.customFields.map(mapElements)}
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            }
                        </Grid>
                    </>
                );
            case FORM_TYPE.categories:
                return (
                    <>
                        <Grid key={field.key} item xs={field.xs} md={field.md}
                              className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                            <FormControl className={globalClasses.formControl}>
                                <FormLabel component="legend"><Typography
                                    variant={'h6'}
                                    className={errors && errors[field.key] ? globalClasses.error : ''}>{typeof field.label === 'function' ? field.label(data) : field.label}{field.required ? '*' : ''}</Typography></FormLabel>
                                <Typography variant={'caption'}>{t('canSelectMultipleTags')}</Typography>
                                {
                                    field.loading ? <ResponsiveCircularLoading/> :
                                        <FormGroup>
                                            {
                                                field.params.categories.map(category => {
                                                        return <FormControlLabel
                                                            key={category.id}
                                                            control={
                                                                <Checkbox
                                                                    checked={!!(data[field.key] || []).find(c => c === category.id)}
                                                                    onChange={(e) => !field.disabled && handleOnChangeCheckbox(e, category, field.key, field.onChange)}
                                                                    name={t(category.translationKey)}
                                                                    disabled={field.disabled}
                                                                />
                                                            }
                                                            label={t(category.translationKey)}
                                                        />
                                                    }
                                                )
                                            }
                                        </FormGroup>
                                }
                            </FormControl>
                            {errors && errors.categories &&
                                <Typography variant={'caption'}
                                            className={globalClasses.error}>{errors && errors[field.key]}</Typography>}
                        </Grid>
                    </>
                )
            case FORM_TYPE.select:
            case FORM_TYPE.multiSelect:
                return (
                    <Grid key={field.key} item xs={field.xs} md={field.md}
                          className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                        <NTMSelect
                            color={'primary'}
                            label={typeof field.label === 'function' ? field.label(data) : field.label}
                            error={errors && errors[field.key]}
                            items={field.params.items}
                            onChange={(value) =>
                                !field.disabled && (
                                    field.type === FORM_TYPE.select ?
                                        handleOnChange(value, field.key)
                                        :
                                        addElementMultiselect(value, field.key)
                                )
                            }
                            value={
                                data[field.key] || (field.type === FORM_TYPE.select ? '' : [])
                            }
                            onDelete={() =>
                                !field.disabled &&
                                (
                                    field.type === FORM_TYPE.select ?
                                        handleOnChange(null, field.key)
                                        :
                                        removeElementMultiselect(field.key)
                                )

                            }
                            disabled={field.disabled}
                            required
                        />
                    </Grid>
                );
            case FORM_TYPE.dateInput:
                return (
                    <Grid key={field.key} item xs={field.xs} md={field.md}
                          className={clsx(globalClasses.marginBottom, globalClasses.paddingMdUp)}>
                        <TextInput
                            label={typeof field.label === 'function' ? field.label(data) : field.label}
                            type="date"
                            color={'primary'}
                            value={unixToString(field.customValue ? field.customValue(data) : data[field.key])}
                            onTextChange={(text) => !field.disabled && handleOnChange(text, field.key)}
                            error={errors && errors[field.key]}
                            required={field.required}
                            disabled={field.disabled}
                            {...field.params}
                        />
                    </Grid>
                );
            case FORM_TYPE.button:
                return (
                    <Grid key={field.key} container
                          justifyContent={'center'}
                          direction={'row'}
                          className={globalClasses.marginBottom}
                    >
                        <Grid item xs={12} md={3}>
                            <Typography align={'center'}>
                                {
                                    typeof field.label === 'function' ? field.label(data) : field.label
                                }
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => field.params.onclick(data, setData)}
                                {...field.params(data, setData)}
                            >
                                {field.customElement(data)}
                            </Button>
                        </Grid>
                    </Grid>
                );
        }
    }

    let components = fields.map(mapElements);

    return (
        <Grid container
              direction="row"
              alignItems="flex-start"
        >
            {
                components
            }

            {
                onSubmit &&
                <Grid container
                      justifyContent={'center'}
                      direction={'row'}
                >
                    <Grid item xs={12} md={3}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={save}
                            disabled={isLoading}
                        >
                            {t('save')}
                        </Button>
                    </Grid>
                </Grid>
            }
            {
                isLoading && <CircularLoading/>
            }
        </Grid>
    );
}
