import BaseModal from "./BaseModal";
import makeStyles from '@mui/styles/makeStyles';
import TextInput from "../forms/TextInput";
import Grid from "@mui/material/Grid";
import React,{useEffect,useState} from "react";
import {Tooltip} from "@mui/material";
import {useGetPartialParameters} from "../../services/ContentManager";
import {useTranslation} from "react-i18next";

const useStylesTooltip = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.primary.light,
    },
    tooltip: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.text.primary,
    },
}));

function CustomTooltip(props) {
    const classes = useStylesTooltip();

    return <Tooltip placement="top" classes={classes} {...props} disableInteractive/>;
}

export default function PartialModal({open, onClose, onSubmit}){

    const {t} = useTranslation();
    let {defaultParameters} = useGetPartialParameters();
    let [parameters, setParameters] = useState({});

    useEffect(() => {
        if(defaultParameters.length > 0) {
            setParameters({
                partialQty : defaultParameters[ 0 ] * 100,
                partialDeviation : defaultParameters[ 1 ] * 100,
                speedThreshold : defaultParameters[ 2 ]
            })
        }
    }, [defaultParameters])

    const onTextChange = (value, name) => {
        let newParameters = {...parameters};
        newParameters[name] = value.trim();
        setParameters(newParameters);
    }

    const save = () => {
        onSubmit(parameters)
        onClose()
    }

    return <BaseModal open={open} onClose={onClose} onSave={save}>
        <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1rem"}}>

            <Grid item xs={12} style={{padding: "16px"}}>
                <CustomTooltip title={t('partialPercentageValidToConsiderSessionValid')}>
                    <div>
                        <TextInput required label={t('partialPercentageValid')} value={parameters.partialQty}
                                   type="number"
                                   min={0} max={100} step={0.1}
                                   onTextChange={(value) => {onTextChange(value, "partialQty")}}/>
                    </div>
                </CustomTooltip>
            </Grid>
            <Grid item xs={12} style={{padding: "16px"}}>
                <CustomTooltip title={t('percentageDifferenceAllowedBetweenCalculatedGPSDistanceAndDistanceDetectedByGyroscope')}>
                    <div>
                        <TextInput required label={t('differencePercentageDistance')} value={parameters.partialDeviation}
                                   type="number"
                                   min={0} max={100} step={0.1}
                                   onTextChange={(value) => {onTextChange(value, "partialDeviation")}}/>
                    </div>
                </CustomTooltip>
            </Grid>
            <Grid item xs={12} style={{padding: "16px"}}>
                <CustomTooltip title={t('speedThresholdToConsiderPartialAsMadeInCar')}>
                    <div>
                        <TextInput required label={t('speedThreshold')} value={parameters.speedThreshold}
                                   type="number"
                                   min={0} max={150} step={0.1}
                                   onTextChange={(value) => {onTextChange(value, "speedThreshold")}}/>
                    </div>
                </CustomTooltip>
            </Grid>

        </Grid>
    </BaseModal>
}