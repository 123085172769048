import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {firebaseAuth} from "../firebase";
import {PAGES} from "../constants/firebaseActionHandler";
import makeStyles from "@mui/styles/makeStyles";
import {Alert, AlertTitle, Grid, Paper, Typography} from "@mui/material";
import logo from "../images/splash.png";
import Link from "@mui/material/Link";
import {useTranslation} from "react-i18next";
import TextInput from "../components/forms/TextInput";
import StartIconButton from "../components/buttons/StartIconButton";
import useGlobalStyles from "../services/useGlobalStyles";
import CircularLoading from "../components/CircularLoading";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(8),
        minHeight: 'calc(100% - 64px)',
        minWidth: '100%'
    },
    logo: {
        height: '7rem',
    },
    paper: {
        height: '80vh',
        width: '80vw'
    },
    content: {
        padding: theme.spacing(3),
        minHeight: '80%',
        minWidth: '100%'
    },
    title: {
        padding: theme.spacing(3),
        fontWeight: 600
    },
    username: {
        color: theme.palette.primary.main
    }
}));

export default function FirebaseActionHandler() {
    let classes = useStyles();
    let globalClasses = useGlobalStyles();
    const params = new URLSearchParams(window.location.search);
    const mode = params.get("mode");
    const oobCode = params.get("oobCode");
    const continueUrl = params.get("continueUrl");
    let [pageToShow, setPageToShow] = useState(PAGES.loading);
    let [email, setEmail] = useState('');
    let [password, setPassword] = useState('');
    let {t} = useTranslation();
    let history = useHistory();

    useEffect(() => {
        switch (mode) {
            case 'resetPassword':
                // Display reset password handler and UI.
                handleResetPassword(oobCode, continueUrl);
                break;
            case 'verifyEmail':
                // Display email verification handler and UI.
                handleVerifyEmail(oobCode, continueUrl);
                break;
            case 'verifyAndChangeEmail':
                // Display email verification handler and UI.
                handleVerifyEmail(oobCode, continueUrl);
                break;
            default:
                pageToShow = PAGES.error;
            // Error: invalid mode.
        }
    }, []);

    function handleResetPassword(actionCode, continueUrl) {
        // Localize the UI to the selected language as determined by the lang
        // parameter.

        // Verify the password reset code is valid.
        firebaseAuth.verifyPasswordResetCode(actionCode).then((email) => {
            setEmail(email);
            setPageToShow(PAGES.passwordChanging);
        }).catch((error) => {
            setPageToShow(PAGES.error);
        });
    }

    function confirmPasswordReset() {

        firebaseAuth.confirmPasswordReset(oobCode, password).then((resp) => {
            // Password reset has been confirmed and new password updated.
            setPageToShow(PAGES.passwordChanged);
        }).catch((error) => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            setPageToShow(PAGES.error);
        });
    }

    function handleVerifyEmail(actionCode, continueUrl) {
        // Localize the UI to the selected language as determined by the lang
        // parameter.
        // Try to apply the email verification code.
        firebaseAuth.applyActionCode(actionCode).then((resp) => {
            // Email address has been verified.
            if (continueUrl && continueUrl !== '') {
                window.location.href = continueUrl;
            } else {
                history.push('/' + PAGES.emailValidated);
            }
            //setPageToShow(PAGES.emailValidated);
        }).catch((error) => {
            // Code is invalid or expired. Ask the user to verify their email address
            // again.
            setPageToShow(PAGES.error);
        });
    }

    let content = <></>;
    switch (pageToShow) {
        // case PAGES.emailValidated:
        //     content = (<>
        //         <Grid container>
        //             <Grid item xs={12}>
        //                 <Typography variant={'h6'} align='center'>{t('emailValidatedDescription')} <Link
        //                     onClick={() => history.push('/login')}>{t('clickHere')}</Link> {t('toAccess')}
        //                 </Typography>
        //                 <Typography variant={'subtitle1'} align='center'>{t('emailValidatedAddShops')}
        //                 </Typography>
        //             </Grid>
        //
        //             <Grid item xs={12} sx={{marginBottom: "0.5rem", p: 3}}>
        //                 <Alert severity="warning">
        //                     <AlertTitle
        //                         sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>{t('warning')}</AlertTitle>
        //                     {t('emailConfirmedNewVendorAlert')}
        //                 </Alert>
        //             </Grid>
        //         </Grid>
        //     </>);
        //     break;
        case PAGES.passwordChanging:
            content = (<>
                <Grid className={globalClasses.marginBottom}>
                    <Typography variant={'h6'}>{t('for')} <b>{email}</b></Typography>
                </Grid>
                <Grid sx={{minWidth: '60%'}}>
                    <TextInput
                        label={t('newPassword')}
                        type={'password'}
                        showPasswordIcon={true}
                        value={password}
                        onTextChange={(text) => setPassword(text)}
                        color={'primary'}
                    />
                </Grid>
                <Grid>
                    <StartIconButton title={t('save')} onClick={confirmPasswordReset} size="large"/>
                </Grid>
            </>);
            break;
        case PAGES.passwordChanged:
            content = (<>
                <Grid>
                    <Typography variant={'h6'}>{t('passwordEditedDescription')}</Typography>
                </Grid>
                <Grid>
                    <StartIconButton title={t('login')} onClick={() => history.push('/login')} size="large"/>
                </Grid>
            </>);
            break;
        case PAGES.loading:
            content = (<>
                <Grid>
                    <CircularLoading/>
                </Grid>
            </>)
            break;
        default:
            content = (<>
                <Grid>
                    <Typography variant={'h6'}>{t('errorActionHandlerDescription')} {email}</Typography>
                </Grid>
            </>)
    }


    return (
        <Grid container justify="center" alignItems="center" className={classes.root} direction={'column'}>
            <Grid item xs/>
            <Paper sx={{width: '80vw', minHeight: '60vh'}} elevation={5}>
                <Grid container sx={{width: '100%', height: '100%'}} alignItems={'center'} justify={'center'}>
                    <Grid container justify="center" alignItems="center" className={classes.content} direction="column">

                        <img className={classes.logo} alt={"home"} src={logo}/>

                        <Grid item className={classes.title}>
                            <Typography variant={'h4'}>
                                {t(pageToShow)}
                            </Typography>
                        </Grid>

                        {content}

                    </Grid>
                </Grid>
            </Paper>
            <Grid item xs/>
        </Grid>
    );

}
