import makeStyles from "@mui/styles/makeStyles";

const useGlobalStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 'bold'
    }, marginBottom: {
        marginBottom: theme.spacing(2)
    }, textCenter: {
        textAlign: 'center'
    }, padding: {
        padding: theme.spacing(3)
    }, marginRight: {
        marginRight: theme.spacing(2)
    }, paddingMdUp: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3)
        },
    }, paddingSmall: {
        padding: theme.spacing(1)
    }, center: {
        position: 'fixed', top: 'calc(50vh - 20px)', left: 'calc(50vw - 20px)'
    }, bottomNavigation: {
        width: '100%', position: "fixed", bottom: 0, left: 0, zIndex: 100
    }, spacing: {
        width: '100%', height: '5em'
    }, backButton: {
        color: theme.palette.primary.main,
    }, backButtonIcon: {
        margin: theme.spacing(1)
    },
    marginBottomSmall: {
        marginBottom: theme.spacing(1)
    },
    textItem: {
        color: "white",
        borderColor: theme.palette.primary.light,
        height: "100%",
    },
    colorGreen: {
        '&:hover': {
            background: theme.palette.green.light
        },
        background: theme.palette.green.main
    },
    colorRed: {
        '&:hover': {
            background: theme.palette.primary.dark
        },
        background: theme.palette.primary.main
    },
    marginSubsError: {/*margine usato per riempire lo spazio dell'errore se non è presente */
        marginBottom: 'calc(1rem + 4px)'
    },
    formControl: {
        width: '100%',
        '& .MuiFormLabel-root': {
            color: theme.palette.text.primary
        }
    },
    error: {
        color: theme.palette.error.main
    }, title: {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
    },
}))

export default useGlobalStyles;