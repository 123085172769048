import {CircularProgress, Grid, IconButton, Typography} from "@mui/material";
import {Delete} from "@mui/icons-material";
import FileInput from "./forms/FileInput";
import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {removeImagesFirebase} from "../services/FirebaseManager";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH} from "../constants/images";
import ResponsiveCircularLoading from "./ResponsiveCircularLoading";
import {FilePond} from "react-filepond";
import {LoadingButton} from "@mui/lab";


/**
 * Mostra un slider con delle immagini dove e' inoltre possibile aggiungerne di nuove (verranno automaticamente caricate su firebase)
 * @param images lista di immagini, o immmagine singola(in questo caso settare single image a true)
 * @param title
 * @param prefix
 * @param folder
 * @param onDeleteImage(img,index) callback function quando viene eliminata un immagine, non aggiorna automaticamente la lista delle immagini
 * @param onAddImage(e) callback function quando viene aggiunta un immagine, non aggiorna automaticamente la lista delle immagini
 * @param isSingleImage
 * @param classes
 * @param className
 * @param error
 * @param disabled
 * @param widthAdjust
 * @param widthAdjustMd
 * @param imageRatioLabel
 * @param imageDimensionLabel
 * @returns {JSX.Element}
 * @constructor
 */
export default function NTMImageInput({
                                          images,
                                          title,
                                          prefix,
                                          folder,
                                          onDeleteImage,
                                          onAddImage,
                                          isSingleImage = false,
                                          classes = {},
                                          className,
                                          error = null,
                                          disabled = false,
                                          widthAdjust = '48px',
                                          widthAdjustMd = '177px',
                                          imageRatioLabel = null,
                                          imageDimensionLabel = null
                                      }) {
    const {t} = useTranslation();
    const useStyles = makeStyles((theme) => ({
        marginBottom: {
            marginBottom: theme.spacing(2)
        },
        paddingMdUp: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3)
            },
        },
        imageItem: {
            width: '10rem',
            height: '10rem',
            '& .filepond--panel.filepond--panel-root': {
                borderRadius: 0,
                border: '1px solid ' + (error ? theme.palette.error.main : theme.palette.secondary.dark)
            },
            '& .filepond--root, .filepond--root .filepond--drop-label': {
                width: '10rem',
                height: '10rem',
            },
            margin: [0, theme.spacing(2), theme.spacing(2), 0].join(' '),
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        slider: {
            overflowX: 'auto',
            //considera il padding per la dimensione
            width: `calc(100vw - ${widthAdjust})`,
            [theme.breakpoints.up('md')]: {
                width: `calc(100vw - ${widthAdjustMd})`,
            }
        },
        deleteButton: {
            // color:theme.palette.primary.contrastText,
            marginLeft: '7rem',
            marginTop: 0,
            backgroundColor: theme.palette.primary.light,
            borderRadius: 0,
            '&:hover': {
                opacity: 1,
                backgroundColor: "rgba(232,222,222,0.9)",
            }
        },
        error: {
            color: theme.palette.error.main
        },
        loading: {
            marginTop: 'calc(-9rem - 20px)'
        },
        ...classes //sovrascrivi le classi esistenti
    }));

    classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([])
    const [isDeleting, setIsDeleting] = useState([]);

    if (isSingleImage)
        images = images ? [images] : []//se l'immagine é singola la trasformo in un array

    let showFileInput = (!disabled && (!isSingleImage || images.length === 0))
    
    return (
        <Grid container direction={'row'} className={className}>
            <Grid item xs={12} className={clsx(classes.marginBottom, classes.paddingMdUp)}>
                <Typography variant={'h6'}>{title}</Typography>
            </Grid>
            <Grid item xs={12} className={clsx(classes.marginBottom, classes.paddingMdUp)}>
                <div className={classes.slider}>
                    <Grid container direction={'row'} wrap={'nowrap'} style={{width: 'max-content'}}>
                        {
                            showFileInput &&
                            <div className={classes.imageItem}>
                                {/*attivare il resize delle immagini*/}
                                <FileInput
                                    folder={folder}
                                    prefix={prefix}
                                    onRequestSave={onAddImage}
                                    onRequestStart={() => setIsLoading(true)}
                                    onRequestEnd={() => setIsLoading(false)}
                                    allowMultiple={!isSingleImage}
                                    maxFiles={isSingleImage ? 1 : 10}
                                    showLoadedImages={false}
                                    allowFileSizeValidation={true}
                                    maxFileSize={'2MB'}
                                    labelMaxFileSizeExceeded={t("FileSizeExceeded")}
                                    labelMaxFileSize={'{filesize} ' + t("maximumFileSize")}
                                    allowImageResize={false}
                                    imageResizeTargetWidth={MAX_IMAGE_WIDTH}
                                    imageResizeTargetHeight={MAX_IMAGE_HEIGHT}
                                />
                                {/*{*/}
                                {/*    (!isSingleImage && isLoading) &&*/}
                                {/*    <ResponsiveCircularLoading*/}
                                {/*        className={classes.loading}/>*/}
                                {/*}*/}
                            </div>
                        }
                        {
                            images && images.map(
                                (img, index) => (
                                    <Grid item
                                          key={index}
                                          style={{
                                              backgroundImage: 'url(' + img + ')',
                                          }}
                                          className={classes.imageItem}
                                    >
                                        {
                                            !disabled &&(
                                            isDeleting.includes(img) ?
                                                <IconButton
                                                    className={classes.deleteButton}
                                                    size="large">
                                                    <CircularProgress size={24} color={"secondary"}/>
                                                </IconButton>
                                                :
                                                <IconButton
                                                    className={classes.deleteButton}
                                                    onClick={() => {
                                                        onDeleteImage(img, index);
                                                        setIsDeleting((isDeleting) => [...isDeleting, img]);
                                                    }}
                                                    size="large">
                                                    <Delete/>
                                                </IconButton>
                                            )
                                        }
                                    </Grid>
                                )
                            )
                        }
                    </Grid>
                    {
                        showFileInput &&
                        <Typography
                            sx={{position: 'absolute', marginTop: '-15px'}}
                            variant={'caption'}
                        >
                            {t('maximumSize')}
                        </Typography>
                    }
                </div>
                {imageRatioLabel && (t('imageRatio') + ' ' + imageRatioLabel)} {imageDimensionLabel && (t('imageDimensions') + ' ' + imageDimensionLabel)}
                {error && <Typography variant={'caption'} className={classes.error}>{error}</Typography>}
            </Grid>
        </Grid>
    );
}