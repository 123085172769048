import { Box, Button, ButtonGroup, Grid, IconButton, Tooltip } from "@mui/material";

export default function MultiButton({ buttons, value, disabled }) {
    return (
        <Box sx={theme => ({
            '& .MuiButtonGroup-root': {
                [[theme.breakpoints.down('md')]]: {
                    flexWrap: 'wrap',
                    width: '100%'
                }
            }
        })}>
            <ButtonGroup sx={{ p: 2, }}>
                {buttons.map(b => <Tooltip title={b.tooltip} disableInteractive open={b.tooltip ? undefined : false}>
                    <Button key={b.value} disabled={disabled} variant={b.value === value ? 'contained' : 'outlined'} onClick={b.onClick} > {b.icon} </Button>
                </Tooltip>
                )}
            </ButtonGroup>
        </Box>
    )
}