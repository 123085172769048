import React, {useContext, useState} from "react";
import {useGetArticles} from "../../../services/ContentManager";
import {ARTICLES, deleteElem, getErrorMessage, put} from "../../../services/Client";
import {UserContext} from "../../App";
import CircularLoading from "../../../components/CircularLoading";
import {isAdmin as getIsAdmin} from "../../../services/PermissionManager";
import {useQueryClient} from "react-query";
import {useSnackbar} from "notistack";
import NTMXGrid, {timestampFormatter} from "../../../components/NTMXGrid";
import {IconButton, Tooltip} from "@mui/material";
import RenderBoolean from "../../../components/cellRender/RenderBoolean";
import DeleteIcon from "@mui/icons-material/Delete";
import {ARTICLE_ROUTE} from "../../../constants/vendors";
import {Link, useHistory} from "react-router-dom";
import {responsiveWidth} from "../../../services/useBreakpoint";
import {Add, Edit, ListAlt, Visibility} from "@mui/icons-material";
import {Grid} from "@mui/material";
import StartIconButton from "../../../components/buttons/StartIconButton";
import {useTranslation} from "react-i18next";
import {FILTERS, MULT_VALUE_TO_POINT} from "../../../constants/articles";
import ArticleList from "../../../components/VendorComponents/ArticleList";

const storageKey = "articles-table-columns";

export default function Articles({uid, urlPrefix = ''}) {
    let queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();
    let history = useHistory();
    const {t} = useTranslation();

    let isAdmin = getIsAdmin(useContext(UserContext));
    let {articles, status} = useGetArticles(uid);

    if (status === 'loading')
        return <CircularLoading/>


    function editArticle(id) {
        history.push(urlPrefix + ARTICLE_ROUTE + (id || ''));
    }

    function articleHistory(id) {
        history.push(urlPrefix + ARTICLE_ROUTE + (id || '') + '/history');
    }

    const editVisible = (id, field, value, row) => {
        enqueueSnackbar(t('saving...'), {variant: "info"});
        put(`${ARTICLES}/${id}/updateVisible`, {body: {[field]: value, shopId: row.shopId}})
            .then(() => {
                enqueueSnackbar(t('saved'), {variant: "success"});
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([ARTICLES, {uid: uid}]));
    };

    return <ArticleList articles={articles} articleHistory={articleHistory}
                        editArticle={editArticle}
                        editVisible={editVisible} disabled={isAdmin}/>

}
