import {useGetProfileUserEnrollments} from "../../services/UserDataManager";
import {GridCellExpand} from "./renderCellExpand";
import {useTranslation} from "react-i18next";

export default function RenderUserEnrollments({enrollments, width}){

    const {t} = useTranslation();
    let values = [];
    let active = enrollments.filter(e => e.endDate > new Date())
    let expired = enrollments.filter(e => e.endDate < new Date())
    if(active.length !== 0) {
        active.forEach(e => values.push(e.organizationTitle))
        values.push(" ")
    }
    if(expired.length !== 0){
        values.push(t('expiredFemale') + ":")
        expired.forEach(e => values.push(e.organizationTitle))
    }

    return <GridCellExpand value={values.length !== 0 ? values.map(v => {
        return {showValue: v}
    }) : ""} width={width}/>

}

export function formatUserEnrollments(enrollments, t) {

    let value = "";
    let active = enrollments.filter(e => e.endDate > new Date())
    let expired = enrollments.filter(e => e.endDate < new Date())

    if(active.length !== 0) active.forEach(e => value += e.organizationTitle + ", ")

    if(expired.length !== 0){
        value += "\n" + t('expiredFemale') + ": "
        expired.forEach(e => value += e.organizationTitle + ", ")
    }

    return value

}
