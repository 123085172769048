import {useHistory, useParams} from "react-router-dom";
import {isAdmin as getIsAdmin} from "../../services/PermissionManager";
import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../App";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {useTranslation} from "react-i18next";
import {
    useGetCategories,
    useGetOrganization,
    useGetOrganizationOffices,
    useGetShops,
    useGetShopsByOrganization,
    useGetVendors
} from "../../services/ContentManager";
import CircularLoading from "../../components/CircularLoading";
import {get, OFFICES, ORGANIZATIONS, post, put, SHOPS, VENDORS} from "../../services/Client";
import {removeImagesFirebase} from "../../services/FirebaseManager";
import ShopData from "../../components/VendorComponents/ShopData";
import useGlobalStyles from "../../services/useGlobalStyles";
import {useGetErrorMessages} from "../../constants/errorMessages";
import {FORM_TYPE} from "../../constants/FormBuilder";
import {Button, Grid, Typography} from "@mui/material";
import {Album, ArrowBack} from "@mui/icons-material";
import {formatAddress, isEmpty, isValidPhone} from "../../services/helper";
import clsx from "clsx";
import FormBuilder from "../../components/FormBuilder";
import {DEFAULT_PREFIX} from "../../constants/vendors";

export default function Office({oid, sid, isFirstShop = false, goBack}) {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();
    const globalClasses = useGlobalStyles();
    const errorMessages = useGetErrorMessages();

    const {categories: allCategories, categoriesStatus} = useGetCategories();

    const [images, setImages] = useState({
        imagesToDelete: [],
        addedImages: []
    });


    const {offices, status} = useGetOrganizationOffices(oid);
    const [defOffice, setDefOffice] = useState(null);

    const [office, setOffice] = useState(null);
    useEffect(() => {
        if (status === 'success') {
            if (!sid) {
                //il primary Shop e' utilizzato come valore di default per gli Shop nuovi
                setDefOffice((offices ?? []).find(s => s.isPrimary === true));
            }
            //se non c'é un id allora é una new
            let newOffice = {};
            if (sid) {
                newOffice = (offices ?? []).find(s => s.id === sid);
            }

            if (!newOffice) {
                goBack();
            }
            const def = {
                images: [],
                categories: [],
                addressInput: formatAddress(newOffice)
            };//valori di default
            setOffice({...def, ...newOffice});
        }
    }, [status]);

    //attendi che siano caricati i vendors
    if (status === 'loading' || categoriesStatus === 'loading' || !office)
        return <CircularLoading/>

    function save(data, stopLoading) {
        let newShop = {...data, ...images};
        newShop.isPrimary = isFirstShop;
        if (newShop.isEcommerce) {
            newShop.address = null;
            newShop.number = null;
            newShop.city = null;
        }
        (newShop.id ? put : post)(ORGANIZATIONS + '/' + oid + '/' + OFFICES, {
            body: newShop,
            elem: newShop.id || ''
        })
            .then(() => {
                //rimuovi le immagini definitivamente da firebase
                removeImagesFirebase(newShop.imagesToDelete);
                queryClient.invalidateQueries([OFFICES, {oid: oid}]).then(() => {
                    stopLoading()
                    goBack();
                });
            })
            .catch(() => {
                enqueueSnackbar(t('errorSavingData'), {variant: "error"})
                stopLoading();
            });
    }

    async function onDeleteLogo(image, index) {
        let isDeletable = false
        try {
            isDeletable = await get(`${SHOPS}/verifyLogoBeforeDelete`, {
                params: {
                    logo: image,
                    updatingShopId: office.id
                }
            });
            if (isDeletable) {//se l'immagine che sto eliminando è uguale a quella del def Shop vuol dire che ho inserito i dati di default e quindi non devo eliminare la foto da firebase
                let newImages = {...images};
                newImages.imagesToDelete.push(image);//eliminare alla fine solo quando salva
            }
        } catch (e) {
            enqueueSnackbar(t('errorOccurred'), {variant: "error"});
        }
        return isDeletable;
    }


    function onAddLogo(image) {
        let newImages = {...images};
        newImages.addedImages.push(image);
        setImages(newImages);
        return true;
    }

    /**
     * imposta lo stato con i valori dello Shop di default
     * @param e
     */
    function handleCopyFromDefault(e) {
        //imposta data come defShop
        let newShop = {...defOffice};
        delete newShop.id;
        setOffice({
            ...office,
            ...newShop,
            primary: false,
            address: null,
            number: null,
            city: null,
            addressInput: null,
            longitude: null,
            latitude: null
        });
    }

    function onError() {
        enqueueSnackbar(errorMessages.ERROR_FORM, {variant: "error"});
    }

    const fields = [
        {
            key: 'goBack',
            type: FORM_TYPE.customElement,
            customElement: <>
                {
                    !isFirstShop &&
                    <Grid container justifyContent="space-between" className={globalClasses.marginBottom}>
                        <Button
                            onClick={(e) => {
                                removeImagesFirebase(images.addedImages);//rimuovi le immagini quando si va indietro
                                goBack(e);
                            }}
                            className={globalClasses.backButton}
                        >
                            <ArrowBack className={globalClasses.backButtonIcon}/>
                            {t('goBack')}
                        </Button>
                    </Grid>
                }
            </>
        },
        {
            key: 'firstShop',
            type: FORM_TYPE.customElement,
            customElement: <>
                {
                    isFirstShop &&
                    <Grid item xs={12} className={globalClasses.title}>
                        <Typography variant={'h5'}>{t('createFirstOffice')}</Typography>
                        <Typography
                            variant={'caption'}> {t('canBeUsedAsModelForNextOffices')}. <br/> {t('enteredDataCouldBeEdited')}
                        </Typography>
                    </Grid>
                }
            </>
        },
        {
            key: 'defaultValues',
            type: FORM_TYPE.customElement,
            customElement: <>
                {
                    defOffice &&
                    <Grid container justifyContent="space-between" className={globalClasses.marginBottom}>
                        <Grid item>
                            <Button
                                onClick={handleCopyFromDefault}
                                className={globalClasses.backButton}
                            >
                                <Album className={globalClasses.backButtonIcon}/>
                                {t('copyDataFromFirstShop')}
                            </Button>
                        </Grid>
                    </Grid>
                }
            </>
        },
        {
            key: 'name',
            label: t('officeName'),
            type: FORM_TYPE.textInput,
            params: {helperText: t('nameDisplayedToUsers')},
        },
        {
            key: 'description',
            label: t('description'),
            type: FORM_TYPE.textInput,
            required: false
        },
        {
            key: 'website',
            label: t('webAddress'),
            type: FORM_TYPE.textInput,
            required: false
        },
        {
            key: 'phone',
            label: t('phoneNumber'),
            type: FORM_TYPE.phone,
            validation: (data) => !isEmpty(data.phonePrefix) && (isEmpty(data.phone) ? errorMessages.MANDATORY : isValidPhone(data.phone)),
            customErrorMessage: errorMessages.INVALID_PHONE
        },
        {
            key: 'logo',
            label: t('logo'),
            type: FORM_TYPE.singleImage,
            params: {
                folder: 'shops/logos',
                onDeleteImage: onDeleteLogo,
                onAddImage: onAddLogo,
                imageRatioLabel: '1:1',
                imageDimensionLabel: '256x256'
            },
        },
        {
            key: 'addressInput',
            label: t('address'),
            type: FORM_TYPE.addressAutocompleteWithMap,
        },
        {
            key: 'spacer',
            type: FORM_TYPE.customElement,
            customElement: <Grid xs={0} md={6}/>
        },
        {
            key: 'mandatoryFields',
            type: FORM_TYPE.customElement,
            customElement: <Typography variant={'caption'}>{t('mandatoryFields')}</Typography>
        },
        {
            key: 'visible',
            label: (data) => data.visible ? t('officeVisible') : t('officeIsNotVisible'),
            type: FORM_TYPE.button,
            params: (data, setData) => ({
                onClick: () => setData({...data, visible: !data.visible}),
                className: data.visible ? globalClasses.colorGreen : globalClasses.colorRed
            }),
            customElement: (data) => data.visible ? t("visible") : t("hidden")
        }
    ];

    return (
        <FormBuilder fields={fields} onSubmit={save} onError={onError} formData={office}
                     setFormData={setOffice} widthAdjust={'112px'} widthAdjustMd={'241px'}/>
    );

}