import BaseModal from "./BaseModal";
import React,{useEffect,useState} from "react";
import Grid from "@mui/material/Grid";
import {TextInputContainer} from "../forms/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import SelectInputBoolean from "../forms/SelectInputBoolean";

export default function EditExpandedCellModal({open, onSubmit, onClose, defaultValues}){

    let [values, setValues] = useState([])

    useEffect(() => {
        setValues(JSON.parse(JSON.stringify(defaultValues)))
    }, [defaultValues, open]);

    const submit = () => {
        let returnValues = []
        values.filter((v,i) => v.value !== defaultValues[i].value).map(v => returnValues.push({id: v.id, value: v.value}))
        onSubmit(returnValues)
        onClose()
    }

    const onTextChange = (value, key) => {
        let newValues = [...values];
        newValues.find(v => v.key === key).value = value
        setValues(newValues);
    }

    return <BaseModal open={open} onClose={onClose} onSave={submit}>
        <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1rem"}}>
            {
                values && values.map(value => {
                                    
                                    if(value.type === "boolean") {
                                        return <Grid> 
                                            <SelectInputBoolean value={value.value} inputLabel={value.key} onChange={(newValue) => onTextChange(newValue,value.key)}/>
                                        </Grid>
                                    }
                                    
                                    return <TextInputContainer xs={12} label={value.key} value={value.value}
                                                   type={value.type || "string"}
                                                   startAdornment={value.startAdornment && <InputAdornment position="start">{value.startAdornment}</InputAdornment>}
                                                   onTextChange={(newValue) => {onTextChange(newValue, value.key)}}/>

                })
            }
            <div style={{height: "1rem", width: "15rem"}}/>
        </Grid>
    </BaseModal>

}