import {useTranslation} from "react-i18next";

export const partialType = {
    UNKNOWN: 0,
    START: 1,
    END: 2,
    IN_PROGRESS: 3,
    PAUSE: 4,
    RESUME: 5,
    SESSION: 6,
    SYSTEM: 7,
    SERVICE: 8,
    BLE: 9,
    GPS: 10,
    OTHER: 11,
    SKIPPED: 12,
}

export function useGetPartialTypeName () {
    const {t} = useTranslation();

    return [
        t('unknown'),
        t('start'),
        t('end'),
        t('inProgress'),
        t('pause'),
        t('resumption'),
        t('debugSession'),
        t('debugSystem'),
        t('debugService'),
        t('debugBLE'),
        t('debugGPS'),
        t('debugOther'),
        t('discarded'),
    ]
}