import { Grid, Pagination } from "@mui/material";
import { useState } from "react";

export default function ListPagination({ paginationSize = 12, elements, renderElement }) {

    const [pagination, setPagination] = useState(0);

    return (

        <Grid container>
            {
                elements
                    .sort((a, b) => b.primary ? 1 : b.id - a.id)
                    .slice((pagination * paginationSize), ((pagination + 1) * paginationSize))
                    .map(renderElement)
            }
            <Grid xs={12} marginTop={2}>
                <Pagination sx={{ width: '100%' }} page={pagination + 1} onChange={(event, value) => setPagination(value - 1)} count={Math.ceil(elements.length / paginationSize)} color="primary" />
            </Grid>
        </Grid>
    )
}