import {FILTERS, MULT_VALUE_TO_POINT} from "../../constants/articles";
import {responsiveWidth} from "../../services/useBreakpoint";
import {Link} from "react-router-dom";
import {ARTICLE_ROUTE} from "../../constants/vendors";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {Add, Edit, ListAlt} from "@mui/icons-material";
import RenderBoolean from "../cellRender/RenderBoolean";
import NTMXGrid, {timestampFormatter} from "../NTMXGrid";
import CircularLoading from "../CircularLoading";
import StartIconButton from "../buttons/StartIconButton";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export default function ArticleList({articles, editArticle, articleHistory, editVisible, disabled}) {
    const {t} = useTranslation();
    let [filter, setFilter] = useState(FILTERS.visible);

    function filterArticles(articles) {
        articles = [...articles].sort((a, b) => b.id - a.id);
        switch (filter) {
            case FILTERS.all:
                return (articles || []);
            case FILTERS.visible:
                return (articles || []).filter(a => a.visible);
            case FILTERS.hidden:
                return (articles || []).filter(a => !a.visible);
        }
    }

    const columns = [
        {
            headerName: t('id'),
            field: 'id',
            width: 50,
            //hide: true,
        },
        {
            headerName: t('edit'),
            field: 'edit',
            width: responsiveWidth({viewWidth: 7, minWidth: 100}),
            renderCell: (params) => <IconButton onClick={() => editArticle(params.id)}><Edit/></IconButton>
        },
        {
            headerName: t('status'),
            field: 'visible',
            width: responsiveWidth({viewWidth: 7, minWidth: 100}),
            renderCell: (params) => <Tooltip title={params.row.visible ? t('active') : t('notActive')}>
                <div><RenderBoolean saveEdit={(id, field, value, row) => editVisible(id, field, value, row)}
                                    params={params}/>
                </div>
            </Tooltip>
        }, {
            headerName: t('title'),
            field: 'title',
            width: responsiveWidth({viewWidth: 14, minWidth: 90}),
        },
        {
            headerName: t('description'),
            field: 'description',
            width: responsiveWidth({viewWidth: 15, minWidth: 150}),
        },
        {
            headerName: t('pointsValue'),
            field: 'points',
            width: responsiveWidth({viewWidth: 10, minWidth: 150}),
        },
        {
            headerName: t('equivalentEuro'),
            field: 'euro',
            width: responsiveWidth({viewWidth: 14, minWidth: 200}),
            renderCell: (params) => (params.row.points || 0) / MULT_VALUE_TO_POINT + t('currency')
        },
        {
            headerName: t('expireDate'),
            field: 'expirationDate',
            width: responsiveWidth({viewWidth: 15, minWidth: 150}),
            ...timestampFormatter
        },
        {
            headerName: t('rest'),
            field: 'numberArticles',
            width: responsiveWidth({viewWidth: 10, minWidth: 120}),
            renderCell: (params) => typeof params.row.numberArticles === 'number' ? (params.row.numberArticles - params.row.redeemNumber) : ''
        }, {
            headerName: t('history'),
            field: 'history',
            width: responsiveWidth({viewWidth: 7, minWidth: 100}),
            renderCell: (params) => <IconButton onClick={(e) => {
                e.stopPropagation();
                articleHistory(params.id)
            }}><ListAlt/></IconButton>
        }
    ];

    // if(!isAdmin) columns.push({
    //     headerName: 'Elimina',
    //     field: 'delete',
    //     width: responsiveWidth(7, 200),
    //     renderCell: (params) => <IconButton onClick={(e) => {
    //         e.stopPropagation();
    //         deleteArticle(params.id)
    //     }}><DeleteIcon/></IconButton>
    // })

    return (
        <NTMXGrid
            rightButton={
                <Grid container justifyContent={"flex-end"} direction={'column'}>
                    {
                        !disabled &&
                        <Grid container justifyContent={"flex-end"}>
                            <StartIconButton
                                onClick={() => editArticle(null)}
                                title={t("newAward")}
                                startIcon={<Add/>}/>
                        </Grid>
                    }
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={4} md={"auto"}>
                            <StartIconButton
                                onClick={() => setFilter(0)}
                                title={t('sowAllAward')} selected={filter === 0}/>
                        </Grid>
                        <Grid item xs={4} md={"auto"}>
                            <StartIconButton
                                onClick={() => setFilter(1)}
                                title={t('sowActiveAward')} selected={filter === 1}/>
                        </Grid>
                        <Grid item xs={4} md={"auto"}>
                            <StartIconButton
                                onClick={() => setFilter(2)}
                                title={t('sowInactiveAward')} selected={filter === 2}/>
                        </Grid>
                    </Grid>
                </Grid>
            }
            columns={columns}
            key={"vendor-article-tables"}
            rows={filterArticles(articles)}
            title={t('awardsCommunity')}
            getRowId={(row) => articles && row.id}
        />
    );
}
