import makeStyles from "@mui/styles/makeStyles";
import React,{useEffect,useState} from "react";
import HoverableButton from "../layout/HoverableButton";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import {Alert} from "@mui/material";
import BaseModal from "../modals/BaseModal";
import {TextInputContainer} from "../forms/TextInput";
import MenuItem from "@mui/material/MenuItem";
import GenderSelect from "../GenderSelect";
import {useTranslation} from "react-i18next";
import useGetSessionType from "../../constants/sessionType";


const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    }
}));

export default function RenderCellRankFilter({params, saveEdit, warningMessage, rankingFilter}) {

    const {t} = useTranslation();
    let classes = useStyles();
    let [modal, setModal] = useState(false);

    let [values, setValues] = useState({});
    let [error, setError] = useState({});

    const sessionType = useGetSessionType();

    useEffect(() => {
        if(modal) setValues({filter: params.row.filter, filterValue: params.row.filterValue})
    }, [modal]);

    const getMinAge = () => {
        return values.filterValue.split(" - ")[0] || ""
    }

    const getMaxAge = () => {
        return values.filterValue.split(" - ")[1] || ""
    }

    const onClose = () => {
        setModal(false);
    }

    const onSubmit = () => {
        if (values.filter !== null && (values.filter === 0 || values.filter === 2) && (values.filterValue === null || values.filterValue === undefined)) {
            setError({filterValue: t('mandatoryField')});
            return;
        }
        if (values.filter !== null && values.filter === 1) {
            if(getMinAge() === "") {
                setError({minAge : t('mandatoryField')});
                return;
            }
            if(parseInt(getMinAge()) < 1) {
                setError({minAge : t('invalidValue')});
                return;
            }
            if(getMaxAge() === "") {
                setError({maxAge : t('mandatoryField')});
                return;
            }
            if(parseInt(getMaxAge()) < parseInt(getMinAge())) {
                setError({maxAge : t('maxAgeLessThanMinAge')});
                return;
            }
        }
        saveEdit(params.row.id, values);
        onClose()
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};

        if(name === "filter") newValues["filterValue"] = "";

        if(name === "minAge") newValues["filterValue"] = value + " - " + getMaxAge();
        else if(name === "maxAge") newValues["filterValue"] = getMinAge() + " - " + value;
        else newValues[name] = value;

        setError({});
        setValues(newValues);
    }

    return <div>
        <HoverableButton
            item={{
                icon: <EditIcon/>,
                name: params.value || ""
            }}
            onClick={() => setModal(true)}
            classes={{root: classes.root}}
        />
        <BaseModal open={modal} onClose={onClose} onSave={onSubmit}>
            {warningMessage &&
                <Grid item xs={12} style={{marginBottom : "0.5rem",padding : "16px"}}>
                    <Alert variant="outlined" severity="warning">{warningMessage}</Alert>
                </Grid>
            }
            <Grid container>
                <TextInputContainer xs={12} label={t('filterCategory')} value={values.filter}
                                    options={rankingFilter} select
                                    onTextChange={(value) => {
                                        onTextChange(value, "filter")
                                    }}
                                    error={error.filter}>
                    <MenuItem key='' value=''>
                        <br/>
                    </MenuItem>
                    {rankingFilter.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
                </TextInputContainer>
                {
                    values.filter !== null && values.filter === 0 &&
                    <TextInputContainer xs={12} required label={t('valueFilter')} value={values.filterValue}
                                        options={sessionType} select
                                        onTextChange={(value) => {
                                            onTextChange(value, "filterValue")
                                        }}
                                        error={error.filterValue}>
                        {sessionType.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
                    </TextInputContainer>
                }
                {
                    values.filter !== null && values.filter === 1 &&
                    <>
                        <TextInputContainer xs={6} required label={t('minAge')} value={getMinAge()}
                                            type="number"
                                            min={1} step={1}
                                            onTextChange={(value) => {
                                                onTextChange(value, "minAge")
                                            }}
                                            error={error.minAge}/>

                        <TextInputContainer xs={6} required label={t('maxAge')} value={getMaxAge()}
                                            type="number"
                                            min={1} step={1}
                                            onTextChange={(value) => {
                                                onTextChange(value, "maxAge")
                                            }}
                                            error={error.maxAge}/>

                    </>
                }
                {
                    values.filter !== null && values.filter === 2 &&
                    <GenderSelect
                        xs={12} required value={values.filterValue}
                        error={error.filterValue}
                        onTextChange={(value) => {
                            onTextChange(value, "filterValue")
                        }}
                    />
                }
                <div style={{width: "15rem"}}/>
            </Grid>
        </BaseModal>
    </div>
}