import {
    useGetOrganizationCustomField,
    useGetOrganizations,
} from "../../services/ContentManager";
import {
    useGetProfileUserAchievements, useGetProfileUserAwards,
    useGetProfileUserEnrollments, useGetProfileUserMessages,
    useGetProfileUserSensors, useGetProfileUserSmartphones, useGetUserIntervalSessions
} from "../../services/UserDataManager";
import NTMXGrid, {
    timestampFormatter
} from "../../components/NTMXGrid";
import React, { useContext, useState } from "react";
import RenderBoolean from "../../components/cellRender/RenderBoolean";
import useGetSessionType from "../../constants/sessionType";
import RenderCell from "../../components/cellRender/RenderCell";
import { getErrorMessage, put, SENSORS, SESSIONS, USERS } from "../../services/Client";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import dayjs from "dayjs";
import SessionTable from "../../components/tables/SessionTable";
import { useTranslation } from "react-i18next";
import useGetUsefulValues from "../../constants/rankingUsefulValues";
import useGetRankingFilter from "../../constants/rankingFilter";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import StartIconButton from "../../components/buttons/StartIconButton";
import CreateSessionModal from "../../components/modals/CreateSessionModal";
import ShowEditSessionsStatus from "../../components/modals/ShowEditSessionsStatus";
import { IconButton } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import MessageInfo from "../Dashboard/Messages/MessageInfo";
import useGetCouponStatus, { COUPON_STATUS } from "../../constants/couponStatus";
import useGetCouponStatusTranslations from "../../constants/couponStatus";
import { UserContext } from "containers/App";
import { roles } from "services/ability";
import {useAwardType, useAwardTypeNames} from "../../constants/awardType";
import {checkRankingFilter} from "../../services/checkRankingFilter";

export function UserSessions({ uid }) {

    const { t } = useTranslation();
    const today = dayjs(new Date());//.format("YYYY-MM-DD");
    const loggedUser = useContext(UserContext);
    let [interval, setInterval] = useState({ start: today.subtract(14, 'day').format("YYYY-MM-DD"), end: today.format("YYYY-MM-DD") })
    let [isEditing, setIsEditing] = useState(false);
    let [showEditStatusModal, setShowEditStatusModal] = useState(false);
    let [sessionsStatus, setSessionsStatus] = useState({});
    const { sessions = [], status } = useGetUserIntervalSessions(uid, interval.start, interval.end)
    const queryKey = [SESSIONS, USERS, { uid }, { start: interval.start, end: interval.end }]
    let queryClient = useQueryClient();
    let [selectedRows, setSelectedRows] = useState([]);

    const saveSessions = (values) => {
        values.uid = uid

        let promiseExecution = async (sessions) => {
            for (let s of sessions) {
                try {
                    await put(`${SESSIONS}/${s}/revalidate`, { body: values });
                    setSessionsStatus(sessionsStatus => ({ ...sessionsStatus, [s]: "saved" }))
                } catch (e) {
                    setSessionsStatus(sessionsStatus => ({ ...sessionsStatus, [s]: getErrorMessage(e) }))
                }
            }
            await queryClient.invalidateQueries(queryKey)
        };
        promiseExecution(selectedRows).then(() => console.log("end"));
    }

    return <>
        <SessionTable sessions={sessions}
            loading={status === "loading"}
            interval={interval}
            setInterval={setInterval}
            queryKey={queryKey}
            checkboxSelection={loggedUser.userType !== roles.PINBIKER}
            selectionModel={selectedRows}
            onSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection);
            }}
            rightButton={loggedUser.userType !== roles.PINBIKER && <StartIconButton
                onClick={() => setIsEditing(true)} title={t('edit')}
                startIcon={<EditIcon />} disabled={selectedRows.length === 0}
            />}
        />
        {isEditing && <CreateSessionModal open={!!isEditing} type={"massiveEdit"}
            onSubmit={(values) => {
                saveSessions(values)
                setShowEditStatusModal(true)
            }}
            onClose={() => setIsEditing(false)}
            multipleSessions={sessions.filter(s => selectedRows.find(id => s.id === id))} />}
        <ShowEditSessionsStatus open={showEditStatusModal}
            onClose={() => {
                setShowEditStatusModal(false)
                setSessionsStatus([])
            }}
            values={sessionsStatus} />
    </>
}

export function UserSensors({ uid }) {

    const { t } = useTranslation();
    const { sensors = [], status } = useGetProfileUserSensors(uid)
    const { enqueueSnackbar } = useSnackbar();
    let queryClient = useQueryClient();

    const saveEditSensor = (id, field, value) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        put(USERS + "/" + uid + "/" + SENSORS, { body: { [field]: value }, elem: id })
            .then(() => enqueueSnackbar(t('saved'), { variant: "success" }))
            .catch(e => enqueueSnackbar(getErrorMessage(e), { variant: "error" }))
            .finally(() => queryClient.invalidateQueries([USERS, { user: uid }, SENSORS]));
    }

    const defaultColumns = [
        {
            headerName: t('name'),
            field: 'name',
            width: 310,
        },
        {
            headerName: t('bikeType'),
            field: 'bikeType',
            width: 250,
        },
        {
            headerName: t('startAssociation'),
            field: 'startAssociation',
            width: 250,
            ...timestampFormatter
        },
        {
            headerName: t('endAssociation'),
            field: 'endAssociation',
            width: 250,
            ...timestampFormatter
        },
        {
            headerName: t('wheelDiameter'),
            field: 'wheelDiameter',
            width: 250,
        },
        {
            headerName: t('firmwareVersion'),
            field: 'firmware',
            width: 250,
            //hide: true,
        },
        {
            headerName: t('hubCoefficient'),
            field: 'hubCoefficient',
            width: 250,
            renderCell: (params) => <RenderCell params={params} type="number" decimals={2} step={0.01}
                saveEdit={(id, field, newValue) => saveEditSensor(params.row.id, field, newValue)} />
        },
        {
            headerName: t('stolen'),
            field: 'stolen',
            width: 250,
            renderCell: (params) => <RenderBoolean params={params} />
        },
    ];

    return <NTMXGrid
        key={"user-sensors-table"}
        columns={defaultColumns}
        rows={sensors || []}
        title={t('sensors')}
        loading={status === "loading"}
        getRowId={(row) => sensors && row.id}
    />
}

export function UserEnrollments({ uid }) {

    const { t } = useTranslation();
    const { enrollments = [], status } = useGetProfileUserEnrollments(uid)
    let { organizations = [] } = useGetOrganizations()

    const defaultColumns = [
        {
            headerName: t('usedCode'),
            field: 'code',
            width: 250,
        },
        {
            headerName: t('activationDate'),
            field: 'activationDate',
            width: 250,
            ...timestampFormatter
        },
        {
            headerName: t('startDate'),
            field: 'startDate_date',
            width: 250,
            ...timestampFormatter
        },
        {
            headerName: t('endDate'),
            field: 'endDate_date',
            width: 250,
            ...timestampFormatter
        },
        {
            headerName: t('deletedAt'),
            field: 'deletedDate',
            width: 250,
            ...timestampFormatter
        },
        {
            headerName: t('organization'),
            field: 'organizationTitle',
            width: 250,
        },
        {
            headerName: t('distanceTraveled'),
            field: 'urbanKm',
            width: 250,
            renderCell: (params) => (params.value && params.value.toFixed(2) || 0) + " km"
        },
        {
            headerName: t('acquiredPoints'),
            field: 'points',
            width: 250,
        },
        {
            headerName: t('acquiredEuro'),
            field: 'euro',
            width: 250,
            renderCell: (params) => (params.value && params.value.toFixed(2) || 0) + ` ${t('currency')}`
        },
    ];

    return <NTMXGrid
        key={"user-enrollments-table"}
        columns={defaultColumns}
        rows={enrollments && organizations ? enrollments.filter(a => !!organizations.find(o => o.id === a.organization)) : []}
        loading={status === "loading"}
        title={t('initiatives')}
    />
}

export function UserAchievements({ uid }) {

    const { t } = useTranslation();
    let { achievements = [], status } = useGetProfileUserAchievements(uid)
    let { organizations = [] } = useGetOrganizations()
    const usefulValues = useGetUsefulValues()
    const sessionType = useGetSessionType()

    function OrganizationFilterValue({ params }) {
        let { customField = [] } = useGetOrganizationCustomField(params.row.organization);
        const achievementsFilter = useGetRankingFilter(customField);

        return (params.value !== null && params.value !== undefined && customField.length > 0)
            ? achievementsFilter.find(elem => checkRankingFilter(elem.id, params.row.filter, params.row.filterValue))?.name
            : "";
    }

    function NationalFilterValue({ params }) {
        const achievementsFilter = useGetRankingFilter();

        return (params.value !== null && params.value !== undefined) ? achievementsFilter.find(elem => elem.id === params.value).name : "";
    }

    achievements = achievements.slice().sort((a, b) => b.endDate - a.endDate)

    const defaultColumns = [
        {
            headerName: 'ID',
            field: 'ID',
            width: 200
        },
        {
            headerName: t('logo'),
            field: 'logo',
            width: 100,
            renderCell: ({ value }) => value ? <Avatar src={value} /> : "",
        },
        {
            headerName: t('name'),
            field: 'name',
            width: 200
        },
        {
            headerName: t('startDate'),
            field: 'startDate_date',
            width: 200,
            //hide: true,
            ...timestampFormatter
        },
        {
            headerName: t('endDate'),
            field: 'endDate_date',
            width: 200,
            //hide: true,
            ...timestampFormatter
        },
        {
            headerName: t('durationInDays'),
            field: 'duration',
            width: 200
        },
        {
            headerName: t('value'),
            field: 'value',
            width: 200,
            valueGetter: (params) => {
                return usefulValues.find(elem => elem.id === params.value).name
            }
        },
        {
            headerName: t('target'),
            field: 'target',
            width: 200,
        },
        {
            headerName: t('score'),
            field: 'score',
            width: 250,
        },
        {
            headerName: t('won'),
            field: 'fullfilled',
            type: "boolean",
            renderCell: (params) => <RenderBoolean params={params} />
        },
        {
            headerName: t('filter'),
            field: 'filter',
            width: 200,
            renderCell: (params) => {
                if (params.value) {
                    if (params.row.organization) return <OrganizationFilterValue params={params} />
                    else return <NationalFilterValue params={params} />
                }
                else return ""
            }
        },
        {
            headerName: t('valueFilter'),
            field: 'filterValue',
            width: 200,
            valueGetter: (params) => {
                if (params.row["filter"] !== null && params.row["filter"] !== undefined) {
                    if (params.row["filter"] === 0) {
                        return sessionType.find(st => st.id == params.value).name       //usa == e non ==== perchè uno dei due puo' essere una stringa e l'altro un numero
                    }
                    if (params.row["filter"] === 1) {
                        return params.value
                    }
                    if (params.row["filter"] === 2) {
                        return params.value === "M" ? t('male') : t('female');
                    }
                }
                return "";
            }
        },
        {
            headerName: t('organization'),
            field: 'organizationTitle',
            width: 250,
        }
    ];

    return <NTMXGrid
        key={"user-achievements-table"}
        columns={defaultColumns}
        rows={achievements && organizations ? achievements.filter(a => !!organizations.find(o => o.id === a.organization)) : []}
        loading={status === "loading"}
        title={t('achievements')}
        getRowId={(row) => achievements && row.id}
    />
}
export function UserSmartphones({ uid }) {

    const { t } = useTranslation();
    let { smartphones = [], status } = useGetProfileUserSmartphones(uid)

    const defaultColumns = [
        {
            headerName: t('model'),
            field: 'model',
            width: 310,
        },
        {
            headerName: t('platform'),
            field: 'platform',
            width: 250,
        },
        {
            headerName: t('appVersion'),
            field: 'appVersion',
            width: 250,
        },
        {
            headerName: t('startAssociation'),
            field: 'startAssociation',
            width: 250,
            ...timestampFormatter
        },
        {
            headerName: t('endAssociation'),
            field: 'endAssociation',
            width: 250,
            ...timestampFormatter
        }
    ];

    return <NTMXGrid
        key={"user-smartphones-table"}
        columns={defaultColumns}
        rows={[...smartphones].sort((a, b) => b.startAssociation - a.startAssociation) || []}
        title={t('smartphones')}
        loading={status === "loading"}
        getRowId={(row) => smartphones && row.id}
    />
}

export function UserAwards({ uid }) {

    const { awards = [], status } = useGetProfileUserAwards(uid)
    const { t } = useTranslation();
    const couponStatusTranslations = useGetCouponStatusTranslations();
    const awardTypes = useAwardType();

    const defaultColumns = [
        {
            headerName: t('name'),
            field: 'name',
            width: 200
        },
        {
            headerName: t('description'),
            field: 'description',
            width: 200
        },
        {
            headerName: t('value'),
            field: 'value',
            width: 100,
            valueGetter: (params) =>
                (params.row.couponStatus === COUPON_STATUS.COUPON_SKIPPED || params.row.couponStatus === COUPON_STATUS.COUPON_SKIPPED_USER_LIMIT) ?
                    0
                    :
                    params.row.value
        },
        {
            headerName: t('obtainingDate'),
            field: 'timestamp',
            width: 200,
            ...timestampFormatter
        },
        {
            headerName: t('organization'),
            field: 'organizationName',
            width: 200
        },
        {
            headerName: t('couponStatus'),
            field: 'couponStatus',
            width: 200,
            valueGetter: (params) => couponStatusTranslations[params.row.couponStatus] || couponStatusTranslations[COUPON_STATUS.COUPON_ASSIGNED]
        },
        {
            headerName: t('initialValue'),
            field: 'itialValue',
            width: 200,
            valueGetter: (params) => params.row.coupon?.initialValue || params.row.value
        },
        {
            headerName: t('awardType'),
            field: 'type',
            width: 200,
            valueGetter: params => {
                const awardTypeFound = awardTypes.find(type => type.id === params.row.type);
                return awardTypeFound ? awardTypeFound.name : "-";
            }
        }
    ];

    return <NTMXGrid
        key={"user-awards-table"}
        initialState={{
            sorting: {
                sortModel: [{ field: 'timestamp', sort: 'desc' }],
            },
        }}
        columns={defaultColumns}
        rows={awards}
        loading={status === "loading"}
        title={t('awards')}
        getRowId={row => row && row.id}
    />
}

export function UserMessages({ uid }) {

    const { messages = [], status } = useGetProfileUserMessages(uid)
    let [showInfo, setShowInfo] = useState()
    const { t } = useTranslation();

    const defaultColumns = [
        {
            headerName: t('sentDate'),
            field: 'createdDate',
            width: 180,
            ...timestampFormatter
        },
        {
            headerName: t('title'),
            field: 'title',
            width: 220
        },
        {
            headerName: t('body'),
            field: "body",
            width: 220
        },
        {
            headerName: t('recipients'),
            field: "",
            width: 130,
            renderCell: (params) => <IconButton onClick={() => setShowInfo(params.row.message)} size="large"><GroupIcon /></IconButton>
        }
    ];

    if (showInfo) return <MessageInfo goBack={() => setShowInfo(null)} messageId={showInfo} />

    return <NTMXGrid
        key={"user-messages-table"}
        initialState={{
            sorting: {
                sortModel: [{ field: 'timestamp', sort: 'desc' }],
            },
        }}
        columns={defaultColumns}
        rows={messages}
        loading={status === "loading"}
        title={t('messages')}
        getRowId={(row) => messages && row.message}
    />
}
