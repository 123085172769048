import { useTranslation } from "react-i18next";
import NTMXGrid, { numberFormatter, timestampFormatter } from "../../components/NTMXGrid";
import RenderCell from "../../components/cellRender/RenderCell";
import RenderBoolean from "../../components/cellRender/RenderBoolean";
import { partialType, useGetPartialTypeName } from "../../constants/partialType";
import { Grid } from "@mui/material";
import StartIconButton from "../../components/buttons/StartIconButton";
import { useState } from "react";
import { FiberManualRecord, FilterList, List } from "@mui/icons-material";
import { LEG_COLOR, useGetLegTypeTranslation } from "../../constants/legType";
import ColoredLabel from "../../components/ColoredLabel";

export default function PartialTable({ session, status, showLegs }) {
    const { t } = useTranslation();
    const partialTypeName = useGetPartialTypeName();
    const legTypeTranslation = useGetLegTypeTranslation();
    let [showDebug, setShowDebug] = useState(false);

    const partialStatus = [
        { id: 0, name: t('valid') },
        { id: 1, name: t('generalError') },
        { id: 2, name: t('inaccurateDistance') },
        { id: 3, name: t('invalidAverageSpeed') },
        { id: 7, name: t('speedPeakDetected') },
        { id: 8, name: t('certificated') }
    ];

    const defaultColumns = [
        showLegs && {
            headerName: t('legCounter'),
            field: 'legCounter',
            width: 100,
            headerAlign: 'left',
            align: "left",
            valueGetter: (params) => params.value || '-',
        },
        showLegs && {
            headerName: t('legType'),
            field: 'legType',
            width: 200,
            headerAlign: 'left',
            align: "left",
            valueGetter: (params) => legTypeTranslation[params.row.legType] || '-',
            renderCell: (params) => (
                <ColoredLabel
                    color={LEG_COLOR[params.row.legType]}
                    label={params.value}
                />
            ),
        },
        {
            headerName: t('time'),
            field: 'timestamp',
            width: 240,
            ...timestampFormatter
        },
        {
            headerName: t('latitude'),
            field: 'latitude',
            width: 280,
            type: "number",
            headerAlign: 'left',
            align: "left",
            renderCell: (params) => <RenderCell params={params} measureUnit="°" />,
            //hide: true,
        },
        {
            headerName: t('longitude'),
            field: 'longitude',
            width: 280,
            type: "number",
            headerAlign: 'left',
            align: "left",
            renderCell: (params) => <RenderCell params={params} measureUnit="°" />,
            //hide: true,
        },
        {
            headerName: t('altitude'),
            field: 'altitude',
            width: 280,
            type: "number",
            headerAlign: 'left',
            align: "left",
            renderCell: (params) => <RenderCell params={params} measureUnit="°" />,
            //hide: true,
        },
        {
            headerName: t('sensorDistance'),
            field: 'sensorDistance',
            width: 170,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number" decimals={4} />,
            ...numberFormatter
        },
        {
            headerName: t('gpsDistance'),
            field: 'gpsDistance',
            width: 170,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number" decimals={4} />,
            ...numberFormatter
        },
        {
            headerName: t('googleMapsDistance'),
            field: 'gmapsDistance',
            width: 170,
            renderCell: (params) => <RenderCell params={params} measureUnit="km" type="number" decimals={4} />,
            ...numberFormatter
            //hide: true,
        },
        {
            headerName: t('deltaRevolutions'),
            field: 'deltaRevs',
            width: 160,
            renderCell: (params) => <RenderCell params={params} type="number" />,
            ...numberFormatter
            //hide: true,
        },
        {
            headerName: t('urban'),
            field: 'urban',
            width: 150,
            renderCell: (params) => <RenderBoolean params={params} />,
            //hide: true,
        },
        {
            headerName: t('valid'),
            field: 'valid',
            width: 150,
            renderCell: (params) => <RenderBoolean params={params} />
        },
        {
            headerName: t('type'),
            field: 'type',
            width: 280,
            valueGetter: (params) => partialTypeName[params.value] || '-'
        },
        {
            headerName: t('status'),
            field: 'status',
            width: 280,
            valueGetter: (params) => {
                const status = partialStatus.find(s => s.id === params.value);
                return status ? status.name : '-';
            },
        },
        {
            headerName: t('extra'),
            field: 'extra',
            width: 280,
            //hide: true
        }
    ];

    const getFilteredPartials = () => {
        if (session && session.partials) {
            if (showDebug) return session.partials;
            return session.partials.filter(p => p.type < partialType.SESSION);
        }
        return [];
    }

    return (
        <>
            {session && session.partials && (
                <NTMXGrid
                    key="session-partials-table"
                    columns={defaultColumns.filter(Boolean)}
                    rows={getFilteredPartials().map((partial, index) => ({
                        ...partial,
                        id: `${partial.timestamp}-${partial.legCounter || ''}-${partial.type || ''}-${index}`
                    }))}
                    title={t('partials')}
                    loading={status === "loading"}
                    getRowId={(row) => row.id}
                    rightButton={
                        <Grid container justifyContent={"flex-end"}>
                            <StartIconButton
                                onClick={() => setShowDebug(!showDebug)}
                                title={showDebug ? t('hideDebug') : t('showDebug')}
                                startIcon={showDebug ? <FilterList /> : <List />}
                            />
                        </Grid>
                    }
                />
            )}
        </>
    );
}
