import React from "react";
import {Route, Switch, useHistory, useParams} from "react-router-dom";
import CircularLoading from "../../components/CircularLoading";
import {useGetVendors} from "../../services/ContentManager";
import {ARTICLE_ROUTE, SHOP_ROUTE} from "../../constants/vendors";
import Article from "./Article/Article";
import ArticleHistory from "./Article/ArticleHistory";
import VendorInfo from "./VendorInfo";
import Shop from "./Shop/Shop";

export default function VendorRoutesAdmin() {
    let history = useHistory();

    let {uid} = useParams();
    let {vendors: vendor, status} = useGetVendors(uid);

    //attendi che siano caricati i vendors
    if (status === 'loading' || !uid)
        return <CircularLoading/>

    if (!vendor)
        history.push('/404');

    let prefix = '/vendors/:uid'

    //usa la history per capire se viene selezionato uno Shop o un Insertion
    return (
        <Switch>
            <Route path={prefix + SHOP_ROUTE + ':id?'}>
                <Shop
                    uid={vendor.uid}
                />
            </Route>
            <Route path={prefix + ARTICLE_ROUTE + ':id?' + '/history'}>
                <ArticleHistory uid={vendor.uid} goBack={() => history.goBack()}/>
            </Route>
            <Route path={prefix + ARTICLE_ROUTE + ':id?'}>
                <Article
                    uid={vendor.uid}
                />
            </Route>
            <Route path={prefix}>
                <VendorInfo uid={vendor.uid}/>
            </Route>
        </Switch>
    );
}
