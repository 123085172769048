import { Grid, Typography } from "@mui/material";
import { TextItem } from "../TextItem";
import Box from "@mui/material/Box";
import RenderUserRedirect from "../cellRender/RenderUserRedirect";
import { unixToString } from "../../services/helper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ConfirmIconButton from "../buttons/ConfirmIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import StartIconButton from "../buttons/StartIconButton";
import RedoIcon from "@mui/icons-material/Redo";
import BaseModal from "./BaseModal";
import React from "react";
import { useTranslation } from "react-i18next";
import { FORM_TYPE } from "../../constants/FormBuilder";
import NTMSelect from "../NTMSelect";
import Link from "@mui/material/Link";
import HoverableButton from "../layout/HoverableButton";
import InfoIcon from "@mui/icons-material/Info";
import { markerType } from "../../constants/markerType";
import { MenuButton } from "../buttons/MenuButton";
import StatusIndicator from "../Statusindicator";
import EditIcon from '@mui/icons-material/Edit';
import { FIELD_TYPE, usePublicUtilityType, useReportType } from "../../constants/publicUtilityRecord";

export function PublicUtilityRecordModal({ open, onClose, record, onDelete, onEditStatus, disabled }) {

    const publicUtilityType = usePublicUtilityType();
    const { t } = useTranslation();
    const column = [
        {
            field: 'uid',
            type: FIELD_TYPE.creator,
        }, {
            field: 'createdAt',
            type: FIELD_TYPE.date,
            label: t('creationDate')
        }, {
            field: 'name',
            type: FIELD_TYPE.textField,
            label: t('name')
        }, {
            field: 'type',
            type: FIELD_TYPE.textField,
            label: t('type'),
            formatValue: value => publicUtilityType[value]
        }
    ];

    return <BaseRecordModal column={column} record={record} onDelete={onDelete} open={open} onClose={onClose}
        onEditStatus={onEditStatus} disabled={disabled} />
}

export function ReportModal({ open, onClose, record, onDelete, onEditStatus, disabled }) {

    const reportType = useReportType();
    const { t } = useTranslation();
    const column = [
        {
            field: 'createdBy',
            type: FIELD_TYPE.creator,
        }, {
            field: 'createdAt',
            type: FIELD_TYPE.date,
            label: t('creationDate')
        }, {
            field: 'notes',
            type: FIELD_TYPE.textField,
            label: t('notes')
        }, {
            field: 'type',
            type: FIELD_TYPE.textField,
            label: t('type'),
            formatValue: value => reportType[value]
        },
        {
            field: 'imageUrl',
            type: FIELD_TYPE.image,
            label: t('image')
        },
    ];

    return <BaseRecordModal column={column} record={record} onDelete={onDelete} open={open} onClose={onClose}
        onEditStatus={onEditStatus} disabled={disabled} />
}

export function BaseRecordModal({ open, onClose, record, column = [], onDelete, onEditStatus, disabled }) {
    const { t } = useTranslation();
    return (<BaseModal open={open} onClose={onClose}>
        <Grid container direction={'column'} spacing={2} alignItems="center" sx={{ width: '100%', mx: 0 }}>
            {
                column.map((c, k) => {
                    if (!record[c.field])
                        return <></>;
                    if (c.type === FIELD_TYPE.textField)
                        return <div key={k} style={{ width: '100%' }}>
                            <TextItem xs={12} md={12} color={'primary'}
                                label={c.label}
                                value={c.formatValue ? c.formatValue(record[c.field]) : record[c.field]} />
                        </div>
                    if (c.type === FIELD_TYPE.date)
                        return <div key={k} style={{ width: '100%' }}>
                            <TextItem xs={12} md={12} color={'primary'} label={c.label}
                                value={unixToString(record[c.field])} />
                        </div>
                    if (c.type === FIELD_TYPE.image)
                        return (
                            <Grid item key={k}>
                                <img style={{ maxWidth: '10em' }} src={record[c.field] || ''} alt="record image"
                                    width={'100%'} />
                            </Grid>
                        )
                    if (c.type === FIELD_TYPE.creator)
                        return <div key={k} style={{ width: '100%' }}>
                            <TextItem
                                xs={12}
                                md={12}
                                color={'primary'}
                                label={t('creator')}
                                value={
                                    <Box sx={{
                                        '& button': { color: 'secondary.light' },
                                        '& button:hover': { color: 'secondary.main' }
                                    }}>
                                        <RenderUserRedirect value={record[c.field]} url={record[c.field]}
                                            showUsername />
                                    </Box>
                                }
                            />
                        </div>
                    // if(c.type === FORM_TYPE.categories)
                    //     return <NTMSelect label={c.label} value={record[c.field]} items={c.categories} />
                })
            }

            {!disabled && <>
                <Grid item xs={12}>
                    <Grid container alignItems={'center'} sx={{ width: '100%' }}>
                        <Grid item xs={8}><StatusIndicator isActive={!record.deleted} /></Grid>
                        <Grid item xs={4}><ConfirmIconButton
                            onConfirm={() => {
                                onEditStatus({ ...record, deleted: !record.deleted });
                                onClose();
                            }}
                            title={t('status')}
                            text={t('requestStatusChange')}

                        >
                            <EditIcon />
                        </ConfirmIconButton></Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container alignItems={'center'}>
                        <Grid item xs={8}>{t('remove')}</Grid>
                        <Grid item xs={4}><ConfirmIconButton
                            onConfirm={() => {
                                onDelete(record);
                                onClose();
                            }}
                            title={t('confirmDeletion')}
                            text={t('requestDefinitiveEliminationFountain')}
                        >
                            <DeleteIcon />
                        </ConfirmIconButton></Grid>
                    </Grid>
                </Grid>
            </>}

        </Grid>
    </BaseModal>);
}