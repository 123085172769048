import BaseModal from "./BaseModal";
import {Typography} from "@mui/material";

export default function AlertModal({open, onClose, onSave, message}) {
    const save = () => {
        onSave();
        onClose();
    }
    return (
        <BaseModal onSave={save} open={open} onClose={onClose}>
            <Typography variant={'h6'}>{message}</Typography>
        </BaseModal>
    )
}