import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {isAdmin as getIsAdmin} from "../../../services/PermissionManager";
import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../App";
import {useGetArticles, useGetShops} from "../../../services/ContentManager";
import {isEmpty, unixToString} from "../../../services/helper";
import CircularLoading from "../../../components/CircularLoading";
import {ARTICLES, get, post, put} from "../../../services/Client";
import {
    Alert,
    AlertTitle,
    Button,
    FormControl,
    FormControlLabel, FormGroup,
    FormLabel,
    Grid,
    Paper, Radio,
    RadioGroup, Switch
} from "@mui/material";
import useGlobalStyles from "../../../services/useGlobalStyles";
import {FORM_TYPE} from "../../../constants/FormBuilder";
import {Album, ArrowBack} from "@mui/icons-material";
import SelectArticleModal from "../../../components/modals/SelectArticleModal";
import FormBuilder from "../../../components/FormBuilder";
import {AD_TYPE, MULT_VALUE_TO_POINT} from "../../../constants/articles";
import {removeImagesFirebase} from "../../../services/FirebaseManager";
import dayjs from "dayjs";
import {useGetErrorMessages} from "../../../constants/errorMessages";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {Box} from "@mui/system";
import TextInput from "../../../components/forms/TextInput";
import {DEFAULT_PREFIX} from "../../../constants/vendors";
import NTMSelect from "../../../components/NTMSelect";
import {PHONE_PREFIXES} from "../../../constants/phonePrefixes";
import AutocompleteAddress from "../../../components/AutocompleteAddress";
import { toNumber } from "../../../services/helper";
import { ArticleAdvertisement } from "./ArticleAdvertisement";

export default function Article({uid}) {
    const globalClasses = useGlobalStyles();
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();
    let history = useHistory();
    const {t} = useTranslation();
    const errorMessage = useGetErrorMessages();
    let isAdmin = getIsAdmin(useContext(UserContext));
    let {id} = useParams();

    let {articles, status} = useGetArticles(uid);
    let {shops, statusShops} = useGetShops(uid);
    const [openCopyModal, setOpenCopyModal] = useState(false);
    const [images, setImages] = useState({
        imagesToDelete: [],
        addedImages: []
    });
    const [adErrors, setAdErrors ] = useState({});

    const def = {//valori di default
        isExpire: false,
        isNumbered: false,
        numberArticles: null,
        visible: true,
        expirationDate: new dayjs().valueOf(),
        isAdvertisementActive: false,
        advertisementType: AD_TYPE.distance,
        advertisementValue: 0
    };
    const [article, setArticle] = useState(null);
    useEffect(() => {
        if (status === 'success') {
            let newArticle = {}
            if (id) {
                newArticle = articles.find(a => a.id + '' === id);
                if (!newArticle)
                    history.goBack();
                newArticle.isExpire = !!newArticle.expirationDate;//indica se l'articolo ha una data di scadenza, non viene salvato il booleano su db
                newArticle.isNumbered = !!newArticle.numberArticles; //indica se l'articolo ha un numero massimo di elementi
                if (!newArticle.expirationDate) newArticle.expirationDate = new dayjs().valueOf();

                if (newArticle.advertisementType === AD_TYPE.country)
                    newArticle.advertisementValueCountry = newArticle.advertisementValue

                if (newArticle.advertisementType === AD_TYPE.distance)
                    newArticle.advertisementValueDistance = newArticle.advertisementValue
            }
            setArticle({...def, ...newArticle});
        }
    }, [status]);

    //attendi che siano caricati
    if (status === 'loading' || statusShops === 'loading' || !article)
        return <CircularLoading/>

    function save(values, stopLoading) {
        if (!values.isExpire) {
            values.expirationDate = null;
        } else {
            values.expirationDate = dayjs(values.expirationDate).add(dayjs.duration({'days': 1})).valueOf()//todo:nel salvataggio le date si modificano
        }
        if (!values.isNumbered) {
            values.numberArticles = null;
        }

        if (values.isAdvertisementActive) {
            if (toNumber(values.advertisementType) === AD_TYPE.country)
            {
                if(isEmpty( values.advertisementValueCountry)){
                    setAdErrors({country: errorMessage.MANDATORY});
                    stopLoading();
                    return;
                }
                values.advertisementValue = values.advertisementValueCountry;
            }

            if (toNumber(values.advertisementType) === AD_TYPE.distance)
            {
                if(isEmpty( values.advertisementValueDistance)){
                    setAdErrors({distance: errorMessage.MANDATORY});
                    stopLoading();
                    return;
                }
                values.advertisementValue = values.advertisementValueDistance;
            }

        } else {
            values.advertisementValue = null;
            values.advertisementType = null;
        }

        //se e' nuovo allora non avra' un id quindi sara' una post
        (values.id ? put : post)(ARTICLES, {
            body: values,
            elem: values.id || ''
        })
            .then((saved) => {
                stopLoading();
                //rimuovi le immagini definitivamente da firebase
                removeImagesFirebase(images.imagesToDelete);
                queryClient.invalidateQueries([ARTICLES, {uid: uid}]).then(() => history.goBack());
                enqueueSnackbar(t('prizeSaved'), {variant: 'success'});
            })
            .catch(() => {
                stopLoading();
                enqueueSnackbar(t('errorSavingData'), {variant: "error"})
            });
    }

    function onError() {
        enqueueSnackbar(t('errorSavingData'), {variant: "error"})
    }

    function handleCopyFromArticle() {
        setOpenCopyModal(true);
    }

    function copyArticle(article) {
        let newArticle = {...article}
        //cancella dati che generano conflitti
        delete newArticle.id;

        setArticle((article) => ({...article, ...newArticle}));
    }

    async function handleDeleteImage(image, index) {
        let isDeletable = false
        try {
            isDeletable = await get(`${ARTICLES}/verifyImageBeforeDelete`, {
                params: {
                    updatingArticleId: article.id,
                    image: image
                }
            })
            if (isDeletable) {//se l'immagine che sto eliminando è uguale a quella del def Shop vuol dire che ho inserito i dati di default e quindi non devo eliminare la foto da firebase
                let newImages = {...images};
                newImages.imagesToDelete.push(image);//eliminare alla fine solo quando salva
            }
            enqueueSnackbar(t('imageDeleted'), {variant: 'success'});
        } catch (e) {
            enqueueSnackbar(t('errorOccurred'), {variant: "error"});
        }
        return isDeletable;
    }

    const handleAddImage = (image) => {
        let newImages = {...images};
        newImages.addedImages.push(image);
        setImages(newImages);
        return true;
    }

    const goBack = async () => {
        await queryClient.invalidateQueries([ARTICLES, {uid: uid}]);
        history.goBack();
    }

    const fields = [
        {
            key: 'goBack',
            type: FORM_TYPE.customElement,
            customElement: <>
                <Grid container justifyContent="space-between" className={globalClasses.marginBottom}>
                    <Button
                        onClick={(e) => {
                            removeImagesFirebase(images.addedImages);//rimuovi le immagini quando si va indietro
                            goBack(e);
                        }}
                        className={globalClasses.backButton}
                    >
                        <ArrowBack className={globalClasses.backButtonIcon}/>
                        {t('goBack')}
                    </Button>
                </Grid>
            </>
        },
        {
            key: 'copyFromArticle',
            type: FORM_TYPE.customElement,
            customElement: <>
                {
                    (articles && articles.length > 0) &&
                    <Grid container justifyContent="space-between" className={globalClasses.marginBottom}>
                        <Grid item>
                            <Button
                                onClick={handleCopyFromArticle}
                                className={globalClasses.backButton}
                            >
                                <Album className={globalClasses.backButtonIcon}/>
                                {t('copyDataFromOtherArticle')}
                            </Button>
                        </Grid>
                    </Grid>
                }
            </>
        }, {
            key: 'title',
            label: t('title'),
            type: FORM_TYPE.textInput,
            disabled: isAdmin,
        },
        {
            key: 'description',
            label: t('description'),
            type: FORM_TYPE.textInput,
            disabled: isAdmin,
        },
        (
            article.id ?
                {
                    key: 'shopId',
                    label: t('shop'),
                    params: {
                        items: shops.map((s) => ({value: s.id, text: s.name}))
                    },
                    type: FORM_TYPE.select,
                    validation: data => (!data.id || !isEmpty(data.shopId)),
                    disabled: isAdmin,
                }
                :
                {
                    key: 'shops',
                    label: t('shops'),
                    params: {
                        items: shops.map((s) => ({value: s.id, text: s.name}))
                    },
                    type: FORM_TYPE.multiSelect,
                    validation: data => !!(data.id || (data.shops && data.shops.length !== 0)),
                    disabled: isAdmin,
                }
        ),
        {
            key: 'points',
            label: t('pointsValue'),
            type: FORM_TYPE.textInput,
            params: {
                type: "number"
            },
            disabled: isAdmin,
        },
        {
            key: 'spacer',
            type: FORM_TYPE.customElement,
            customElement: <Grid md={6} xs={0}/>,
        },
        {
            key: 'euro',
            label: t('nominalValueInEuro'),
            type: FORM_TYPE.textItem,
            customValue: (data) => (data.points || 0) / MULT_VALUE_TO_POINT + t('currency'),
            required: false,
            disabled: isAdmin,
        },
        {
            key: 'isExpire',
            type: FORM_TYPE.switchContainer,
            label: t('canExpire'),
            params: {
                isInnerElementVisible: (isExpire) => isExpire
            },
            disabled: isAdmin,
            customFields: [
                {
                    key: 'expirationDate',
                    label: t('expireDate'),
                    type: FORM_TYPE.dateInput,
                    params: {
                        min: unixToString(new Date()),
                    },
                    xs: 12,
                    md: 12,
                    validation: data => !(data.isExpire && isEmpty(data.expirationDate)),
                    disabled: isAdmin,
                },
            ]
        },
        {
            key: 'isNumbered',
            type: FORM_TYPE.switchContainer,
            label: t('hasALimit'),
            params: {
                isInnerElementVisible: (isNumbered) => isNumbered
            },
            disabled: isAdmin,
            customFields: [
                {
                    key: 'numberArticles',
                    label: t('numberOfElements'),
                    type: FORM_TYPE.textInput,
                    params: {
                        type: "number",
                        min: article.redeemNumber
                    },
                    xs: 12,
                    md: 12,
                    validation: data => {
                        if (data.isNumbered) {
                            if (isEmpty(data.numberArticles) || data.numberArticles < 0)
                                return errorMessage.MANDATORY;
                            if ((data.numberArticles || 0) - data.redeemNumber < 0)
                                return `${t('minimumNumberItemError')} ${data.redeemNumber}`;
                        }
                    },
                },
                {
                    key: 'rest',
                    label: t('rest'),
                    type: FORM_TYPE.textItem,
                    customValue: (data) => (data.numberArticles || 0) - data.redeemNumber,
                    required: false,
                },
            ]
        },
        {
            key: 'image',
            label: t('articleImage'),
            type: FORM_TYPE.singleImage,
            params: {
                folder: 'articles/images',
                onDeleteImage: handleDeleteImage,
                onAddImage: handleAddImage,
                imageRatioLabel: '1:1',
                imageDimensionLabel: '256px x 256px'
            },
            disabled: isAdmin,
        },
        {
            key: 'visible',
            label: (data) => data.visible ? t('awardIsVisible') : t('awardIsNotVisible'),
            type: FORM_TYPE.button,
            params: (data, setData) => ({
                onClick: () => setData({...data, visible: !data.visible}),
                className: data.visible ? globalClasses.colorGreen : globalClasses.colorRed
            }),
            customElement: (data) => data.visible ? t("activeAward") : t("notActiveAward")
        }
    ];

    return (
        <>
            <FormBuilder fields={fields} onSubmit={save} onError={onError} formData={article} setFormData={setArticle}/>
            <SelectArticleModal onSubmit={copyArticle} onClose={() => setOpenCopyModal(false)} open={openCopyModal}
                                articles={articles} currentArticleId={article.id}/>
        </>
    );
}