import {Grid, Paper, Typography} from "@mui/material";
import logo from "../../images/splash.png";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {useTranslation} from "react-i18next";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(8),
        minHeight: 'calc(100% - 64px)',
        minWidth: '100%'
    },
    logo: {
        height: '7rem',
    },
    paper: {
        height: '80vh',
        width: '80vw'
    },
    content: {
        padding: theme.spacing(3),
        minHeight: '80%',
        minWidth: '100%'
    },
    title: {
        padding: theme.spacing(3),
        fontWeight: 600
    },
    username: {
        color: theme.palette.primary.main
    }
}));

export default function HelpPage() {

    const classes = useStyles();
    const {t} = useTranslation();

    return (<Grid container justify="center" alignItems="center" className={classes.root} direction={'column'}>
        <Grid item xs/>
        <Paper className={classes.paper} elevation={5}>
            <Grid container justify="center" alignItems="center" className={classes.content} direction="column">

                <img className={classes.logo} alt={"home"} src={logo}/>

                <Grid item className={classes.title}>
                    <Typography variant={'h5'}>{t('sendEmailAt') + ' '}
                        <Link href={'mailto:support@pin.bike'}>support@pin.bike</Link>
                    </Typography>
                </Grid>

                <Grid>
                    <Typography variant={'h6'}>{t('helpYou')}</Typography>
                </Grid>

            </Grid>
        </Paper>
        <Grid item xs/>
    </Grid>)
}