import * as React from "react";
import firebase from "firebase/app";
import "firebase/storage";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import i18next from "../../i18n"
import {useTranslation} from "react-i18next";

// And import the necessary css
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./filepond.css";
import Box from "@mui/material/Box";
import {useState} from "react";	//remove credits

// register the filepond plugins for additional functionality
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImageResize);

// make a reference to our firebase storage
const storage = firebase.storage().ref();

/**
 * carica uno o molteplici file su firebase e li mostra, se eliminati verranno eliminati anche da firebase
 * @param onRequestSave
 * @param onRequestStart
 * @param onRequestEnd
 * @param folder
 * @param prefix
 * @param acceptedFileTypes
 * @param defaultFiles
 * @param label
 * @param allowMultiple
 * @param maxFiles
 * @param showLoadedImages se settato mostra le immagini caricate nel container, se settato a false ignora la visualizzazione di ogni nuovo file caricato
 * @param allowFileSizeValidation
 * @param maxFileSize
 * @param labelMaxFileSizeExceeded Status message shown when large file is dropped.
 * @param labelMaxFileSize Detail message shown when max file size was exceeded. {filesize} is replaced with the value of the maxFileSize property.
 * @param allowImageResize
 * @param imageResizeTargetWidth
 * @param imageResizeTargetHeight
 * @returns {JSX.Element}
 * @constructor
 */
export default function FileInput({
                                      onRequestSave = () => {
                                      },
                                      onRequestStart = () => {
                                      },
                                      onRequestEnd = () => {
                                      },
                                      folder = "images",
                                      prefix = "",
                                      acceptedFileTypes = ['image/*'],
                                      defaultFiles = [],
                                      label = i18next.t('dragNewFile'),
                                      allowMultiple = false,
                                      maxFiles = 1,
                                      showLoadedImages = true,
                                      allowFileSizeValidation = false,
                                      maxFileSize,
                                      labelMaxFileSizeExceeded,
                                      labelMaxFileSize,
                                      allowImageResize = false,
                                      imageResizeTargetWidth,
                                      imageResizeTargetHeight,
                                  }) {

    const {t} = useTranslation();
    const translation = {
        labelIdle: label + " " + t('or') + " " + "<span class='filepond--label-action'>" + t("browse") + "</span>",
        labelInvalidField: t('invalidFile'),
        labelFileWaitingForSize: t('waitingForSize'),
        labelFileSizeNotAvailable: t('sizeNotAvailable'),
        labelFileCountSingular: 'file in list',
        labelFileCountPlural: 'files in list',
        labelFileLoading: t('loading'),
        labelFileAdded: t('added'),
        labelFileLoadError: t('loadError'),
        labelFileRemoved: t('removed'),
        labelFileRemoveError: t('removeError'),
        labelFileProcessing: t('loading'),
        labelFileProcessingComplete: t('loadingComplete'),
        labelFileProcessingAborted: t('loadingAborted'),
        labelFileProcessingError: t('loadingError'),
        labelFileProcessingRevertError: t('loadingRevertError'),
        labelTapToCancel: t('tapToCancel'),
        labelTapToRetry: t('tapToRetry'),
        labelTapToUndo: t('tapToUndo'),
        labelButtonRemoveItem: t('remove'),
        labelButtonAbortItemLoad: t('abort'),
        labelButtonRetryItemLoad: t('retry'),
        labelButtonAbortItemProcessing: t('abort'),
        labelButtonUndoItemProcessing: t('abort'),
        labelButtonRetryItemProcessing: t('retry'),
        labelButtonProcessItem: t('load')
    };

    // use a useState hook to maintain our files collection
    const [files, setFiles] = React.useState(defaultFiles);
    const [loading, setLoading] = useState(false);

    const requestSave = (...props) => {
            setLoading(false);
            onRequestSave(...props);
        },
        requestStart = (...props) => {
            setLoading(true);
            onRequestStart(...props)
        },
        requestEnd = (...props) => {
            setLoading(false);
            onRequestEnd(...props);
        };

    const server = {
        // this uploads the image using firebase
        process: (fieldName, file, metadata, load, error, progress, abort) => {
            // create a unique id for the file
            const path = `${folder}/${prefix}/${new Date().getTime()}`;
            // upload the image to firebase
            const task = storage.child(path).put(file);
            // monitor the task to provide updates to FilePond
            task.on(
                firebase.storage.TaskEvent.STATE_CHANGED,
                snap => {
                    // provide progress updates
                    progress(true, snap.bytesTransferred, snap.totalBytes)
                },
                err => {
                    // provide errors
                    requestEnd && requestEnd();
                    error(err.message)
                },
                () => {
                    requestEnd && requestEnd();
                    // the file has been uploaded
                    load(path);
                    storage.child(path).getDownloadURL().then(url => {
                        if (!showLoadedImages) {//elimino la lista di file per non mostrarle nel container
                            setFiles([]);
                        }
                        requestSave && requestSave(url);
                    })
                }
            )
        },
        // this loads an already uploaded image to firebase
        load: (source, load, error, progress, abort) => {
            // reset our progress
            progress(true, 0, 1024);
            // fetch the download URL from firebase
            storage
                .child(source)
                .getDownloadURL()
                .then(url => {
                    // fetch the actual image using the download URL
                    // and provide the blob to FilePond using the load callback
                    let xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = function (event) {
                        let blob = xhr.response;
                        load(blob)
                    };
                    xhr.open('GET', url);
                    xhr.send()
                })
                .catch(err => {
                    requestEnd && requestEnd();
                    error(err.message);
                    abort()
                })
        },

        revert: (uniqueFileId, load) => {
            storage.child(uniqueFileId).delete().then(null)
            load()
            requestSave && requestSave(null);
        }
    };

    return (
        <Box
            sx={allowMultiple && loading && {
                '& .filepond--root .filepond--drop-label, .filepond--root .filepond--drop-label label ': {
                    height: '2em !important',
                    minHeight: '2em !important'
                }
            }}>
            <FilePond
                files={files}
                acceptedFileTypes={acceptedFileTypes}
                allowMultiple={allowMultiple}
                maxFiles={maxFiles}
                onupdatefiles={fileItems => {
                    setFiles(fileItems.map(fileItem => fileItem.file))
                }}
                server={server}
                {...translation}
                onaddfilestart={requestStart}
                allowFileSizeValidation={allowFileSizeValidation}
                maxFileSize={maxFileSize}
                labelMaxFileSizeExceeded={labelMaxFileSizeExceeded}
                labelMaxFileSize={labelMaxFileSize}
                allowImageResize={allowImageResize}
                imageResizeTargetWidth={imageResizeTargetWidth}
                imageResizeTargetHeight={imageResizeTargetHeight}
                imageResizeUpscale={false}
            />
        </Box>
    )
}