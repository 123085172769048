import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import NTMXGrid from "../../components/NTMXGrid";
import { useReportType } from "../../constants/publicUtilityRecord";
import StatusIndicator from "../../components/Statusindicator";
import NTMButton from "../../components/NTMButton";
import dayjs from "dayjs";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import Link from "@mui/material/Link";
import HoverableButton from "components/layout/HoverableButton";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    }
}));

export default function ReportList({ reports, onReportClick, disabled }) {
    const { t } = useTranslation();
    const reportType = useReportType();
    const [isShowingReports, setIsShowingReports] = useState(false)
    
    const classes = useStyles();

    const format = (row, field, timezone) => {
        if (!row) return;
        let value = row[field], template = "DD/MM/YYYY HH:mm:ss";
        if (!value) return "";
        else if (!timezone) return dayjs(new Date(value)).format(template);
        else return dayjs.tz(new Date(value), timezone).format(template) + " (" + timezone + ")";
    }

    const col = [
        {
            field: 'createdBy',
            headerName: t('userID'),
            width: 280,
            valueGetter: ({ row, field }) => row[field],
        }, {
            field: 'username',
            headerName: t('username'),
            renderCell: (params) =>
                <Link href={"/profile/" + params.row.createdBy} target="_blank" underline='none'>
                    <HoverableButton
                        item={{
                            icon: <InfoIcon />,
                            name: params.row.username
                        }}
                        classes={{ root: classes.root }}
                    />
                </Link>,
            width: 200,
            // valueGetter: ({value}) => (get(USERS, {elem: value}).than((user) => user.username ))
        }, {
            field: 'email',
            headerName: t('email'),
            valueGetter: ({ row, field }) => row[field],
            width: 200,
        }, {
            field: 'createdAt',
            headerName: t('creationDate'),
            width: 200,
            valueGetter: ({ row, field }) => format(row, field),
        }, {
            field: 'notes',
            headerName: t('notes'),
            width: 200,
        }, {
            field: 'type',
            headerName: t('type'),
            valueGetter: ({ value }) => reportType[value],
            width: 200,
        },
        {
            field: 'l',
            headerName: t('position'),
            width: 250,
            valueGetter: ({ value }) => `${value[0]}-${value[1]}`
        },
        {
            field: 'deleted',
            headerName: t('status'),
            valueGetter: ({ value }) => (!value ? t('active') : t('notActive')),
            renderCell: ({ row }) => <StatusIndicator color={'primary'} isActive={!row.deleted} />,
            width: 200,
        },
        {
            headerName: t('details'),
            field: "details",
            width: 90,
            sortable: false,
            disableColumnMenu: true,
            resizable: false,
            renderCell: params => <IconButton onClick={() => onReportClick(params.row)}><Visibility /></IconButton>
        }
    ]

    return <Box sx={{
        '& .MuiDataGrid-root': {
            opacity: isShowingReports ? 1 : 0.5
        }
    }}
    >
        <Box sx={{ textAlign: 'center' }}>
            <NTMButton
                /* sx={{ position: 'absolute', zIndex: 100, marginTop: 40, marginLeft: 'calc(50% - 6em - 46px - 20px - 2px)' }} */
                startIcon={isShowingReports ? <Visibility /> : <VisibilityOff />}
                title={isShowingReports ? t('hide') : t('show')}
                onClick={() => setIsShowingReports(isShowingReports => !isShowingReports)} outlinedVariant disabled={disabled}
                sx={{ width: 'calc(8em + 20px)', justifyContent: 'flex-start' }}
            ></NTMButton>
        </Box>
        <NTMXGrid
            key={"report-table"}
            columns={col}
            rows={isShowingReports ? reports : []}
            getRowId={(row) => reports && row.key}
        />

    </Box>
}